.InnerHandpicked1BannerImageClass1{
    width:100%;
    /* height: 15%; */
    height:600px;
    /* overflow:hidden; */
}
.InnerHandpicked1BannerHeadingSliderInfo h3{
    text-align: left;
    margin-left: 4%;
}
.InnerHandpicked1BannerMainContainer{
    overflow:hidden;
    /* border: 2px solid red; */
}
.InnerHandpicked1BannerHeading{
    position:absolute;
    top:22%;
    font-size: 36px;
    font-weight: 700;
    color:#ffffff;
    margin-left:4%;
    /* border: 2px solid red; */
}

.InnerHandpicked1BannerModificationOfPara{
    position: absolute;
    top:30%;
    font-size:24px;
    margin-left:4%;
    color:#ffffff;
    font-weight: 400;

}

.InnerHandpicked1BannerSubHeading{
    position: absolute;
    top: 50%;
    color:#ffffff;
    margin-left:2%;
}
