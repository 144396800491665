.travelguideBannerHeading {
    font-weight: bold;
    margin-left: 32%;
    margin-top: 8%;
    color: white;
    font-weight: 500;
    font-size: 30px;
    font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    /* text-decoration: underline; */
  
  }
  
  .vaccy1 {
    /* background-color: transparent; */
    color: white;
    /* width: auto; */
    margin-left: 35%;
    /* margin-right: 45%; */
    /* padding-left: 1.5%; */
    font-weight: 400;
    font-size: 18px;
    /* height: 45px; */
    /* padding-top: 1%; */
    /* border-radius: 5px; */
  }
  
  