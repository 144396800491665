

.partners_title {
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(154, 148, 148);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: #3380f2;
}

._maincarouseldiv{
  /* border: 1px solid red; */
  width: 99%;
}

._carouseldiv1 {
  position: relative;
  margin-bottom: 10%;
}
._imagediv {
  height: 100%;
  width: 112%;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
._hoverdiv {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 85%;
  background-color: yellow;
  visibility: hidden;
}
._carouseldiv1 > div:hover {
  visibility: visible;
}
._staticdiv {
  border: 1px solid darkblue;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 14.3%;
  background-color: #203e50;
  text-align: center;
  box-sizing: border-box;
  font-size: larger;
  padding-top: 4%;
}
