/* .navbar-container {
    height: 80px;
    z-index: 1;
    position: fixed;
    width: 80%;
  }
  .navbar-fixed {
    background: rgb(51,128,242);
    position: fixed;
    transition: all 1s ease;
    width: 100%;
  } */
  .hotelnavbar_fixed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    z-index: 100;
    position: fixed;
    height: 80px;
    background-color: #3380f2;
  }
  .menu-links {
    display: flex;
    flex-direction: row;
    color: white;
    text-align: center;
    margin-top: 2%;
    line-height: 15px;
  }
  
  .fixed-menu-link {
    height: 40px;
    width: 50px;
    border-radius: 7px;
    color: #fff;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    margin-right: 12px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }
  /* .transparentNavbar{
    display: flex;
    width: 85%;
    height: 70px;
    margin-left: 15%;
  } */
  
  .hotelnavbarmoredropdowncontent{
    display: none;
    position: absolute;
    color: white;
    background-color: #3380f2;
    width: 200px;
    height: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    margin-top: 14%;
    font-size: 17px;
  }
  #moreoptions:hover .hotelnavbarmoredropdowncontent{
     display: block;
  }
  .hotelnavbarmoreOptions:hover {
    background-color: white;
    color: #3380f2;
  }
  .hotelnavbarmoreOptions{
  margin-top: 5%;
  padding: 5%;
  color: white;
  text-decoration: none;
  /* border: 2px solid red; */
  }
 .hotelnavbarcontacticon{
   margin-left: 1%;
   margin-right: 5%;
   /* border: 2px solid red; */
 }
 .hotelnavbarmiceicon{
  margin-left: -18%;
  margin-right: 5%;
  /* border: 2px solid red; */
}
.hotelnavbarofferIcon{
  margin-left: -14%;
  margin-right: 5%;
  /* border: 2px solid red; */
}
.hotelnavbarinspireIcon{
  margin-left: -14%;
  margin-right: 5%;
  /* border: 2px solid red; */
}
/* .logo2 {
    height: 50px;
    width: 150px;
    margin-top: 1%;
    margin-left: -15%;
    display: flex;
  }
  .logo2 img {
    height: 50px;
    width: 150px;
  } */
  /* .logo1 {
    cursor: pointer;
    height: 50px;
    width: 150px;
    margin: 9% 0 0% 50%;
  } */
  /* .logo1 img {
    height: 90px;
    width: 150px;
  } */
  /* .navbar {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 70px;
  } */
  #navbarLinksButtons {
    margin-top: 5% 0 5% 0;
  }
  .navbar-links-container {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: row;
    margin-right: 2%;
    color: white;
    font-size: 13px;
    margin-top: 1%;
  }
  /* .navbar-links-container1 {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: row;
    margin-right: 2%;
    color: white;
    font-size: 13px;
    margin-top: 1%;
    margin-left: 97%;
  } */
  
  .nav-link {
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    border: 2px solid white;
    color: white;
    margin-top: 0;
  }
  
  #hotellogin-register{
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    border: 2px solid white;
    color: white !important;
    margin-top: 0;
    text-decoration: none !important;
  }
  /* #hotellogin-register:hover{
    text-decoration: none !important;
    color: #3380f2 !important;
    cursor: pointer;
    background-color: white !important;
  } */
  .nav-link p {
    margin-top: 6px;
    font-weight: 700;
  }
  #hotellogin-register p{
    margin-top: 6px;
    font-weight: 700;
  }
  .nav-link:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    border:3px solid #3380f2;
    color: #3380f2 !important;
  }
  
  .hotellogin-register {
    height: 36px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
    border: 2px solid white;
    background-color: #3380f2;
  }
  /* .hotellogin-register:hover {
    background-color: white;
    color: #3380f2;
  } */
  
  
  .hotellogin-register:hover .LoginRegisterDropDown {
    display: block;
  }
  /* .topbanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 10px 10px;
    background: #fff;
    border-radius: 7px;
    position: relative;
    z-index: 0;
    height: 240px;
    margin: -10px 60px;
  } */
  
  /* .topbanner-service {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding: 0 15px 15px;
    width: 98%;
  } */
  
  .nav-link:hover .chooseCountry {
    display: block;
  }
  
  
  /* .chooseCountry {
    width: 200px;
    height: 220px;
    position: absolute;
    z-index: 1;
    margin-top: -0.5%;
    background-color: #3380f2;
    border-radius: 5px;
    display: none;
    font-weight: 700;
    color: white;
  } */
  .countrynames{
    margin-top: 4%;
    margin-left: 2%;
    padding: 3%;
    background-color: #3380f2;
    color: white;
    
  }
  .countrynames:hover{
    background-color: white;
    color: #3380f2;
  }
  .flags {
    margin-left: 20%;
    width: 20px;
    height: 12px;
  }
  /* .partLeft {
    width: 50%;
    height: 100%;
    border-right: 3px solid #3380f2;
    color: #3380f2;
  }
  .partRight {
    width: 50%;
    height: 100%;
    color: #3380f2;
  } */
  /* .chooseCountry ul li {
    list-style: none;
    margin-top: 15px;
  }
  .chooseCountry ul li img {
    width: 20px;
    height: 12px;
  }
  .chooseCountry ul li:hover {
    background-color: #3380f2;
    color: white;
  }
  .chooseCurrrency {
    width: 350px;
    height: 200px;
    border: 3px solid #3380f2;
    position: fixed;
    z-index: 2;
    margin-top: 16%;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    display: flex;
    line-height: 20px;
  }
  .chooseCurrrency ul li {
    list-style: none;
    margin-top: 15px;
  }
  .chooseCurrrency ul li img {
    width: 40px;
    height: 24px;
    margin: auto;
  }
  .partone {
    width: 33%;
    height: 100%;
    border-right: 3px solid #3380f2;
  }
  .parttwo {
    width: 33%;
    height: 100%;
    border-right: 3px solid #3380f2;
  }
  .partthree {
    width: 33%;
    height: 100%;
  }
  .chooseCurrrency ul li:hover {
    background-color: #3380f2;
    color: white;
  } */
  /* .NavbarMoreOption {
    width: 200px;
    height: 150px;
    position: fixed;
    z-index: 1;
    margin-top: 4%;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    margin-left: 30%;
    color: white;
    background-color:#3380f2;
  }
  .navbarOptions {
    height: 25%;
    text-align: left;
  }
  .contactIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .miceIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .inspireIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .offersIcon {
    margin-left: 30px;
    margin-right: 10px;
  } */
  .LoginRegisterDropDown {
    width: 200px;
    height: 220px;
    position: absolute;
    display: none;
    z-index: 1;
    margin-top: 0%;
    right: 0;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    font-size: 15px;
    color: white;
    background-color:#3380f2;
  }
  .LoginRegisterOptions {
    margin-top: 2%;
    height: 18%;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
  }
  .LoginRegisterOptions:hover{
    background-color: white;
    color: #3380f2;
  }
  .LoginRegisterOptions1 {
    margin-top: 2%;
    height: 18%;
    text-align: center;
    font-weight: 200;
    margin-left: 2%;
  }
  .LoginRegisterDropDown1 {
    width: 200px;
    height: 220px;
    position: absolute;
    display: none;
    z-index: 1;
    margin-top: 0%;
    left:0;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    font-size: 15px;
    color: white;
    background-color:#3380f2;
  }
  .LoginRegisterOptions1:hover{
    background-color: white;
    color: #3380f2;
  }
  .LoginIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .RegisterIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .BlogsIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .PaymentIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .BookingIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  #hide {
    margin-bottom: 20px;
  }
  .travelflapmainlogo{
    color: white;
    margin-top: 20px;
    position: fixed;
    z-index:1 ;
    display: none;
  }
  .checkmark{
    border:"1px solid";
    height: 20px;
    width: 100%;
  }
  @media screen and (max-width: 950px) {
    .navbar-container{
        width: 100%;
    }
    .transparentNavbar{
      width: 100%;
      margin: auto;
    }
    .hotelnavbar_fixed {
      width: 100%;
      font-size: 20px;
    }
    
    .navbar-links-container1{
        margin: auto;
    }
    .navbar-links-container{
      display: none;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -20%;
    }
    .LoginRegisterDropDown
    {
      margin-top: 30%;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
  }
  
  @media screen and (max-width: 780px) {
    .navbar-container{
        width: 85%;
        margin: auto;
    }
    .logo2{
      display: none;
    }
    .hotelnavbar_fixed {
      width: 100%;
      font-size: 20px;
    }
    .navbar-links-container1{
        margin-left:25%;
    }
    .navbar-links-container{
      display: none;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -25%;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
  }
  /* @media screen and (max-width: 450px) {
    .navbar-container{
        width: 25%;
        margin: auto;
    }
    .logo2{
      display: none;
    }
    .hotelnavbar_fixed {
      width: 100%;
      font-size: 20px;
      border: 2px solid;
      display: none;
    }
    .transparentNavbar{
      display: none;
    }
    .navbar-links-container1{
      display: none;
    }
    .navbar-links-container1{
      margin-left: -35px;
    }
    .travelflapmainlogo{
      display: flex;
      flex-direction: row;
      width: 85%;
    }
    .hamburgermenuicon{
      margin-left: 30%;
      margin-top: 2%;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -25%;
      border: 1px solid;
    }
    .fixed-menu-link{
      border: 1px solid ;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
    .hotellogin-register{
      font-size: 10px;
      font-weight: 100;
      width: 80px;
    }
    .Menuoptions{
      width: 80px;
      height: 320px;
      position: fixed;
      z-index: 1;
      transition: all 1s ease;
      margin-top: 18%;
      background-color: #3380f2;
      border-radius: 5px;
      margin-left: 38%;
      color: white;
    }
    .Menudropdowns div{
      margin-top: 5px;
    }
    .Menuoptions:hover{
      background-color: white;
      color: #3380f2;
    }
    .Menudropdownsoptions{
      padding: 8px;
    }
    .Menudropdownsoptions:hover{
      background-color: #3380f2;
      color: white;
    }
  } */
  
  