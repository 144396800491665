.hotelBookingMultiImageOuterDiv{
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    margin-bottom: 3%;
    margin-top: 2%;
}

.hotelBookingMultiImageMainDiv{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.hotelBookingMultiImageInnerDiv1{
    /* border: 1px solid black; */
    width: 47%;
    margin-right: 1%;
    /* margin-left: 2%; */
}


.hotelBookingMultiImageInnerDiv1 img{
    height: 375px;
    width: 100%;
    object-fit: cover;
}

.hotelBookingMultiImageMiddleMainDiv{
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    width: 47%;
    /* margin-left: 2%; */
}

.hotelBookingMultiImageInnerDiv2{
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
}

.hotelBookingMultiImageSubDiv1{
    /* border: 1px solid purple; */
    width: 48%;
    margin-right: 2%;
    height: 180px;
  
}

.hotelBookingMultiImageSubDiv1 img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}

.hotelBookingMultiImageSubDiv2{
    /* border: 1px solid greenyellow; */
    width: 50%;
    height: 180px;
}

.hotelBookingMultiImageSubDiv2 img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}


.hotelBookingMultiImageInnerDiv3{
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row;
}

.hotelBookingMultiImageSubDiv3{
    /* border: 1px solid black; */
    width: 48%;
    margin-right: 2%;
    height: 180px;
  
}

.hotelBookingMultiImageSubDiv3 img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}

.hotelBookingMultiImageSubDiv2{
    /* border: 1px solid greenyellow; */
    width: 50%;
    height: 180px;
}

.hotelBookingMultiImageSubDiv4{
    /* border: 1px solid greenyellow; */
    width: 50%;
    height: 180px;
}

.hotelBookingMultiImageSubDiv4 img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}






