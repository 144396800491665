.hotelBookingPloiciesMainContainer {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 1%;
}

.hotelBookingPoliciesOuterHeading {
  /* border: 1px solid black; */
  font-size: 28px;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  line-height: 20px;
  vertical-align: baseline;
  margin-top: 3%;
}

.hotelBookingPoliciesInnerContainer {
  /* border: 1px solid gray; */
  margin-top: 2%;
  border-radius: 5px;
}

.hotelBookingPoliciesTopContextDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
  margin-top: 1%;
}

/* .hotelBookingPolicyContextSubDiv{
    border: 1px solid black;
} */

.hotelBookingPolicyContextSubDivChild1 {
  margin-top: -1%;
}

.hotelBookingPoliciesCardsMainDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
  display: flex;
  flex-direction: row;
}

.hotelBookingPoliciesCards {
  /* border: 1px solid red; */
  margin-right: 2%;
  background-color: RGBA(0, 0, 0, 0);
}

.hotelBookingPoliciesInnerContainer1 {
  /* border: 1px solid red; */
  margin-top: 5%;
  margin-left: 1%;
}

.hotelBookingPoliciesInnerContainer1 h2 {
  font-size: 25px;
  vertical-align: baseline;
  font-weight: 500;
}

.hotelBookingPoliciesHelpfulFactsDiv {
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.hotelPoliciesFactInnerDiv1 {
  /* border: 1px solid black; */
  width: 50%;
  margin-top: 1%;
  /* height: 100px; */
}

.hotelPoliciesFactInnerDiv1 h5 {
  font-size: 22px;
  color: #393939 !important;
  font-weight: 500;
  vertical-align: baseline;
  line-height: 20px;
  margin-bottom: 2%;
}

.hotelBookingFactSubDiv {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.hotelBookingFactSubDivDetails1 {
  /* border: 1px solid black; */
  margin-left: 1%;
  width: 50%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelBookingFactSubDivDetails1 p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelBookingFactSubDivDetails2 {
  /* border: 1px solid purple; */
  width: 50%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelBookingFactSubDivDetails2 p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelPoliciesFactInnerDiv2 {
  /* border: 1px solid green; */
  width: 50%;
  margin-top: 1%;
  /* height: 100px; */
}

.hotelPoliciesFactInnerDiv2 h5 {
  font-size: 22px;
  color: #393939 !important;
  font-weight: 500;
  vertical-align: baseline;
  line-height: 20px;
  margin-bottom: 2%;
}

.hotelPoliciesPropertyInnerDiv {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}

.hotelBookingPropertySubDetailsDiv1 {
  /* border: 1px solid red; */
  width: 60%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelBookingPropertySubDetailsDiv1 p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelBookingPropertySubDetailsDiv2 {
  /* border: 1px solid black; */
  width: 40%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelBookingPropertySubDetailsDiv1 h5 {
  font-size: 18px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelBookingPropertyInfoDiv3 {
  /* border: 1px solid red; */
  margin-top: -5%;
}

.hotelBookingPropertyInfoDiv3 h5 {
  font-size: 22px;
  color: #393939 !important;
  font-weight: 500;
  vertical-align: baseline;
  line-height: 20px;
  margin-bottom: 2%;
}

.hotelBookingPropertyInfoInnerDiv {
  /* border: 1px solid black; */
  width: 50%;
  display: flex;
  flex-direction: row;
}

.hotelPropertyInfoSubDiv1 {
  /* border: 1px solid rebeccapurple; */
  width: 50%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelPropertyInfoSubDiv1 p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelPropertyInfoSubDiv2 {
  /* border: 1px solid green; */
  width: 50%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.hotelPropertyIndoSubDiv2 h5 {
  font-size: 18px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelBoookingPropertyOtherDiv {
  /* border: 1px solid red; */
  width: 90%;
  margin-top: 2%;
}

.hotelBoookingPropertyOtherDiv h5 {
  font-size: 22px;
  color: #393939 !important;
  font-weight: 500;
  vertical-align: baseline;
  line-height: 20px;
  margin-bottom: 2%;
}

.hotelBoookingPropertyOtherDiv p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
  line-height: 20px;
  vertical-align: baseline;
}

.hotelBookingPropertOthrSubDiv {
  /* border: 1px solid black; */
  margin-left: 2%;
}

.hotelBookingPropertOthrSubDiv ul.h5 {
  list-style-type: disc;
}
