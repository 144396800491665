.handpickedHillStationsdestinationContainer{
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    height: auto;
    margin-top: 2%;
  }
  
  .handpickedHillStationsdestinationHeading{
    height: 40px;
    font-weight: 700;
    margin: auto;
    margin-top:1% !important;
    margin-left: 1%;
}
.handpickedHillStationsdestinationHeading h2{
  font-weight: 700;
}

  .carousel .slick-dots {
    bottom: -2.5vw;
  }
  
  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  
  
  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  
  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }
  
  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }
  
  .carousel .slick-next {
    right: -55px;
  }
  
  .carousel .slick-prev {
    left: -55px;
  }
  
  .carousel .slick-dots {
    bottom: -2.5vw;
  }
  
  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  
  
  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  
  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }
  
  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }
  
  .carousel .slick-next {
    right: -55px;
  }
  
  .carousel .slick-prev {
    left: -55px;
  }
    
  
  .handPickedHillStationsDestinationwrapper {
    position: relative;
    width: 106%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 15%;
  }
  .newMultisection_handPickedHillStationsDestinationimage {
  margin-left: 1%;
  width: 95%;
  height: 350px;
  /* border: 2px solid red; */
  border-radius: 20px !important;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  }
  .newMultisection_handPickedHillStationsDestinationimage:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .newMultisection_handPickedHillStationsDestinationimage:hover .handPickedHillStationsDestinationimagecardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .handPickedHillStationsDestinationimagecardImage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    border-radius: 20px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .handPickedHillStationsDestinationimagecardImage img {
    width: 100%;
    filter: brightness(0.8);
    -o-object-fit: cover;
    object-fit: cover;
  }
  .newMultisection_handPickedHillStationsDestinationimage:hover .handPickedHillStationsDestinationimagecardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .handPickedHillStationsDestinationdetails {
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    line-height: 15px;
  }
  
  .handPickedHillStationsDestinationdetails h5 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }
  
  .handPickedHillStationsDestinationdetails h5 .job-title {
    font-size: 13.5px;
    line-height: 1.5;
    color: white;
    font-weight: 300;
    display: block;
  }
  
  