.cta_area {
  /* border: 1px solid black; */
  padding: 25px 0;
  /* margin-top: 2%; */
  background: #f6f6f6;
}

.cta_left {
  display: flex;
  align-items: center;
}

.cta_content {
  /* border: 1px solid red !important; */
  color: #fff;
  margin-bottom: 25px;
}

.cta_content h3 {
  /* border: 1px solid red; */
  font-size: 19px;
  font-weight: 700;
  line-height: 1.5;
  color: #3380f2;
}

.cta_content h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

#newemail {
  border: 3px solid #3380f2;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.btn_theme1 {
  color: white !important;
  background-color: rgb(51, 128, 242) !important;
}
