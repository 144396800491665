.Cruise_carouselmain {
  height: 750px !important;
  box-sizing: border-box;
  /* border: 3px solid red; */
}

.rentalsTopBannerdiv {
  height: 750px;
  /* border: 2px solid red; */
}

.rentalsTopBannerdiv img {
  height: 750px;
  filter: brightness(0.4) !important;
}

.rental_bannertitle {
  /* position: absolute; */
  background: transparent !important;
}

.rental_bannertitle p {
  position: absolute;
  top: 12%;
  left: 34%;
  color: whitesmoke;
  font-size: 40px;
  font-family: sans-serif;
}

.rental_bannertitle span {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 56px;
  padding-left: 5px;
  font-style: italic !important;
}

.carousel-caption > .carouselbtn {
  /* border: 1px solid red !important; */
  font-size: 20px;
  font-weight: 600;
  border-radius: 25px;
  padding: 11px 0px;
  width: 7%;
  margin: auto;
  color: white;
  border: none;
  text-align: center;
  margin-top: 15px;
  background-color: #3408f3;
}

.carouselbtn:hover {
  background: #3153f2;
}

/* css for the banner slider buttons */
/* .carousel-control-prev-icon {
  color: white;
  border: 2px solid red !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.carousel-control-prev {
  width: 7% !important;
}

.carousel-control-next {
  width: 7% !important;
}

.carousel-control-next-icon {
  color: white;
  border: 2px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.carousel-control-prev-icon {
  width: 24px;
  height: 24px;
}

.carousel-control-next-icon {
  width: 24px;
  height: 24px;
} */

.cruise_mycarousel h1 {
  /* border: 1px solid red; */
  margin-bottom: 7 5%;
  font-size: 112px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 134.4px;
  font-weight: 900;
}

.cruise_mycarousel h2 {
  /* border: 1px solid blue; */
  font-size: 100px;
  line-height: 120px;
  font-weight: 500;
  vertical-align: baseline;
  font-style: italic;
}
#cruise_mainsection {
  position: relative;
}
/* .carousel-indicators {
    width: 8%;
    margin-left: 91% !important;
  } */

.carousel-control-prev {
  display: none !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-indicators button {
  display: none;
}
.carousel-caption {
  margin-bottom: 14%;
}

.cruise_mainsection {
  /* background-color: red; */
  height: 10000;
  z-index: 100000000000;
}

/* Css for services section */
#cruise_mainsection {
  position: relative;
}

/* Css for services section */
#cruise_mainsection {
  position: relative;
}

.formservices {
  width: 72%;
  /* top: 3%; */
  bottom: 77%;
  margin: auto;
  display: flex;
  height: 75px;
  justify-content: center;
  position: absolute;
  left: 14%;
  /* z-index: 1; */
  /* gap: 1.5%; */
}

.formservices:hover div {
  color: white;
}

.formservices div {
  /* border: 1px solid red; */
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  z-index: 2;
}

.formservices div:hover * {
  background: #3380f2;
}

.serviceicons {
  font-size: 20px;
}

.formservices p {
  color: black;
  margin-top: 10%;
  line-height: 21px;
  vertical-align: baseline;
  font-weight: 600;
  text-transform: uppercase;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.formservices p:hover {
  color: white;
}

.formservices .icons:hover {
  color: blue;
}

.formservices div:hover {
  background-color: #3380f2;
}

.formservices div img {
  height: 23px;
  width: 33px;
  margin-bottom: 16px;
  filter: invert(1.6);
}

.formservices div:nth-child(1) {
  background-color: white;
  border-top-left-radius: 105px;
  width: 12%;
}

.formservices div:nth-child(2) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(3) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(4) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(5) {
  background-color: white;
  width: 12%;
}

.formservices div:nth-child(6) {
  background-color: white;
  width: 13%;
}

.formservices div:nth-child(7) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(8) {
  background-color: white;
  border-top-right-radius: 105px;
  width: 10%;
  gap: 3%;
}

/* Hover property for the services section */

.formservices div:nth-child(1):hover {
  background-color: #3380f2;
  color: white;
}

.formservices div:nth-child(2):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(3):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(4):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(5):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(6):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(7):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(8):hover {
  background-color: #3380f2;
}
@media only screen and (min-width: 300px) and (max-width: 980px) {
  .formservices {
    flex-direction: column;
    /* position: static; */
    display: none;
  }

  #cruise_mainsection {
    position: static;
  }
}

/* This css part is for the devesh carousel form section */

/* This css is for the travel form details */
.main-trevform {
  position: relative;
}

.text-info {
  /* color: RGB(255, 255, 255);/ */
  color: azure;
  font-size: 23px;
  font-family: "Open Sans", sans-serif;
}

.trevFormdiv {
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  bottom: 74%;
}

.forminput_value {
  margin-bottom: 8%;
  color: #3153f2;
}

.forminput_value .form-label {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.trevformrow {
  position: absolute;
  width: 75%;
  top: 10%;
  background-color: rgb(51, 128, 242, 0.7);
  right: 13%;
  border-radius: 33px;
  height: 285px;
}
.trevButton {
  background-color: #3153f2;
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: white;
  border-radius: 25px;
  padding: 9px 31px;
  width: 150%;
}
.trevButton:hover {
  background: blue;
}
.main-main {
  width: 100%;
  height: 700px;
  /* background-color: green; */
  position: relative;
}
.newhomebanner_main {
  height: 700px;
  box-sizing: border-box;
  /* border: 3px solid red; */
}

.apnatext {
  width: 70%;
  height: 100px;
  z-index: 11;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 75%;
  left: 50%;
}
.apnatext > p {
  color: whitesmoke;
  font-size: 40px;
  font-family: sans-serif;
}
.apnatext span {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 56px;
  padding-left: 5px;
  padding-right: 5px;
  color: #3153f2;
}
.newhomebannerimage img {
  object-fit: cover;
  height: 740px;
}

.newhomebannervideo {
  width: 100%;
}

.carousel-caption > .carouselbtn {
  /* border: 1px solid red !important; */
  font-size: 20px;
  font-weight: 600;
  border-radius: 25px;
  padding: 11px 0px;
  width: 7%;
  margin: auto;
  color: white;
  border: none;
  text-align: center;
  margin-top: 15px;
  background-color: #3408f3;
}

.carouselbtn:hover {
  background: #3153f2;
}

.carousel-control-prev-icon {
  /* border: 1px solid red !important; */
  color: white;

  border: 2px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.carousel-control-prev {
  width: 7% !important;
}

.carousel-control-next {
  width: 7% !important;
}

.carousel-control-next-icon {
  color: white;
  border: 2px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.newhomebanner_mycarousel h1 {
  /* border: 1px solid red; */
  margin-bottom: 7 5%;
  font-size: 112px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 134.4px;
  font-weight: 900;
}

.carousel-control-prev-icon {
  width: 24px;
  height: 24px;
}

.carousel-control-next-icon {
  width: 24px;
  height: 24px;
}

.newhomebanner_mycarousel h2 {
  /* border: 1px solid blue; */
  font-size: 100px;
  line-height: 120px;
  font-weight: 500;
  vertical-align: baseline;
  font-style: italic;
}
#newhomebanner_mainsection {
  position: relative;
}
/* .carousel-indicators {
      width: 8%;
      margin-left: 91% !important;
    } */

.carousel-control-prev {
  display: none;
}

.carousel-control-next {
  display: none;
}
.carousel-indicators button {
  display: none;
}
.carousel-caption {
  margin-bottom: 14%;
}

.newActivityTopBannerdiv {
  height: 720px;
}

.newActivityTopBannerdiv img {
  filter: brightness(0.4) !important;
}

.newhomebanner_mainsection {
  /* background-color: red; */
  /* height: 10000; */
  z-index: 100000000000;
}

/* Css for services section */
#newhomebanner_mainsection {
  position: relative;
}

/* Css for services section */
#newhomebanner_mainsection {
  position: relative;
}

.formservices {
  width: 72%;
  /* top: 3%; */
  bottom: 77%;
  margin: auto;
  display: flex;
  height: 75px;
  justify-content: center;
  position: absolute;
  left: 14%;
  /* z-index: 1; */
  /* gap: 1.5%; */
}

.formservices:hover div {
  color: white;
}

/* .formservices:hover{
        background: #3380f2;
      }
       */
.formservices div {
  /* border: 1px solid red; */
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  z-index: 2;
}

.formservices div:hover * {
  background: #3380f2;
}
.serviceicons {
  font-size: 20px;
}

.formservices p {
  color: black;
  margin-top: 10%;
  line-height: 21px;
  vertical-align: baseline;
  font-weight: 600;
  text-transform: uppercase;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-size: 12px;
}

.formservices p:hover {
  color: white;
}
.formservices .icons:hover {
  color: blue;
}
.formservices div:hover {
  background-color: #3380f2;
}

.formservices div img {
  height: 23px;
  width: 33px;
  margin-bottom: 16px;
  filter: invert(1.6);
}

.formservices div:nth-child(1) {
  background-color: white;
  border-top-left-radius: 105px;
  width: 12%;
}

.formservices div:nth-child(2) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(3) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(4) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(5) {
  background-color: white;
  width: 12%;
}

.formservices div:nth-child(6) {
  background-color: white;
  width: 13%;
}

.formservices div:nth-child(7) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(8) {
  background-color: white;
  border-top-right-radius: 105px;
  width: 10%;
  gap: 3%;
}

/* Hover property for the services section */

.formservices div:nth-child(1):hover {
  background-color: #3380f2;
  color: white;
}

.formservices div:nth-child(2):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(3):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(4):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(5):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(6):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(7):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(8):hover {
  background-color: #3380f2;
}
@media only screen and (min-width: 300px) and (max-width: 980px) {
  .formservices {
    flex-direction: column;
    /* position: static; */
    display: none;
  }

  #newhomebanner_mainsection {
    position: static;
  }
}

/* This css part is for the devesh carousel form section */

/* This css is for the travel form details */
.main-trevform {
  position: relative;
}

.text-info {
  /* color: RGB(255, 255, 255);/ */
  color: azure;
  font-size: 23px;
  font-family: "Open Sans", sans-serif;
}

.trevFormdiv {
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  bottom: 74%;
}

.forminput_value {
  margin-bottom: 8%;
  color: #3153f2;
}

.forminput_value .form-label {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.trevformrow {
  position: absolute;
  width: 75%;
  top: 10%;
  background-color: rgb(51, 128, 242, 0.7);
  right: 13%;
  border-radius: 33px;
  height: 285px;
}
.trevButton {
  background-color: #3153f2;
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: white;
  border-radius: 25px;
  padding: 9px 31px;
  width: 150%;
}
.trevButton:hover {
  background: blue;
}
