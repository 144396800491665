.main-main {
  width: 100%;
  height: 756px;
  /* background-color: green; */
  position: relative;
}
.newStaycationbanner_main {
  height: 756px;
  box-sizing: border-box;
  /* border: 3px solid red; */
}

.newStaycationBannerText {
  width: 70%;
  height: 100px;
  z-index: 11;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 75%;
  left: 50%;
}
.newStaycationBannerText > p {
  color: whitesmoke;
  font-size: 40px;
  font-family: sans-serif;
}
.newStaycationBannerText span {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 56px;
  padding-left: 5px;
  padding-right: 5px;
  color: #3153f2;
}
.newhomebannerimage img {
  object-fit: cover;
  height: 740px;
}

.newhomebannervideo {
  width: 100%;
}

.carousel-caption > .carouselbtn {
  /* border: 1px solid red !important; */
  font-size: 20px;
  font-weight: 600;
  border-radius: 25px;
  padding: 11px 0px;
  width: 7%;
  margin: auto;
  color: white;
  border: none;
  text-align: center;
  margin-top: 15px;
  background-color: #3408f3;
}

.carouselbtn:hover {
  background: #3153f2;
}


.newhomebanner_mycarousel h1 {
  /* border: 1px solid red; */
  margin-bottom: 7 5%;
  font-size: 112px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 134.4px;
  font-weight: 900;
}


.newhomebanner_mycarousel h2 {
  /* border: 1px solid blue; */
  font-size: 100px;
  line-height: 120px;
  font-weight: 500;
  vertical-align: baseline;
  font-style: italic;
}

#newStaycationbanner_mainsection {
  position: relative;
  height: 756px !important;
}

.carousel-caption {
  margin-bottom: 14%;
}

.experimentTopBannerdiv {
  height: 757px;
}

.experimentTopBannerdiv img {
  height: 757px;
  filter: brightness(0.4) !important;
}

.newStaycationbanner_mainsection {
  /* background-color: red; */
  /* height: 10000; */
  z-index: 100000000000;
}

/* Css for services section */
#newStaycationbanner_mainsection {
  position: relative;
}

/* Css for services section */
#newStaycationbanner_mainsection {
  position: relative;
}

.formservices {
  width: 72%;
  /* top: 3%; */
  bottom: 77%;
  margin: auto;
  display: flex;
  height: 75px;
  justify-content: center;
  position: absolute;
  left: 14%;
  /* z-index: 1; */
  /* gap: 1.5%; */
}

.formservices:hover div {
  color: white;
}

/* .formservices:hover{
      background: #3380f2;
    }
     */
.formservices div {
  /* border: 1px solid red; */
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  z-index: 2;
}

.formservices div:hover * {
  background: #3380f2;
}
.serviceicons {
  font-size: 20px;
}

.formservices p {
  color: black;
  margin-top: 10%;
  line-height: 21px;
  vertical-align: baseline;
  font-weight: 600;
  text-transform: uppercase;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-size: 12px;
}

.formservices p:hover {
  color: white;
}
.formservices .icons:hover {
  color: blue;
}
.formservices div:hover {
  background-color: #3380f2;
}

.formservices div img {
  height: 23px;
  width: 33px;
  margin-bottom: 16px;
  filter: invert(1.6);
}

.formservices div:nth-child(1) {
  background-color: white;
  border-top-left-radius: 105px;
  width: 12%;
}

.formservices div:nth-child(2) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(3) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(4) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(5) {
  background-color: white;
  width: 12%;
}

.formservices div:nth-child(6) {
  background-color: white;
  width: 13%;
}

.formservices div:nth-child(7) {
  background-color: white;
  width: 10%;
}

.formservices div:nth-child(8) {
  background-color: white;
  border-top-right-radius: 105px;
  width: 10%;
  gap: 3%;
}

/* Hover property for the services section */

.formservices div:nth-child(1):hover {
  background-color: #3380f2;
  color: white;
}

.formservices div:nth-child(2):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(3):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(4):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(5):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(6):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(7):hover {
  background-color: #3380f2;
}
.formservices div:nth-child(8):hover {
  background-color: #3380f2;
}
@media only screen and (min-width: 300px) and (max-width: 980px) {
  .formservices {
    flex-direction: column;
    /* position: static; */
    display: none;
  }

  #newStaycationbanner_mainsection {
    position: static;
    height: 550px;
  }
  .newStaycationbanner_main {
    height: 600px;
    box-sizing: border-box;
    /* border: 3px solid red; */
  }
  .experimentTopBannerdiv img {
    height: 600px;
    /* width: 300px; */
    /* border: 2px solid peru; */
  }
  .newStaycationBannerText {
    width: 70%;
    height: 70px;
    z-index: 11;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 72%;
    left: 55%;
  }
  .newStaycationBannerText > p {
    color: whitesmoke;
    font-size: 20px;
    font-family: sans-serif;
  }
  .newStaycationBannerText span {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 30px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 700;
    color: #3153f2;
  }
}

/* This css part is for the devesh carousel form section */

/* This css is for the travel form details */
.main-trevform {
  position: relative;
}

.text-info {
  /* color: RGB(255, 255, 255);/ */
  color: azure;
  font-size: 23px;
  font-family: "Open Sans", sans-serif;
}

.trevFormdiv {
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  bottom: 74%;
}

.forminput_value {
  margin-bottom: 8%;
  color: #3153f2;
}

.forminput_value .form-label {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.trevformrow {
  position: absolute;
  width: 75%;
  top: 10%;
  background-color: rgb(51, 128, 242, 0.7);
  right: 13%;
  border-radius: 33px;
  height: 285px;
}
.trevButton {
  background-color: #3153f2;
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: white;
  border-radius: 25px;
  padding: 9px 31px;
  width: 150%;
}
.trevButton:hover {
  background: blue;
}
