/* 
.HandPickedWeekendDestinationsBannerDiv{
    width: 100%;
    height: 300px;
    border: 2px solid red;
    color: white !important;
    padding-top: 150px;
    padding-left: 100px;
    position: absolute;
    margin-top: -25%;
}

.HandPickedWeekendDestinationsBannerDiv h5{
    font-weight: 700;
    font-size: 22px;
    
} */

.handPickedWeekendDestinationsBannerDiv{
    padding-left: 70px;
    color: white;
    margin-top:-15%;
    position:absolute;
}
.HandpickedWeekendDestinationPagebannerHeading{
    font-weight: 700;
    margin-left: 5%;
    margin-top: 15%;
    color: white;
  }