

.partners_title {
    font-size: 28px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3%;
    margin-bottom: 3%;
    text-decoration: none solid rgb(154, 148, 148);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    color: #3380f2;
  }
  
  .newhome_ourpartnersmaincarouseldiv{
    /* border: 1px solid red; */
    width: 100%;
    height: 150px;
    text-align: center;
  }
  
  .newhome_ourpartnersmaincarouseldiv h2{
    /* margin-left: 5%; */
    font-weight: 700;
    color: #3380f2;
    font-size: 30px;
    /* margin-left: 48%; */
  }
  ._carouseldiv1 {
    /* border: 2px solid red; */
    width: 80% !important;
    height: 100px !important;
    object-fit: cover;
    background-size: cover;
    /* margin-left: 4% !important; */
    /* position: relative; */
    /* margin-bottom: 10%; */
  }
  ._imagediv {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  ._hoverdiv {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 85%;
    background-color: yellow;
    visibility: hidden;
  }
  ._carouseldiv1 > div:hover {
    visibility: visible;
  }
  ._staticdiv {
    border: 1px solid darkblue;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 14.3%;
    background-color: #203e50;
    text-align: center;
    box-sizing: border-box;
    font-size: larger;
    padding-top: 4%;
  }
  