

.cruiseBookingBookingFaqContainer{
    width: 90%;
    margin: auto;
    margin-top: 2%;
}

.cruiseBookingBookingFaqtexts{
    width: 90%;
}

.accordion-button{
    z-index: 0 !important;
}





