.homecarddropcontainer{
    display: flex;
    height: 80px !important;
    background-color: #3380f2;
}

.homecardinnerdropdowncontiner1{
    /* background-color: red; */
    width: 40%;
    margin-left: 6%;
}

.homecardinnerdropdowncontiner1 h2{
    color: white;
   margin-top: 6%;
   font-size: 26px;
   font-weight: 700;

}
.homecarddropdowncontainer2{
    /* border: 1px solid red; */
    /* border: 3px solid white; */
    /* background-color: blue; */
    width: 50%;
    margin-left: 28%;
    margin-top: 0.5%;
}

.optiondropdown{
    text-align: left;
    width: 1000px !important;
}
.countrycruisecardselectdrop{
    border: 2px solid white !important;
    background-color: #3380f2 !important;
    border-radius: 20px !important;
    margin-top: 2%;
    color: white !important;
    font-weight: 600 !important;
    height: 40px !important;
    width: 58% !important;
}

.countrycruisecardselectdrop:hover{
    background-color: white !important;
    cursor: pointer;
    color: #3380f2 !important;
}

.countrycruisecardselectdrop option:hover{
    background-color: blue !important;
}


.countryhomecardselectdrop{
    border: 2px solid white !important;
    border-radius: 20px !important;
    background-color: #3380f2 !important;
    color: white !important;
    margin-top: 4%;
    font-weight: 600 !important;
    height: 40px !important;
    /* width: 90% !important; */
}
.countryhomecardselectdrop:hover{
    background-color: white !important;
    cursor: pointer;
    color: #3380f2 !important;
}
.optionshomecardcontainer{
    /* border: 2px solid white; */
    display: flex;
    margin-top: 1%;
    margin-left: 7%;
}

.singlehomedropcontainer1{
    margin-left: -25%;
    border: none;
}
.singlehomedropcontainer{
    /* border: 1px solid red; */
    /* width: 80%; */
    margin-left: 4%;
    border: none;
}
.singlehomedropcontainer > .homecardbutton{
    /* margin-left: 3%; */
    border: 2px solid white !important;
    background-color: #3380f2 !important;
    margin-left:35%;
    margin-top: 6%;
    height: 40px !important;
    color: white;
    font-weight: 700;
    padding-top: 6px;
    padding-left: 16px;
    width: 122%;
    height: 42px;
    border-radius: 20px;
}

.singlehomedropcontainer button:hover{
    background-color: white !important;
    color: #3380f2;
}
/* style={{ marginLeft: "8%", background: "white", color: "blue" }} */