#filter-newFlightsPagetrave-container {
  box-sizing: border-box;
  justify-content: space-between;
  margin: auto;
  top: 47%;
  background-color: white;
  width: 70%;
  /* border: 3px solid black; */
  color: #3380f2 !important;
  left: 16%;
  border-radius: 10px;
  position: absolute !important;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.multiroundbuttons {
  outline: none;
  box-shadow: none;
  margin-left: 2%;
  margin-top: 1%;
  color: gray;
  /* border: 2px solid saddlebrown; */
}

.multiroundbuttons select {
  border: 2px solid #dddddd75;
  width: 12%;
  font-size: 15px;
  padding: 5px 10px;
}

/* This css is for the experiment input div part */
.newFlightsinputmaindiv {
  margin-top: 1.5%;
  margin-left: 0.9%;
  width: 99%;
  display: flex;
  flex-direction: row;
  /* border: 2px solid firebrick; */
}
.newFlightsInputContainerDiv{
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  margin-top: -1%;
}
.newFlightsDestinationInputContainer {
  width: 50%;
  /* border: 2px solid gold; */
}

.newFlightsDestinationInputContainer p {
  /* border: 2px solid red; */
  height: 10px;
  margin-left: 5%;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #818090;
  font-size: 15px;
}

.newFlightsDestinationInputContainer1 {
  width: 50%;
  /* border: 2px solid green; */
}

.newFlightsDestinationInputContainer1 p {
  height: 10px;
  margin-left: 2%;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #818090;
  font-size: 15px;
}

.newFlightsNameInput {
  border: 1px solid rgb(236, 234, 234);
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 10px;
  color: #212529;
  width: 92%;
  height: 40px;
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
  flex-direction: row;
  margin-left: 5%;
  margin-bottom:5%;
}

.newFlightsNameInput1 {
  border: 1px solid rgb(236, 234, 234);
  border-radius: 5px;
  width: 95%;
  margin-left: 2%;
  margin-right: 2% !important;
  padding-left: 5px;
  padding-top: 5px;
  height: 40px;
  display: flex;
  flex-direction: row;
}

.newFlightsinputNameContainer input {
  width: 90% !important;
  color: black;
  border: none !important;
  outline: none !important;
  padding-left: 4%;
  font-size: 15px;
}

.newFlightsinputNameContainer input::placeholder {
  color: black;
}

.newFlightsinputNameContainer .hotelinputIconContainer {
  font-size: 30px !important;
}
.newFlightsDates{
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
}
.newFlightsCheckIn_dateContainer {
  width: 50%;
  margin-top: -4%;
  /* border: 2px solid darkblue; */
}

.newFlightsCheckIn_dateContainer p {
  height: 20px;
  margin-left: 5%;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #818090;
  font-size: 15px;
  /* border: 2px solid red; */
}

.newFlightsCheckIn_dateContainer input {
  border: 1px solid rgb(236, 234, 234) !important;
  border-radius: 5px;
  margin-top: -7%;
  margin-left: 5%;
  width: 100%;
  border: none;
  outline: none !important;
  padding-top: 2%;
  height: 35px;
  width: 90% !important;
  color: black;
  outline: none !important;
  padding-left: 4%;
  font-size: 15px;
}

.newFlightsCheckout_DateContainer {
  width: 50%;
  margin-top: -4%;
  /* border: 2px solid  red; */
}

.newFlightsCheckout_DateContainer p {
  height: 20px;
  margin-left: 2%;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #818090;
  font-size: 15px;
}

.newFlightsCheckout_DateContainer input {
  border: 1px solid rgb(236, 234, 234) !important;
  border-radius: 5px;
  margin-top: -5%;
  width: 100%;
  border: none;
  outline: none !important;
  padding-top: 2%;
  height: 35px;
  width: 90% !important;
  color: black;
  outline: none !important;
  padding-left: 4%;
  font-size: 15px;
}

.newFlightsGuest_outerDiv {
  width: 30%;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #818090;
  font-size: 15px;
  /* border: 2px solid green; */
  margin-top: -1%;
}

#newFlightsPage_departure {
  display: flex;
  border: 1px solid rgb(236, 234, 234);
  flex-direction: row;
  width: 90%;
  margin-top: -5% !important;
  color: black;
  background-color: white !important;
  border-radius: 5px;
  padding-left: 3%;
}

#newFlightsPage_departure h5 {
  font-size: 15px;
  padding-top: 9px;
  padding-left: 6px;
  margin-top: 0% !important;
  font-weight: 400;
}

.dropdownnewFlightsPage-content {
  display: none;
  position: absolute;
  color: white !important;
  background-color: #3380f2;
  width: 200px;
  height: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  margin-top: 3.7%;
  /* border: 2px solid red; */
}

.dropdownnewFlightsPage-content div:hover {
  background-color: white !important;
  color: #3380f2 !important;
}

#newFlightsPage_departure:hover .dropdownnewFlightsPage-content {
  display: block;
}

.navbarnewFlightPageOptions {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 2%;
}

.newFlightHomeGuestIcon {
  font-size: 24px;
  margin-left: 15% !important;
  margin-top: 2% !important;
  margin-left: 7% !important;
  background-color: white !important;
}

.newFlight_SearchButton {
  width: 18%;
  /* border: 2px solid red; */
}

.newFlight_SearchButton button {
  margin-top: 16%;
  width: 90%;
  height: 45px;
  padding-left: 10%;
  font-weight: 400;
  font-size: 18px;
  background-color: #3380f2;
  /* padding-top: 5%; */
}

.newFlight_SearchButton button:hover {
  background-color: white;
  color: #3380f2;
}


.newFlight_chooseClass1 select {
  background-color: none;
  color: #393939;
  margin-top: 3%;
  width: 90%;
  border: 1px solid rgb(236, 234, 234);
  border-radius: 5px;
  font-size: 15px;
  height: 40px;
  outline: none;
  margin-left: 5%;
  margin-bottom: 10%;
}

.newFlight_chooseClass2 select {
  background-color: none;
  color: #393939;
  margin-top: 3%;
  width: 90%;
  border: 1px solid rgb(236, 234, 234);
  border-radius: 5px;
  font-size: 15px;
  height: 40px;
  outline: none;
}


.carouselnewExperimentbannermenu {
  width: 64%;
  height: 90px;
  margin: auto;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  position: absolute;
  bottom: 56%;
  left: 23%;
  z-index: 10000;
  color: white !important;
  border-radius: 40px;
}

/* This css part includes to overlapping and hovering the things */
.bottomupExperimentclass1 {
  top: 10%;
  right: 86%;
  z-index: 9 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass1:hover {
  z-index: 107 !important;
  margin-top: -2%;
}

.bottomupExperimentclass2 {
  top: 10%;
  right: 78%;
  z-index: 8 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass2:hover {
  z-index: 106 !important;
  margin-top: -2%;
}

.bottomupExperimentclass3 {
  top: 10%;
  right: 70%;
  z-index: 7 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass3:hover {
  z-index: 106 !important;
  margin-top: -2%;
}

.bottomupExperimentclass4 {
  top: 10%;
  right: 61.6%;
  z-index: 6 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass4:hover {
  z-index: 105 !important;
  margin-top: -2%;
}

.bottomupExperimentclass5 {
  top: 10%;
  right: 52.5%;
  z-index: 5 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass5:hover {
  z-index: 104 !important;
  margin-top: -2%;
}

.bottomupExperimentclass6 {
  top: 10%;
  right: 44%;
  z-index: 4 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass6:hover {
  z-index: 103 !important;
  margin-top: -2%;
}

.bottomupExperimentclass7 {
  top: 10%;
  right: 36%;
  z-index: 3 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass7:hover {
  z-index: 102 !important;
  margin-top: -2%;
}

.bottomupExperimentclass8 {
  top: 10%;
  right: 27%;
  z-index: 2 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass8:hover {
  z-index: 101 !important;
  margin-top: -2%;
}

.bottomupExperimentclass9 {
  top: 10%;
  right: 19%;
  z-index: 1 !important;
  font-size: 15px;
  font-weight: 500;
}

.bottomupExperimentclass9:hover {
  z-index: 100 !important;
}

/* This css is for the icons and there hovering properties */

.carouselnewExperimentbannermenu-icon {
  height: 98px;
  width: 10%;
  border-radius: 12px;
  background: transparent;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  cursor: pointer;
  border: none !important;
  color: white;
  text-decoration: none;
  z-index: 100;
  position: absolute;
}

.carouselnewExperimentbannermenu-icon span {
  white-space: nowrap;
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.carouselnewExperimentbannermenu-icon-active {
  background: #3380f2 !important;
  border-color: white !important;
  height: 70px;
  width: 90px;
  border-radius: 7px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  color: white !important;
  text-decoration: none;
}

.carouselnewExperimentbannermenu-icon:hover {
  text-decoration: none;
  color: #3380f2;
  background-color: white;
  transition: all 0.5s ease;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.carouselnewExperimentbannermenu-icon span {
  white-space: nowrap;
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.carouselnewExperimentbannermenu-icon-active {
  background: #3380f2 !important;
  border-color: white !important;
  height: 70px;
  width: 90px;
  border-radius: 7px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  color: white !important;
  text-decoration: none;
}

.newHomemoredropdownbannercontent {
  display: none;
  position: absolute;
  color: white;
  background-color: #3380f2;
  width: 200px;
  height: 245px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  top: 58%;
}

#borderrightnewhomebanner:hover .newHomemoredropdownbannercontent {
  display: block;
}

/* This css is for the one way round trip button */

.moreOptions:hover {
  background-color: white;
  color: #3380f2;
}

.moreOptions {
  margin-top: 5%;
  padding: 5%;
  color: white;
}

.moreOptions a {
  text-decoration: none;
  color: white;
}

.decrease {
  margin-left: 10px;
  margin-right: 20px;
}


@media (max-width: 480px) {

  .carouselnewExperimentbannermenu {
    display: none;
  }
  
  #filter-newFlightsPagetrave-container {
    flex-direction: column;
    background-color: white;
    width: 90%;
    top: 10%;
    left:5%;
    /* border: 2px solid red; */
    color: #3380f2;
    border-radius: 10px;
    position: absolute !important;
    z-index: 10;
  }

  .newFlightsinputmaindiv{
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
  }
  .newFlightsDestinationInputContainer {
    width: 65%;
    /* border: 2px solid gold; */
    margin: auto;
    margin-left: 1%;
    height: 60px;
  }
  
  .newFlightsDestinationInputContainer1 {
    width: 65%;
    /* border: 2px solid gold; */
    margin: auto;
    margin-left: 1%;
    height: 60px;
  }
  .newFlightsDestinationInputContainer1 p{
    height: 10px;
    /* border: 2px solid red; */
    font-size: 15px;
  }
  .newFlightsDates{
    /* border: 2px solid gold; */
    margin-top: 7%;
    height: 150px;
  }
  .newFlightsCheckIn_dateContainer {
    width: 70%;
    margin: auto;
    /* border: 2px solid darkblue; */
    height: 120px;
  }
  .newFlightsCheckout_DateContainer {
    width: 70%;
    margin: auto;
    /* border: 2px solid  red; */
    height: 120px;
  }
  .newFlightsGuest_outerDiv {
    width: 70%;
    margin-left: 20%;
    /* border: 2px solid green; */
    /* text-align: center; */
  }
  .newFlightsGuest_outerDiv>p{
    margin-left: 30%;
  }
  .newFlight_SearchButton {
    width: 60%;
    margin-left: 23%;
    /* border: 2px solid red; */
  }
  
  .newFlight_SearchButton button {
    /* border: 2px solid  red; */
    margin: 4%;
    padding: 4%;
    font-weight: 500;
  }
  .multiroundbuttons{
    /* border: 2px solid black; */
    padding-left: 7%;
    margin-top: 5%;
  }
  .newFlightsNameInput{
    border: none;
    border-bottom: 2px solid lightgray;
    font-size: 15px;
    height: 40px;
    /* border: 2px solid red; */
    margin-top: 2%;
  }
  .newFlightsNameInput1{
    border: none;
    border-bottom: 2px solid lightgray;
    height: 40px;
    /* border: 2px solid red; */
  }


}

@media screen and (min-width: 560px) and (max-width: 950px) {
  .carouselnewExperimentbannermenu {
    display: none;
  }

  .filter-newFlightsPagetrave-container {
    flex-direction: column;
    margin-top: 5%;
  }
}
