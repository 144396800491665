.whychoose_TravelFlapmaindiv {
  /* border: 1px solid red; */
  /* background-color: #3380f2 !important; */
  width: 100%;
  height: auto;
  /* border: 3px solid red; */
  margin: auto;
  margin-top: 4%;
  text-align: center;
  color: black;
}
/* .whychooseheading{
  background-color: #3380F2;
} */
.whychooseheading h1 {
  /* border: 1px solid red !important; */
  margin-bottom: 2%;
  padding-top: 13px !important;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  /* margin-left: 8%; */
  /* border-bottom: 2px solid #3380F2; */
  color: #3380f2 !important;
  height: 55px;
  /* padding-top:8px ; */
}

.whychoose_TravelFlapdatadiv {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  /* border: 2px solid black; */
}
.one_TravelFlap_test {
  /* border: 1px solid white; */
  width: 33%;
}
.whychoose_TravelFlapheadinglogos {
  width: 90%;
  /* border: 1px solid red; */
  height: 120px;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 2%;
  text-align: left;
  margin-left: 12%;
}

.whyChooseTravelFlap_imagediv {
  /* border: 1px solid red; */
  /* background-color: #3380f2; */
  width: 150px;
  height: 70px;
}
/* .whychoose_TravelFlapheadinglogos1{
    width: 95%;
    border: 1px solid white;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 1%;
    text-align: center;
    
} */
.whychoose_TravelFlapheadinglogos h5 {
  /* border: 1px solid red; */
  font-weight: 500;
  /* margin-left: 1%; */
  color: #393939;
  margin-top: 2%;
}
.whychoose_TravelFlaplogos {
  object-fit: cover !important;
  border: 1px solid white;
  border-radius: 50px;
  background-color: white;
  width: 70px;
  height: 70px;
  margin-left: 34%;
  /* margin-top: 1%; */
  /* border: 2px solid; */
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  color: #3380f2;
  border: 3px solid #3380f2;
  transform: rotateY(180deg);
}

@media screen and (min-width: 300px) and (max-width: 500px) {
 
  .whychoose_TravelFlapdatadiv{
    display: flex;
    flex-direction: column;
  }
  .one_TravelFlap_test {
    /* border: 1px solid red; */
    width: 95%;
    margin: auto;
  }
  .whychoose_TravelFlapheadinglogos{
    width: 100%;
    /* border: 1px solid gold; */
    height: auto;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 4%;
    text-align: left;
    margin-left: 1%;
  }
  .whychoose_TravelFlapheadinglogos>h5{
    /* border: 2px solid; */
    font-size: 15px;
    margin-top: 4%;
    margin-left: 2%;
  }
  .whyChooseTravelFlap_imagediv {
    /* border: 1px solid red; */
    /* background-color: #3380f2; */
    width: 70px;
    height: 40px;
    text-align: center;
  }
  .whyChooseTravelFlap_imagediv>img{
    /* border: 2px solid red; */
    height: 50px;
    width: 50px;
    margin-top: 1%;
  }
}