.cheapflights{
    /* border: 2px solid red; */
    margin-bottom: 2%;
    height: auto;
}
.cheapflightsouterdiv{
    width: 100%;
    height: auto;
    /* border: 2px solid red; */
}

.cheapflightcontainerdiv{
    margin: auto;
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}
.cheapflightdiv1{
    width: 25%;
    height: 140px;
    display: flex;
    flex-direction: column;
    margin-top: -2%;
    margin-left: 0%;
    /* border: 2px solid red; */
    /* border-right: 2px solid lightgray;*/
}
.cheapflightdiv2{
    width: 25%;
    height: 140px;
    display: flex;
    flex-direction: column;
    margin-top: -2%;
    margin-left: 13%;
    /* border: 2px solid red; */
    /* border-right: 2px solid lightgray;*/
}
.cheapflightdiv3{
    width: 25%;
    height: 140px;
    display: flex;
    flex-direction: column;
    margin-top: -2%;
    margin-left: 13%;
    /* border: 2px solid red; */
    /* border-right: 2px solid lightgray;*/
}
.cheapflightdiv4{
    width: 25%;
    height: 100%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
}
.cheapflightdetails{
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 93%;
    height: 90px;
    /* margin: auto; */
    margin-top: 2%;
    margin-left: 3%;
    display: flex;
    flex-direction: row;
}
.cheapflightdetailsflightlogos{
    /* border: 2px solid red; */
    width: 25%;
    /* height: 100px; */
}
.cheapflightdetailsflightlogos img{
    width: 70%;
    height: 95%;
    /* border: 2px solid red; */
}
.cheapflightnames{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 20px;
    font-weight: 600;
    margin-left: -4%;
}
.cheapflightdetailsflightTimming{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 3.5%;
    font-weight: 600;
}
.cheapflightdetailsflightStops{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 3.5%;
    font-weight: 600;
}
.cheapflightdetailsflightPrice{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    font-size: 20px;
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
}
.cheapflightBooknowButton{
    background-color: #3380F2;
    color: white;
    border-radius: 5px;
    width: 50%;
    height: 35px;
    font-size: 15px;
    border: none;
    margin-left: 50%;
}
.cheapflightdropdown{
    border-radius: 15px;
    width: 80%;
    height:45px;
    margin-top:4%;
    font-size: 13px;
    border: none;
    margin-left: 24%;
    /* border: 2px solid red; */
}
.cheapflightdropdownbutton{
    /* border: 2px solid saddlebrown; */
    /* border-bottom: 2px solid #3380F2; */
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    color: #393939;
    font-size: 17px;
    font-weight: 500;
    border: none;
   }

.cheapflighticon{
    margin-left: 4%;
}
.cheapflightdownarrrow{
    margin-left: 15%;
}
.cheapflightdata{
    color: rgb(60, 60, 60);
    margin-top: 2%;
    font-weight: 700;
}
.cheapflightdata:hover{
    background-color: #3380F2;
    color: White;
}
.cheapflightcountryborder{
    /* border: 2px solid blueviolet; */
    width:90%;
    height: 520px;
    /* margin: auto; */
    /* margin-top: 5%; */
    border-radius: 10px;
}
.cheapflights{
    width: 100%;
    height: 700px;
    /* background-color: #3380F2; */
    /* border: 2px solid red; */
    margin: auto;
}
.cheapflightsheading{
    width: 100%;
    height: 60px;       
    /* border: 2px solid green; */
    color:#3380f2;
    font-size: 35px;
    font-weight: 700;
    padding-top:10px;
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    padding-left: 4%;
}
.cheapflightsselectoptions{
    /* border: 2px solid red; */
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: 0%;
    flex-direction: row;
}
.cheapflightselectdiv{
    display: flex;
    flex-direction: row;
    margin-top: 0.2%;
    /* border: 2px solid red; */
    margin-left: 56%;
    width: 14%;
}
.cheapflightselectdiv1{
    display: flex;
    flex-direction: row;
    width: 17%;
    margin-top: 0.2%;
    /* border: 2px solid red; */
    margin-left: 2%;
    height: 20px !important;
}
.cheapflightselectdiv3{
    color: #393939;
    font-weight: 700;
    font-size: 16px;
    margin-left: 3%;
    margin-top: 1%;
}
.cheapflightsicondiv{
    /* border: 2px solid gr een; */
    width: 26%;
    height: 35px;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
}
.cheapflightsicondiv3{
    /* border: 2px solid gr een; */
    width: 26%;
    height: 35px;
    background-color: #3380F2;
    border-radius: 0px 10px 10px 0px;
    padding-top: 5%;
    color: white;
}
.cheapflightsselect1{
    /* border: 2px solid gold !important; */
    border: none;
    height: 35px;
    width: 50%;
    /* padding-right: 20%; */
    border-radius: 10px 0px 0px 10px;
    appearance: none;
    text-align: left;
    padding-left: 10%;
    color: #3380F2 !important;
    font-weight: 500;
    font-size: 14px;
    font-weight: 700;
    outline: none;
    /* margin-left: 30%; */
}

.cheapflightsselect2{
    /* border: 2px solid gold !important; */
    border: none;
    height: 35px;
    width: 60%;
    padding-right: 20%;
    border-radius: 10px 0px 0px 10px;
    appearance: none;
    text-align: left;
    padding-left: 10%;
    color: #3380F2 !important;
    font-weight: 500;
    font-size: 14px;
    font-weight: 700;
    outline: none !important;
    /* margin-left: 30%; */
}
.cheapflightsselect3{
    /* border: 2px solid gold !important; */
    border: none;
    height: 35px;
    width:56%;
    padding-right: 20%;
    border-radius: 10px 0px 0px 10px;
    appearance: none;
    text-align: left;
    padding-left:14%;
    color: white !important;
    font-weight: 500;
    font-size: 14px;
    font-weight: 700;
    background-color: #3380F2;
    /* margin-left: 30%; */
}
.cheapflightsicon{
    color: #3380F2;
    margin-top: -6%;
    height: 15px;
    margin-left: 21%;
}
.cheapflightFilters{
    /* border: 2px solid red; */
    width: 100%;
    /* height: 80px; */
    display: flex;
    flex-direction: row;
}
.cheapflightbookings{
    /* border: 2px solid  gold; */
    width: 45%;
    height: 70px;
    margin-left: 4%;
    display: flex;
    flex-direction: row;
}
.cheapflightbookingDeparture{
    background-color: #CCE0FB;
    height: 50px;
    width: 45%;
    border-radius: 10px;
    margin: 1%;
    color: #393939;
    padding: 12px 20px;
    font-weight: 400;
    margin-left: 3.5%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}
.cheapflightbookingDeparture input{
    background-color: #CCE0FB;
    border: none;
    color: #393939;
    margin-left: 5%;
    /* border: 2px solid gold; */
}
.cheapflightbookingReturn input{
    background-color: #CCE0FB;
    border: none;
    color: #393939;
    margin-left: 5%;
    /* border: 2px solid red; */
}
.cheapflightbookingReturn{
    background-color: #CCE0FB;
    height: 50px;
    width: 45%;
    border-radius: 10px;
    margin: 1%;
    color: #393939;
    padding: 12px 20px;
    font-weight: 400;
    margin-left: 3%;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    /* border: 2px solid green; */
}

.cheapflightcountryfilters{
    /* border: 2px solid  red; */
    width: 45%;
    height: 70px;
    margin-left: 1.5%;
    display: flex;
    flex-direction: row;
}
.cheapflightcountryfilters>select{
    /* border: 2px solid red; */
    background-color: #CCE0FB;
    font-size: 14px;
    border-radius: 10px;
}
.cheapflightsmaindiv{
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row;
    /* border: 2px solid black; */
    margin: auto;
    /* background-image: url("./cheapflightsbg.jpg"); */
    /* filter: brightness(0.5); */
    object-fit: cover;
    background-size: cover;
    border-radius: 18px;
}
.cheapflightscountrynames{
    width: 27%;
    height: 520px;
    margin-top: 1.5%;
    overflow-y: scroll;
    /* border: 2px solid gold; */
}
.cheapflightscountryactivediv{
    width: 70%;
    height: 35px;
    color: black;
    font-weight: 600;
    font-size: 19px;
    /* border-radius: 15px; */
    text-align: center;
    padding-top: 1%;
    margin-top: 9%;
    margin-left: 10%;
    border-bottom: 2px solid lightgray;
    /* background-color: #3380F2; */
}
.cheapflightscountrydiv{
    width: 70%;
    height: 30px;
    color: #3380F2;
    font-weight: 600;
    font-size: 18px;
    /* border-radius: 15px; */
    text-align: center;
    padding-top: 1%;
    margin-top: 5%;
    margin-left: 10%;
    background-color: white;
    /* border: 2px solid red; */
    border-bottom: 2px solid lightgray;
}
.cheapflightdetailscontainer{
    width: 66.5%;
    height: 520px;
    display: flex;
    /* margin-top: 1.5%; */
    margin-left: 2%;
    flex-direction: column;
    /* border: 2px solid green; */
    border-radius: 10px;
    overflow-y: scroll;
}
.cheapflightdetailsContainerDiv{
    /* border: 2px solid red; */
    height: 320px;
    /* margin-top: 5%; */
    /* overflow-y: scroll; */
}
.cheapflightalphafilter{
    border: none;
    height: 39px;
    width: 51%;
    padding-right: 28%;
    border-radius: 18px;
    /* appearance: none; */
    text-align: center;
    color: #3380F2;
    font-weight: 600;
    /* margin-right: 50%; */
    font-size: 13px;
    margin-left: 65%;
    margin-top: 2%;
}
.cheapflightapidata{
    width: 95%;
    height: 80px;
    /* border: 1px solid blue; */
    margin-top: 2%;
    margin-left: 3%;
    /* background-color: blue; */
    /* opacity: 0.2; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
    /* background: transparent; */
    /* margin: auto; */
    display: flex;
    flex-direction: row;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3380F2; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#bbd2f6; 
    height: 10px;
  }

  /* Media queries for the mobile and the tablet devices */

@media (max-width: 480px) {

    .cheapflightFilters{
      /* border: 2px solid gold; */
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .cheapflightbookings{
        /* border: 2px solid red; */
        width: 100%;
        margin-left: 0%;
    }
    .cheapflightsheading{
        font-size: 20px;
        /* border: 2px solid green; */
        padding-left: 10%;
    }
    .cheapflightcountryfilters{
        /* border: 2px solid  lightgray; */
        width: 100%;
        margin-left:0%;
    }
    .cheapflightsmaindiv{
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: column;
    }
    .cheapflightdetailscontainer{
        width: 95%;
        display: flex;
        flex-direction: column;
        /* border: 2px solid green; */
        border-radius: 10px;
        overflow-y: scroll;
        margin-bottom: 5%;
    }
    .cheapflightscountrynames{
        width: 95%;
        margin: auto;
        margin-top: 0%;
        overflow-y: none;
        /* border: 2px solid gold; */
        display: none;
    }
    .cheapflightdetailsflightTimming{
        /* border: 2px solid red; */
        width: 25%;
        font-size: 11px;
        text-align: center;
        padding-top: 30px;
        display: none;

    }
    .cheapflightdetailsflightStops{
        display: none;
    }
    .cheapflightdetailsflightlogos{
        width: 30%;
        /* border: 2px solid red; */
        text-align: center;
       
    }
   
    .cheapflightnames{
        /* border: 2px solid red; */
        width: 32%;
        color: #393939;
        text-align: center;
        font-size: 10px;
        padding-top: 30px;
        font-weight: 600;
        margin-left: 0%;
    }
    .cheapflightdetailsflightPrice{
        width: 38%;
        /* border: 2px solid red; */
        text-align: center;
        padding-right: 0px;
        padding-top: 28px;
        font-size: 15px;
    }
    .cheapflightBooknowButton{
        width: 60%;
        height: 25px;
        font-size: 10px;
        margin:auto;
        margin-top: 1%;
    }
}