.handpickedlink {
  text-decoration: none;
}
.handpickedheading {
  font-size: 25px;
  font-weight: 700;
  width: 30%;
  margin-left: 7%;
  margin-top: 1.3%;
  color: white;
}
.handpickedsliderbuttons {
  color: white;
  border-radius: 20px;
  display: flex;
  margin: 1% 6% 1% 52%;
  width: 5%;
}
.handpickedbuttons {
  justify-content: flex-end;
  width: -1%;
}
.handpickedheadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #3380f2 !important;
  height: 75px;
}
.handpickedcardmaindiv {
  width: 90%;
  margin: auto;
}

.handpickedcardwrapper {
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.handpickedcard {
  width: 100%;
  height: 350px;
  /* border: 2px solid black; */
}

.handpicked_title1 {
  /* border: 2px solid red; */
  position: absolute;
  top: 60%;
  left: 7%;
  height: 70px;
  width: 100%;
}

.handpickedcard-title{
  color: white;
  font-size: 20px;
  margin-top: 1%;
}

.handpicked_title1:hover{
  position: absolute;
  height: 70px;
}

.handpickedbottomheading {
  top: 65%;
  background-color: white;
  border-radius: 12px;
  left: 10%;
  width: 35%;
  height: 26px;
}

.handpickedbottomheading h1{
  color: #3380f2;
  font-size: 18px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 9px;
}
.handpickedcard_img {
  width: 100%;
  height: 300px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* border: 2px solid red; */
  cursor: pointer;
}

/* .handpickedcard :hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 270px;
  height: 320px;
} */



/* 
  .handpickedcard_img h1:hover{
    background-color: #3380f2;
    color: white;
    width: 50% !important;
    height: auto !important;
  } */

.handpickedcard_img img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 15px;
}
/* .handpickedcard-title {
  margin: auto;
  margin-top: 2%;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 29px;
  vertical-align: baseline;
  color: #3380f2;
  font-weight: 700;
} */

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.handpickedcardwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}
