.flightNearYouInnerWebpageDataMainDiv{
    /* border: 2px solid black; */
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: row;
}
.flightNearYouInnerWebpageDataFiltersDiv{
    /* border: 2px solid red; */
    width: 30%;
    height:970px;
    margin-top: 2%;
    border-radius: 10px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.flightNearYouInnerWebpageDataFilter{
    border: 2px solid lightgray;
    /* margin-top: 2%; */
    width: 90%;
    height: 970px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.flightNearYouInnerWebpageFilter1{
    /* border: 2px solid gold; */
    width: 90%;
    height: 180px;
    margin-top: 2%;
}
.flightNearYouInnerWebpageFilter1 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.flightNearYouInnerWebpageFilter1 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 17px;
    height:25px;
}
.flightNearYouInnerWebpageFilter2{
    /* border: 2px solid red; */
    width: 90%;
    height: 180px;
    margin-top: 4%;
}
.flightNearYouInnerWebpageFilter2 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.flightNearYouInnerWebpageFilter2 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 17px;
    height:25px;
}
.flightNearYouInnerWebpageFilter3{
    /* border: 2px solid red; */
    width: 90%;
    height: 200px;
    margin-top: 5%;
}
.flightNearYouInnerWebpageFilter4{
    /* border: 2px solid red; */
    width: 90%;
    height: 200px;
    margin-top: 5%;
}
.flightNearYouInnerWebpageFilter4 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.flightNearYouInnerWebpageFilter4 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 17px;
    height:25px;
}
.flightNearYouInnerWebpageFilter5{
    /* border: 2px solid red; */
    width: 90%;
    height: 250px;
    margin-top: 5%;
}
.flightNearYouInnerWebpageStops{
    font-size: 20px;
    font-weight: 700;
    /* border: 2px solid red; */
    margin-bottom: 1%;
}
.flightNearYouInnerWebpageTime{
    /* border: 2px solid firebrick; */
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1%;
}
.flightNearYouInnerWebpageTimeButtons{
    background-color: white;
    border: 1px solid lightgray;
    width: 40%;
    height: 40px;
}
.flightNearYouInnerWebpageTimeButtons:hover{
    background-color: #3380F2;
    color:white
}
.flightnearinnerpageslidecontainer{
    width: 80%;
    margin-left: 3%;
    margin-top: -1%;
}
.flightNeartakeoffData{
    color: gray;
    font-size: 12px;
    /* margin-top: 2%; */
    margin-left: 3%;
}
.flightNearDurationrangeText{
    color: #393939;
    font-weight: 700;
    font-size: 15px;
    margin-top: 1%;
    /* border: 2px solid green; */
}

.flightNearYouInnerWebpageDataDetails{
    /* border: 2px solid blue; */
    width: 70%;
    height: 800px;
}
.flightNearYouinnerpageFlightDetails{
    /* border: 2px solid red; */
    height: auto;
}
.flightNearYouinnerPageDatesOptions{
    border: 2px solid lightgray;
    width: 95%;
    height: 80px;
    margin: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.flightNearYouinnerPageDatesOptionsDiv{
    /* border: 2px solid red; */
    width: 20%;
    height: 78px;
    color: #393939;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid lightgray;
}
.flightNearYouinnerPageDatesOptionsDiv1{
    /* border: 2px solid red; */
    width: 20%;
    height: 100px;
    color: #3380F2;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-right:none;
    /* padding-top: 15px; */
}
.flightNearYouinnerPageDatesOptionsDiv1 h4{
    font-weight: 700;
    font-size: 20px;
    margin-top: 10%;
}
.flightNearYouinnerPageDatesOptionsDiv1 h6{
   font-weight: 700;
   font-size: 14px;
   color: #3380F2;
}
.flightNearYouinnerPageDatesOptionsDiv h4{
    font-weight: 700;
    font-size: 20px;
    margin-top: 10%;
}
.flightNearYouinnerPageDatesOptionsDiv h6{
   font-weight: 700;
   font-size: 14px;
   color: gray;
}
.flightNearYouInnerWebpageDataDetailsButtons{
    /* border: 2px solid red; */
    width: 75%;
    height: 100px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.flightNearinnerBest{
    /* border: 2px solid green; */
    width: 35%;
    height: 100px;
    text-align: center;
    font-weight: 700;
    border-right: 2px solid lightgray;
    /* border-bottom: 5px solid #3380F2; */
    color: #393939;
    padding-top: 15px;
    cursor: pointer;
}
.flightNearinnerCheap{
    /* border: 2px solid green; */
    width: 30%;
    height: 100px;
    text-align: center;
    font-weight: 700;
    border-right: 2px solid lightgray;
    color: #393939;
    padding-top: 15px;
    cursor: pointer;
}
.flightNearinnerFast{
    /* border: 2px solid green; */
    width: 30%;
    height: 100px;
    text-align: center;
    font-weight: 700;
    /* border-right: 2px solid lightgray; */
    color: #393939;
    padding-top: 15px;
    cursor: pointer;
}
.active{
    border-bottom: 5px solid #3380F2;
}
.flightNearinnerpagedetails{
    /* border: 1px solid lightgray; */
    border-radius: 5px;
    width: 93%;
    height: 80px;
    /* margin: auto; */
    margin-top: 1.5%;
    margin-left: 3%;
    display: flex;
    flex-direction: row;
}
.flightNearinnerOption1{
    /* border: 2px solid  red; */
    margin-top: 4%;
}
/* .flightNearinnerOption1 input{
    width: 100%;
} */
.flightNearinnerpagedetailsflightlogos{
    /* border: 2px solid red; */
    width: 25%;
    /* height: 100px; */
}
.flightNearinnerpagedetailsflightlogos img{
    width: 70%;
    height: 95%;
    /* border: 2px solid red; */
}
.flightNearinnerpagenames{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 20px;
    font-weight: 600;
    margin-left: -4%;
    font-size: 18px;
}
.flightNearinnerpagedetailsflightTimming{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 3.5%;
    font-weight: 600;
}
.flightNearinnerpageplaneIcon{
    /* border: 2px solid red; */
    width: 8%;
    font-size: 22px;
    padding-top: 33px;
}
.flightNearinnerpagedetailsflightStops{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    text-align: center;
    padding-top: 3.5%;
    font-weight: 600;
    font-size: 18px;
    border-right: 2px solid lightgray;
}
.flightNearinnerpagedetailsflightPrice{
    /* border: 2px solid red; */
    width: 25%;
    color: #393939;
    font-size: 30px;
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
}
.flightNearinnerpagedetailscontainer{
    width: 66.5%;
    height: 520px;
    display: flex;
    /* margin-top: 1.5%; */
    margin-left: 2%;
    flex-direction: column;
    /* border: 2px solid green; */
    border-radius: 10px;
    overflow-y: scroll;
}
.flightNearinnerpagedetailsContainerDiv{
    /* border: 2px solid red; */
    height: 320px;
    /* margin-top: 5%; */
    /* overflow-y: scroll; */
}
.flightNearinnerpageBooknowButton{
    background-color: #3380F2;
    color: white;
    border-radius: 5px;
    width: 60%;
    height: 35px;
    font-size: 15px;
    border: none;
    margin-left: 40%;
    margin-top: 10%;
}
.flightNearinnerpageBooknowButton:hover{
    background-color: white;
    color: #3380F2;
    border: 2px solid  #3380F2;
}
.flightNearinnerpageViewallDealsButton{
    background-color: white;
    border: 2px solid lightgray;
    color: #393939;
    border-radius: 5px;
    width: 60%;
    height: 35px;
    margin-top: -10%;
    font-size: 14px;
    margin-left: 40%;
}
.flightNearinnerpageViewallDealsButton:hover{
    background-color: #3380F2;
    color: white;
}
.flightNearYouFlightDetails{
    border: 2px solid lightgray;
    height: 210px;
    margin-top: 2%;
    border-radius: 10px;
    width: 95%;
    margin: auto;
    margin-top: 2%;
}
.innerflightNearYouShowButton{
    background-color: #3380F2;
    color: white;
    width: 95%;
    height: 60px;
    margin: auto;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    margin-left: 2.5%;
    margin-top: 2%;
}