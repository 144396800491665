.newhomeTourDealsmainoutercontainer {
  /* border: 2px solid red !important; */
  border: none !important;
  background-color: #fafafa !important;
}

.newhomeTourDealsmainoutercontainer img {
  height: 500px !important;
}
/* 
.newhomeToursDealoverimagemaindiv{
  border: 2px solid red;
} */

.newHomeToursDealsliderdiv {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  /* border: 2px solid red; */
}
.newHomeTourDealscardimage{
  /* border: 2px solid red; */
  height: 350px;
}
.activityexperimentcardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.activityexperimentcardimage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newHomeToursDealsliderdiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* border-radius: 10%; */
}

.newHomeToursDealdetails {
  position: absolute;
  /* bottom: -77%; */
  top: 35%;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 100;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.newHomeToursDealdetails :hover {
  color: white;
}

.newHomeToursDealdetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.newHomeToursDealdetails h2 .activityexperimentjobtitle {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.card:hover .newHomeToursDealdetails {
  bottom: 1;
}

.newHomeToursDealdetails p{
  border: 3px solid white;
  width: 52%;
  border-radius: 20px;
  margin: auto;
  height: 30px;
  margin-top: 15%;
  padding-top: 5px;
  font-size: 12px;
}



.newHomeToursDealsliderdiv:hover .activityexperimentcardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  /* border-radius: 10%; */
}

.newhomeTourDealsheadingcontainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}
.newhomeTourDealsheadingcontainer h2 {
  /* border: 1px solid purple; */
  color: #3380f2;
  font-weight: 700;
  font-size: 35px !important;
  margin-left: 3%;
  margin-top: 1%;
  margin-bottom: 2%;
}

.newHomeTourDealsDropContainer {
  /* border: 1px solid red; */
  width: 20%;
  margin-left: 64%;
  margin-top: 0.1%;
}

.newToursDealsCountryDrop {
  margin-top: 5% !important;
  margin-bottom: 2%;
  margin-left: 22%;
  color: #3380f2 !important;
  font-weight: 700;
  cursor: pointer;
  border-radius: 20px !important;
}
.newToursDealsCountryDrop:hover {
  background-color: #3380f2;
  color: white !important;
  font-weight: 700;
}


.newhomemaincard {
  height: 700px !important;
  /* border: 2px solid red !important; */
}

.newhomemaincard .backgroundimageToursDeal {
  height: 700px;
  /* border: 2px solid red !important; */
}

/* this css file is taken for reference from the handpicked session */
.tourDealsnewhomepagelink {
  text-decoration: none;
  height: 370px;
}

.tourdealsnewhomepagecardmaindiv {
  width: 95%;
  margin: auto;
}

.tourdealsnewhomepagecardwrapper {
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.newhomeToursDealcard {
  width: 100%;
  height: 350px;
  margin-top: 5%;
  /* border: 2px solid black; */
}

.toursDealnewhomepage_title1 {
  background-color: white;
  position: absolute;
  top: 65%;
  left: 9%;
  opacity: 0.7;
  /* display: flex;
  flex-direction: row; */
  /* bottom: 10%; */
  height: 120px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  width: 90%;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;  */
}

.toursDealnewhomepagecard-title {
  color: white;
  font-size: 20px;
  margin-top: 1%;
}

.toursDealnewhomepage_title1:hover {
  position: absolute;
  height: 120px;
}

.toursDealbottomcontentcontainer {
  /* border: 1px solid red; */
  width: 50%;
}

.toursDealnewhomepagebottomheading h2 {
  color: #3380f2;
  font-size: 28px;
  font-weight: 700;
  margin-top: 4%;
  margin-left: 5%;
}

.toursDealnewhomepagebottomheading p {
  font-size: 19px;
  margin-left: 5%;
}

.toursDealnewhomepagecard_img {
  width: 100%;
  /* height: 410px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  /* border: 2px solid red; */
  cursor: pointer;
  border-radius: 15px;
}

.toursDealnewhomepagecard_img img {
  object-fit: cover;
  width: 108% !important;
  height: 360px !important;
  border-radius: 15px;
  filter: brightness(0.7);
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.tourdealsnewhomepagecardwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}


/* ----------------- New button css ----------------  */


.newHomeTourDealDropContainer {
  width: 10%;
  margin-left: 70%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height:39px;
  border-radius: 25px;
  /* border: 2px solid green; */
}
.newHomeTourDealDropContainer:hover .newTourDealicondiv{
  background-color:white;
  color: #3380F2;
  cursor: pointer;
}
.newHomeTourDealCountryDrop {
   /* border: 3px solid red !important; */
 background-color: #3380F2 !important;
 width: 80% !important;
 height: 39px !important;
 color:white !important;
 font-weight: 700 !important;
 font-size: 17px !important;
 cursor: pointer;
 border-radius: 25px 0px 0px 25px !important;
 border-right: none !important;
 appearance: none !important;
 padding-top: 2.5% !important;
 border: none;
}
.newHomeTourDealDropContainer select{
  /* border: 1px solid red !important; */
  background-image: none !important;
}

.newHomeTourDealCountryDrop:hover {
    background-color: white !important;
    color: #3380F2 !important;
    font-weight: 700;
    /* border: 3px solid #3380F2 !important; */
}
.newTourDealicondiv{
    width: 20%;
    color: white;
    border: 3px solid #3380F2;
    background-color: #3380F2;
    height: 39px;
    /* font-size: 18px !important; */
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none !important;
    /* border: 2px solid yellow; */
}
.tourdealicon{
  margin-left: -37%;
  /* width: 100% !important; */
  color: #3380F2;
  border: 3px solid #3380F2;
  background-color: white;
  height: 25px;
  width: 25px;
  font-size: 18px !important;
  border-radius: 100%;
  margin-top: 20% !important;
  /* margin-bottom: 105%;*/
}