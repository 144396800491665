/* .newPopularDestinationMaincarddiv{
    border: 1px solid red;
} */

.newPopularDestinationsContainer {
  /* border: 1px solid red; */
  height: 600px;
}

.newPopularDestinationMaincarddiv {
  height: fit-content;
  /* border: 1px solid red !important; */
  border: none !important;
  background-color: white !important;
}

.newPopularDestinationsHeadingouterdiv h2 {
  color: #3380f2;
  margin-left: 2%;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0.5%;
  margin-bottom: 2%;
}

.newPopularDestinationsHeadingouterdiv {
  display: flex;
  flex-direction: row;
}

.newPopularbelowselectdiv {
  /* border: 2px solid gold; */
  width: 45%;
  height: 35px;
  color: #393939;
  margin-left: 30%;
}

.newPopularbelowselectdivbuttons {
  width: 90%;
}

.internationalButton {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 10%;
  font-size: 17px !important;
  font-weight: 600;
  /* margin-left: 10% !important; */
  text-align: center !important;
}

.DomesticButton {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  font-size: 17px !important;
  font-weight: 600;
  text-align: center !important;
}

.newPopularbelowSelect {
  /* border: 2px solid red; */
  margin: auto;
  width: 20%;
  height: 35px;
  color: #393939;
  font-size: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 40%;
  border-bottom: 2px solid #393939;
}

.belowInternationalheading {
  margin-left: 2%;
  width: 12%;
  font-size: 28px;
  margin-bottom: 3%;
  border-right: 2px solid lightgray;
}

.belowDomesticHeading {
  margin-left: 1.5%;
  font-size: 28px;
  /* border: 2px solid red; */
}

.newPopularDestinationsSlidercontainer {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.newhomeTopCititesSlidercontentdiv {
  width: 30% !important;
}

.newhomeTopCititesSlidercontentdiv h2 {
  font-size: 28px;
  margin-left: 3%;
}

/* This css refernce is taken from the TopCitites main page if any issue look at that component */
.topcitiescontainer {
  /* border: 1px solid blue; */
  width: 100% !important;
  margin-bottom: 4%;
  /* background-color: green; */
}

.topcities_title {
  display: flex;
  margin-left: 40%;
  margin-bottom: 2%;
  margin-top: -3%;
  font-size: 40px;
  color: rgb(51, 128, 242);
}

.topcities_title h2 {
  color: rgb(51, 128, 242);
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0.4%;
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.newPopularDestinationsslickslidercontainer {
  width: 97%;
  margin: auto;
}

.card-wrapper {
  /* border: 1px solid black; */
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.newPopularDestinationsdiv {
  width: 100%;
  border: none;
  border-radius: 16px !important;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.newPopularDestinationscardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newPopularDestinationscardimage img {
  width: 100%;
  height: 100%;
  filter: brightness(0.9);
  -o-object-fit: cover;
  object-fit: cover;
}

.newPopularDestinationsdiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newPopularDestinationsdiv:hover .newPopularDestinationscardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newPopularDestinationsdetails {
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.newPopularDestinationsdetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.newPopularDestinationsdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.newPopularDestinationsdetails button {
  margin-left: 35%;
}

.newhomeTopCitiesBottomTexts {
  margin-top: -20%;
  z-index: 100000;
}

.newhomeTopCitiesBottomTexts h2 {
  text-align: center;
  font-size: 20px;
}

.newhomeTopCitiesBottomTexts p {
  text-align: center;
  font-size: 14px;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: whitesmoke !important;
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: white !important;
  background-color: blue !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

/* ----------------- New button css ----------------  */

#HolidaysPopularDestinationDropContainer {
  width: 9.5% !important;
  margin-left: 65%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  /* border: 2px solid #3380F2; */
  outline: none;
  padding-left: 15px;
}

#HolidaysPopularDestinationCountrydrop {
  background-color: #3380f2 !important;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 15px;
}

#HolidaysPopularDestinationicondiv {
  width: 20%;
  color: white;
  background-color: #3380f2;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

#HolidaysPopularDestinationicon {
  margin-left: -37%;
  color: white;
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: 47%;
}

.newPopularDestinationsicondiv {
  width: 20%;
  color: #3380f2;
  border: 3px solid #3380f2;
  background-color: White;
  height: 39px;
  font-size: 18px !important;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
  border: 2px solid yellow;
}

.newPopularDestinationsicon {
  margin-left: -37%;
  width: 100% !important;
  color: white !important;
  border: 3px solid #3380f2;
  background-color: #3380f2 !important;
  height: 25px;
  width: 25px;
  font-size: 18px !important;
  border-radius: 100%;
  margin-top: 29% !important;
  margin-bottom: 105%;
}

/* this is the css file for the different devices */
@media (max-width: 480px) {
  /* .newPopularDestinationMaincarddiv{
    border: 1px solid red;
} */

  .newPopularDestinationsContainer {
    /* border: 1px solid red; */
    height: 600px;
  }

  .newPopularDestinationMaincarddiv {
    height: fit-content;
    /* border: 1px solid red !important; */
    border: none !important;
    background-color: white !important;
  }

  .newPopularDestinationsHeadingouterdiv {
    display: flex;
    flex-direction: row;
    text-align: center !important;
  }

  .newPopularDestinationsHeadingouterdiv h2 {
    width: 100%;
    color: black;
    margin-left: 2%;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0.5%;
    margin-bottom: 4%;
  }

  .newPopularbelowselectdiv {
    /* border: 2px solid gold; */
    width: 100%;
    height: 35px;
    color: #393939;
    margin-bottom: 3% !important;
    margin-left: 0% !important;
  }

  .newPopularbelowselectdivbuttons {
    width: 100%;
    margin-bottom: 3%;
  }

  .internationalButton {
    outline: none;
    border: 2px solid #3380f2;
    width: 23.5%;
    height: 35px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 10%;
    font-size: 12px !important;
    font-weight: 500 !important;
    /* margin-left: 10% !important; */
    text-align: center !important;
  }

  .DomesticButton {
    outline: none;
    border: 2px solid #3380f2;
    width: 18%;
    height: 35px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
  }

  .newPopularbelowSelect {
    /* border: 2px solid red; */
    margin: auto;
    width: 20%;
    height: 35px;
    color: #393939;
    font-size: 22px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 40%;
    border-bottom: 2px solid #393939;
  }

  .belowInternationalheading {
    margin-left: 2%;
    width: 12%;
    font-size: 28px;
    margin-bottom: 3%;
    border-right: 2px solid lightgray;
  }

  .belowDomesticHeading {
    margin-left: 1.5%;
    font-size: 28px;
    /* border: 2px solid red; */
  }

  .newPopularDestinationsSlidercontainer {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }

  .newhomeTopCititesSlidercontentdiv {
    width: 30% !important;
  }

  .newhomeTopCititesSlidercontentdiv h2 {
    font-size: 28px;
    margin-left: 3%;
  }

  /* This css refernce is taken from the TopCitites main page if any issue look at that component */
  .topcitiescontainer {
    /* border: 1px solid blue; */
    width: 100% !important;
    margin-bottom: 4%;
    /* background-color: green; */
  }

  .topcities_title {
    display: flex;
    margin-left: 40%;
    margin-bottom: 2%;
    margin-top: -3%;
    font-size: 40px;
    color: rgb(51, 128, 242);
  }

  .topcities_title h2 {
    color: rgb(51, 128, 242);
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    margin-top: 0.4%;
    font-size: 28px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  .newPopularDestinationsslickslidercontainer {
    width: 97%;
    margin: auto;
  }

  .card-wrapper {
    /* border: 1px solid black; */
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .newPopularDestinationsdiv {
    width: 100%;
    border: none;
    border-radius: 16px !important;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .newPopularDestinationscardimage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newPopularDestinationscardimage img {
    width: 100%;
    height: 100%;
    filter: brightness(0.9);
    -o-object-fit: cover;
    object-fit: cover;
  }

  .newPopularDestinationsdiv:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newPopularDestinationsdiv:hover .newPopularDestinationscardimage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newPopularDestinationsdetails {
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: white;
  }

  .newPopularDestinationsdetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .newPopularDestinationsdetails h2 .job-title {
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    display: block;
  }

  .newPopularDestinationsdetails button {
    margin-left: 35%;
  }

  .newhomeTopCitiesBottomTexts {
    margin-top: -20%;
    z-index: 100000;
  }

  .newhomeTopCitiesBottomTexts h2 {
    text-align: center;
    font-size: 20px;
  }

  .newhomeTopCitiesBottomTexts p {
    text-align: center;
    font-size: 14px;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    background: whitesmoke !important;
    border: 1px solid rgb(51, 128, 242);
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: white !important;
    background-color: blue !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  /* ----------------- New button css ----------------  */

  #HolidaysPopularDestinationDropContainer {
    display: none !important;
  }

  #HolidaysPopularDestinationDropContainer {
    width: 9.5% !important;
    margin-left: 65%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white !important;
    font-weight: 700;
    justify-content: space-between;
    /* border: 2px solid #3380F2; */
    outline: none;
    padding-left: 15px;
  }

  #HolidaysPopularDestinationCountrydrop {
    background-color: #3380f2 !important;
    width: 80%;
    height: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 15px;
  }

  #HolidaysPopularDestinationicondiv {
    width: 20%;
    color: white;
    background-color: #3380f2;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  #HolidaysPopularDestinationicon {
    margin-left: -37%;
    color: white;
    height: 21px;
    width: 20px;
    border-radius: 100%;
    margin-top: 47%;
  }

  .newPopularDestinationsicondiv {
    width: 20%;
    color: #3380f2;
    border: 3px solid #3380f2;
    background-color: White;
    height: 39px;
    font-size: 18px !important;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
    border: 2px solid yellow;
  }

  .newPopularDestinationsicon {
    margin-left: -37%;
    width: 100% !important;
    color: white !important;
    border: 3px solid #3380f2;
    background-color: #3380f2 !important;
    height: 25px;
    width: 25px;
    font-size: 18px !important;
    border-radius: 100%;
    margin-top: 29% !important;
    margin-bottom: 105%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
