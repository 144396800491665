.newwhyChoosemaindiv {
  /* border: 2px solid red; */
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  background-color: #f3f7fa;
}

.newwhychooseheading {
  color: #393939;
  font-size: 25px;
  font-weight: 700;
  /* border: 2px solid red; */
  width: 100%;
  text-align: center;
  margin-top: 2%;
}

.newwhychooseOptions {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
}

.newwhychooseOptions1 {
  width: 33%;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.newWhychooseIcon1 {
  width: 30%;
  height: 150px;
  margin-left: 35%;
  margin-top: 5%;
  border-radius: 50%;
  background-color: white;
}

.newWhychooseIcon1 img {
  width: 60%;
  height: 90px;
  margin: 20%;
}

.newWhychooseContent {
  text-align: center;
  color: #393939;
}

.newWhychooseContent h4 {
  font-weight: 700;
  font-size: 20px;
}

.newWhychooseContent p {
  font-size: 15px;
}

/* css media query code for the responsive components */

@media (max-width: 480px) {
  .newwhyChoosemaindiv {
    /* border: 2px solid red; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #f3f7fa;
  }

  .newwhychooseheading {
    /* border: 2px solid red; */
    color: #393939;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 2%;
  }

  .newwhychooseOptions {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: row;
  }

  .newwhychooseOptions1 {
    border: 1px solid white;
    width: 33%;
    height: 220px;
    display: flex;
    flex-direction: column;
  }

  .newWhychooseIcon1 {
    /* border: 1px solid black; */
    width: 30%;
    height: 80px;
    margin-left: 35%;
    margin-top: 5%;
    background-color: white;
  }

  .newWhychooseIcon1 img {
    /* border: 1px solid red; */
    width: 100%;
    height: 70px;
    margin: 20%;
    object-fit: contain;
  }

  .newWhychooseContent {
    text-align: center;
    color: #393939;
  }

  .newWhychooseContent h4 {
    font-weight: 700;
    font-size: 14px;
  }

  .newWhychooseContent p {
    font-size: 10px;
    font-weight: 400;
    vertical-align: baseline;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
