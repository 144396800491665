
.staycationOptionOuterDiv{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    margin-top: 5%;
}

.iconsOptionDiv{
    /* border: 2px solid black; */
    width: 20%;
    margin-left: 5%;
}
/* .staticImageSection{
    border: 2px solid red;
} */

.headingContainer{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 5%;
}


.NewStaycationStaticBannersmainDiv{
    width: 90%;
    height: 600px;
    /* border: 2px solid red; */
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}
.NewStaycationStaticBannersDivOne{
    width: 30%;
    height: 600px;
    /* border: 2px solid gold; */
    margin-left: 1%;
    cursor: pointer;
}
.NewStaycationStaticBannerImageOne{
    width: 100%;
    height: 550px;
    /* border: 2px solid red; */
    /* margin-bottom: 10%; */
    border-radius: 2px;
    border-radius: 10px;
}
.NewStaycationStaticBannerImageOne img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.imageOffContentdiv p{
    /* border: 1px solid red; */
    position: absolute;
    background-color: #3380f2;
    margin-left: 22%;
    width: 5%;
    padding-left: 10px;
    padding-top: 3px;
    border-radius: 2px;
    height: 30px;
    color: white;
    /* margin-top: 0.2%; */
}
.NewStaycationStaticBannersDivTwo{
    width: 30%;
    height: 600px;
    /* border: 2px solid green; */
    margin-left: 2%;
}
.NewStaycationStaticBannerImageTwo{
    width: 100%;
    height: 270px;
    cursor: pointer;
    /* border: 2px solid red; */
    border-radius: 5    px;
    margin-bottom: 2%;
    /* margin-top: 5%; */
}
.NewStaycationStaticBannerImageTwo img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.NewStaycationStaticBannersDivThree{
    width: 30% !important;
    height: 600px;
    /* border: 2px solid green; */
    margin-left: 2%;
}
.NewStaycationStaticBannerImageThree{
    width: 100%;
    height: 270px;
    cursor: pointer;
    /* border: 2px solid red; */
    border-radius: 5px;
    margin-bottom: 2%;
    /* margin-top: 5%; */
}
.NewStaycationStaticBannerImageThree img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}