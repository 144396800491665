/* .hotelBookingPageBannerOuterDiv {
  border: 1px solid red;
} */

.hotelBookingImageContentWrapper {
  /* border: 2px solid lightgray; */
  border-radius: 5px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.hotelBookingBannerImageDiv {
  /* border: 1px solid black; */
  width: 50%;
  margin-top: 1%;
  height: 500px;
}

.hotelBookingBannerImageDiv img {
  width: 100%;
  height: 500px;
  border-radius: 5px;
}

.hoteBookingContextDiv {
  /* border: 2px solid red; */
  width: 50%;
  margin-left: 2%;
  margin-top: 1%;
}

.hotelBookingCalenderOuterDiv {
  /* border: 1px solid red; */
  position: relative;
  /* height: 100px; */
  display: flex;
  width: 100%;
  flex-direction: row;
}

.hotelBookingCalenderContainer {
  /* border: 1px solid black; */
  margin-left: 1%;
  width: 60%;
  display: flex;
  flex-direction: row;
}

#hotelBooking_departure {
  /* border: 1px solid red; */
  /* border: 2px solid #dddddd78; */
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  width: 63%;
  color: black !important;
  background-color: #dddddd78 !important;
  border-radius: 5px;
  height: 40px !important;
  /* border: 2px solid lightgray; */
  border-radius: 6px;
  margin-top: 0%;
}

.hotelBookingGuestIcon {
  /* border: 1px solid red; */
  font-size: 23px;
  margin-left: 4% !important;
  margin-top: 3% !important;
  /* background-color: #dddddd78 !important; */
  color: black !important;
  /* background-color: #8DBBFF; */
}

#hotelBooking_departure h3 {
  /* border: 1px solid black; */
  font-size: 15px !important;
  /* font-weight: 700; */
  /* margin-left: -7%; */
  padding: 7px;
  color: grey !important;
  padding-left: 15px !important;
  padding-top: 4%;
  margin-top: 0.5%;
  /* height: 35px;
  border: 2px solid lightgray;
  border-radius: 6px;
  margin-top: 8%; */
}

#hotelBooking_departure:hover .hotelBoookingdropdown_content {
  display: block;
}

.hotelBoookingdropdown_content {
  display: none;
  position: absolute;
  color: white;
  background-color: #3380f2 !important;
  width: 200px;
  height: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 2.6%;
}
.hotelBoookingdropdown_content div:hover {
  background-color: white !important;
  color: #3380f2;
}

.navbarHotelBookingPageOptions {
  margin-top: 2%;
  padding-top: 12px;
  padding-bottom: 12px;
  /* border: 2px solid red; */
}

.hotelBookingCalenderInnerDiv {
  /* border: 1px solid black; */
  width: 47%;
  margin-right: 3%;
}

.hotelBookingCalenderInnerDiv1 {
  /* border: 1px solid black; */
  width: 47%;
}

.hotelBookingCalenderInnerDiv1 input {
  /* border: 1px solid red; */
  position: absolute;
  /* margin-left: 45%;   */
  border-radius: 5px;
  height: 40px;
  background-color: #dddddd78;
  border: none !important;
  padding-left: 10px;
  width: 23%;
}

.hotelBookingCalenderInnerDiv input {
  /* border: 1px solid red; */
  position: absolute;
  /* margin-left: 45%;   */
  border-radius: 5px;
  height: 40px;
  background-color: #dddddd78;
  border: none !important;
  padding-left: 10px;
  width: 23%;
}

/* .hotelBookingCalenderInnerDiv input::placeholder{
 
} */

.hotelBookingPersonsInnerDiv {
  /* border: 1px solid green; */
  width: 50%;
  display: flex;
  flex-direction: row;
  /* margin-left: 7%; */
}

.hotelBookingSearchButtonOuterDiv {
  /* border: 1px solid purple; */
  width: 15%;
  margin-left: 2%;
}

.hotelBookingSearchButtonInnerDiv {
  /* border: 1px solid yellow; */
  width: 100%;
  border-radius: 5px;
  height: auto;
  background-color: #3380f2;
  height: 39px;
  color: white !important;
}

.hotelBookingHotelContentDiv{
    /* border: 1px solid red; */
    margin-left: 1%;
}

.hotelBookinHotelName {
  /* border: 1px solid black; */
  margin-top: 4%;
  height: 100px;
  display: flex;
  flex-direction: row;
}

.hotelBookingHotelHeading {
  /* border: 1px solid red; */
  width: 40%;
}

.hotelBookingHotelHeading h2 {
  font-size: 30px;
}

.hotelBookingHotelInfoDiv {
  /* border: 1px solid red; */
  width: 70%;
}

.hotelBookingHotelInfoDiv p {
  font-size: 15px;
  margin-left: 10%;
}

.hotelBookingRatingDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
  margin-top: -4%;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.hoteBoookingRatingExtraDiv {
  /* border: 1px solid black; */
  margin-left: 18%;
}

.hoteBoookingRatingExtraDiv h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10%;
}

.hotelBookingRatingNumber h3 {
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 50%;
}

.hotelBookingHotelReview {
  /* border: 1px solid green; */
  margin-left: 1%;
}

.hotelBookingHotelReview p {
  /* border: 1px solid black; */
  row-gap: -5px !important;
  font-size: 13px;
  font-weight: 500;
  margin-top: 12%;
}

.miniContext {
  margin-top: -14% !important;
}

.hotelBookingPriceDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
}

.hotelBoookingPriceDisplayInnerDiv {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
}

.hotelBookingPriceDisplayDiv h3 {
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 40%;
}

.hotelBookingPriceDescription {
  margin-left: 1%;
}

.hotelBookingPriceDescription p {
  /* border: 1px solid red; */
  font-size: 13px;
  font-weight: 500;
  margin-top: 25%;
}

.hotelBookingButton {
  /* border: 1px solid red !important; */
  margin-top: 4%;
  margin-left: 10%;
  width: 50%;
}

.hotelBookingButton button {
  width: 40%;
  margin-left: 58%;
  padding-left: 28px;
}

.hotelBookingButton button:hover{
  background-color: white;
  border: 1px solid #3380f2;
  color: #3380f2;
}

.miniContextPrice {
  /* border: 1px solid red; */
  margin-top: -1% !important;
}

.hotelBookingOtherHotelsDiv {
  border-top: 2px solid black;
  width: 95%;
  margin-top: 2%;
}

.hotelBookingOtherHotelsDiv h5 {
  margin-top: 2%;
  margin-left: 1%;
}

/* .hotelBookingOtherHotelInnerDiv{
  border: 1px solid black;
 
}
 */

.hotelBookingOtherHotelSubDiv1 {
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.hotelBookingOtherHotelSubDiv2 {
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.hotelBookingOtherHotelSubDiv3 {
  /* border-bottom: 1px solid lightgray; */
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.hotelBookingOtherHotelChildDiv1 {
  /* border: 1px solid black; */
  width: 10%;
}

.hotelBookingOtherHotelChildDiv2 {
  /* border: 1px solid purple; */
  width: 40%;
}
.hotelBookingOtherHotelChildDiv2 p {
  margin-top: 5%;
  margin-left: -2%;
  color: gray;
}

/* .hotelBookingOtherHotelChildDiv3{
  border: 1px solid green;
  height: 30px;

} */

.hotelBookingOtherHotelChildDiv3  {
  /* border: 1px solid red; */
  margin-left: 30%;
  /* margin-top: 10%; */
}

.hotelBookingChildButton {
  /* border: 1px solid red; */
  height: 33px;
  width: 100%;
  margin-top: 8%;
  padding-top: 3px;
  /* margin-left: 150%; */
  /* padding-left: 1px !important; */
}


.hotelBookingChildButton:hover{
  background-color: white;
  border: 1px solid #3380f2;
  color: #3380f2;
}
