.InnerhandPicked1SliderCardsmainDiv{
    width: 100% !important;
    margin: auto;
    /* border: 1px solid red; */
}
.InnerhandPicked1SliderCardsContainer{
    width: 25%;
}
.InnerhandPicked1SliderCardsDataContainer{
    width: 100%;
    height: 400px;
    /* border: 2px solid red; */
}
.InnerhandPicked1SliderCardsTitle{
    font-size:18px;
    font-weight:700;
    text-align:left;
}
.InnerhandPicked1SliderCardsdetails{
    font-size: 13px;
    text-align: left;
}
.InnerHandpicked1SliderButton{
    /* border: 2px solid red; */
    width: 29%;
    height: 37px;
    font-size: 14px;
    font-weight: 700;
    margin-top: -4%;
    margin-left: 68%;
    padding-left: 14px;
}