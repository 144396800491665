.FlightPageGoodtoKnowmaindiv{
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    margin-top: 12%;

}
.flighttopdiv{
    /* border: 2px solid blue; */
    font-size: 25px;
    font-weight: 700;
    padding-left: 5%;

}

.flighttopdiv1{
    display: flex;
    flex-direction: row;
    /* border: 2px solid green ; */
    padding-left: 8%;
    /* padding-top: 2%; */
    /* width: 90%; */
    margin-top: 1.5%;
}

.seasondiv{
    /* border: 1px  solid gray; */
    width:15% ;
    margin-left: 7%;
    padding-top: 2%;
    padding-left: 2%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7%;
    

}

.seasondiv1{
    /* border: 1px  solid gray; */
    width:15% ;
    margin-left: 7%;
    padding-top: 2%;
    padding-left: 2%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7%;
}


.intrectcolor{
    color: rgb(62, 152, 182);

}

.intrectwithheading{
    font-size: 22px;
    font-weight: 700;
    
}
.flighttopdiv2{
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
    padding-left: 8%;
    padding-top: 2%;
}