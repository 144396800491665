.extendedCardOuterDiv {
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 1%;
}

.extendedCard {
  /* border: 2px solid red; */
  border: none !important;
}

.discoverExtendedStayMainDiv {
  /* border: 1px solid red; */
  text-align: center;
}

.extendedImageContent {
  /* border: 2px solid red; */
  margin-top: 3% !important;
  font-size: 32px !important;
  font-weight: 600;
}

.extendedImageTextDiv {
  /* border: 1px solid black; */
  font-size: 20px;
  font-weight: 400;
}

.extendedImageButtonDiv {
  /* border: 1px solid red !important; */
  width: 12%;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.extendedImageButtonDiv:hover {
  color: #3380f2 !important;
  background-color: white !important;
}

/* this css file is for the responsive comps for the different devices */

@media (max-width: 480px) {
  .extendedCardOuterDiv {
    /* border: 2px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 5%;
  }

  .discoverExtendedImageDiv {
    /* border: 1px solid red; */
    width: 100%;
    height: 200px;
  }

  .extendedCard {
    /* border: 2px solid red; */
    border: none !important;
  }

  .discoverExtendedStayMainDiv {
    /* border: 1px solid red; */
    text-align: center;
  }

  .extendedImageContent {
    /* border: 2px solid red; */
    margin-top: 3% !important;
    font-size: 28px !important;
    font-weight: 600;
  }

  .extendedImageTextDiv {
    /* border: 1px solid black; */
    font-size: 20px;
    font-weight: 400;
  }

  .extendedImageButtonDiv {
    /* border: 1px solid red !important; */
    width: 40%;
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .extendedImageButtonDiv:hover {
    color: #3380f2 !important;
    background-color: white !important;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
