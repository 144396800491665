.newSimilarPopularDestinationcontainer{
  /* border: 1px solid rgb(0, 255, 26); */
  width: 88%;
  margin: auto;
  height: 450px;
  margin-top: 3%;
}

.newSimilarPopularDestinationHeadingContainer{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* padding-bottom: 1%; */
  font-weight: 700;
  font-size: 25px;
  margin-left: 2%;
}

.newSimilarPopularDestinationHeadingContainer h2{
  font-size: 30px;
  font-weight: 700;
  font-family: 'TT Hoves Variable',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
  /* margin-top: 50%; */
  /* margin-left:1%; */
  margin-bottom: 2%;
  width:65%;
  color: #393939   !important;
}


/* .clientstestimonial_title {
  font-size: 27px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(45,44,44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51,128,242);
} */


/* .newSimilarPopularDestinationwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
} */


/* .newMultisection_SimilarPopularDestinationimage {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
} */

/* 
.newSimilarPopularDestinationcardImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 400px !important;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newSimilarPopularDestinationcardImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
} */

/* .newMultisection_SimilarPopularDestinationimage:hover img {
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newMultisection_SimilarPopularDestinationimage:hover .newSimilarPopularDestinationcardImage {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: all 0.9s;
  transition: all 0.9s;
} */

/* .newWorldSimilarPopularDestinationdetails {
  position: absolute;
  bottom: -62%;
  left: 0;
  background: #3380f2;
  width: 100%;
  height: 220px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
 */
/* .newWorldSimilarPopularDestinationdetails h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
  color: white;
}
 */
/* .newWorldSimilarPopularDestinationdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  color: white;
  font-weight: 300;
  display: block;
}

.card:hover .newWorldSimilarPopularDestinationdetails {
  bottom: 0;
} */

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}



.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}


/* .clientstestimonial_title {
  font-size: 27px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(45,44,44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51,128,242);
} */



/* .newMultisection_SimilarPopularDestinationimage {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
 */
/* .newSimilarPopularDestinationcardImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
 */
/* .newSimilarPopularDestinationcardImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newMultisection_SimilarPopularDestinationimage:hover img {
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newMultisection_SimilarPopularDestinationimage:hover .newSimilarPopularDestinationcardImage {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: all 0.9s;
  transition: all 0.9s;
} */

/* .newWorldSimilarPopularDestinationdetails {
  position: absolute;
  bottom: -50%;
  left: 0;
  background: #3380f2;
  width: 100%;
  height: 213px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.newWorldSimilarPopularDestinationdetails h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.newWorldSimilarPopularDestinationdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  color: white;
  font-weight: 300;
  display: block;
}

.card:hover .newWorldSimilarPopularDestinationdetails {
  bottom: 0;
} */

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}



.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}
  

.newSimilarPopularDestinationwrapper {
  position: relative;
  width: 90%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 2px solid red; */
}
.newMultisection_SimilarPopularDestinationimage {
margin-left: 1%;
width: 95%;
height: 350px;
/* border: 2px solid #3380F2 !important; */
border-radius: 20px !important;
overflow: hidden;
cursor: pointer;
position: relative;
/* background-color: #3380F2; */
}
.newMultisection_SimilarPopularDestinationimage:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newMultisection_SimilarPopularDestinationimage:hover .newSimilarPopularDestinationcardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newSimilarPopularDestinationcardImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  border-radius: 20px !important;
  z-index: 2;
  /* border: 2px solid red !important; */
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newSimilarPopularDestinationcardImage img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
}
.newMultisection_SimilarPopularDestinationimage:hover .newSimilarPopularDestinationcardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
.newWorldSimilarPopularDestinationdetails {
  position: absolute;
  text-align: center;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 15px;
}

.newWorldSimilarPopularDestinationdetails h5 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white !important;
  font-size: 25px;
  font-weight: 700;
}

.newWorldSimilarPopularDestinationdetails h5 .job-title {
  font-size: 22px;
  line-height: 1.5;
  color: white;
  font-weight: 300;
  display: block;
}

/* .card:hover .newWorldSimilarPopularDestinationdetails {
  bottom: 0;
} */


/* ----------------- view all deals button css ----------------  */


.newSimilarPopularDestinationdealsbutton {
  width: 9%;
  height: 35px !important;
  margin-left: 22%;
  margin-top: 3%;
  display: flex;
  padding-top: 3px;
  padding-left: 8px;
  border: 2px solid #3380F2;
  border-radius: 8px;
  flex-direction: row;
  border-right: none;
  height: 40px;
  color: #3380F2;
  font-weight: 700;
  border: 2px solid #3380F2;
  border-radius: 25px;
}
.newSimilarPopularDestinationdealsbutton:hover{
  background-color:#3380F2;
  color: white;
}



/* -----------------------------zoomming effect ----------------------------*/

.cardtextSimilarPopularDestinationmostpicked_maindiv{
  width: 115% !important;
  height: 410px !important;
  text-align: center;
  /* border: 2px solid gold; */
  transform: scale(0.8);
  transition: .7s;
  border-radius: 20px;
  margin-left: -3%;
  margin-top: -11%;
  width: 102%;
  border-radius: 20px !important;
  overflow: hidden !important;
  cursor: pointer;
  position: relative;
  text-align: center;
}

/* .cardtextSimilarPopularDestinationmostpicked_maindiv {
margin-left: 3%;
width: 102%;
border-radius: 20px !important;
overflow: hidden !important;
cursor: pointer;
position: relative;
text-align: center;
} */

.SimilarPopularDestinationsMostPickedzoomming >img {
width: 100%;
height: 100%;
border-radius: 25px;
}

.cardtextSimilarPopularDestinationmostpicked_maindiv:hover .SimilarPopularDestinationsMostPickedzoomming >img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}


.SimilarPopularDestinationsMostPickedzoomming {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border-radius: 20px !important;
  z-index: -1;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.SimilarPopularDestinationsMostPickedzoomming img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  position: relative;
}
