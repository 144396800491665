.footerparentcontainer {
  display: flex;
  height: 360px;
}

.mapimagediv{
  /* border: 2px solid blue; */
  width: 40%;
}
.footer {
  width: 100%;
  background: #3380f2;
  display: flex;
  justify-content: center;
  column-gap: 1%;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

.footercontainer {
  /* border: 2px solid red; */
  /* background: #3380f2; */
  display: flex;
}

.footerSectionMapcontainer {
  /* border: 1px solid red; */
  width: 100%;
  height: 400px;
  /* padding: 21px; */
  display: flex;
  flex-direction: column;
  background: #3380f2;
  /* border: 2px solid blue; */
  row-gap: 16px;
  color: white;
}

.footerSectionMapcontainer h2 {
  margin-left: 15%;
  margin-top: 8%;
  text-transform: uppercase;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* .footerSectionMapcontainer img{
  border: 1px solid red;
  width: 100%;
} */

/* .footerSectionMapdiv img{
  width: 100%;
  object-fit: contain;
} */

.footerSectionbottom {
  width: 30%;
  padding: 45px;
  display: flex;
  flex-direction: column;
  /* border: 2px solid blue; */
  row-gap: 16px;
  color: white;
}

.footerSectionbottom h4 {
  margin-bottom: 5%;
  text-align: left;
  font-size: 28px;
}

.footerSectionMapdiv {
  width: 18%;
  padding: 21px;
  display: flex;
  flex-direction: column;
  /* border: 2px solid blue; */
  row-gap: 16px;
  color: white;
}
.footerSectionMapdiv .footerMap {
  border: 1px solid red;
  margin-left: -60%;
  height: 285px;
  margin-top: 22%;
}
.footerMap img {
  border: 1px solid blue;
  width: 100%;
}

.footerSectionbottom .footerLogo {
  font-size: 24px;
  margin-top: 0%;
  line-height: 45px;
  font-weight: 800;
  text-transform: uppercase;
}

.footerLogo img {
  width: 70%;
  margin: 0px 0px;
}
 
.footerSectionbottom .footerlogodesc{
  /* border: 1px solid red !important; */
  font-size: 13px;
}
.footer-heading {
  font-size: 15px;
  margin-top: 0%;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 22.5px;
  text-transform: uppercase;
}

h4 img {
  border-radius: 56%;
}
.footersocialmedia {
  display: flex;
  width: 100%;
  /* margin-top: 24% !important; */
  justify-content: center;
  column-gap: 12px;
}

.socialmedia div {
  width: 54%;
  height: 40px;
  margin: 20px 0;
  background-color: white;
  border-radius: 20px;
}
.socialIcons {
  color: #dee2e6;
  font-size: 28px;
  padding: 10%;
}
.socialIcons:hover {
  color: rgb(2, 2, 86);
}

.footerLogo {
  font-size: 32px;
  color: white;
}
.footerLogo img {
  margin: 0px 3px;
}

.blogs {
  display: flex;
  column-gap: 12px;
}
.blogImg {
  width: 75px;
  height: 75px;
}

.blogsecond {
  padding-top: 1%;
}

.blogImg img {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.blogText {
  width: 70%;
  text-align: left;
}
.blogText p {
  margin: 0;
  font-size: 13px;
  color: white;
}
.blogText p:nth-child(1) {
  color: white;
  margin-top: 5%;
}
.blogText p:nth-child(1):hover {
  color: rgb(2, 2, 86);
}

.tags {
  display: flex;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
.tags button {
  border: 1px solid red;
  padding: 6px 21px;
  font-size: 16px;
  background: none;
  border: 1px solid white;
  border-radius: 1px;
  color: white;
}
.tags button:hover {
  background-color: rgba(255, 255, 255, 0.877);
  color: blue;
}

.footerContectText {
  width: 70%;
  text-align: left;
}
.footerContectText p {
  margin: 0;
  padding: 0 10px;
  color: white;
}

.footerContect {
  display: flex;
  column-gap: 5px;
}

.footerContect p {
  /* border: 1px solid red; */
  text-decoration: none;
  color: white;
  font-size: 13px;
  text-align: left;
  margin: 0;
}

#contactdetails {
  width: 35%;
}

.footerContect {
  font-size: 15px;
}

.contact_number > p {
  margin: 0;
}
.footerContect p:hover {
  color: rgb(2, 2, 86);
}
.footerContect img {
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.contecticonsdiv {
  height: 40px;
}

.contecticonsmap {
  font-size: 26px;
  color: white;
}

.contecticonscall {
  font-size: 26px;
}

.contecticonsglobe {
  font-size: 23px;
}

.contecticonsmail {
  font-size: 26px;
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .footer {
    flex-direction: column;
  }
  .footerSectionbottom {
    width: 100%;
  }

  .footerLogo {
    font-size: 25px;
    color: white;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1400px) {
  .footer {
    flex-direction: column;
  }
  .footerSectionbottom {
    width: 100%;
  }
}
