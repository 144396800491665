.newInternationalCruisecontainer {
  width: 100%;
  margin: auto;
  height: 500px;
}

.newInternationalCruiseHeadingContainer {
  display: flex;
  flex-direction: row;
}

.newInternationalCruiseHeadingContainer h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 3%;
  margin-left: 1%;
  margin-bottom: 2%;
  width: 65%;
  color: #3380f2;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

.newInternationalCruisewrapper {
  position: relative;
  width: 112% !important;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.newMultisection_InternationalCruiseimage {
  margin-left: 1%;
  width: 95%;
  height: 350px;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.newMultisection_InternationalCruiseimage:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newMultisection_InternationalCruiseimage:hover
  .newInternationalCruisecardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newInternationalCruisecardImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  border-radius: 20px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newInternationalCruisecardImage img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
}

.newMultisection_InternationalCruiseimage:hover
  .newInternationalCruisecardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newWorldInternationalCruisedetails {
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 15px;
}

.newWorldInternationalCruisedetails h5 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.newWorldInternationalCruisedetails h5 .job-title {
  font-size: 13.5px;
  line-height: 1.5;
  color: white;
  font-weight: 300;
  display: block;
}

/* ----------------- view all deals button css ----------------  */

.newInternationalCruisedealsbutton {
  width: 9%;
  height: 35px !important;
  margin-left: 22%;
  margin-top: 3%;
  display: flex;
  padding-top: 3px;
  padding-left: 8px;
  border: 2px solid #3380f2;
  border-radius: 8px;
  flex-direction: row;
  border-right: none;
  height: 40px;
  color: #3380f2;
  font-weight: 700;
  border: 2px solid #3380f2;
  border-radius: 25px;
}

.newInternationalCruisedealsbutton:hover {
  background-color: #3380f2;
  color: white;
}


/* this is the css file code for the multi device comps responsive */
@media (max-width: 480px) {
  .newInternationalCruisecontainer {
    width: 100%;
    margin: auto;
    height: 500px;
  }

  .newInternationalCruiseHeadingContainer {
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 70px;
  }

  .newInternationalCruiseHeadingContainer h2 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 3%;
    margin-left: 1%;
    margin-bottom: 2%;
    width: 100%;
    color: black;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  .newInternationalCruisewrapper {
    position: relative;
    width: 112% !important;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .newMultisection_InternationalCruiseimage {
    margin-left: 1%;
    width: 95%;
    height: 350px;
    border-radius: 20px !important;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .newMultisection_InternationalCruiseimage:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newMultisection_InternationalCruiseimage:hover
    .newInternationalCruisecardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newInternationalCruisecardImage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    border-radius: 20px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newInternationalCruisecardImage img {
    width: 100%;
    filter: brightness(0.8);
    -o-object-fit: cover;
    object-fit: cover;
  }

  .newMultisection_InternationalCruiseimage:hover
    .newInternationalCruisecardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newWorldInternationalCruisedetails {
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    line-height: 15px;
  }

  .newWorldInternationalCruisedetails h5 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .newWorldInternationalCruisedetails h5 .job-title {
    font-size: 13.5px;
    line-height: 1.5;
    color: white;
    font-weight: 300;
    display: block;
  }

  /* ----------------- view all deals button css ----------------  */

  .newInternationalCruisedealsbutton {
    display: none !important;
  }  

  .newInternationalCruisedealsbutton {
    width: 9%;
    height: 35px !important;
    margin-left: 22%;
    margin-top: 3%;
    display: flex;
    padding-top: 3px;
    padding-left: 8px;
    border: 2px solid #3380f2;
    border-radius: 8px;
    flex-direction: row;
    border-right: none;
    height: 40px;
    color: #3380f2;
    font-weight: 700;
    border: 2px solid #3380f2;
    border-radius: 25px;
  }

  .newInternationalCruisedealsbutton:hover {
    background-color: #3380f2;
    color: white;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
