.container_NewApplication_footprint{
    width: 95%;
    height: auto;
    /* border: 2px solid red; */
    margin: auto;
    margin-top: 2%;
    position: relative;
}

.container_NewApplication_footprint h4{
    color: #3380f2;
    font-weight: 700;
    font-size: 30px;
    margin-left: 4%;
}


.container_footpront::before{
    content: "";
    background-image:url("https://etimg.etb2bimg.com/photo/93229844.cms");
    background-size: cover;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #000;
}
.container_NewApplication_child{
    width: 100%;
    height: 270px;
    border-radius: 20px;
    /* border: 2px solid #3380f2; */
    /* margin: auto; */
    /* margin-top: 2.5%; */
    /* background-color: whitesmoke; */
    /* opacity: 0.9; */
}

.down_NewApplication_child{
    width: 100%;
    height: 70px;
    margin: auto;
    /* color: #3380f2; */
    margin-bottom: 3%;
    border-radius: 20px 20px 0px 0px;
    padding-top: 1%;
    text-align: left;
    /* background-color: whitesmoke; */
    /* margin-top:  %; */
    margin-left: 2%;
    /* opacity: 0.9; */
    font-weight: 700 !important;
    /* border: 2px solid #3380F2; */
    
}
.down_NewApplication_child h1{
    font-weight: 600 !important;
    /* border: 1px solid red; */
    font-size: 32px;
    margin-left: 2% !important;
    color: #3380f2;
}
.details_NewApplication_card{
    display: flex;
    height: 100px;
    /* border: 2px solid red; */
    color: #3380f2;
}
.details_NewApplication_card h1{
    font-weight: 700;
}
.details_NewApplication_card h5{
    font-weight: 700;
}
.details_NewApplication_card h2{
    font-weight: 700;
}
.details_NewApplication_card>div{
    height:100% ;
    text-align: center;
}

.newApplicationbottomcontainer{
    /* border: 1px solid red; */
    padding-top: 12px;
    /* background-color: #3380f2; */
    color: #3380F2;
    width: 15%;
}

.newApplicationbottomcontainer1{
    /* border: 1px solid red; */
    padding-top: 18px;
    width: 16%;
    text-align: center;
   /* background-color: #3380f2; */
   color: #3380F2;
   margin-left: 3%;
   border-left: 1px solid #3380F2;
   /* border-right: 1px solid #3380F2; */
}

.newApplicationbottomcontainer2{
    /* border: 1px solid red; */
    padding-top: 12px;
   /* background-color: #3380f2; */
    color: #3380F2;
    margin-left: 3%;
    width: 18%;
    border-left: 1px solid #3380F2;
   border-right: 1px solid #3380F2;
}

.newApplicationbottomcontainer3{
    /* border: 1px solid red; */
    padding-top: 12px;
    width: 15%;
    /* background-color: #3380f2; */
    color: #3380F2;
    margin-left: 3%;
}
.processApplicationimage{
    /* border: 2px solid red; */
    width: 20%;
    height: 284px !important;
    margin-top: -10%;
    background-image: url("./planeanimation.gif");
    background-size: cover;
    object-fit: cover;
}

@media screen and (min-width: 300px) and (max-width: 500px) {

    .container_NewApplication_footprint{
        margin-top: 10%;
        /* border: 2px solid red; */
        height: auto;
    }
    
    .container_NewApplication_footprint h4{
        font-size: 20px;
        margin-left: 4%;
        color: #393939;
    }
    .down_NewApplication_child h1{
        font-size: 18px;
        color: #393939;
    }
    .processApplicationimage{
        background-image: none;
    }
    .details_NewApplication_card{
        /* border: 2px solid  red; */
        margin: auto;
        width: 90%;
    }
    .details_NewApplication_card h1{
        font-size: 18px;
        /* border: 2px solid red; */
    }
    .details_NewApplication_card h5{
        font-size: 16px;
        font-weight: 400;
        /* border: 2px solid gold; */
    }
    
    .newApplicationbottomcontainer{
        width: 30%;
    }
    .newApplicationbottomcontainer1{
        width: 25%;
        padding-left: 15px;
    }
    .newApplicationbottomcontainer2{
        width: 25%;
        padding: 15px;
    }
    .newApplicationbottomcontainer3{
        width: 20%;
    }
}



