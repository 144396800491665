.popularDestinationOutercontainer {
  height: 520px;
}

.newPopularStaycationHeadingContainer {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
}

.newPopularStaycationlink {
  text-decoration: none;
  height: 430px;
}

.popularStaycationpageheading {
  font-size: 30px !important;
  font-weight: 700 !important;
  width: 30%;
  margin-left: 5.5%;
  margin-top: 2.1% !important;
  font-weight: 700;
  margin-bottom: 2%;
  color: #3380f2;
}

.handmadesicon {
  width: 10%;
  color: #3380f2 !important;
  border: 3px solid #3380f2;
  background-color: #3380f2 !important;
  height: 43px;
  font-size: 18px !important;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

.newHandPickedCountryDrop {
  margin-left: 20%;
  color: white !important;
  font-weight: 700;
  cursor: pointer;
  height: 43px !important;
  border-radius: 20px 0px 0px 20px !important;
  background-color: #3380f2 !important;
  border-right: none !important;
  border: 2px solid red !important;
  width: 52% !important;
}

.newHandPickedCountryDrop:hover {
  background-color: white !important;
  color: #3380f2 !important;
  font-weight: 700;
}

.handpickednewhomepagesliderbuttons {
  color: white;
  border-radius: 20px;
  display: flex;
  margin: 1% 6% 1% 52%;
  width: 5%;
}

.handpickednewhomepagebuttons {
  justify-content: flex-end;
  width: -1%;
}

.popularStaycationpageheadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #3380f2 !important;
  height: 75px;
  margin-top: 2%;
}

.newPopularStaycationsCardmaindiv {
  width: 90%;
  margin: auto;
}

.popularStaycationCardwrapper {
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.handpickedcard {
  width: 100%;
  height: 350px;
}

.handpickedcard:hover .handpickedcard-img-top {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.newPopularStaycation_title1 {
  position: absolute;
  top: 80%;
  left: 7%;
  height: 70px;
  width: 100%;
}

.newPopularStaycationcard-title {
  color: white;
  font-size: 20px;
  margin-top: 1%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  z-index: 2;
  position: absolute;
}

.handpickednewhomepagebottomheading {
  top: 65%;
  background-color: white;
  border-radius: 12px;
  left: 10%;
  width: 35%;
  height: 26px;
}

.handpickednewhomepagebottomheading h1 {
  color: #3380f2;
  font-size: 18px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 9px;
}

.newPopularStaycationCard_img {
  margin-left: 0% !important;
  height: 350px !important;
  width: 100% !important;
  border-radius: 20px !important;
  overflow: hidden !important;
  margin-top: 3%;
}

.newPopularStaycationCard_img {
  margin-left: 3%;
  width: 100%;
  border-radius: 20px !important;
  overflow: hidden !important;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.testhover > img {
  width: 100%;
  height: 100%;

  border-radius: 25px;
}

.newPopularStaycationCard_img:hover .testhover > img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.testhover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border-radius: 20px !important;
  z-index: -1;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
}

.testhover img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  position: relative;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background-color: darkblue !important;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.popularStaycationCardwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

/* ----------------- New button css ----------------  */

#newPopularStaycationDropContainer {
  width: 9% !important;
  margin-left: 48.5%;
  margin-top: 2.1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  /* border: 2px solid #3380F2; */
  outline: none;
  padding-left: 15px;
}

#newPopularStaycationCountryDrop {
  background-color: #3380f2 !important;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 15px;
}

#newPopularStaycationicondiv {
  width: 20%;
  color: white;
  background-color: #3380f2;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

#newPopularStaycationicon {
  margin-left: -37%;
  color: white;
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: 47%;
}

/* this is the css code for the comps to be the multi device responsive */

@media (max-width: 480px) {
  .popularDestinationOutercontainer {
    height: 520px;
  }

  .newPopularStaycationHeadingContainer {
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
  }

  .newPopularStaycationlink {
    text-decoration: none;
    height: 430px;
  }

  .popularStaycationpageheading {
    font-size: 30px !important;
    font-weight: 700 !important;
    width: 100%;
    text-align: center;
    margin-left: 5.5%;
    margin-top: 2.1% !important;
    font-weight: 700;
    margin-bottom: 2%;
    color: black;
  }

  .handmadesicon {
    width: 10%;
    color: #3380f2 !important;
    border: 3px solid #3380f2;
    background-color: #3380f2 !important;
    height: 43px;
    font-size: 18px !important;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  .newHandPickedCountryDrop {
    margin-left: 20%;
    color: white !important;
    font-weight: 700;
    cursor: pointer;
    height: 43px !important;
    border-radius: 20px 0px 0px 20px !important;
    background-color: #3380f2 !important;
    border-right: none !important;
    border: 2px solid red !important;
    width: 52% !important;
  }

  .newHandPickedCountryDrop:hover {
    background-color: white !important;
    color: #3380f2 !important;
    font-weight: 700;
  }

  .handpickednewhomepagesliderbuttons {
    color: white;
    border-radius: 20px;
    display: flex;
    margin: 1% 6% 1% 52%;
    width: 5%;
  }

  .handpickednewhomepagebuttons {
    justify-content: flex-end;
    width: -1%;
  }

  .popularStaycationpageheadingcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #3380f2 !important;
    height: 75px;
    margin-top: 2%;
  }

  .newPopularStaycationsCardmaindiv {
    width: 90%;
    margin: auto;
  }

  .popularStaycationCardwrapper {
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .handpickedcard {
    width: 100%;
    height: 350px;
  }

  .handpickedcard:hover .handpickedcard-img-top {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .newPopularStaycation_title1 {
    position: absolute;
    top: 80%;
    left: 7%;
    height: 70px;
    width: 100%;
  }

  .newPopularStaycationcard-title {
    color: white;
    font-size: 20px;
    margin-top: 1%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    z-index: 2;
    position: absolute;
  }

  .handpickednewhomepagebottomheading {
    top: 65%;
    background-color: white;
    border-radius: 12px;
    left: 10%;
    width: 35%;
    height: 26px;
  }

  .handpickednewhomepagebottomheading h1 {
    color: #3380f2;
    font-size: 18px;
    font-weight: 700;
    padding-top: 3px;
    padding-left: 9px;
  }

  .newPopularStaycationCard_img {
    margin-left: 0% !important;
    height: 350px !important;
    width: 100% !important;
    border-radius: 20px !important;
    overflow: hidden !important;
    margin-top: 3%;
  }

  .newPopularStaycationCard_img {
    margin-left: 3%;
    width: 100%;
    border-radius: 20px !important;
    overflow: hidden !important;
    cursor: pointer;
    position: relative;
    text-align: center;
  }

  .testhover > img {
    width: 100%;
    height: 100%;

    border-radius: 25px;
  }

  .newPopularStaycationCard_img:hover .testhover > img {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .testhover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    border-radius: 20px !important;
    z-index: -1;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
  }

  .testhover img {
    width: 100%;
    filter: brightness(0.8);
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
    position: relative;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    background-color: darkblue !important;
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  img {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .popularStaycationCardwrapper {
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    background: rgb(51, 128, 242);
    border: 1px solid rgb(51, 128, 242);
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  /* ----------------- New button css ----------------  */

  #newPopularStaycationDropContainer {
    display: none !important;
  }

  #newPopularStaycationDropContainer {
    width: 9% !important;
    margin-left: 48.5%;
    margin-top: 2.1%;
    display: flex;
    flex-direction: row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white !important;
    font-weight: 700;
    justify-content: space-between;
    /* border: 2px solid #3380F2; */
    outline: none;
    padding-left: 15px;
  }

  #newPopularStaycationCountryDrop {
    background-color: #3380f2 !important;
    width: 80%;
    height: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 15px;
  }

  #newPopularStaycationicondiv {
    width: 20%;
    color: white;
    background-color: #3380f2;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  #newPopularStaycationicon {
    margin-left: -37%;
    color: white;
    height: 21px;
    width: 20px;
    border-radius: 100%;
    margin-top: 47%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
