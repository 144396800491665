

.PopularDestinationButtongropu {
  width: 60%;
  height: 50px;
  margin-top: 5%;
  margin-left: 7%;

}

.ButtonBorder{
  /* border: 2px solid gold; */
  width: 62%;
  height: 45px;
  margin-top: 5%;
  background-color: white;
}
.innerpopularfilterButtonBorder{
  /* border: 2px solid gold; */
  width:100%;
  height: 45px;
  margin-top: 5%;
  background-color: #3380F2;
}
.innerpopularButtonClick{
  /* border: 2px solid green; */
  width: 25%;
}
.Buttoninner1{
  border: 1px solid lightgray;
  width: 100%;
  height: 45px;
  color: gray;
  background-color: white;
}
.innerpopularFilterButton1{
  border: 1px solid lightgray;
  width: 100%;
  height: 45px;
  color: white;
  background-color: #3380F2;
}


.Buttoninner:hover {
  background-color: #3380f2 !important;
  color: white;
}

.innerpopulardestinationactive1 {
  background-color: #3380F2;
  color: white;
}
.innerpopularFilteractive1 {
  background-color:white;
  color:#3380F2;
}





/* policycss */


.PopularDestinationAboutspace {
  margin-top: 2%;
  /* margin-left: %; */
  font-weight: 700;
  font-size: 30px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.PopularDestinationAboutpragraf {
  /* margin-left: 5%; */
  text-align: justify;
  /* width: 90px; */
  margin-right: 20%;

}
.innerpopulardestinationFilterButtons{
  /* border: 2px solid red; */
  width: 80%;
  height: 100px;
}

.PopularDestinationAboutimage {
  object-fit: cover;
  background-size: auto;
  /* background-color: aquamarine;  */
  /* border: 2px solid gold; */
  width: 100%;
  height: 140px;
  /* margin-left: 5%; */
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}
.innerPopularDestinationAboutimage{
  object-fit: cover;
  background-size: auto;
  /* background-color: aquamarine;  */
  /* border: 2px solid green; */
  width: 100%;
  height: 250px;
  /* margin-left: 5%; */
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}
.PopularDestinationAboutbox {
  /* margin-top: 3px; */
  /* border: 7px solid rgb(130, 199, 199); */
  width: 100%;
  height: 100%;
  border-radius: 8px;
  /* margin-bottom: 7%; */
  /* margin-/left: 2%; */
  /* padding: 5px; */
}

.PopularDestinationAboutimageouteriv {
  /* border: 2px solid brown; */
  width: 70%;
  height: auto;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
.popularDestinationOverviewContainer1{
  /* border: 2px solid salmon; */
  width:100%;
  height:300px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid lightgray;
}
.popularDestinationOverviewContainer1Heading{
  /* border: 2px solid red; */
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}
.popularDestinationOverviewContainer1ImagesDiv{
  /* border: 2px solid red; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
}
.popularDestinationOverviewContainer1Image1{
  /* border: 2px solid lightgrey; */
  object-fit: cover;
  background-size: cover;
  width: 35%;
  border-radius: 8px;
}
.popularDestinationOverviewContainer1Image2{
  /* border: 2px solid green; */
  object-fit: cover;
  background-size: cover;
  width: 35%;
  margin-left: 5%;
  border-radius: 8px;
}
.popularDestinationOverviewContainer2{
  /* border: 2px solid saddlebrown; */
  width: 100%;
  height:170px;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  border-bottom: 2px solid lightgray;
}
.popularDestinationOverviewContainer2Heading{
  /* border: 2px solid red; */
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}
.popularDestinationOverviewContainer2FlightData{
  /* border: 2px solid red; */
  width: 100%;
  height: 100px;
}
.popularDestinationOverviewContainer3{
  /* border: 2px solid gold; */
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid lightgray;
  margin-bottom: 2%;
}
.popularDestinationOverviewContainer3Heading{
  /* border: 2px solid red; */
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}
.popularDestinationOverviewContainer3HotelDetails{
  /* border: 2px solid red; */
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: row;
}
.popularDestinationOverviewContainer3HotelImageDiv{
  /* border: 2px solid green; */
  width: 45%;
  height: 250px;
}
.popularDestinationOverviewContainer3HotelImage{
  width: 90%;
  height: 80%;
  margin-left: 5%;
  margin-top: 5%;
}
.popularinneroverviewMaindivContainer{
  /* border: 2px solid red; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
.popularinneroverviewMaindivContainerleftSide{
  /* border: 2px solid red; */
  width: 20%;
  height: 800px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid lightgray;
}
.popularinneroverviewMaindivContainerleftDate{
  width: 60%;
  height: 42px;
  background-color: white;
  border: 2px solid #3380F2;
  border-radius: 15px;
  margin-top: 5%;
  margin-left: 20%;
  padding-top: 4%;
  padding-left: 5%;
}
.popularinneroverviewMaindivContainerleftDate:hover{
  background-color: #3380F2;
  color: white;
}
.PopularDestinationAboutcard {
  object-fit: cover;
  background-size: auto;
  /* background-color: aquamarine;   */
  /* border: 2px solid gold; */
  width: 50%;
  height: 120px;
  margin-left: 15%;

  margin-top: 6%;

  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;



}

.aboutheading {
  /* width: 50%;
  height: 120px; */
  margin-left: 2%;
  margin-top: 8%;
  font-size: 20px;
  font-weight: 500;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
.innerpopularhotelaboutheading{
  margin-left: 2%;
  margin-top: 13%;
  font-size: 20px;
  font-weight: 500;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
.innerpopularoverviewHotelActivityDetails{
  /* border: 2px solid red; */
  width: 80%;
  height: 300px;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}
.innerpopularoverviewActivityDetails{
  /* border: 2px solid green; */
  width: 48%;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.innerpopularoverviewHotelDetails{
  /* border: 2px solid green; */
  width: 48%;
  height:300px;
  display: flex;
  flex-direction: column;
}

.innerpopularoverviewActivitiesFilter{
  /* border: 2px solid red; */
  width: 60%;
  margin-left: 4%;
}
.innerpopularoverviewActivitiesFilter h6{
  color: #393939;
  font-weight: 700;
  font-size: 15px;
}
.innerpopularoverviewActivitiesFilter p{
  color: #393939;
  font-size: 15px;
  margin-top: -1%;
}
.innerpopularoverviewActivitiesFilter1{
  /* border: 2px solid red; */
  width: 60%;
  margin-top: 1%;
}
.innerpopularoverviewActivitiesFilter1 h6{
  color: #393939;
  font-weight: 700;
  font-size: 15px;
}
.innerpopularActivityHeading{
  font-size: 20px;
  font-weight: 700;
  margin-top: 4%;
  /* border: 2px solid red; */
}
.innerpopularoverviewHotelDetails h2{
  font-size: 20px;
  font-weight: 700;
}
.innerpopularoverviewActivityDetails h2{
  font-size: 20px;
  font-weight: 700;
}
.innerpopularHotelFilter{
  /* border: 2px solid red; */
  width: 80%;
  margin-left: 5%;
}
.innerpopularHotelFilter h3{
  font-size: 18px;
  font-weight: 700;
  color: #393939;
}
.innerpopularHotelFilter p{
  font-size: 12px;
  color: #393939;
  margin-top: -1%;
  font-weight: 200;
}
.innerpopularHotelFilter h6{
  font-size: 15px;
  font-weight: 700;
  color: #393939;
  /* margin-top: -2%; */
}
.innerpopularHotelFilter li{
  list-style-type: disc;
  line-height: 10px;
  margin-left: 1%;
}

.innerpopularHotelFilter1{
  /* border: 2px solid red; */
  width: 80%;
  margin-top: 1%;
  /* margin-left: 5%; */
}
.innerpopularHotelFilter1 h3{
  font-size: 18px;
  font-weight: 700;
  color: #393939;
}
.innerpopularHotelFilter1 p{
  font-size: 12px;
  color: #393939;
  margin-top: -1%;
  font-weight: 200;
}
.innerpopularHotelFilter1 h6{
  font-size: 15px;
  font-weight: 700;
  color: #393939;
  /* margin-top: -2%; */
}
.innerpopularHotelFilter1 li{
  list-style-type: disc;
  line-height: 10px;
  margin-left: 1%;
}
.innerPopularhotelImage{
  /* border: 2px solid red; */
  width: 25%;
  height: 200px;
  margin: auto;
}
.descriptionParentDiv {
  /* margin-top: 2%; */
  /* border: 2px solid fuchsia; */
  width: 70%;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  /* margin-left: 4% ; */
  height: auto;
}
.innerpopularDestinationAccomodationDiv{
  /* border: 2px solid lawngreen; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
.popularDescriptionInnerDiv {
  /* border: 2px solid gold; */
  width: 100%;
  height: 200px;
  /* margin-top: 7%; */
}
.innerpopulardestinationIncExc{
  /* border: 2px solid darksalmon; */
  display: flex;
  flex-direction: row;
}

.innerpopulardestinationOverviewDiv{
  /* border: 2px solid green; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.innerpopulardestinationCancel{
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
}
/* accomodation component css */
.starheading {

  width: 100px;
  height: 50px;
  /* padding-top: 4%; */
  /* border: 1px solid red; */
  margin-top: 5%;
  font-weight: 500;
  font-size: 20px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.Accomodationbox {
  /* margin-top: 3px; */
  /* border: 2px solid orange; */
  width: 30%;
  height: 120%;
  /* margin-bottom: 50px; */
 
  /* padding: 5%; */
}

.Accomodationbox1 {
  /* margin-top: 3px; */
  /* border: 2px solid blue; */
  width: 45%;
  height: 120%;
  /* margin-bottom: 10%; */
}


.Accomodationouterdiv {
  /* border: 2px solid gold; */
  height: 270px;
  width: 65%;
  /* margin-top: 4%; */
}

.star {
  display: flex;
  flex-direction: row;
  /* margin-left: 10%; */
  /* border: 2px solid green; */
  width: 100px;
  height: 50px;
  padding-top: 5%;
  font-size: 25px;
  /* background-color: #3380f2; */
  color: #3389f2;


}

.stardiv {
  display: flex;
  /* border: 1px solid greenyellow; */
  height: 50px;
}

.AccomodationCard {
  margin-left: 2%;
  /* margin-top: 4%; */
  /* border: 2px solid lightseagreen; */
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}


/* 
cancellation policy css */
.cancellationPolicydiv {
  width: 750px;
  /* margin-left: 7%; */
  background-color: #ECF3F9;
  /* border: 2px solid pink; */
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 8%; */
  /* margin-bottom: 8%; */
}




.tableborder {
  /* border: 1px solid grey; */
  width: 400px;
  height: 50px;
  padding-left: 10px;
  font-size: 14px;
}

.policyheading {
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.unorderlist::marker {
  font-size: 20px;
  font-weight: bolder;
  list-style-type: disc;
}

.tremsconditiondiv {
  width: 750px;
  /* margin-le/ft: 7%; */
  text-align: justify;
  /* margin-bottom: 10%; */

  /* border: 1px solid lightgrey; */
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;


}

.tremsheading {
  font-size: 20px;
  font-weight: 500;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
   /* border: 2px solid blue; */
   /* width: auto; */
}




/* inclusion exclusion component css */

.inclusionexclusiondiv {
  width: 750px;
  /* margin-left: 7%; */
  /* border: 2px solid yellow; */
  background-color: #ECF3F9;
  margin-top: 3%;

}

.inclusionexclusiontext {
  /* border: 2px solid red; */
  margin-left: 5%;
  /* padding-top: 2%; */
  padding-right: 2%;
  padding-bottom: 2%;
  text-align: justify;
}

.inclusionexclusionheading {
  margin-left: 5% ;
  padding-top: 4%;
  font-weight: 500;
  font-size: 20px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont,
   'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

}

.exclusiondiv {
  width: 750px;
  /* margin-left: 7%; */
  /* border: 2px solid yellow; */
  background-color: #ECF3F9;
  /* margin-top: 2%; */


}




/* .promocoode {
    border: 2px solid green;
    width: 35%;
    margin-left: 10%;
    margin-left: 75%;
    margin-right: 10%;
    margin-top: -58%;
    margin-bottom: 40%; 
} */


.paymentdisplay {
  display: flex !important;
  flex-direction: row !important;
}

.applyPromocode {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin-bottom: 2%;
  margin-top: 2%;
  height: 120px;
  /* height: 80px; */
  border: 1px solid lightgray;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border: 1px whitesmoke; */
}



.couponInput {
  border: 1px solid gray;
  margin-top: 5%;
  /* margin-right: 5%; */
}

.couponButton {
  border: 1px solid gray;
  border-left: none;
  height: 40px;
  width: 20%;
  margin-top: 5%;
  margin-right: 5%;
  padding-right: 40px;
}

.paymentValueBody {
  /* border: 2px solid orangered; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  width: 370px;
  margin-top: 6%;
}

.paymentdiv {
  /* border: 1px solid red; */
  text-align: right;
  margin-left: 15%;
  font-weight: 700;
  /* margin-bottom: 10%; */

}

.sandquerybutton {
  background-color: white;
  border: 1px solid gray;
  color: grey;
  padding-left: 110px;
}

.booknowbutton {
  padding-left: 110px;
  padding-right: 50px;
}

.applyPromocodeparentdiv {

  margin-left: 4%;
}

.popularDestinationChildInnerPageBookingContact{
  border: 1px solid lightgray;
  width: 94%;
  height: 250px;
  border-radius: 8px;
  margin-left: 1%;
  margin-top: 5%;
}
.popularDestinationinnerchildApplyButton{
  color: #393939;
  border: 1px solid lightgray;
  font-size: 15px;
  margin: auto;
  margin-top: 8%;
  width: 80%;
  height: 45px;
  margin-left: 12%;
  background-color: white;
  font-weight: 500;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
}
.popularDestinationChildInnerOffersDiv{
  background-color: #ECF3F9;
  width: 94%;
  height: 200px;
  /* border: 2px solid red; */
  border-radius: 8px;
  margin-left: 1%;
  margin-top: 8%;
  display: flex;
  flex-direction: column;
}
.popularDestinationChildInnerOffersHeading{
  /* border: 2px solid red; */
  width: 95%;
  height: 70px;
  margin-left: 4%;
  margin-top: 4%;
  display: flex;
  flex-direction: row;
}
.popularDestinationChildInnerOffersHeading p{
   color: #393939;
   font-size: 15px;
}

.popularDestinationChildInnerOffersDivLoginButton{
  background-color: white;
  border: none;
  color: #3380F2;
  width: 50%;
  height: 35px;
  border-radius: 15px;
  margin-left: 5%;
}
.popularDestinationChildInnerOfferslist{
  list-style-type: disc;
  margin-top: 2%;
  font-size: 15px;
}
/* //------------------------------------------------ OVERVIEW FILTER BUTTON CSS ---------------------------------// */

.popularInnerFilterdetails{
  /* border: 1px solid lightgray; */
  border-radius: 5px;
  width: 93%;
  height: 100px;
  margin-top: 1.5%;
  display: flex;
  flex-direction: row;
}
.popularInnerFilterdetailsflightlogos{
  /* border: 2px solid red; */
  width: 25%;
}
.popularInnerFilterdetailsflightlogos img{
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
}
.popularInnerFilternames{
  /* border: 2px solid red; */
  width: 25%;
  color: #393939;
  text-align: center;
  padding-top: 20px;
  font-weight: 600;
  margin-left: -4%;
  font-size: 18px;
}
.popularInnerFilterdetailsflightTimming{
  /* border: 2px solid red; */
  width: 25%;
  color: #393939;
  text-align: center;
  padding-top: 3.5%;
  font-weight: 600;
}
.popularInnerFilterplaneIcon{
  /* border: 2px solid red; */
  width: 8%;
  font-size: 22px;
  padding-top: 24px;
}
.popularInnerFilterdetailsflightStops{
  /* border: 2px solid red; */
  width: 25%;
  color: #393939;
  text-align: center;
  padding-top: 3.5%;
  font-weight: 600;
  font-size: 18px;
}
.popularInnerFilterdetailsflightDays{
  /* border: 2px solid red; */
  width: 20%;
  color: #393939;
  font-size: 30px;
  text-align: center;
  padding-right: 10px;
  padding-top: 10px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}
.innerPopularDestinationCovidGuideLines{
  /* border: 2px solid red; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.innerPopularDestinationCovidGuideLinesHeading{
  font-size: 25px;
  font-weight: 700;
  color: #393939;
  margin-top: 5%;
}
.innerPopularDestinationCovidGuideLinesList{
  list-style: disc;
  color: #393939;
  font-size: 15px;
  margin-top: 2%;
}