.activitiesInnerWebPageCardsFilterContainer{
    /* border: 2px solid red; */
    width: 100%;
    height: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.activitiesInnerWebPageCardsFilterDiv{
   /* border: 2px solid gold; */
   width: 20%;
   height: auto;
   margin-left: 5%;
}
.activitiesInnerWebPageCardsDiv{
    /* border: 2px solid green; */
    width: 75%;
    height: auto;
}

/* ---------------------------------------------Filter Div CSS ---------------------------------------------------- */

.activitiesInnerWebpageDataFilter{
    border-right: 3px solid lightgray;
    /* border: 2px solid lightgray; */
    margin-left: 1%;
    width: 95%;
    height: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.activitiesInnerWebpageFilter1{
    /* border: 2px solid gold; */
    width: 90%;
    height: 180px;
    margin-top: 2%;
}
.activitiesInnerWebpageFilter1 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.activitiesInnerWebpageFilter1 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 17px;
    height:25px;
}
.activitiesInnerWebpageFilter2{
    /* border: 2px solid red; */
    width: 90%;
    height: 180px;
    margin-top: 4%;
}
.activitiesInnerWebpageFilter2 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.activitiesInnerWebpageFilter2 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 17px;
    height:25px;
}
.activitiesInnerWebpageFilter3{
    /* border: 2px solid red; */
    width: 90%;
    height: 200px;
    /* margin-top: 2%; */
}
.activitiesInnerWebpageFilter4{
    /* border: 2px solid red; */
    width: 90%;
    height: 200px;
    margin-top: 5%;
}
.activitiesInnerWebpageFilter4 input{
    /* border: 2px solid red; */
    margin-top: 1.5%;
}
.activitiesInnerWebpageFilter4 label{
    /* border: 2px solid gold; */
    margin-top: 2.5%;
    font-size: 15px;
    height:25px;
}
.activitiesInnerWebpageFilter5{
    /* border: 2px solid red; */
    width: 90%;
    height: 250px;
    margin-top: 5%;
}
.activitiesInnerWebpageStops{
    font-size: 20px;
    font-weight: 700;
    /* border: 2px solid red; */
    margin-bottom: 1%;
}
.activitiesInnerWebpageDurations select {
    background-color: none;
    color: #393939;
    margin-top: 3%;
    width: 70%;
    border: 1px solid lightgray;
    font-size: 15px;
    height: 40px;
    outline: none;
    /* margin-left: %; */
  }
.activitiesInnerWebpageTime{
    /* border: 2px solid firebrick; */
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1%;
}
.activitiesInnerUserRatings{
    /* border: 2px solid red; */
    width: 70%;
    display: flex;
    flex-direction:row;
    height: 45px;
}
.activitiesInnerRatings{
    border: 2px solid lightgray;
    width: 20%;
    height: 35px;
    color: grey;
    margin-left: 5%;
}
.activitiesInnerRatings:hover{
    color: #3380F2;
    border: 2px solid #3380F2;
}
.activitiesInnerWebpageTimeButtons{
    background-color: white;
    border: 1px solid lightgray;
    width: 40%;
    height: 40px;
}
.activitiesInnerWebpageTimeButtons:hover{
    background-color: #3380F2;
    color:white
}
.flightnearinnerpageslidecontainer{
    width: 80%;
    margin-left: 3%;
    margin-top: -1%;
}
.flightNeartakeoffData{
    color: gray;
    font-size: 12px;
    /* margin-top: 2%; */
    margin-left: 3%;
}
.flightNearDurationrangeText{
    color: #393939;
    font-weight: 700;
    font-size: 15px;
    margin-top: 1%;
    /* border: 2px solid green; */
}


/* -------------------------------------------------Cards CSS ----------------------------------------- */


.activitiesInnerWebPageCardsContainer{
    border: 2px solid lightgray;
    width: 90%;
    height: 250px;
    border-radius: 10px;
    margin-left: 3%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.activitiesInnerWebPageCardsImage1{
    /* border: 2px solid gold; */
    width: 100%;
    height: 220px;
    margin-top: 5%;
    margin-left: 3%;
}
.activitiesInnerWebPageCardsImage{
    /* border: 2px solid red; */
    width: 30%;
    height: 220px;
    margin-top: 1.5%;
    margin-left: 1%;
}
.activitiesInnerWebPageCardsHeadings{
    /* border: 2px solid red; */
    width: 48%;
    height: 220px;
    margin-top: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
}
.activitiesInnerWebPageCardsHeadings h3{
    font-size: 18px;
    color: #393939;
    font-weight: 700;

}
.activitiesInnerWebPageCardsHeadings p{
    color: #393939;
    font-size: 14px;
}
.activitiesInnerWebPageCardsButtons{
    border-left: 2px solid lightgray;
    /* border: 2px solid red; */
    width: 18%;
    height: 220px;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: right;
    display: flex;
    flex-direction: column;
}
.activitiesInnerSemiHeadings1{
    color: #3380F2;
    margin-top: 1%;
}
.activitiesInnerSemiHeadings2{
    color: #3380F2;
    margin-top: -1%;
}
.activitiesInnerWebPageCardsButtons h2{
   color: #3380F2;
   font-size: 25px;
   font-weight: 700;
}
.activitiesInnerWebPageViewMoreButtons{
    border: 2px solid lightgray;
    background-color: white;
    color:#393939;
    width: 80%;
    height: 40px;
    border-radius: 5px;
    margin-left: 20%;
}
.activitiesInnerWebPageViewMoreButtons:hover{
    background-color: #3380F2;
    color: white;
}
.activitiesInnerWebPageBookNowButtons{
    border: none;
    /* border: 2px solid lightgray; */
    background-color: #3380F2;
    color:white;
    width: 80%;
    height: 40px;
    border-radius: 5px;
    margin-left: 20%;
    margin-top: 5%;
}
.activitiesInnerWebPageBookNowButtons:hover{
    background-color: white;
    color: #3380F2;
    border: 2px solid #3380F2;
}
.activitiesInnerWebPageTimeRatings{
    /* border: 2px solid gold; */
    height: 45px;
    display: flex;
    flex-direction: row;
}
.activitiesInnerWebPageTimeRatings button{
    width: 22%;
    height: 30px;
    border-radius: 5px;
    margin-left: 15%;
    font-size: 14px;
    border: 1px solid lightgray;
    background-color: white;
    color: #393939;
}
.innerActivitiesShowButton{
    background-color: #3380F2;
    color: white;
    width: 60%;
    height: 60px;
    margin: auto;
    margin-left: 20%;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    margin-top: 2%;
    margin-bottom: 2%;
}
.innerActivitiesShowButton:hover{
    background-color: white;
    color: #3380F2;
    border: 2px solid #3380F2;
}