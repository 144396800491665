.hotelReviewOuterDiv {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.hotelReviewOuterDiv h2{
  font-size: 30px;
}

.hotelBookingStarRatingDiv{
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.hotelBookingReviewButtonDiv {
  border-bottom: 1px solid black;
  height: 80px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.hotelBookingReviewButtonDiv h5 {
  /* border: 1px solid green; */
  width: 40%;
}

.hotelBookingReviewButton {
  /* border: 1px solid black; */
  width: 60%;
}

/* .hotelBookingReviewInnerButtonDiv{
    border: 1px solid red;
} */

/* This css reference from top part */

.hotelBookingReviewButtonMainDiv {
  /* border: 2px solid black !important; */
  width: 100%;
  /* margin-top: 2%; */
  /* margin-left: 2%; */
}

.hotelBookingReviewButtonMainDiv label {
  margin-left: 2% !important;
  /* align-items: center; */
  text-align: center;
}

.hotelBookingReviewButton1 {
  outline: none;
  border: 2px solid #3380f2;
  width: 0%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 78px;
}

.hotelBookingReviewButton2 {
  outline: none;
  border: 2px solid #3380f2;
  width: 7%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 74px;
}

.hotelBookingReviewButton3 {
  outline: none;
  border: 2px solid #3380f2;
  width: 7%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 61px;
}

.hotelBookingReviewButton4 {
  outline: none;
  border: 2px solid #3380f2;
  width: 7%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 40px;
}

/* This css part is for the client review in texts */
.hotelBookingReviewTextContainer {
  border-bottom: 1px solid black;
  /* height: 500px; */
  width: 90%;
  margin: auto;
  margin-top: 1%;
}

.hotelBookingReviewInnerDiv{
  margin-bottom: 3%;
}

/* .hotelBookingClientStarRating{
    border: 1px solid black;
} */

.hotelBookingClientReviewContent {
  /* border: 1px solid red; */
  width: 60%;
}

.hotelBookingClientReviewContent p {
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  vertical-align: baseline;
  font-size: 16px;
}

.hotelBookingClientReviewContent h5 {
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
  vertical-align: baseline;
  font-size: 18px;
  margin-top: -1%;
}

.hotelBookingReviewDate{
    /* border: 1px solid red; */
    margin-top: -1%;
    color: gray;
}

/* .hotelBookingMoreClientReviews{
    border: 1px solid purple;
} */

.hotelBookingMoreClientReviews h5{
    text-align: center;
    cursor: pointer;
    color: #3380f2;
}

.hotelBookingMoreClientReviews h5:hover{
    color: #393939;
}





