.newflightfaqmaindiv {
  background-color: #3380f2;
  height: auto;
  /* padding-bottom: 1%; */
}
.newflightfaqheadings {
  color: white;
  font-weight: 700;
  padding-top: 3%;
  padding-left: 2%;
  text-align: center;
}

.newflightfaqaccordiondiv {
  /* border: 2px solid red; */
  margin: auto;
  width: 70%;
}

.newflightfaqaccordiondivcontainer {
  /* border: 1px solid red; */
  background-color: #3380f2;
  /* border-radius: 15px; */
  color: white;
  /* margin-bottom: 4%; */
  margin-top: 2%;
}

.accordion-button {
  background-color: #3380f2;
  color: white;
}

.accordion-button::after {
  content: "\2212" !important;
  width: 35px;
  font-size: 25px;
  text-align: center;
  /* border-radius: 20px; */
  right: 15px;
  top: 11px;
  position: absolute;
  appearance: none !important;
  /* background: #3380F2; */
  /* color: white; */
  /* border: 2px solid red; */
}

.accordion-button.collapsed::before {
  /* content: "\002B"; */
  content: none ;
}

.accordion-button::after {
  background-image: initial;
}

.accordion-button:not(.collapsed)::after {
  background-image: initial;
}

.newflightquestiondiv {
  width: 100%;
  height: 150px;
  background-color: #3380f2;
  /* border: 2px solid ; */
  color: white;
  font-size: 15px;
  text-align: center;
}
.newflightquestionheading {
  margin-top: 2%;
}
.newflightquestiontext {
  font-size: 16px;
}
