
.staycationOptionOuterDiv{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    margin-top: 5%;
    width: 100%;
}

.iconsOptionDiv{
    /* border: 2px solid black; */
    width: 15%;
    margin-left: 7%;
    /* height: 380px; */
    border-right: 3px solid lightgray;
}
.iconsOptionDivHeading p{
    color: #393939;
    border: 2px solid lightgray;
    font-size: 15px;
    /* padding: 8px; */
    padding-left: 12px;
    padding-top: 5px;
    width: 50%;
    height: 35px;
    margin: auto;
    margin-top: 4%;
    border-radius: 15px;
}

.iconsOptionDivIconsText{
   /* border: 2px solid palegoldenrod; */
   width: 80%;
   height:320px;
   margin: auto;
   margin-top: 3%;
   display: flex;
   flex-direction: row;
   border-bottom: 2px solid lightgray;
}
.iconsOptionDivIcons{
    /* border: 2px solid red; */
    width: 50%;
    height: 320px;
    display: flex;
    flex-direction: column;
}
.newStaycationIcons{
    width: 51%;
    height: 41px;
    /* border: 2px solid red; */
    margin-left:25%;
    margin-top: 10%;
}
.newStaycationIcons img{
    width: 100%;
    height: 100%;
}
.iconsOptionDivText{
    /* border: 2px solid red; */
    width: 50%;
    height: 320px;
    display: flex;
    flex-direction: column;
}
.newStaycationIconsText{
    width: 80%;
    height: 41px;
    /* border: 2px solid red; */
    margin-left:5%;
    margin-top: 10%;
    color: #393939;
    font-size: 17px;
    font-weight: 700;
    padding-top: 18px;
}
.iconsOptionDivIconsTextSeemore{
    border: 2px solid lightgray;
    width: 50%;
    height: 40px;
    margin: auto;
    margin-top: 8%;
    border-radius: 15px;
    padding-left: 11px;
    padding-top: 5px;
    cursor: pointer;
}
.staticImageSection{
    /* border: 2px solid saddlebrown; */
    width: 70%;
    z-index: -1;
}

.headingContainer{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 0%;
}


.NewStaycationStaticBannersmainDiv{
    width: 98%;
    height: 400px;
    /* border: 2px solid slateblue; */
    /* margin: auto; */
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    /* margin-top: 2%; */
}
.NewStaycationStaticBannersDivOne{
    width: 30%;
    height: 380px;
    /* border: 2px solid gold; */
    margin-left: 1%;
    cursor: pointer;
}
.NewStaycationStaticBannerImageOne{
    width: 100%;
    height: 380px;
    /* border: 2px solid red; */
    /* margin-bottom: 10%; */
    border-radius: 2px;
    border-radius: 10px;
}
.NewStaycationStaticBannerImageOne img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    z-index: -1;
}

.imageOffContentdiv p{
    /* border: 1px solid red; */
    position: absolute;
    background-color: #3380f2;
    margin-left: 15%;
    width: 5%;
    padding-left: 10px;
    padding-top: 3px;
    border-radius: 2px;
    height: 30px;
    color: white;
    margin-top: 1%;
}
.NewStaycationStaticBannersDivTwo{
    width: 31%;
    height: 400px;
    /* border: 2px solid purple; */
    margin-left: 3%;
}
.NewStaycationStaticBannerImageTwo{
    width: 100%;
    height: 190px;
    cursor: pointer;
    /* border: 2px solid red; */
    border-radius: 5px;
    margin-bottom: 2%;
    /* margin-top: 5%; */
}
.NewStaycationStaticBannerImageTwo img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.NewStaycationStaticBannersDivThree{
    width: 31%;
    height: 400px;
    /* border: 2px solid green; */
    margin-left: 3%;
}
.NewStaycationStaticBannerImageThree{
    width: 100%;
    height: 190px;
    cursor: pointer;
    /* border: 2px solid red; */
    border-radius: 5px;
    margin-bottom: 2%;
    /* margin-top: 5%; */
}
.NewStaycationStaticBannerImageThree img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}


/* --------------------Hovering Div ---------------------------------------- */


.newstaycationhoveringDiv{
    /* border: 2px solid red; */
    width: 1200px;
    height: 258px;
    display: none;
    margin-left: 101%;
    margin-top: -220%;
    z-index: 10000;
    background-color: #F8F8FD;
    /* display: flex;
    flex-direction: row; */
/* 
    display: none;
    position: absolute;
    color: white;
    background-color: #3380f2;
    width: 200px;
    height: 245px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px; */
}

.iconsOptionDivIconsTextSeemore:hover .newstaycationhoveringDiv{
    display: block;
}
.staycationhover{
    width: 90%;
    margin: auto;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
}


 .hovericonsOptionDivIconsText{
    /* border: 2px solid red; */
    width: 17%;
    height:250px;
    display: flex;
    flex-direction: row;
 }

 .hovericonsOptionDivIcons{
    /* border: 2px solid red; */
    width: 50%;
    height: 250px;
    display: flex;
    flex-direction: column;
}
.hovericonsOptionDivText{
    width: 50%;
    height: 250px;
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
}

.hovernewStaycationIcons{
    width: 41%;
    height: 41px;
    /* border: 2px solid red; */
    margin-left:25%;
    margin-top: 10%;
}
.hovernewStaycationIcons img{
    width: 100%;
    height: 100%;
}
.hovernewStaycationIconsText{
    width: 60%;
    height: 41px;
    /* border: 2px solid red; */
    margin-left:5%;
    margin-top: 10%;
    color: #393939;
    font-size: 17px;
    font-weight: 700;
    padding-top: 18px;
}
.hovernewStaycationIconsText:hover{
    text-decoration: underline;
}