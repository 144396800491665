/* .StaycationsBookingPageBannerOuterDiv {
  border: 1px solid red;
} */

.StaycationsBookingImageContentWrapper {
  /* border: 2px solid lightgray; */
  border-radius: 5px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.StaycationsBookingBannerImageDiv {
  /* border: 1px solid black; */
  width: 50%;
  margin-top: 1%;
  height: 500px;
}

.StaycationsBookingBannerImageDiv img {
  width: 100%;
  height: 500px;
  border-radius: 5px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  /* outline: black; */
  /* background-color: rgba(0, 0, 0, 0.3); */
  background-size: 100%, 100%;
  border-radius: 50%;
  /* border: 1px solid black; */
}

.hoteBookingContextDiv {
  /* border: 2px solid red; */
  width: 50%;
  margin-left: 2%;
  margin-top: 1%;
}

.StaycationsBookingCalenderOuterDiv {
  /* border: 1px solid red; */
  position: relative;
  /* height: 100px; */
  display: flex;
  width: 100%;
  flex-direction: row;
}

.StaycationsBookingCalenderContainer {
  /* border: 1px solid black; */
  margin-left: 1%;
  width: 60%;
  display: flex;
  flex-direction: row;
}

#StaycationsBooking_departure {
  /* border: 1px solid red; */
  /* border: 2px solid #dddddd78; */
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  width: 63%;
  color: black !important;
  background-color: #dddddd78 !important;
  border-radius: 5px;
  height: 40px !important;
  /* border: 2px solid lightgray; */
  border-radius: 6px;
  margin-top: 0%;
}

.StaycationsBookingGuestIcon {
  /* border: 1px solid red; */
  font-size: 23px;
  margin-left: 4% !important;
  margin-top: 3% !important;
  /* background-color: #dddddd78 !important; */
  color: black !important;
  /* background-color: #8DBBFF; */
}

#StaycationsBooking_departure h3 {
  /* border: 1px solid black; */
  font-size: 15px !important;
  /* font-weight: 700; */
  /* margin-left: -7%; */
  padding: 7px;
  color: grey !important;
  padding-left: 15px !important;
  padding-top: 4%;
  margin-top: 0.5%;
  /* height: 35px;
  border: 2px solid lightgray;
  border-radius: 6px;
  margin-top: 8%; */
}

#StaycationsBooking_departure:hover .StaycationsBoookingdropdown_content {
  display: block;
}

.StaycationsBoookingdropdown_content {
  display: none;
  position: absolute;
  color: white;
  background-color: #3380f2 !important;
  width: 200px;
  height: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 2.6%;
}
.StaycationsBoookingdropdown_content div:hover {
  background-color: white !important;
  color: #3380f2;
}

.navbarStaycationsBookingPageOptions {
  margin-top: 2%;
  padding-top: 12px;
  padding-bottom: 12px;
  /* border: 2px solid red; */
}

.StaycationsBookingCalenderInnerDiv {
  /* border: 1px solid black; */
  width: 47%;
  margin-right: 3%;
}

.StaycationsBookingCalenderInnerDiv1 {
  /* border: 1px solid black; */
  width: 47%;
}

.StaycationsBookingCalenderInnerDiv1 input {
  /* border: 1px solid red; */
  position: absolute;
  /* margin-left: 45%;   */
  border-radius: 5px;
  height: 40px;
  background-color: #dddddd78;
  border: none !important;
  padding-left: 10px;
  width: 23%;
}

.StaycationsBookingCalenderInnerDiv input {
  /* border: 1px solid red; */
  position: absolute;
  /* margin-left: 45%;   */
  border-radius: 5px;
  height: 40px;
  background-color: #dddddd78;
  border: none !important;
  padding-left: 10px;
  width: 23%;
}

/* .StaycationsBookingCalenderInnerDiv input::placeholder{
 
} */

.StaycationsBookingPersonsInnerDiv {
  /* border: 1px solid green; */
  width: 50%;
  display: flex;
  flex-direction: row;
  /* margin-left: 7%; */
}

.StaycationsBookingSearchButtonOuterDiv {
  /* border: 1px solid purple; */
  width: 15%;
  margin-left: 2%;
}

.StaycationsBookingSearchButtonInnerDiv {
  /* border: 1px solid yellow; */
  width: 100%;
  border-radius: 5px;
  height: auto;
  background-color: #3380f2;
  height: 39px;
  color: white !important;
}

.StaycationsBookingStaycationsContentDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
}

.StaycationsBookinStaycationsName {
  /* border: 1px solid black; */
  margin-top: 4%;
  height: 100px;
  display: flex;
  flex-direction: row;
}

.StaycationsBookingStaycationsHeading {
  /* border: 1px solid red; */
  width: 40%;
}

.StaycationsBookingStaycationsHeading h2 {
  font-size: 30px;
}

.StaycationsBookingStaycationsInfoDiv {
  /* border: 1px solid red; */
  width: 70%;
}

.StaycationsBookingStaycationsInfoDiv p {
  font-size: 15px;
  margin-left: 10%;
}

.StaycationsBookingRatingDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
  margin-top: -4%;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.hoteBoookingRatingExtraDiv {
  /* border: 1px solid black; */
  margin-left: 18%;
}

.hoteBoookingRatingExtraDiv h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10%;
}

.StaycationsBookingRatingNumber h3 {
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 50%;
}

.StaycationsBookingStaycationsReview {
  /* border: 1px solid green; */
  margin-left: 1%;
}

.StaycationsBookingStaycationsReview p {
  /* border: 1px solid black; */
  row-gap: -5px !important;
  font-size: 13px;
  font-weight: 500;
  margin-top: 12%;
}

.miniContext {
  margin-top: -14% !important;
}

.StaycationsBookingPriceDiv {
  /* border: 1px solid red; */
  margin-left: 1%;
}

.StaycationsBoookingPriceDisplayInnerDiv {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
}

.StaycationsBookingPriceDisplayDiv h3 {
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 40%;
}

.StaycationsBookingPriceDescription {
  margin-left: 1%;
}

.StaycationsBookingPriceDescription p {
  /* border: 1px solid red; */
  font-size: 13px;
  font-weight: 500;
  margin-top: 25%;
}

.StaycationsBookingButton {
  /* border: 1px solid red !important; */
  margin-top: 4%;
  margin-left: 10%;
  width: 50%;
}

.StaycationsBookingButton button {
  width: 40%;
  margin-left: 58%;
  padding-left: 28px;
}

.StaycationsBookingButton button:hover {
  background-color: white;
  border: 1px solid #3380f2;
  color: #3380f2;
}

.miniContextPrice {
  /* border: 1px solid red; */
  margin-top: -1% !important;
}

.StaycationsBookingOtherStaycationssDiv {
  border-top: 2px solid black;
  width: 95%;
  margin-top: 2%;
}

.StaycationsBookingOtherStaycationssDiv h5 {
  margin-top: 2%;
  margin-left: 1%;
}

/* .StaycationsBookingOtherStaycationsInnerDiv{
  border: 1px solid black;
 
}
 */

.StaycationsBookingOtherStaycationsSubDiv1 {
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.StaycationsBookingOtherStaycationsSubDiv2 {
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.StaycationsBookingOtherStaycationsSubDiv3 {
  /* border-bottom: 1px solid lightgray; */
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
}

.StaycationsBookingOtherStaycationsChildDiv1 {
  /* border: 1px solid black; */
  width: 10%;
}

.StaycationsBookingOtherStaycationsChildDiv2 {
  /* border: 1px solid purple; */
  width: 40%;
}
.StaycationsBookingOtherStaycationsChildDiv2 p {
  margin-top: 5%;
  margin-left: -2%;
  color: gray;
}

/* .StaycationsBookingOtherStaycationsChildDiv3{
  border: 1px solid green;
  height: 30px;

} */

.StaycationsBookingOtherStaycationsChildDiv3 {
  /* border: 1px solid red; */
  margin-left: 30%;
  /* margin-top: 10%; */
}

.StaycationsBookingChildButton {
  /* border: 1px solid red; */
  height: 33px;
  width: 100%;
  margin-top: 8%;
  padding-top: 3px;
  /* margin-left: 150%; */
  /* padding-left: 1px !important; */
}

.StaycationsBookingChildButton:hover {
  background-color: white;
  border: 1px solid #3380f2;
  color: #3380f2;
}
