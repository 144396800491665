.topcitiescontainer{
  /* border: 1px solid blue; */
  width: 100% !important;
  margin-bottom: 4%;
  /* background-color: green; */
}

.topcities_title {
  display: flex;
  margin-left: 40%;
  margin-bottom: 2%;
  margin-top: -3%;
  font-size: 40px;
  color: rgb(51, 128, 242);
}

.topcities_title h2{
  color: rgb(51, 128, 242);
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0.4%;
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.slickslidercontainer{
  /* border: 1px solid red; */
  /* width: 50%; */
  /* margin-left: -3%; */
  /* margin-right: 5%; */
  width: 90%;
  margin: auto;
}

.card-wrapper {
  /* border: 1px solid black; */
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.topcitiesdiv {
  /* border: 1px solid red !important; */
  width: 100%;
  border: none;
  border-radius: 16px !important;
  /* border-bottom-left-radius: 50%; */

  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.topcitiescardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 400px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
 /* border-bottom-left-radius: 50%; */
}

.topcitiescardimage img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  -o-object-fit: cover;
  object-fit: cover;
}

/* .topcitiesdiv img{
  border-bottom-left-radius: 25%;
} */

.topcitiesdiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.topcitiesdiv:hover .topcitiescardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.topcitiesdetails {
  position: absolute;
  bottom: -56%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.topcitiesdetails :hover {
  color: white;
}

.topcitiesdetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.topcitiesdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.card:hover .topcitiesdetails {
  bottom: 0;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}
