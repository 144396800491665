.cheapCarRentalsMainOuterDiv {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 3%;
}

.cheapCarRentalsHeadingDiv h3 {
  font-size: 25px;
  line-height: 20px;
  vertical-align: baseline;
  font-weight: 600;
}

.cheapCarRentalsFilterDiv {
  /* border: 1px solid black; */
  width: 80%;
  display: flex;
  flex-direction: row;
}

.cheapCarRentalsFilterDiv select {
  margin-right: 1%;
  margin-top: 2%;
  /* border: 1px solid red; */
}

.cheapCarRentalsCardsInnerDiv1 {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  column-gap: 25px;
}

.cheapCarRentalsCardsInnerDiv2 {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  column-gap: 25px;
}

.cheapCarRentalsInnerDiv {
  /* border: 1px solid red; */
  width: 100%;
}

.rentalsCardsImageDiv {
  position: relative;
  height: 170px;
  /* border: 2px solid red; */
}

.rentalsCardsImageDiv img {
  object-fit: cover;
  width: 60%;
  margin: auto;
  margin-top: 17%;
  margin-bottom: 5%;
  margin-left: 15%;
  /* left: 2%; */
}

.rentalsCardsImageDiv .rentalsCarLogo {
  position: absolute;
  width: 20%;
  right: 77%;
  margin-top: 3%;
}

.cheapCarRentalCardHeadingDiv {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  height: 50px;
  margin-top: 4%;
}

.cheapCarRentalCardHeadingDiv h5 {
  /* border: 1px solid black; */
  width: 100%;
  color: #393939;
  font-weight: 400;
  /* margin-top: 1%; */
  font-size: 14px;
  vertical-align: baseline;
  line-height: 20px;
  column-gap: 3px;
  display: flex;
  flex-direction: row;
}

.cheapCarRentalCardHeadingDiv button {
  /* border: 1px solid red; */
  height: 25px;
  padding-top: 0.1px;
  font-size: 14px;
  margin-top: -1%;
  /* justify-content: center; */
  margin-left: 5%;
  color: #3380f2;
  background-color: white;
  border-radius: 15px;
}

.cheapCarRentalsCarDetailsDiv {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 7px;
  column-gap: 5px;
  margin-left: -3%;
  background-color: #dae9ff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cheapCarRentalsDetailsInnerDiv1 {
  border-right: 2px solid #393939;
  width: 32%;
  row-gap: 1px;
  height: 40px;
  margin-top: 1.5%;
  text-align: center;
}

.cheapCarPrice {
  /* border: 1px solid brown; */
  bottom: 20%;
  margin-top: -20%;
  font-weight: 600;
  font-size: 15px;
  color: #393939;
}

.cheapCarDescription {
  /* border: 1px solid green; */
  margin-top: -2% !important;
  color: #393939;
}

.cheapCarRentalsDetailsInnerDiv2 {
  border-right: 2px solid #393939;
  width: 32%;
  row-gap: 1px;
  height: 40px;
  margin-top: 1.5%;
  text-align: center;
}

.cheapCarRentalsDetailsInnerDiv3 {
  /* border-right: 2px solid #393939; */
  width: 32%;
  row-gap: 1px;
  height: 40px;
  margin-top: 1.5%;
  text-align: center;
}

.cheapCarRentalsTimeDescDiv {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.cheapCarRentalsTimeDescInnerDiv {
  width: 50%;
}
.cheapCarRentalsTimeDescInnerDiv p {
  /* border: 1px solid black; */
  width: 100%;
}

.cheapCarRentalsBookButton {
  /* border: 1px solid red; */
  justify-content: center;
  width: 37%;
  position: absolute;
  bottom: 10%;
  right: 2%;
}

.cheapCarRentalPerDayPrice {
  color: #3380f2;
  margin-top: 8%;
  margin-left: -2%;
}

.cheapCarRentalsDayPeriod {
  margin-top: -6.5%;
  margin-left: -2%;
  color: #393939;
  font-weight: 600;
  font-size: 14px;
}

.cheapCarRentalsDayPeriod span {
  color: gray;
}
