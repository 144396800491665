.Newtrippage-maindiv{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;

}
.newtripdivdiv1{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: row;
    height: 500px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 2%;
}

.newtripdivdiv1-containdiv{
    /* border: 2px solid pink; */
    display: flex;
    flex-direction: column;
    /* width: 45%; */
    margin-left: 2%;
    /* margin-right: 7%; */
    margin-top: 4%;

}
.newtripdivdiv1-containheadingdiv{
   
    /* border: 2px solid yellow; */
    width: 70%;
    margin-left: 10%;
}
.newtripdivdiv1-containheadingdiv h3{
    font-size: 30px;
    font-weight: 800;
    color: #393939;
    /* border: 2px solid yellow; */
}
.newtripdivdiv1-pragraphdiv{
    /* border: 2px solid green; */
    width: 55%;
    margin-left: 13%;
    margin-top: 3%;
    /* padding-left: 2%; */
}
.newtripdivdiv1-pragraphdiv{
    font-size: 20px;
    font-weight: 500;
    color: #393939;
    text-align: center;
    justify-content: center;
}


.newtripdivdiv1-button{
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: row;
    margin-top: 4%;
    width: 60%;
    height: 50px ;
    margin-left: 7%;
}
.newtripdivdiv1-button1{
    background-color: #3380f2;
    color: white;
    border: none;
    width: 50%;
    border-radius: 5px;
   
}

.newtripdivdiv1-button2{
    /* background-color: #3380f2; */
    /* color: white; */
    border: 1px solid lightgray;
    width: 50%;
    margin-left: 4%;
    border-radius: 5px;
   
}

.newtripdivdiv1-imagediv{
    /* border: 2px solid fuchsia; */
    width: 75%;
    /* background-color: #3380f2; */


}

.newtripdivdiv1-imagemaindiv{
    /* border: 2px solid seagreen; */
    background-color: #C1DAFF;
    width: 60%;
    height: 100%;
    margin-left: 10%;
   
    /* position: relative; */
    /* z-index: 2; */


}
.newtripdivdiv1-imagemaindiv2{
    /* border: 2px solid red; */
    background-color: #C1DAFF;
    width: 60%;
    height: 100%;
    /* position: absolute; */
    /* z-index: 0; */
   
    /* margin-left:%; */
     position: relative;
    z-index: 2;  
    margin-top: -73%;
    /* margin-left: 15%; */
    border-radius: 10px;


}
.tripimg{
    height: 98%;
    width: 98%;
    margin-top: 1%;
    margin-left: 1%;
    border-radius: 10px;
}
.newtripdivdiv2{
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;

}
.newtripdivdiv2-1stcontaindiv{
    /* border: 2px  solid purple; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    margin-left: 4%;
    margin-right: 4%;
    /* width: 40%; */
    height: 300px;
}
.stcontaindiv-contain{
    /* border: 2px solid royalblue; */
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 1%;
    width: 38%;
}
.imagsection{
    /* border: 2px solid darkviolet; */
    width: 30%;
    height: 300px;
    margin-left: 5%;
    border-radius: 5px;
}
.imagsection1{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.headtrip{
    /* border: 2px solid orangered; */
    margin-left: 8%;
    margin-right: 4%;
    margin-top: 3%;

}
.headtrip h4{
    font-size: 30px;
    font-weight: 550;
    color: #393939;
}

.rectangeimg{
    /* border: 2px solid green ; */
    width: 75%;
    margin-left: 8%;
    margin-top: 4%;
    /* position: absolute; */
    /* z-index: 0; */
    /* border-radius: 5px; */
}

.altimagediv{
    width: 100%;
    border-radius: 10px;

}


.newtripdivdiv3{
    /* border: 2px solid red; */

}


.divcount{
    /* border: 2px solid yellow; */
    /* margin-left: 25%; */
    width: 100%;
    justify-content: center;
    text-align: center;

  

}

.divcount h3{
    font-size: 25px;
    font-weight: 800;
    color: #393939;
} 


.divcount2{
    /* border: 2px solid yellow; */
    margin-left: 18%;
    margin-top: 2%;
    margin-right:15% ;
    width: 60%;
  

}
.divcount2 p{
    font-size: 20px;
    font-weight: 500;
    color: #393939;
    justify-content: center;
    text-align: center;
}

.divcount3{
    /* border: 2px solid yellow; */
    /* margin-left: 20%; */
    margin-top: 2%;
    width: 35%;
    margin-left: 30%;
  

}
.divcount3 p{
    font-size: 20px;
    font-weight: 500;
    color: #3380f2;
    justify-content: center;
    text-align: center;
}
.signbtn{
    background-color: #3380f2;
    color: white;
    border: none;
    width: 40%;
    border-radius: 5px;
    margin-top: -5%;
    

}
.signbtndiv{
    /* position: relative; */
    /* z-index: 1; */
    /* margin-top: 15; */
    margin-top: -5;
}

