.innerVisapagemainDiv{
  /* border: 2px solid red; */
  height:700px;
}
.innerVisaHeadingButtons{
    /* border: 2px solid green; */
    background-color: #e8f4ff;
    height: 50px;
    width: 100%;
    border: none;
    font-weight: 700;
}
.active{
    color: #393939;
    border-bottom: 2px solid #393939;
}
.innervisaChangemainDiv{
   width: 100%;
   height: 80px;
   border: 1px solid lightgray;
   background-color: #e8f4ff !important;
   border-radius: 10px;
   /* margin-top: -2%; */
   /* margin: auto; */
}
.innervisabuttonouterDiv{
    display: flex;
    flex-direction: row;
}
.innervisabuttonDiv{
    /* border: 2px solid blue; */
    width: 12%;
    /* height: 35px; */
    margin: auto;
    margin-top: 1%;
}

.innerVisaChangeableDataBannerdropcontainer {
    /* border: 1px solid red; */
    width: 30%;
    /* margin-left: 40%; */
    border: none;
    height:50px;
  }

  .innerVisaChangeableDataBannerdropcontainer select{
    font-size: 15px;
    height: 50px;
    border: 2px solid #dddddd78;
    background-color: #e8f4ff;
  }

  .innerVisaChangeableDataBannerdropcontainer select::placeholder{
    padding-top: 4px;
  }
  
  .innerVisaChangeableDataBannerdropcontainer1 {
    margin-left: 3%;
    width: 30%;
    height: 50px;
  }

  .innerVisaChangeableDataBannerdropcontainer1 p{
     height: 10px;
     color: #818090;
  }

  .innerVisaChangeableDataBannerdropcontainer1 select{
    font-size: 15px;
    height: 50px;
    padding: 5px 10px;
    border: 2px solid #dddddd78;
    background-color: #e8f4ff;
  }
  .innerVisaChangeableButton{
    /* border: 2px solid red; */
    width: 60%;
    height: 60px;
    display: flex;
    flex-direction: row;
    /* margin-left: 41%; */
    margin-top: 2%;
  }
  .visaTypeMainDiv{
    /* border: 2px solid salmon; */
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .innerVisaTypedetailsDiv{
    /* border: 2px solid red; */
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .innerVisaTypeDetailContainer{
    border:1px solid lightgray;
    width: 95%;
    height: 160px;
    /* margin: auto; */
    margin-top: 1.5%;
    display: flex;
    flex-direction: row;
  }
  .innervisaInformationDiv{
    /* border: 2px solid saddlebrown; */
    width: 70%;
    padding-left: 15px;
  }
  .innerVisaRate{
    color: gray;
    font-size: 15px;
    margin-top: 1%;
  }
  .innerVisaTypeofVisa{
    font-weight: 700;
    color: #393939;
    font-size: 18px;
    margin-top: -3%;
  }
  .innerVisaDescription{
    color: grey;
    margin-top: -3%;
    text-align: justify;
    font-size: 12px;
  }
  .innerVisaPrice{
    color: #393939;
    font-size: 14px;
    margin-top: -1%;
  }
  .visaPrice{
    color: #3380F2;
    font-weight: 700;
    font-size: 25px;
    margin-top: -3%;
  }
  .innerVisaAddtoCartDiv{
    /* border: 2px solid black; */
    width: 30%;
  }
  .innervisaCartcounter{
    border: 2px solid lightgray;
    width: 60%;
    height: 40px;
    border-radius: 15px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 15%;
    justify-content:space-between;
  }

  .innervisaaddbutton{
    /* margin-right: 9%; */
    width: 19%;
    height: 25px;
    color: #3380F2;
    /* background-color: #3380F2; */
    /* border: 2px solid #3380F2; */
    border-radius: 35px;
    font-size: 25px;
    font-weight: 700;
    margin-left: 9%;
    text-align: center;
    /* margin-top: 4%; */
    cursor: pointer;
    /* padding-top: 2px; */
  }
  .innervisasubtractbutton{
    margin-right: 9%;
    width: 19%;
    height: 25px;
    color: #3380F2;
    /* background-color: #3380F2; */
    /* border: 2px solid #3380F2; */
    border-radius: 22px;
    font-size: 25px;
    font-weight: 700;
    /* margin-left: -9%; */
    text-align: center;
    /* margin-top: 3%; */
    cursor: pointer;
  }
  .innerVisacountertext{
    font-size: 25px;
    font-weight: 700;
    color: #3380F2;
    /* margin-left: 15%; */
  }
  .innerVisaAddtocartButton{
    background-color: #3380F2;
    color: white;
    font-weight: 600;
    font-size: 18px;
    width: 60%;
    height: 40px;
    margin-top: 10%;
    padding:8px 18px;
    margin-left: 20%;
  }
  .innerVisaAddtocartButton :hover{
    background-color: white;
    color: #3380F2;
    border: 2px solid #3380F2;
    cursor: pointer;
  }

  
  .innerVisaTypecontactDiv{
    /* border: 2px solid gold; */
    width: 35%;
    margin-top: -4.5%;
  }



.innervisaPageDocumentMainDiv{
  /* border: 2px solid black; */
  height: auto;
  width: 80%;
}
.innervisaDocumentHeading{
  font-weight: 700;
  font-size: 25px;
  margin-top: 1%;
  /* margin-left: 4%; */
  color: #393939;
}
.innervisaPageDocumentContainer{
  width: 90%;
  margin-top: 3%;
  height: auto;
  border: 2px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #393939;
}
.innervisapageDoumentsLists{
  list-style-type:disc;
  margin-top:3%;
  margin-left: 3%;
}
.innervisaprocessMainDiv{
  /* border: 2px solid red; */
  width: 80%;

}
.innervisaProcessheading{
  color: #393939;
  font-size: 28px;
  font-weight: 700;
  width: 80%;
  margin-top: 1%;
  margin-left: 5%;
  /* border: 2px solid red; */
}
.innervisaProcesscontainerDiv{
  /* border: 2px solid black; */
  width: 90%;
  height: 150px;
  margin: auto;
  margin-top: 7%;
  object-fit: cover;
  background-size: cover;
}
.innervisaProcessImage{
  width: 100%;
  height: 100%;
}
.visainnerSample{
  /* border: 2px solid green; */
  width: 80%;
  height: 550px;
  /* margin: auto; */
}
.visainnerImageDiv{
  border: 2px solid lightgray;
  width: 45%;
  height: 100%;
  margin: auto;
}
.visainnerImageDiv img{
  width: 100%;
  height: 100%;
}
.visaInnerEmbasy{
  width: 80%;
  /* border: 2px solid red; */
}
.visaInnerEmbasyLinks{
  width: 70%;
  height: 100px;
  /* border: 2px solid gold; */
  margin-top: 2%;
}
.visaInnerEmbasyHeadings{
  color: #393939;
  font-weight: 700;
  font-size: 20px;
}
.VisaEmbasyLinks{
  font-size: 15px;
  color: #818090;
  list-style-type: square;
  /* font-weight: 700; */
}