.newExclusiveDealscontainer {
  display: flex;
  height: 80px;
  position: relative;
  /* background-color: #3380f2; */
  z-index: 1000;
}

.hotelinnerdropdowncontiner1 {
  /* background-color: red; */
  width: 50%;
  margin-left: 6%;
}

.hotelinnerdropdowncontiner1 h2 {
  color: white;
  margin-top: 5%;
  font-size: 26px;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100% !important;
}
.newExclusiveDealsinnerdropdown {
  /* border: 1px solid red; */
  /* border: 3px solid white; */
  /* background-color: blue; */
  width: 70%;
  margin-left: 9%;
}

.newExclusiveDealscountryDrop {
  border: 2px solid white !important;
  background-color: white !important;
  border-radius: 10px !important;
  color: #3380f2 !important;
  font-weight: 600 !important;
  height: 38px !important;
  /* width: % !important; */
}

.newExclusiveDealscountryDrop:hover {
  background-color: #3380f2 !important;
  color: white !important;
}

.newExclusiveDealscountryDrop1 {
  border: 2px solid white !important;
  background-color: white !important;
  border-radius: 10px !important;
  color: #3380f2 !important;
  font-weight: 700 !important;
  margin-left: 10% !important;
  height: 38px !important;
}

.newExclusiveDealscountryDrop1:hover {
  background-color: #3380f2 !important;
  color: white !important;
}

.newExclusiveDealsoptionsContainer {
  /* border: 2px solid white; */
  display: flex;
  margin-top: 3%;
  margin-left: 5% !important;
}

.newExclusiveDealssingledrop {
  /* border: 1px solid red; */
  /* width: 80%; */
  margin-left: 3%;
  border: none;
}

.newExclusiveDealssingledrop1 {
  border: none;
  margin-left: -20%;
}

.newExclusiveDealssingledrop > .homecardtextbutton {
  /* margin-left: 3%; */
  border: 2px solid white !important;
  background-color: #3380f2 !important;
  color: white;
  font-weight: 700;
  width: 135%;
  height: 38px;
  border-radius: 20px;
  margin-left: -20%;
}

.newExclusiveDealssingledrop button:hover {
  background-color: white !important;
  color: #3380f2;
}
/* style={{ marginLeft: "8%", background: "white", color: "blue" }} */

@media (max-width: 480px) {
  .newExclusiveDealscontainer {
    display: flex;
    height: 80px;
    position: relative;
    /* background-color: #3380f2; */
    z-index: 1000;
  }

  .hotelinnerdropdowncontiner1 {
    /* background-color: red; */
    width: 50%;
    margin-left: 6%;
  }

  .hotelinnerdropdowncontiner1 h2 {
    color: white;
    margin-top: 5%;
    font-size: 26px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    width: 100% !important;
  }
  .newExclusiveDealsinnerdropdown {
    /* border: 1px solid red; */
    /* border: 3px solid white; */
    /* background-color: blue; */
    width: 90%;
    margin-left: 5%;
  }

  .newExclusiveDealscountryDrop {
    border: 2px solid white !important;
    background-color: white !important;
    border-radius: 10px !important;
    color: #3380f2 !important;
    font-weight: 600 !important;
    height: 38px !important;
    /* width: % !important; */
  }

  .newExclusiveDealscountryDrop:hover {
    background-color: #3380f2 !important;
    color: white !important;
  }

  .newExclusiveDealscountryDrop1 {
    border: 2px solid white !important;
    background-color: white !important;
    border-radius: 10px !important;
    color: #3380f2 !important;
    font-weight: 700 !important;
    margin-left: 10% !important;
    height: 38px !important;
  }

  .newExclusiveDealscountryDrop1:hover {
    background-color: #3380f2 !important;
    color: white !important;
  }

  .newExclusiveDealsoptionsContainer {
    /* border: 2px solid white; */
    width: 100%;
    display: flex;
    margin-top: 3%;
    margin-left: 5% !important;
  }

  .newExclusiveDealssingledrop {
    /* border: 1px solid red; */
    /* width: 100%; */
    margin-left: 3%;
    /* border: none; */
  }

  .newExclusiveDealssingledrop1 {
    border: none;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
}
 */
