.PopularDestination0filterCardsmaindiv{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
}





.PopularDestination0Cardsmaindiv{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-top: 8%;
    margin-left: 4%;

}
.ppcard{
    /* border: 2px solid yellow; */
    width: 90%;
}
.PopularDestination0Cardsmaindiv2{
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
    width: 100%;
    /* height: 550px; */
}
.Destiouterdiv{
    /* border: 2px solid yellow; */
    margin-left: 2%;
    margin-top: 2%;
    width: 30%;
    /* height: 440px; */
    


}

.DestiCardimage{
    /* border: 2px solid red ; */
    width: 100%;
    /* width: auto; */
}

.DestiRecommendedimage{
    /* border: 2px solid aqua; */
    /* width: auto; */
    width: 100%;

}

.DestiTextcard{
    /* border: 2px solid red; */
    width: 100%;
    font-family: 'TT Hoves Variable',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
}
.DestiCardtitle{
    /* border: 2px solid green; */
    font-weight: 700;
    font-size: 20px;
    /* margin-bottom: 2%; */

}
.travalflightactivity{
    /* border: 2px solid blue ; */
    display: flex;
    flex-direction: column;

}
.flightacco{
    /* border: 2px solid green; */
     margin-top: 5%;
     width: 120%;

}
.flightacco1{
    /* border: 2px solid red; */
     margin-top: 5%;
     width: 120%;
     margin-right: 30%;
}


.holidaytravalflightactivity{
    display: flex;
    flex-direction: row;
    /* border: 2px solid palevioletred; */
    font-weight: 500;
    font-size: 16px;
    color: #5D6771;
    /* margin-top: 1%; */
}
.holidaytravalflightactivity1{
    display: flex;
    flex-direction: row;
    /* margin-left: 2%; */
    /* border: 2px solid red; */
    font-weight: 500;
    font-size: 16px;
    color: #5D6771;
    /* width: 10%; */
    
}
.holidaytravalflightactivity2{
    display: flex;
    flex-direction: column;
    /* border: 2px solid purple; */
    /* margin-left: 5%; */
}
.activitydays{
    display: flex;
    flex-direction: row;
    /* border: 2px solid green; */

}


.nspan{ 
    /* border: 2px solid yellow ; */
    /* margin-left: 2%; */
    margin-top: 8%;

}
.nspan1{ 
    /* border: 2px solid yellow ; */
    margin-left: 4%;
    margin-top: 8%;

}

.amountdiv{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    /* margin-top: 2%; */
}


.amountdiv h5 {
    font-weight: 550;
    font-size: 20px;
    /* margin-right: 2%; */
    margin-top: 5%;
    
    
    /* padding-left: 10%; */

}

.amountdiv p{
    font-weight: 400;
    font-size: 12px;
    color: #5D6771;
    /* margin-left: 35%; */
    /* padding-bottom: 5%; */
    /* border: 2px solid yellow; */
    margin-top: 7%;
    margin-right: 3%;

}
.flightactivitybutton{
    /* border: 2px solid gray ; */
    display: flex;
    flex-direction: row;
    /* background-color: #3380F2; */

}
.viwemorebutton{
    color: #5D6771;
    border: 1px solid #5D6771;
    width: 120%;
    height: 35px;
    margin-left: 35%;

    /* background-color5D6771; */
}
.viwemorebutton1{
    color: white;
    border: none; 
    width: 170%;
    height: 35px;

    background-color: #3380F2;
    margin-left: 10%;
}
.bookingbutton{
    margin-left: 18%;
}



.PopularDestination0Filtermaindiv{
    display: flex;
    flex-direction: column;
    /* border: none; */
    width: 20%;
    border-right: 1px solid lightgrey;

   
    /* padding-top: 1%; */



}

.budgetdiv{
    /* border:  2px solid yellow; */
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 3%;
    margin-top: 12%;
}


.budgetdiv p{
    font-size: 13px;
    font-weight: 500;
    /* margin-top: 20%; */
    margin-left: 10%;
    /* padding-top: 10%; */


}


.valuerange{
 width : 100%;
}
.duretiondiv{
    display: flex;
    flex-direction: row;
    /* border: 2px solid pink; */

}
.duretiondiv h5{
    font-size: 22px;
    font-weight: 500;
    margin-left: 15%;

}
.persondiv{
    font-size: 13px;
    font-weight: 500;
    /* border: 2px solid yellow; */
    margin-top: 5%;
    /* padding-top: 5%; */
    width: 100%;
     margin-left: 2%;
}

.persondiv2{
    /* border: 2px solid green; */
    padding-top: 5%;
    height: 32px;
    /* justify-content: center; */
    text-align: center;

}
.hotelchoice{
    /* border:  2px solid green; */
    margin-left: 3%;
    margin-top: 10%;
    padding-top: 5%;
    padding-left: 5%;
}

.hotelchoicestar{
    /* border: 2px solid yellow ; */
    display: flex;
    flex-direction: row;
    margin-top: 6%;

}
 .hotelchoicedropdown{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    margin-top: 10%;
 }
 .selectoptiondiv{
    /* color: black; */
    /* border: 1px solid #5D6771; */
    width: 90%;
    height: 40px;
    margin-left: 5%;
    margin-top: 10%;
    
 }
 .selectoptiondiv1{
    color: black;
    border: 1px solid lightgrey;
    /* width: 100%; */
    /* margin-left: 5%; */
    height: 100%;
    width: 100%;
    border-radius: 5px;
 }
 .radio0{
    display: flex;
    flex-direction: column;
    margin-top: 12%;
    padding-left: 9%;
 }
 .radio0div{
  /* border: 2px solid yellowgreen; */
  /* margin-left: 3%; */
  margin-top: 5%;
 }
 .radio0div1{
    /* border: 2px solid yellowgreen; */
    /* margin-left: 3%; */
    margin-top: 5%;
    
   }

 .hotelrating{
    border: 1px solid lightgray;

    /* margin-bottom: 15%; */
    width: 40%;
    height: 25px;
    /* margin-left: 4%; */
    font-weight: 500;
    color: #5D6771;
    text-align: center;
    border-radius: 5px;
    margin-right: 3%;
    
 }