.accordionMainOuterDiv{
    /* border: 1px solid red; */
    width: 99.5%;
    margin: auto;
}

.statedrop1header{
    border: 1px solid blue;
}

.statedroptexts{
    width: 100%;
}

.accordion-button{
    z-index: 0 !important;
}