

#filter-travel-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  margin: auto;
  top: 37%;
  background-color: white;
  width: 70%;
  /* border: 3px solid #3380f2 !important; */
  color: #3380f2 !important;
  height: 245px;
  /* opacity: 0.9; */
  left: 15%;
  border-radius: 10px;
  position: absolute;
  z-index: 10;
  margin-bottom: 5%;
}
.chooseoptions {
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
  font-weight: 600;
  font-size: 18px;
  width: 95%;
  border-bottom: 1px solid lightblue;
  height: 29px;
  margin: 5% 0 0% 4.5%;
}

.radiodesc{
  /* border: 1px solid red; */
  margin-left: 0.6%;
}
.chooseoptions1 label {
  margin-top: 1% !important;
  margin-left: 0.5%;
}
.chooseoptions2 {
  margin-left: 5%;
}
.chooseoptions3 {
  margin-left: 5%;
}
.maindatesections {
  border-bottom: 1px solid lightblue;
  width: 100%;
  height: 90px;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
}
.filter-fromTo {
  display: flex;
  cursor: pointer;
  padding: 15px;
  position: relative;
  transition: background-color 0.5s linear;
  height: 60%;
  width: 35%;
  margin-top: 0%;
}

#dateonewayform {
  border: none;
  /* border: 2px solid #3380f2; */
  width: 100% !important;
  margin-top: -10%;
  margin-left: -19%;
  height: 50px;
}

#dateonewayform::placeholder {
  /* border: 2px solid #3380F2; */
  font-size: 30px !important;
  font-weight: 700;
  color: #3380f2;
}
.selectdate {
  /* border: 1px solid ; */
  height: 70%;
  margin-top: 7%;
  margin-left: 5%;
}
.selectdate h5 {
  font-size: 14px !important;
  font-weight: 500;
  margin-top: -12%;
  margin-bottom: 13%;
}
.from-name {
  line-height: 1px;
  width: 45% !important;
  font-size: 11.5px;
}
.from-name h5 {
  font-size: 15px;
  margin-left: 25%;
}
.from-name2 h5 {
  font-size: 15px;
  margin-left: 20%;
}
.cityinput {
  margin-top: -5%;
  margin-left: 10%;
  color: #3380f2;
  border: none;
  height: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 29px !important;
}
.cityinput::placeholder {
  color: #3380f2;
}
.from-name2 {
  line-height: 5px;
  width: 45%;
  margin-left: 4%;
  margin-top: 0%;
  font-size: 11.5px;
}
#formdates {
  display: flex;
  margin: 12px;
  margin-left: 0%;
  margin-top: 0.6%;
}

.selectdate {
  line-height: 2px;
}
#selectReturndate1 {
  line-height: 2px;
  margin-left: 7%;
  height: 70%;
  margin-top: 7%;
}
#selectReturndate1 h5 {
  font-size: 16px;
  font-weight: 500;
  margin-top: -12.5%;
  margin-bottom: 13%;
  margin-left: -7%;
}
#travel {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  width: 33%;
  /* border: 1px solid; */
  height: 70%;
  margin-top: 3%;
  margin-left: -4%;
}
#departure {
  margin: 12px;
  border: 1.4px solid #3380f2;
  background-color: #3380f2;
  width: 51%;
  border-radius: 8px;
  height: 43px;
  cursor: pointer;
  margin-left: 0.5%;
  margin-top: -2%;
  color: white;
}
#departure:hover{
  background-color: blue;
  color: white;
}
#departure h3 {
  font-size: 13px;
  font-weight: 700;
  padding: 12px;
  padding-left: 16px;
}
.searchbtn {
  font-size: 15px;
  font-weight: 700;
  background-color: #3380f2 !important;
  text-transform: capitalize;
  color: white !important;
  border: 2px solid #3380f2 !important;
  border-radius: 10px;
  margin-top: 3%;
  height: 45px;
  width: 40%;
  margin-left: 2%;
  margin-top: -2%;
}
.searchbtn:hover {
  background-color: blue !important;
  border: 3px solid blue !important;
  color: white !important;
}
.carouselmenu {
  width: 55%;
  margin: auto;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  margin-top: 10px;
  position: relative;
  z-index: 1000;
  bottom: 73%;
  color: white  !important;
  border-radius: 40px;
}
#borderLeft{
  border-radius: 40px 0 0 40px;
}
#borderright{
  border-radius: 0 40px 40px 0;
}

/* #iconnames {
  color: #3380f2;
  font-size: 15px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-variant: normal;
  line-height: normal;
  vertical-align: baseline;
}
.iconactive{
  color: white;
} */
.carouselmenu-icon {
  height: 80px;
  width: 15%;
  background-color: #3380f2;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: white;
  text-decoration: none;
}
.flighttext{
  color: white;
  text-decoration: none;
}
.flighttext:hover{
  color: #3380f2;
}
.carouselmenu-icon:hover {
  text-decoration: none;
  color: #3380f2;
  background-color: white;
  transition: all 0.5s ease;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.carouselmenu-icon span {
  white-space: nowrap;
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.carouselmenu-icon-active {
  background: #3380f2 !important;
  border-color: white !important;
  height: 70px;
  width: 90px;
  border-radius: 7px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  color: white !important;
  text-decoration: none;
}
.moredropdowncontent{
  display: none;
  position: absolute;
  color: white;
  background-color: #3380f2;
  width: 200px;
  height: 215px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  margin-top: 32%;
}
#borderright:hover .moredropdowncontent{
   display: block;
}
.moreOptions:hover {
  background-color: white;
  color: #3380f2;
}
.moreOptions{
margin-top: 5%;
padding: 5%;
color: white;
}
.moreOptions a{
  text-decoration: none;
  color: white;
}
.date-picker input {
  width: 100%;
  border: none;
}
.downarrowicon {
  margin-top: 1%;
}
.dropdown-content {
  display: none;
  position: absolute;
  color: white;
  background-color: #3380f2;
  width: 200px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: -1%;
}
.dropdown-content div:hover {
  background-color: white;
  color: #3380f2;
}
#departure:hover .dropdown-content {
  display: block;
}
.navbarOptions {
  margin-top: 5%;
  padding-top: 5%;
}
.decrease {
  margin-left: 10px;
  margin-right: 20px;
}

.checkmark {
  display: flex;
  flex-direction: row;
  margin-bottom: 2%;
  height: 20px;
}
.flightscheckbox {
  margin-left: 5%;
  margin-bottom: -0.5%;
  margin-top: -3%;
}
.flightscheckbox1 {
  margin-left: 0.5%;
  margin-top: -1.2%;
}

@media screen and (min-width: 560px) and (max-width: 950px) {
  .carouselmenu {
    display: none;
  }
  .selectdate {
    margin-top: 2%;
  }
  #selectReturndate1 {
    margin-top: 2%;
  }
  /* .from-name{
        border: 1px solid red;
    } */
  .filter-fromTo {
    width: 33%;
    margin-top: 0% !important;
  }

  .dropdown-content {
    margin-top: 8%;
  }

  .filter-travel-container {
    flex-direction: column;
    margin-top: 5%;
  }
  .filter-fromTo {
    margin: auto;
    margin-top: 25px;
  }
  #formdates {
    width: 33%;
    margin: auto;
    margin-top: -3%;
    margin-left: 15%;
    border: 1px solid;
  }

  #travel {
    margin: auto;
    margin-top: 1%;
    margin-left: 16%;
    width: 65%;
    flex-direction: row;
    font-size: 13px;
  }
  .navbar_fixed {
    /* display: none; */
    width: 100%;
    font-size: 20px;
  }
  .searchbtn {
    width: 100%;
    margin-left: 28%;
    margin-top: 15%;
    padding: 9px;
    font-size: 15px;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .selectdate {
    margin-top: -8%;
    margin-left: 3%;
  }
  #selectReturndate1 {
    margin-top: -8%;
    margin-left: 4%;
  }
  #dateonewayform::placeholder {
    margin-top: -2% !important;
    font-size: 13px;
    border: none;
  }

  .carouselmenu {
    display: none;
  }
  .from-name {
    margin-top: -5%;
    line-height: -20px;
    /* border: 1px solid green; */
  }

  .from-name2 {
    margin-top: -6%;
    line-height: -20px;
    border: 2px solid red;
  }
  .cityinput::placeholder {
    font-size: 15px;
    border: none;
  }

  .dropdown-content {
    margin-top: 13%;
  }

  .searchbtn {
    margin-top: 20%;
    width: 100%;
    padding: 10px;
    font-size: 12px;
  }

  #formdates {
    display: flex;
    flex-direction: row;
    /* border: 1px solid; */
  }
  .filter-travel-container {
    flex-direction: column;
    border: none;
  }
  .filter-travel-container {
    flex-direction: column;
  }
  .filter-fromTo {
    margin: auto;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    /* border:1px solid blueviolet; */
    width: 100%;
  }
  #formdates {
    margin: auto;
    margin-top: 25px;
  }
  #travel {
    margin: auto;
    margin-right: 14%;
    margin-top: 3%;
    flex-direction: row;
    font-size: 13px;
  }
  #departure {
    margin-top: 1%;
  }
}
