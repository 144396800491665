.destinput {
    border: 1px solid blue;
    background-color: white;
    color: black;
    width: 18%;
    height: 45px;
    margin-left: 3%;
  }
  
  .destinputdiv {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    /* margin-left: %; */
    width: 85%;
    height: 130px;
    position: absolute;
    z-index: 2;
    margin-top: -3%;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding-top: 3%;
    margin-left: 7%;
    
  }
  .goingtodiv{
    /* border: 2px solid yellow; */
    width: 20%;
    margin-left: 3%;
  }
  .goingtodiv1{
    border: 1px solid lightgray;
    width: 100%;
    height: 40px;
    padding-left: 10%;
    
  }
  .goingtodivbutton{
    width: 100%;
    padding-left: 40%;
  }
  
  
  
 
  
  
  