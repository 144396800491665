.newExclusivemaindiv {
  width: 99.3%;
  height: 650px;
  margin-bottom: 4%;
  /* border: 2px solid green; */
}

.newExclusivedealsheadingcontainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  width: 97%;
  color: #393939;
  margin: auto;
  background-color: white !important;
  margin-top: 1%;
  height: 80px;
}

.newExclusivedealsheading1 {
  font-size: 35px;
  font-weight: 700;
  width: 20%;
  margin-left: 5%;
  margin-top: 1.4%;
  color: #3380f2;
  /* border: 2px solid red; */
}

.newExclusivedealsheading2 {
  font-size: 15px;
  font-weight: 700;
  margin: auto;
  width: 75%;
  color: #393939;
  display: flex;
  flex-direction: row;
  /* border: 2px solid red; */
}

.newExclusivedealsheading2 h3 {
  margin-left: 10%;
  font-size: 20px;
  margin-top: 2%;
  font-weight: 700;
  color: #393939;
  /* border: 2px solid red; */
}

.newExclusivedealsheading2 h3:hover {
  text-decoration: underline;
}

.newExclusivedealsheadingbuttons {
  /* border: 1px solid yellow; */
  color: #3380f2;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 1%;
  margin-left: 100%;
}

.newExclusivedealsheadingbuttons button {
  color: #3380f2 !important;
  background-color: #3380f2 !important;
}

.newExclusivedealsconatinerdiv {
  /* border: 2px solid gold; */
  width: 98%;
  height: 550px;
  margin: auto;
  margin-top: 0%;
  display: flex;
  flex-direction: row;
}

.newExclusivedealsimageheading {
  width: 40%;
  height: 100%;
  /* position: relative; */
  /* border: 2px solid red; */
  /* border-right: 2px solid #3380F2; */
}

.newExclusivedealsselectoptions {
  background-color: white;
  color: #3380f2;
  /* border-radius: 15px; */
  /* border: 2px solid red; */
  border: none;
  z-index: 100;
  height: 40px;
  width: 40%;
  font-weight: 700;
  padding-left: 2%;
  margin-left: 10%;
  margin-top: 10%;
}

.newExclusiveImagesDiv {
  /* border: 1px solid red; */
  position: relative;
}

.newExclusivedealsimagebackside {
  z-index: 0;
  border-right: 2px solid #3380f2 !important;
  margin-top: 4%;
  width: 95%;
  position: absolute;
  /* height: 100%; */
  /* border: 2px solid black; */
}

.newExclusivedealsimagefrontside {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 1;
  margin-left: 10%;
  margin-top: 3%;
  /* width: 78%;
    height: 500px; */
  /* margin-top: -77%; */
  /* margin-left: -50% !important; */
}

.newExclusivedealsheadings {
  /* border: 2px solid red; */
  color: white;
  position: relative;
  margin-top: 15%;
  z-index: 1000;
  margin-left: 15%;
  width: 70%;
}

.newExclusiveheadingsboldheadings {
  /* position: absolute; */
  font-weight: 700;
  font-size: 35px;
  /* margin-left: 5%; */
}

.newExclusiveDropdownDiv {
  /* border: 2px solid  red; */
  z-index: 1000;
  /* position: absolute; */
}

.newExclusivedealssliderdata {
  width: 60%;
  height: 500px;
  /* border: 2px solid darkblue; */
  margin-top: 1.5%;
}
.newExclusiveouterdivtop{
  width: 50%;
  height: 220px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}
.newExclusivecardimageDiv{
  /* border: 2px solid red; */
  width: 45%;
  height: 250px;
  vertical-align: top;
}
.newExclusiveDealsImage{
  /* border: 2px solid red; */
  height: 220px;
  width: 100%;
}
.newExclusivecarddescmainDiv{
  /* border: 2px solid gold; */
  width: 52%;
  float: right;
  padding: 8px;
  margin-top: -58%;
}
.newExclusivecardimage {
  border: none;
  width: 40%; 
  object-fit: cover;
  height: 250px;
  /* border: 2px solid gold; */
}
.exclusiveDealsImageDiv{
  /* border: 2px solid red; */
  width: 40%;
}
.newExclusivecardimage img {
  height: 230px;
  width: 40%;
  /* border: 2px solid firebrick; */
}

.newExclusivecardInnerImage {
  /* border: 2px solid red; */
  /* opacity: 0.6; */
  filter: brightness(60%);
  height: 250px;
  width: 100%;
}

.newExclusiveimagetextcontainer {
  width: 100%;
  height: 250px;
  position: relative;
  /* border: 2px solid black; */
}

.newExclusivedealstextcard {
  margin-left: 45%;
  width: 100%;
  position: absolute;
  /* top: 10%; */
  /* border: 2px solid seagreen; */
}

.newExclusivedealscardtitle {
  color: #393939;
  font-weight: 700;
  font-size: 18px;
  margin-top: 2%;
}

.newExclusivedealsbutton {
  border: 3px solid #3380f2;
  width: 55%;
  height: 35px;
  border-radius: 25px !important;
  background-color: white;
  color: #3380f2;
  padding: 2px 12px;
  font-weight: 700 !important;
  margin-bottom: 0% !important;
  margin-top: -4%;
  font-size: 14px !important;
}

.newExclusivedealsbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  /* padding: 7px 7px;
    font-size: 14px !important; */
}

.newExclusivedealscard_desc {
  font-size: 14px;
  color: #393939;
}

/* this css is for the responsive of the pages f0r the mobile and web responsive */

/* Media query for the Mobile Device */

@media (max-width: 480px) {
  .newExclusivemaindiv {
    /* border: 1px solid red; */
    width: 99.3%;
    height: 950px;
    margin-bottom: 4%;
  }

  .newExclusivedealsheadingcontainer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    width: 97%;
    color: #393939;
    margin: auto;
    background-color: white !important;
    margin-top: 1%;
    height: 80px;
  }

  .newExclusivedealsheading1 {
    display: none;
  }

  .newExclusivedealsheading2 {
    display: none;
  }

  .newExclusivedealsheading2 h3 {
    margin-left: 10%;
    font-size: 20px;
    margin-top: 2%;
    font-weight: 700;
  }

  .newExclusivedealsheading2 h3:hover {
    text-decoration: underline;
  }

  .newExclusivedealsheadingbuttons {
    color: #3380f2;
    justify-content: flex-end;
    margin-left: 100%;
    cursor: pointer;
    margin-top: 1%;
  }
  .newExclusivecarddescmainDiv{
    /* border: 2px solid red; */
    margin-top: -70%;

  }
  .newExclusivedealsheadingbuttons button {
    color: #3380f2 !important;
    background-color: #3380f2 !important;
  }

  .newExclusivedealsconatinerdiv {
    width: 100%;
    height: 350px;
    margin: auto;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
  }

  .newExclusivedealsimageheading {
    width: 90%;
    /* height: 100%; */
  }

  .newExclusivedealsselectoptions {
    background-color: white;
    color: #3380f2;
    border: none;
    z-index: 100;
    height: 40px;
    width: 40%;
    font-weight: 700;
    padding-left: 2%;
    margin-left: 4%;
    margin-top: 10%;
  }

  .newExclusiveImagesDiv {
    position: relative;
  }

  .newExclusivedealsimagebackside {
    display: none;
  }

  .newExclusivedealsimagefrontside {
    position: absolute;
    /* border: 2px solid red; */
    width: 100%;
    /* height: 70%; */
    z-index: 1;
    margin-left: 4%;
    margin-top: 1%;
  }

  .newExclusivedealsheadings {
    color: white;
    position: relative;
    margin-top: 20%;
    z-index: 100;
    margin-left: 15%;
    width: 70%;
  }

  .newExclusiveheadingsboldheadings {
    font-weight: 700;
    font-size: 30px;
    vertical-align: baseline;
    margin-bottom: 0%;
  }

  .newExclusiveHeadingsLowerHeadings {
    font-size: 18px;
    font-weight: 400;
    vertical-align: baseline;
    line-height: 20px;
    margin-top: 1%;
  }

  .newExclusiveDropdownDiv {
    z-index: 1000;
    /* border: 1px solid red; */
  }

  .newExclusivedealssliderdata {
    width: 95%;
    margin: auto;
    margin-top: 35%;
    /* border: 2px solid red; */
  }

  .newExclusivecardimage {
    /* border: none; */
    /* border: 2px solid red; */
    width: 100%;
    object-fit: cover;
  }

  .newExclusivecardimage img {
    height: 220px !important;
    width: 100%;
  }

  .newExclusivecardInnerImage {
    filter: brightness(60%);
    width: 100%;
  }

  .newExclusiveimagetextcontainer {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 10% !important;
    height: 200px !important;
    position: relative;
  }

  .newExclusivedealstextcard {
    margin-left: 10% !important;
    width: 90% !important;
    position: absolute;
    top: 0%;
  }

  .newExclusivedealscardtitle {
    color: #393939;
    font-weight: 700;
    font-size: 16px;
    margin-top: 2%;
  }

  .newExclusivedealsbutton {
    border: 3px solid #3380f2;
    width: 70%;
    border-radius: 25px;
    background-color: white;
    color: #3380f2;
    padding: 2px 12px;
    font-weight: 700;
    margin-bottom: 0%;
    margin-top: 0%;
    font-size: 14px;
  }

  .newExclusivedealsbutton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
  }

  .newExclusivedealscard_desc {
    font-size: 14px;
    color: #393939;
  }
}


