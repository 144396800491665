/* 

.newHolidaysCategoryMainDiv{
    width: 85%;
    height: 220px;
    margin: auto;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    background-color: #F3F7FA;
}
.newHolidaysCategorycard{
    width: 25%;
    height: 170px;
    margin: 2% 0% 0% 0%;
    display: flex;
    flex-direction: row;
    text-align: left;
}
.newHolidaysCategorycardimageDiv{
    height: 100px;
    border-radius: 10px;
    width: 70%;
    margin: auto;
    margin-top: 15%;
    cursor: pointer;
} */

.newHolidayCategoryMainDiv{
    width: 100%;
    height: 350px;
    margin: auto;
    /* border: 1px solid gold; */
    display: flex;
    flex-direction: column;
    /* background-color: #F3F7FA; */
}
.newHolidaysCategoryheading{
    color: #3380F2;
    font-size: 28px;
    font-weight: 700;
    height: 50px;
    margin-left: 3%;
    width: 95%;
    /* border: 2px solid red; */
}
.newHolidaysCategoryheadingText{
    color: #393939;
    /* border: 2px solid red; */
    width: 90%;
    font-size: 19px;
    margin-left: 3%;
}
.newholidaysCategorycontainer{
    /* border: 2px solid green; */
    width: 85%;
    height: 200px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-left: 6.5%;
}
.newHolidayCategoryTheme{
    border: 0.2px solid lightgray;
    width:22%;
    height: 140px;
    margin-top: 4%;
    border-radius: 15px;
    margin-left: 3%;
    background-color: white;
}
.holidayTheme{
    /* border: 2px solid salmon; */
    width: 30%;
    height: 85px;
    margin-top: -13%;
    margin-left: 35%;
}
.holidayThemeText{
    color: #393939;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    margin-top: 13%;
}
/* .newHolidaysCategorycardsubheading{
    color:#393939;
    font-size: 25px;
    font-weight: 700;
    margin: auto;
    z-index: 1;
    text-align: center;
    margin-left: 3.5%;
    position: absolute;
    
}
.newHolidaysCategorycardimages{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    filter: brightness(0.6);
    z-index: 0;
} */

