.NewStaycationpicsSlidermaindiv{
    width: 100%;
    height: 500px;
    /* margin-bottom: 4%; */
    /* margin-top: 2%; */
    /* border: 2px solid green; */
}
.NewStaycationpicsSliderdealsheadingcontainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #3380F2;
  /* margin: auto; */
  background-color: white !important;
  margin-top: 1%;
  height: 80px;
}
.NewStaycationpicsSliderdealsheading1{
  font-size: 35px;
  font-weight: 700;
  width: 20%;
  margin-left: 5%;
  margin-top: 1.4%;
  color: #3380F2;
}
.NewStaycationpicsSliderdealsheading2{
  font-size: 15px;
  font-weight: 700;
  margin: auto;
  color: #3380F2;
  display: flex;
  flex-direction: row;
}
.NewStaycationpicsSliderdealsheading2 h3{
  margin-left: 10%;
  font-size: 20px;
  margin-top: 2%;
  font-weight: 700;
}
.NewStaycationpicsSliderdealsheading2 h3:hover{
  text-decoration: underline;
}
.NewStaycationpicsSliderdealsheadingbuttons {
  /* border: 1px solid yellow !important; */
  color: #3380F2;
  justify-content: flex-end;
  width: -1%;
  margin-left: 100%;
  cursor: pointer;
  margin-top: 1%;
}

.NewStaycationpicsSliderdealsheadingbuttons button {
  color: #3380f2 !important;
  background-color: #3380F2 !important;
}
.NewStaycationpicsSliderdealsconatinerdiv{
    width: 100%;
    height: 450px;
    margin: auto;
    /* border: 2px solid gold; */
    margin-top: 0%;
    display:flex;
    flex-direction: row;
}
.NewStaycationpicsSliderdealsimageheading{
    width: 40%;
    height: 100%;
    /* border: 2px solid red; */
    /* border-right: 2px solid #3380F2; */
}
.NewStaycationpicsSliderdealsselectoptions{
  background-color: white;
  color: #3380F2;
  /* border: 2px solid white !important; */
  /* border-radius: 15px; */
  border: none;
  z-index: 100;
  height: 40px;
  width: 40%;
  font-weight: 700;
  padding-left: 2%;
  margin-left: 10%;
  margin-top: 10%;
}
.NewStaycationpicsSliderdealsimagebackside{
    z-index: 0;
    border-right: 2px solid #3380F2;
    margin-top: 4%;
    width: 98%;
    height: 100%;
    /* border: 2px solid black; */
}
.NewStaycationpicsSliderdealsimagefrontside{
    z-index: 1;
    /* border: 2px solid red; */
    width: 78%;
    height: 104%;
    margin-top: 2%;
    margin-left: 9%;
}
.NewStaycationpicsSliderdealsheadings{
    color: white;
    margin-top: -60%;
    z-index: 10;
    margin-left: 15%;
    width: 70%;
}
.NewStaycationpicsSliderheadingsboldheadings{
    font-weight: 700;
    font-size: 35px;
    margin: auto;
    margin-top: 8%;
    font-style: italic;
    /* margin-left: 5%; */
}
.NewStaycationpicsSliderdealssliderdata{
    width: 59%;
    height:100%;
    margin-top: 2%;
    margin-left: -2%;
    /* border: 2px solid gold; */
}
.NewStaycationpicsSlidercardimage {
    border: none;
    width: 100% !important;
    object-fit: cover;
    border-radius: 15px;
    height: 200px;
    /* border: 2px solid red; */
  }
  
  .NewStaycationpicsSlidercardimage img{
    height: 200px;
    width: 100% ;
    border-radius: 15px !important;
  }
  .NewStaycationpicsSliderouterdivtop{
    /* border: 2px solid blue; */
    border-radius: 15px;
    width: 95% !important;
    /* margin-left: 4%; */
  }
  .NewStaycationpicsSliderimagetextcontainer {
    width: 100%;
    height: 200px !important;
    /* border: 2px solid black; */
    border-radius: 15px;
  }
  .NewStaycationpicsSliderdealstextcard {
    margin-left: 40% !important;
    width: 64% !important;
    margin-top: -15% !important;
  }
  .NewStaycationpicsSliderdealscardtitle {
    color: #3380f2 !important;
    font-size: 18px;
    margin-top: 2%;
  }
  .NewStaycationpicsSliderdealsbutton {
    border: 3px solid #3380f2;
    width: 43%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 2px 12px;
    font-weight: 700 !important;
    margin-bottom: 0% !important;
    margin-top: -4%;
    font-size: 14px !important;
  }
  .NewStaycationpicsSliderdealsbutton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    /* padding: 7px 7px;
    font-size: 14px !important; */
  }
  .NewStaycationpicsSliderdealscard_desc{
    font-size: 14px;
  }