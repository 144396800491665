

.innerVisaTouristPlaceHeading{
    width:25%;
    height: 40px;
    font-weight: 700;
    /* margin: auto; */
    /* border: 2px solid red; */
    text-align: left;
    /* border-bottom: 3px solid #3380F2; */
    margin-top:1%;
    margin-left: 7%;
    /* text-decoration: underline; */
}
.innerVisaTouristPlaceHeading h3{
  font-weight: 700;
}

/* .innerVisaTouristPlaceHeading h2{
    text-decoration: underline;
} */

.innerVisaTouristPlaceconatinerDiv{
    /* border-top: 2px solid #3380F2; */
    /* border-bottom: 2px solid #3380F2; */
    /* border: 2px solid red; */
    width: 100%;
    height: auto;
    margin: auto;
    padding-top: 20px;
    position:relative;
}
.innerVisaTouristPlaceSliderConatiner{
    /* border: 2px solid red; */
    width:85%;
    margin: auto;
    margin-top: 2%;
}
.innerVisaTouristPlaceSliderConatiner1{
    /* border: 2px solid yellow; */
    width: 100%;
    margin: auto;
    margin-top: 3%;
}
.innerVisaTouristPlaceSliderConatiner2{
    /* border: 2px solid yellow; */
    width: 100%;
    margin: auto;
    margin-top: 3%;
}
.innerVisaTouristPlaceouterdiv {
    border: 1px solid lightgray !important;
    display: flex !important;
    flex-direction: row !important;
    width: 95% !important;
    height: 370px !important;
    /* margin-left: 15%; */
    /* box-shadow: 4px 4px 4px rgb(211,211,211); */
    border: none;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .innerVisaTouristPlaceouterdiv:hover {
    /* padding: 5px; */
    /* box-shadow: 5px 10px 25px  #3380f2; */
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  }
  .innerVisaTouristPlaceImagetextcontainer {
    width: 100%;
    height: 370px;
    /* border: 2px solid red; */
  }
  .innerVisaTouristPlaceCardimage {
    border: none;
    width: 100% !important;
    height: 180px;
    /* margin-left: 2%; */
    /* margin-top: 2%; */
  }
  .handPickedDestinationHillStationsRecommendedimage{
    height: 200px;
    object-fit: cover;
    width: 100%;
    margin: auto;
    /* margin-top: 4%; */
    border-radius: 7px 7px 0px 0px !important;
  }
  .innerVisaTouristPlaceTextcard {
    /* border: 1px solid black; */
    /* margin-left: 9% !important; */
    width: 100% !important;
  }

  .innerVisaTouristPlaceCarddescmain {
    /* border: 1px solid red; */
    width: 100%;
    /* margin-top: -2%; */
  }
  .innerVisaTouristPlaceCarddescmain p{
    font-size: 12px;
    margin-top: -2%;
  }
  .innerVisaTouristPlaceCardtitle {
    /* border: 1px dotted slateblue; */
    color: #393939;
    font-size: 20px;
    font-weight: 700;
    margin-top: 6%;
  }
  .innerVisaTouristPlaceButton{
    background-color: #3380F2;
    color: white;
    border: none;
    font-size: 12px;
    border-radius: 5px;
    width: 27%;
    margin-bottom: -3%;
    height: 31px;
    margin-left: 73%;
    
  }