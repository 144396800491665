.hotelDataDropdown_container {
  margin-top: 3%;
  height: 390px;
}

.hotelData_maincontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  gap: 5%;
}

.hotelsData_dropdowncontext {
  background-color: whitesmoke !important;
  border-radius: 9px;
  color: #393939;
}

.hotelsDatadropdownmaincontainer1 {
  margin-top: 2%;
  width: 35%;
  color: #393939;
  margin-left: 2%;
}

.hotelsDatadropdownmaincontainer2 {
  margin-top: 2%;
  color: whitesmoke;
  width: 35%;
}

.hotelsDatadropdownmaincontainer3 {
  margin-top: 2%;
  color: whitesmoke;
  width: 35%;
}

.faq_RentalsHeading {
  font-size: 30px;
  font-weight: 700;
  padding-left: 20px;
}

.accordion_Rentalsdropdownmaincontainer3 {
  margin-top: 3% !important;
  width: 50%;
  color: #393939;
  margin: auto;
  text-align: center;
}

.accordion-button {
  background-color: whitesmoke !important;
  color: #393939;
}

/* media query css for the different devices */
@media (max-width: 480px) {
  .hotelDataDropdown_container {
    margin-top: 3%;
    height: 390px;
  }

  .hotelData_maincontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    gap: 5%;
  }

  .hotelsData_dropdowncontext {
    background-color: whitesmoke !important;
    border-radius: 9px;
    color: #393939;
  }

  .hotelsDatadropdownmaincontainer1 {
    margin-top: 2%;
    width: 35%;
    color: #393939;
    margin-left: 2%;
  }

  .hotelsDatadropdownmaincontainer2 {
    margin-top: 2%;
    color: whitesmoke;
    width: 35%;
  }

  .hotelsDatadropdownmaincontainer3 {
    margin-top: 2%;
    color: whitesmoke;
    width: 35%;
  }

  .faq_RentalsHeading {
    font-size: 30px;
    font-weight: 700;
    padding-left: 20px;
  }

  .accordion_Rentalsdropdownmaincontainer3 {
    margin-top: 3% !important;
    width: 50%;
    color: #393939;
    margin: auto;
    text-align: center;
  }

  .accordion-button {
    background-color: whitesmoke !important;
    color: #393939;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
