.rideOffersMainOterDiv {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.rideOfferHEadingsDiv {
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
}

.rideOfferHEadingsDiv h3 {
  font-weight: 600;
  vertical-align: baseline;
  font-size: 25px;
  width: 50%;
}

.rideOfferSelectDiv {
  /* border: 1px solid black; */
  margin-left: 38%;
  border: 1px solid #3380f2;
  border-radius: 7px;
  width: 11.5%;
}

.rideOfferSelectDiv select{
  color: #3380f2;
  box-shadow: none;
  background-image: cross-fade(0.5);
}

.rideOfferCardsOuterDiv {
  /* border: 1px solid lightgray; */
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  /* height: 300px; */
  /* margin-top: 2%; */
  column-gap: 45px;
  /* width: 95%;
    margin: auto; */
}

.ridesOfferInnerImageDiv {
  /* border-right: 1px solid red; */
  /* border: none; */
  /* border: 1px solid lightgray; */
  /* height: 250px; */
  margin-top: 1%;
  margin-left: 1%;
  /* margin-right: 3%;
  margin-bottom: 2%; */
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* object-fit: cover; */
}

.ridesOfferInnerImageDiv img {
  /* height: 150px;   */
  width: 80%;
  object-fit: cover;
  margin-left: 10%;
  margin-top: 12%;
  margin-left: 10%;
}


/* .offeredServicesContentFiv{
  border: 1px solid red;
} */

.offeredServicesContentFiv h5{
  font-weight: 600;
  font-size: 25px;
  vertical-align: baseline;
  line-height: 20px;
  text-align: center;
  margin-top: 3%;
}

.offeredServicesContentFiv p{
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  vertical-align: baseline;
  color: #393939;
  margin-top: 4%;
  margin-bottom: 3%;
}

.ridesOfferInnerImageDiv1 {
  /* border-right: 1px solid red; */
  border: none;
  /* border-right: 1px solid lightgray; */
  height: 250px;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  width: 25%;
  cursor: pointer;

  /* object-fit: cover; */
}

.ridesOfferInnerImageDiv1 img {
  height: 250px;
  object-fit: cover;
}
