.contactUSFormMainDiv{
    /* border: 2px solid red; */
    width: 100%;
    height: 670px;
}
.contactUSFormcontainerdiv{
    border: 2px solid lightgray;
    width: 85%;
    height: 600px;
    margin: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.contactInformation{
    /* border: 2px solid brown; */
    width: 35%;
    height: 550px;
    margin: 2%;
    position: relative;
    /* background-image: url(./rectangle.png); */
}
.contactInformationBackground{
    width: 100%;
    height: 100%;
}
.contactInformationDetails{
    position: absolute;
    z-index: 1;
    width: 90%;
    height: 500px;
    /* border: 2px solid black; */
    margin: 5%;
    top: 1%;
    color: white;
    display: flex;
    flex-direction: column;
}
.contactInformationDetailsheading{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
}
.contactInformationDetailsheading h3{
    color: white;
    font-size: 25px;
    font-weight: 700;
}
.contactInformationsocial{
    /* border: 2px solid red; */
    height: 200px;
    margin-top: 15%;
}
.contactInformationsocial1{
    height: 50px;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
}
.contactInformationsocial1Icon{
    color: white;
    font-size: 20px;
}
.contactInformationsocial1Text{
    color: white;
    margin-left: 5%;
    margin-top: 1%;
}
.contactInformationsocial2{
    height: 50px;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    margin-top: 8%;
}
.contactInformationsocial3{
    height: 50px;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    margin-top: 8%;
}

.contactFormMaindiv{
    /* border: 2px solid red; */
    width: 70%;
    height: 600px;
    
}

.contactformDiv1{
    /* border: 2px solid red; */
    height: 70px;
    margin-top: 2.5%;
}
.contactselectSubjectheading{
    color: grey;
    /* border: 2px solid red; */
    margin-left: 20%;
    width: 70%;
    bottom: 0;
}
.contactformRadioselect{
    /* border: 2px solid red; */
    height: 50px;
    width: 70%;
    /* margin: auto; */
    display: flex;
    flex-direction: row;
    margin-left: 17%;
    margin-top: -2%;
}
.contactFirstName{
    border: none;
    border-bottom: 2px solid lightgray;
    outline: none;
    color:#3380F2;
    margin-left: 20%;
    /* border: 2px solid red; */
    background-color: transparent;
}
.contactLastName{
    border: none;
    border-bottom: 2px solid lightgray;
    outline: none;
    color:#3380F2;
    margin-left: 10%;
    background-color: transparent;
    /* border: 2px solid red; */
}
.textareamsg{
    width: 75%;
    height: 150px;
    outline: none;
    /* margin: 5%; */
    resize: none;
    margin-left:12%;
    padding: 10px;
    border: 1px solid lightgray;
    color: #3380F2;
}
.contactformDiv2{  
    /* border:2px solid red; */
    width: 80%;
    margin: auto;
    line-height: 15px;
    color: gray;
    margin-top: 2%;
}
.contactformDiv2 p{
    padding-left: 80px;
}
.planeimage{
    /* border: 2px solid red; */
    margin-left: 50%;
    width: 25%;
    margin-top: -3%;
    /* height: 80px; */
    /* margin-top: -5%; */
}
.submitcontact{
    background-color: #3380F2;
    width: 17%;
    height: 40px;
    border-radius: 10px;
    color: white;
    margin-left: 62.5%;
    padding: 7px 15px;
    margin-top: 1%;
    /* border: 2px solid red; */
}
.contactIconsSocialMedia{
    /* border: 2px solid red; */
    margin-top: 32%;
    width:65%;
    height: 60px;
    color: white !important;
    position: relative;
}
.contactfootersocialmedia1 {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 3%;
    color: White !important;
    font-size: 15px;
  }
 .radiolablemargin{
    padding-top: 12px;
    color: grey;
    /* margin-left: 4%; */
 }
 .contactformradioheadings{
    margin-left: 4%;
 }