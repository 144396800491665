/* ------------------------------New Footer ---------------------------------------------- */

.newHomeFootermainDiv {
  /* border: 2px solid red; */
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.newHomeFootersectionOne {
  /* border: 2px solid yellow; */
  width: 100%;
  height: 403px;
  display: flex;
  flex-direction: row;
}

.newHomeFooterMapDiv {
  /* border: 2px solid blue; */
  width: 50%;
  height: 403px;
  position: relative;
  background-color: #ffffff;
}

.newHomeFooterMapImage {
  /* border: 2px solid purple; */
  width: 94%;
  height: auto;
  margin-left: 10%;
  object-fit: cover !important;
  /* margin-top: 3%; */
}

.newHomeFooterMapText {
  /* border: 2px solid red; */
  width: 30%;
  height: 80px;
  margin-left: 10%;
  color: #3380f2;
  line-height: 17px;
  margin-top: 1%;
}

.newHomeFooterMapText1 {
  font-weight: 700;
}

.newHomeFooterMapText2 {
  font-size: 15px;
}

.newHomeFooterlinkDiv {
  /* border: 2px solid saddlebrown; */
  width: 50%;
  height: 380px;
}

.newHomeFooterlogoCurrencyDropDown {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.newFooterCurrencyDropDown {
  margin-left: 30%;
  margin-top: 2%;
  width: 30%;
}

.newFooterCurrencyDropDown h5 {
  color: #3380f2;
  font-size: 15px;
}

.newHomeFootersocialIconsText {
  border: 1px solid white;
  width: 100%;
  height: 125px;
  background-color: #ffffff;
}

.newHomeFootersocialText {
  color: #3380f2;
  width: 80%;
  margin-top: 2%;
  margin-left: 9%;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.footernewHomesocialmedia {
  display: flex;
  width: 50%;
  height: 35px;
  justify-content: center;
  column-gap: 20px;
  margin-top: 2%;
  color: white !important;
}

.newHomefooterlinks {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.newHomefooterlinks1 {
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  width: 27%;
  height: 180px;
  color: #3380f2;
  margin-left: 6%;
  cursor: pointer;
}

.newHomefooterlinks1 h5 {
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.newHomefooterlinks1 li {
  border: 10x solid red !important;
  font-size: 13.5px;
  font-weight: 500;
}

.newHomeFootersectionTWo {
  width: 100%;
  height: 220px;
  line-height: 10px;
}

.newHomeFootersectionLogos {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  color: #3380f2;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  background-color: #ffffff;
}

.newHomeFootersectionLogos p {
  padding-top: 12px;
  margin-top: 2%;
}

.newHomeFootersectionLogosDiv {
  width: 60%;
  margin-top: -1% !important;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.newHomeFooterImages {
  width: 25%;
  margin-top: 1%;
  height: 50px;
  column-gap: 5% !important;
}

.newHomeFooterlinkImage {
  width: 24%;
  height: 45px;
  margin-top: 3%;
  margin-left: 10%;
  object-fit: cover;
}

.newHomeFootersectionLogosImages {
  border: 2px solid white;
  height: 42px;
  width: 100%;
  gap: 5% !important;
}

.newHomeFootersectionLogosTravelFlap {
  height: 90px;
  width: 90%;
  margin: auto;
  margin-top: 1%;
  margin-left: 15%;
  object-fit: cover;
  z-index: 10000;
}

.newHomeFootersectionLogosBlueberry {
  border: 1px solid white;
  height: 32px;
  width: 83%;
  margin: auto;
  margin-top: 5%;
  margin-left: 15%;
  object-fit: cover;
}

.newHomeFootersectionLogosNextHolidays {
  height: 28px;
  width: 72%;
  margin: auto;
  margin-top: 3%;
  margin-left: -8%;
  object-fit: cover;
}

.newHomeFootersectionLogosVisaFetch {
  height: 40px;
  width: 52%;
  margin: auto;
  margin-top: 7%;
  margin-left: -30%;
  object-fit: cover;
}

.newHomeFooterwavesection {
  width: 100%;
  height: 115px;
  z-index: 0;
  background-color: #ffffff;
}

.newHomeFootertextandPayment {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  margin-top: -2%;
  z-index: 1;
}

.newHomeFootercopyrightText {
  color: white;
  font-size: 12px;
  font-weight: 400;
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-left: 5%;
}

.newFooterPaymentIamges {
  width: 40%;
  height: 50px;
  margin-left: 5%;
  margin-top: -1.2%;
}

.paymentGatewayGroup {
  border: 1px solid red;
  width: 70% !important;
  left: 5% !important;
}

.newFooterCountryDrop {
  background-color: white !important;
  width: 59% !important;
  height: 30px !important;
  margin-bottom: 3% !important;
  color: #3380f2 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 2px;
  border: none;
  border-radius: 1px;
}

.newFooterCountryDrop:hover {
  background-color: #3380f2 !important;
  color: white !important;
  font-weight: 700;
  border: 3px solid white !important;
}

/* Tnis css is for the hover part in the global map */

.newYorkLocation {
  position: absolute;
  top: 35%;
  left: 25%;
  width: 30px;
  height: 30px;
}

.zambiaLocation {
  position: absolute;
  top: 64%;
  left: 62%;
  width: 30px;
  height: 30px;
}

.germanyLocation {
  position: absolute;
  top: 37%;
  left: 55%;
  width: 30px;
  height: 30px;
}

.indiaLocation {
  position: absolute;
  top: 47%;
  left: 72%;
  width: 30px;
  height: 30px;
}

.uaeLocation {
  position: absolute;
  top: 49%;
  left: 64%;
  width: 30px;
  height: 30px;
}

.tooltipCustomised {
  background-color: #3380f2;
  width: 130px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;
  color: white;
  margin-top: 3%;
  margin-left: -1.5%;
  position: relative;
}

.tooltipCustomised > p {
  font-size: 10px;
  margin: 0;
}

.indicate {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  left: 15px;
  transform: rotate(45deg);
  background-color: #3380f2;
}

.nav-link {
  height: 36px;
  border-radius: 4px;
  background-color: #3380f2;
  cursor: pointer;
  margin: 0 5px 0 0;
  width: 70px;
  text-align: center;
  color: white;
  margin-top: 0;
}

@media (max-width: 480px) {
  .newHomeFootermainDiv {
    /* border: 2px solid red; */
    width: 98%;
    height: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .newHomeFootersectionOne {
    /* border: 2px solid yellow; */
    width: 100%;
    height: 403px;
    display: flex;
    flex-direction: column;
  }

  .newHomeFooterMapDiv {
    /* border: 2px solid blue; */
    width: 90%;
    height: 403px;
    margin: auto;
    position: relative;
    background-color: #ffffff;
  }

  .newHomeFooterMapImage {
    /* border: 2px solid purple; */
    width: 93%;
    /* height: auto; */
    /* margin-left: 10%; */
    /* object-fit: cover !important; */
    /* margin-top: 3%; */
  }

  .newHomeFooterMapText {
    /* border: 2px solid red; */
    width: 30%;
    height: 80px;
    margin-left: 10%;
    color: #3380f2;
    line-height: 17px;
    margin-top: 1%;
  }

  .newHomeFooterMapText1 {
    font-weight: 700;
  }

  .newHomeFooterMapText2 {
    font-size: 15px;
  }

  .newHomeFooterlinkDiv {
    /* border: 2px solid saddlebrown; */
    width: 99%;
    height: 380px;
  }

  .newHomeFooterlogoCurrencyDropDown {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
  }

  .newFooterCurrencyDropDown {
    margin-left: 10%;
    margin-top: 2%;
    width: 100%;
  }

  .newFooterCurrencyDropDown h5 {
    color: #3380f2;
    font-size: 15px;
    font-weight: 500;
  }

  .newHomeFootersocialIconsText {
    border: 1px solid white;
    width: 100%;
    height: 125px;
    background-color: #ffffff;
  }

  .newHomeFootersocialText {
    color: #3380f2;
    width: 95%;
    margin: auto;
    margin-top: 2%;
    font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 18px;
  }

  .footernewHomesocialmedia {
    display: flex;
    width: 50%;
    height: 35px;
    justify-content: center;
    column-gap: 20px;
    margin-top: 2%;
    color: white !important;
  }

  .newHomefooterlinks {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
  }

  .newHomefooterlinks1 {
    font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
    width: 27%;
    height: 180px;
    color: #3380f2;
    margin-left: 6%;
    cursor: pointer;
    display: none;
  }

  .newHomefooterlinks1 h5 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  .newHomefooterlinks1 li {
    /* border: 10x solid red !important; */
    font-size: 13.5px;
    font-weight: 500;
  }

  .newHomeFootersectionTWo {
    width: 100%;
    height: 220px;
    line-height: 10px;
    margin-top: 4%;
  }

  .newHomeFootersectionLogos {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    color: #3380f2;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    background-color: #ffffff;
  }

  .newHomeFootersectionLogos p {
    padding-top: 12px;
    margin-top: 2%;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }

  .newHomeFootersectionLogosDiv {
    width: 100%;
    margin-top: 0% !important;
    margin: auto;
    display: flex;
    flex-direction: row;
  }

  .newHomeFooterImages {
    /* border: 1px solid red; */
    width: 25%;
    margin-top: 1%;
    height: 50px;
    column-gap: 9% !important;
  }

  .newHomeFooterlinkImage {
    width: 35%;
    height: 45px;
    margin-top: 3%;
    margin-left: 10%;
    object-fit: contain;
  }

  .newHomeFootersectionLogosImages {
    border: 2px solid white;
    height: 42px;
    width: 100%;
    gap: 5% !important;
  }

  .newHomeFootersectionLogosTravelFlap {
    height: 60px;
    width: 100%;
    margin: auto;
    margin-top: 0%;
    margin-left: 5%;
    object-fit: contain;
    /* z-index: 10000; */
  }

  .newHomeFootersectionLogosBlueberry {
    border: 1px solid white;
    height: 32px;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    margin-left: 5%;
    object-fit: contain;
  }

  .newHomeFootersectionLogosNextHolidays {
    height: 28px;
    width: 72%;
    margin: auto;
    margin-top: 3%;
    margin-left: -8%;
    object-fit: contain;
  }

  .newHomeFootersectionLogosVisaFetch {
    height: 35px;
    width: 62%;
    margin: auto;
    margin-top: 2%;
    margin-left: -60%;
    object-fit: contain;
  }

  .newHomeFooterwavesection {
    width: 100%;
    height: 115px;
    z-index: 0;
    background-color: #ffffff;
  }

  .newHomeFootertextandPayment {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    margin-top: -13%;
    z-index: 1;
  }

  .newHomeFootercopyrightText {
    color: white;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-left: 5%;
  }

  .newFooterPaymentIamges {
    width: 40%;
    height: 50px;
    margin-left: 5%;
    margin-top: -1.2%;
  }

  .paymentGatewayGroup {
    border: 1px solid red;
    width: 70% !important;
    left: 5% !important;
  }

  .newFooterCountryDrop {
    background-color: white !important;
    width: 59% !important;
    height: 30px !important;
    margin-bottom: 3% !important;
    color: #3380f2 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    border: none;
    border-radius: 1px;
  }

  .newFooterCountryDrop:hover {
    background-color: #3380f2 !important;
    color: white !important;
    font-weight: 700;
    border: 3px solid white !important;
  }

  /* Tnis css is for the hover part in the global map */

  .newYorkLocation {
    position: absolute;
    top: 35%;
    left: 25%;
    width: 30px;
    height: 30px;
  }

  .zambiaLocation {
    position: absolute;
    top: 64%;
    left: 62%;
    width: 30px;
    height: 30px;
  }

  .germanyLocation {
    position: absolute;
    top: 37%;
    left: 55%;
    width: 30px;
    height: 30px;
  }

  .indiaLocation {
    position: absolute;
    top: 47%;
    left: 72%;
    width: 30px;
    height: 30px;
  }

  .uaeLocation {
    position: absolute;
    top: 49%;
    left: 64%;
    width: 30px;
    height: 30px;
  }

  .tooltipCustomised {
    background-color: #3380f2;
    width: 130px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 5px;
    color: white;
    margin-top: 3%;
    margin-left: -1.5%;
    position: relative;
  }

  .tooltipCustomised > p {
    font-size: 10px;
    margin: 0;
  }

  .indicate {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    left: 15px;
    transform: rotate(45deg);
    background-color: #3380f2;
  }

  .nav-link {
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    color: white;
    margin-top: 0;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
