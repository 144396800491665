.innerPopularDestination2scrollDatamaindiv{
  width: 100%;
  height: 1000px;
}

.innerPopularDestination2scrollDataOuterdiv {
  border: 2px solid lightgray !important;
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  height: 420px !important;
  transition: all 300ms ease-in-out;
}

.innerPopularDestination2scrollDataOuterdiv:hover {
  width: 100%;
  /* padding: 5px; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.innerPopularDestination2scrollDataHeadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  margin-top: 3%;
}

.innerPopularDestination2scrollDataHeading1 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 2%;
  margin-left: 5%;
  width:65%;
  color: #393939;
  /* color: #3380f2; */
}

.newviewallActivityAroundbuttons {
  color: white;
  font-weight: 700;
  text-decoration: none !important;
  display: flex;
  margin: 1.5% 0 0 55%;
  width: 56%;
}

.innerPopularDestination2scrollDataSliderContainer{
  width: 90%;
  margin: auto;
  margin-top: 1.7%;
}
.innerPopularDestination2scrollDataCardtitle {
  color: #393939 !important;
  font-size: 22px;
  margin-top: 1%;
  font-weight: 700;
}

.innerPopularDestination2scrollDataCard_desc {
  width: 90%;
  color: #303030;
  font-size: 14px;
  text-align: left;
}

.domesticWorldButtonDiv{
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.domesticWorldButtonDiv h5{
  font-size: 14px;
  /* border: 1px solid black; */
  margin-top: 3%;
  font-weight: 600;
  width: 50%;
  color: gray;
}

.domesticWorldButtonDiv span{
  color: #3380f2;
  font-size: 18px;
  margin-left:1%;
}
.innerPopularDestination2scrollDataButton {
  border: 2px solid #3380f2;
  width: 32%;
  border-radius: 15px !important;
  background-color: #3380F2;
  color: white;
  padding: 4px 9px;
  font-weight: 500;
  margin-left: 20%;
  margin-bottom: 1%;
  /* margin-top: 3%; */
  font-size: 14px !important;
}
.innerPopularDestination2scrollDataButton:hover {
  border: 2px solid #3380F2;
  background-color: white;
  color: #3380F2;
  /* font-size: 14px !important; */
}

.innerPopularDestination2scrollDataImagetextcontainer {
  width: 100%;
  height: 430px;
  /* border: 2px solid red; */
  /* border-radius: 0px 0px 10px 10px !important; */
}

.innerPopularDestination2scrollDataTextcard {
  margin-left: 9% !important;
  width: 100% !important;
}

.innerPopularDestination2scrollDataTextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}
.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.innerPopularDestination2scrollDataCardimage {
  /* border: 2px solid rebeccapurple; */
  width: 100% !important;
  height: 230px;
  /* margin-left: 4%; */
  /* margin-top: 4%; */
}

.activityAroundimage{
  height: 240px !important;
  width: 100% !important;
  border-radius: 10px 10px 0px 0px;
}

.innerPopularDestination2scrollDataCarddescmain {
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

.newviewallActivityAroundtext{
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 34%;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-top: 4px;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.newviewallActivityAroundtext:hover{
  background-color: white;
  color: #3380f2;
}

.newActivityAroundHeadingbuttons {
  justify-content: flex-end;
  /* width: -1%; */
  margin-left: 6%;
  margin-top: -1%;
  background-color: #3380f2 !important;
}


.newActivityAroundHeadingbuttons button{
  color: #3380f2 !important;
  background-color: white !important;
}


.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}

.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
}
.buttons:hover {
  transform: scale(1.1);
}



@media screen and (max-width: 950px) {
  .innerPopularDestination2scrollDataImagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .newviewallActivityAroundbuttons {
    display: none;
  }
  .innerPopularDestination2scrollDataHeading1 {
    display: none;
  }
  .innerPopularDestination2scrollDataHeadingcontainer {
    flex-direction: column;
  }
  .internationalheading2 {
    width: 100%;
  }
  .innerPopularDestination2scrollDataImagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .innerPopularDestination2scrollDataTextcard {
    margin-left: 8px;
  }
}

@media screen and (max-width: 450px) {
  .innerPopularDestination2scrollDataImagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .newviewallActivityAroundbuttons {
    display: inline-block;
    vertical-align: top;
    margin-top: -50px;
    text-align: right;
    width: 40%;
    font-size: 14px;
    margin-left: 52%;
  }
  .internationalheading2 {
    display: none;
  }
  .newActivityAroundHeadingbuttons {
    display: none;
  }
  .innerPopularDestination2scrollDataHeading1 {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    font-size: 16px;
    margin-left: 3%;
  }
  .innerPopularDestination2scrollDataHeadingcontainer {
    flex-direction: column;
  }
  .innerPopularDestination2scrollDataImagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .innerPopularDestination2scrollDataTextcard {
    margin-left: 8px;
  }
}

.innerPopularDestination2arrowgif{
    width: 4%;
    height: 50px;
    /* border: 2px solid red; */
    margin: auto;
    cursor: pointer;
}
