.staycationsnavbardiv{
    width: 100%;
    height: 120px;
    display: flex;
    margin-top: 2%;
    border-bottom: 5px solid lightgray;
}
.staycationnavbaricons{
    width: 110% !important;
    height: 90px;
    /* border: 2px solid red; */
    /* color: #3380F2; */
    text-align: center;
    font-weight: 500;
    margin-left: 5%;
    font-size: 15px;
}
.staycationnavbariconsdiv {
    width: 80%;
    /* margin: auto; */
    margin-left:5% !important;
    /* border: 2px solid gold; */
  }
.staycationsnavbarimages{
     /* margin-left: 37% !important; */
     width: 100%;
     height: 80px;
     object-fit: cover;
  }
  
  .staycationsnavbarimages:hover{
    cursor: pointer;
  }

  .staycationsfilterdiv{
    display: flex;
    margin-left: 4%;
    flex-direction: column;
    width: 50%;
    margin-top: 0.7%;
    /* border: 1px solid red; */
    /* padding-top: 0.8%; */
  }

  .filterdiv{
    border: 1px solid;
    width: 50% !important;
    height: 30px;
    /* margin-left: 5%; */
    color: black;
    border-radius: 9px;
    font-size: 15px;
    padding-left: 7px;
    padding-top: 2px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    /* text-align: center; */
  }

  .filterIcon{
    margin-left: 22%;
  }


.viewAllButton{
  /* border: 1px solid red; */
  height: 30px;
  border-radius: 9px;
  width: 50%;
  margin-top: 5% !important;
}

.viewAllButton button{
  width: 100%;
  border-radius: 9px;
  border: 1px solid black;
  height: 30px;
}

