.PopularDestiBannerHeading {
  font-weight: bold;
  margin-left: 40%;
  margin-top: 8%;
  color: white;
  font-weight: 500;
  font-size: 30px;
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  /* text-decoration: underline; */

}

.vaccy {
  background-color: #3380F2;
  color: white;
  width: auto;
  margin-left: 41%;
  margin-right: 45%;
  padding-left: 1.5%;
  font-weight: 400;
  font-size: 13px;
  height: 45px;
  padding-top: 1%;
  border-radius: 5px;
}

