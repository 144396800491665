.NewOffersPlanVacationMainDiv{
    /* border: 2px solid red; */
    width: 100%;
    height: 600px;
}
.NewOffersPlanVacationContainer{
    /* border: 2px solid red; */
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: row;
}
.NewOffersPlanVacationContainerImageDiv{
    /* border: 2px solid gold; */
    width: 50%;
    height: 600px;
    object-fit: cover;
    background-size: cover;
}
.NewOffersPlanVacationContainerImage{
  margin-left: 15%;
  height: 100%;
  width: 70%;
  /* border: 2px solid red; */
}
.NewOffersPlanVacationContainerHeading{
    /* border: 2px solid green; */
    width: 50%;
    height: 600px;
}
.NewOffersPlanVacationHeading{
    font-size: 40x;
    font-weight: 700;
    color: #393939;
    margin-top: 20%;
    /* margin-left: 4%; */
    text-align: center;
}
.NewOffersPlanVacationText{
    font-size: 25px;
    /* font-weight: 700; */
    color: #393939;
    margin-top: 5%;
    text-align: center;
}