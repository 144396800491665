.innerhndpicked1BestTimeMainContainerPage{
    overflow:hidden;
    /* border: 2px solid red; */
}

.innnerhandpicked1BestTimeHeading{
    margin-top: 0% !important;
}

.innerhndpicked1BestTimeModificationOfCard{
    /* border: 2px solid red; */
    width:75%;
    margin: auto;
    margin-top: -2%;
}
.innerhandpicked1BestTimeCardContainer{
    /* border: 2px solid red; */
    width: 22%;
    margin-left: 1%;
}
.innerhndpicked1BestTimeModificationOfLastCard{
    margin-left:2%;
    width: 30%;
    /* border: 2px solid green; */
}
.innerhandpicked2BestTimeButon{
    width: 52%;
    font-weight: 700;
    padding-left:20px;
    margin: auto;
    height: 43px;
}
.innerhandpicked2BestTimeButon1{
    width: 40%;
    font-weight: 700;
    padding-left:20px;
    margin: auto;
    height: 43px;
    padding-left: 25px;
}
.innerhandpicked2BestTimeCard{
    text-align: center;

}
.innerhandpicked2BestTimeCard h5{
    font-weight: 700;
}