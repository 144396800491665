.activitiesChildInnerPageButtonsDetails {
  /* border: 2px solid darkblue; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

/* -------------------------------------------------Button CSS ----------------------------------------------- */

.activitiesChildInnerPageButtonsOuterDiv {
  /* border: 2px solid red; */
  width: 89%;
  height: 60px;
  margin: auto;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}
.activitiesChildInnerPageButtonsDiv a {
  text-decoration: none;
  /* color: #3380F2; */
}
.activitiesChildInnerPageButtonsDiv:hover {
  background-color: #e8f4ff;
  color: white !important;
}
.activitiesChildInnerPageButtonsDiv1 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2.5%;
  color: #393939;
}
.activitiesChildInnerPageButtonsDiv2 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 6%;
  color: #393939;
}
.activitiesChildInnerPageButtonsDiv3 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 6%;
  color: #393939;
}
.activitiesChildInnerPageButtonsDiv4 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 4%;
  color: #393939;
}
.activitiesChildInnerPageButtonsDiv5 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 5%;
  color: #393939;
}
.activitiesChildInnerPageButtonsDiv6 {
  border: 1px solid lightgray;
  width: 17%;
  height: 60px;
  padding-top: 1.2%;
  padding-left: 7%;
  font-weight: 700;
  font-size: 14px;
  color: #393939;
}

/* -----------------------------------------------------------Data Div--------------------------------------------------- */

.activitiesChildInnerPageDetailsOuterDiv {
  /* border: 2px solid  chartreuse; */
  width: 89%;
  height: auto;
  margin: auto;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}
.activitiesChildInnerPageDetailsMainDiv {
  /* border: 2px solid salmon; */
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
}
#activitiesChildInnerPageDetailsOverviewSection {
  /* border: 2px solid goldenrod; */
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
}
#activitiesChildInnerPageDetailsOverviewSection h3 {
  font-size: 27px;
  font-weight: 700;
  color: #393939;
}
#activitiesChildInnerPageDetailsOverviewSection h6 {
  color: #393939;
  font-size: 15px;
  font-weight: 700;
}
#activitiesChildInnerPageDetailsOverviewSection h4 {
  color: #393939;
  font-size: 22px;
  font-weight: 700;
  margin-top: 2%;
}
#activitiesChildInnerPageDetailsOverviewSection p {
  color: #393939;
  font-size: 13px;
}
.activitiesChildInnerPageRefundDates {
  /* border: 2px solid greenyellow; */
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
}
.activitiesChildInnerPageRefundDates h3 {
  font-weight: 700;
  font-size: 15px;
  color: #393939;
}
.activitiesChildInnerPageRefundDates p {
  font-size: 12px;
  color: #393939;
}
.activitiesChildInnerPageRefund {
  /* border: 2px solid sandybrown; */
  width: 50%;
  height: 70px;
  margin-top: 3%;
  line-height: 15px;
}
.activitiesChildInnerPageDates {
  /* border: 2px solid sandybrown; */
  width: 30%;
  height: 70px;
  margin-top: 3%;
  margin-left: 15%;
}
#activitiesChildInnerPageDetailsLocationSection {
  /* border: 2px solid darkgreen; */
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
}
.activitiesChildInnerPageDetailsLocationHeading {
  /* border: 2px solid greenyellow; */
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
}
.activitiesChildInnerPageDetailsLocationHeading h4 {
  color: #393939;
  font-weight: 700;
  font-size: 18px;
}
.activitiesChildInnerPageDetailsLocationHeading p {
  color: #393939;
  font-size: 15px;
  margin-left: 1%;
}
.activitiesChildInnerPageDetailsLocation {
  /* border: 2px solid red; */
  width: 100%;
  height: 150px;
  background-size: cover;
  object-fit: cover;
}
.activitiesChildInnerPageDetailsLocationImage {
  width: 100%;
  height: 100%;
}
.activitiesChildInnerGetDirection {
  width: 20%;
  height: 45px;
  background-color: #3380f2;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding-top: 1.5%;
  padding-left: 5%;
  margin-top: 3%;
}
#activitiesChildInnerPageDetailsHighlightSection {
  /* border: 2px solid olive; */
  width: 100%;
  height: 200px;
  margin-top: 5%;
}
#activitiesChildInnerPageDetailsHighlightSection h5 {
  font-size: 18px;
  font-weight: 700;
  color: #393939;
}
.activitiesChildInnerHighlights {
  list-style-type: disc;
  color: #393939;
  font-weight: 400;
  margin-top: 1%;
}
#activitiesChildInnerPageDetailsPackageSection {
  /* border: 2px solid darkgoldenrod; */
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  /* background-color: #C7DEFF; */
}
.activitiesChildInnerPagePackageHeading {
  font-weight: 700;
  color: #393939;
}
.activitiesChildInnermainDiv {
  height: 500px;
  /* border: 2px solid red; */
  background-color: #e8f4ff;
}
.activitieschildPackageDate {
  border-bottom: 1px solid lightgray;
  /* border: 2px  solid red; */
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
}
.activitieschildPackageDatecalender {
  /* border: 2px solid saddlebrown; */
  width: 50%;
  height: 100px;
  color: grey;
  font-size: 16px;
  padding: 1% 2%;
}
.activitieschildPackageTravellers {
  /* border: 2px solid red; */
  width: 50%;
  height: 100px;
  color: grey;
  font-size: 16px;
  padding: 1% 2%;
}
.calenderIconActiviesInner {
  margin-left: 55%;
  margin-top: 5%;
}
.activitiesChildInnerPagePackages {
  /* border: 2px solid green; */
  width: 95%;
  height: 150px;
  margin: auto;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  background-color: white;
}
.activitiesChildPackageName {
  /* border: 2px solid seagreen; */
  width: 60%;
  height: 120px;
  padding: 2%;
}
.activitiesChildPackageName h6 {
  color: #393939;
  font-weight: 700;
}
.activitiesChildPackageName p {
  color: #393939;
  font-size: 12px;
}

.activitiesChildPackageRate {
  /* border: 2px solid seagreen; */
  width: 40%;
  height: 140px;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 2%;
}
.activitiesChildPackageRate h4 {
  font-weight: 700;
  color: #393939;
}
.activitiesChildPackageRateButton {
  background-color: white;
  border: 2px solid #3380f2;
  color: #3380f2;
  width: 30%;
  height: 70px;
  border-radius: 5px;
  margin-left: 70%;
  /* margin-bottom: 2%; */
}
.activitiesChildPackageRateButton:hover {
  background-color: #3380f2;
  color: white;
}
#activitiesChildInnerPageDetailsReviewSection {
  /* border: 2px solid red; */
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 7%;
}
.activitiesChildInnerPageDetailsReviewDetails {
  /* border: 2px solid gold; */
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
}
.activitiesChildInnerPageDetailsReviewHeadings {
  /* border: 2px solid red; */
  width: 100%;
  height: 40px;
  font-size: 22px;
  color: #393939;
  font-weight: 700;
}
.activitiesChildInnerPageDetailsReviewStars {
  /* border: 2px solid red; */
  height: 30px;
  color: gold;
  width: 100%;
}
.activitiesChildInnerPageDetailsReviewButtons {
  border-bottom: 2px solid #393939;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  color: #393939;
  margin-top: 2%;
}
.activitieschildAllReviewButton {
  background-color: #3380f2;
  height: 35px;
  font-size: 15px;
  color: white;
  width: 10%;
  border: none;
  border-radius: 5px;
  margin-left: 55%;
}
.activitieschildLatestReviewButton {
  background-color: white;
  height: 35px;
  font-size: 15px;
  color: grey;
  width: 10%;
  border: none;
  border-radius: 5px;
  margin-left: 3%;
  border: 1px solid grey;
}
.activitieschildOldestReviewButton {
  background-color: white;
  height: 35px;
  font-size: 15px;
  color: grey;
  width: 10%;
  border: none;
  border-radius: 5px;
  margin-left: 3%;
  border: 1px solid grey;
}
.activitiesChildReviews {
  /* border: 2px solid darkorange; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.activitiesChildReviewsBox {
  /* border: 2px solid firebrick; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
}
.activitiesChildInnerPageDetailsReview {
  /* border: 2px solid red; */
  width: 100%;
  height: 100px;
  margin-top: 2%;
  color: #393939;
}
.activitiesChildInnerPageDetailsCustomerName {
  color: #393939;
  font-weight: 700;
  font-size: 15px;
}
.activitiesChildInnerPageDetailsdate {
  color: gray;
}
.activitiesChildInnerPageBookingDiv {
  /* border: 2px solid salmon; */
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.activitiesChildInnerPageBookingDates {
  border: 1px solid lightgray;
  width: 80%;
  height: 350px;
  border-radius: 15px;
  margin-left: 20%;
}
.activitiesChildInnerPageBookingDates h5 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 4%;
  margin-left: 4%;
}
/* .activitiesChildInnerPageBookingDatesnTime{
    display: flex;
    flex-direction: row;
} */
.activitiesChildInnerPageDate {
  color: #393939;
  border: 1px solid lightgray;
  font-size: 15px;
  margin: auto;
  margin-top: 8%;
  width: 80%;
  height: 45px;
  margin-left: 12%;
  background-color: white;
  font-weight: 500;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
.activitiesChildInnerPageTime {
  color: #393939;
  border: 1px solid lightgray;
  font-size: 15px;
  margin: auto;
  margin-top: 8%;
  width: 80%;
  height: 45px;
  margin-left: 12%;
  background-color: white;
  font-weight: 500;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
.activitiesChildinnerBookNowButton {
  color: #393939;
  background-color: #3380f2;
  font-size: 15px;
  margin: auto;
  margin-top: 8%;
  width: 80%;
  height: 45px;
  margin-left: 12%;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-align: left;
  padding-left: 27%;
  padding-top: 10px;
}
.activitiesChildInnerPageBookingContact {
  border: 1px solid lightgray;
  width: 80%;
  height: 250px;
  border-radius: 15px;
  margin-left: 20%;
  margin-top: 10%;
}
.activitiesinnerchildApplyButton {
  color: #393939;
  border: 1px solid lightgray;
  font-size: 15px;
  margin: auto;
  margin-top: 8%;
  width: 80%;
  height: 45px;
  margin-left: 12%;
  background-color: white;
  font-weight: 500;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
}
.activitiesinnerchildApplyButton:hover {
  background-color: white !important;
  color: #3380f2 !important;
  border: 2px solid #3380f2;
}
.activitiesChildinnerPrice {
  color: #3380f2;
  font-size: 25px;
  text-align: right;
  margin-right: 8%;
  margin-top: 5%;
}
.activitiesChildinnerPrice p {
  color: lightgray;
  font-size: 12px;
}
