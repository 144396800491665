
.InnerPopularDestination2BannerHeading{
    font-weight: 700;
    margin-left: 5%;
    margin-top: -1%;
    color: white;
    font-size: 35px;
  }
  .InnerPopularDestination2BannerSubHeading{
    color: white;
    margin-left: 5%;
    margin-top: 15%;
  }