.newSearchPlaceDestinationContainer {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.newSearchPlaceDestinationHeadingContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.newSearchPlaceDestinationHeadingContainer h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 2%;
  margin-left: 1%;
  margin-bottom: 2%;
  width: 65%;
  color: #3380f2;
}

.clientstestimonial_title {
  font-size: 27px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 128, 242);
}

.newSearchPlaceDestinationWrapper {
  position: relative;
  width: 106%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

.clientstestimonial_title {
  font-size: 27px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 128, 242);
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

.newSearchPlaceDestinationImage {
  /* border: 2px solid black !important; */
  margin-left: 3%;
  width: 102%;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.newSearchPlaceDestinationImage:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newSearchPlaceDestinationImage:hover .newSearchPlaceDestinationCardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newMultisection_Activityimage:hover .newSearchPlaceDestinationCardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newSearchPlaceDestinationCardImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  border-radius: 20px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newSearchPlaceDestinationCardImage img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
}

.newMultisection_Activityimage:hover .newSearchPlaceDestinationCardImage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newSearchPlaceDestinationDetails {
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 15px;
}

.newSearchPlaceDestinationDetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.newSearchPlaceDestinationDetails h2 .job-title {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  font-weight: 300;
  display: block;
}

/*css media query for the different devices  */

@media (max-width: 480px) {
  .newSearchPlaceDestinationContainer {
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
  }

  .newSearchPlaceDestinationHeadingContainer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    height: 60px;
  }

  .newSearchPlaceDestinationHeadingContainer h2 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 2%;
    /* margin-left: 1%; */
    margin-bottom: 2%;
    width: 100%;
    color: black;
  }

  .clientstestimonial_title {
    font-size: 27px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 4%;
    margin-bottom: 3%;
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    color: rgb(51, 128, 242);
  }

  .newSearchPlaceDestinationWrapper {
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  .clientstestimonial_title {
    font-size: 27px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 4%;
    margin-bottom: 3%;
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    color: rgb(51, 128, 242);
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  .newSearchPlaceDestinationImage {
    margin-left: 0%;
    width: 100%;
    border-radius: 20px !important;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }
  .newSearchPlaceDestinationImage:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newSearchPlaceDestinationImage:hover .newSearchPlaceDestinationCardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newMultisection_Activityimage:hover .newSearchPlaceDestinationCardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newSearchPlaceDestinationCardImage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    border-radius: 20px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newSearchPlaceDestinationCardImage img {
    width: 100%;
    filter: brightness(0.8);
    -o-object-fit: cover;
    object-fit: cover;
  }

  .newMultisection_Activityimage:hover .newSearchPlaceDestinationCardImage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newSearchPlaceDestinationDetails {
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    line-height: 15px;
  }

  .newSearchPlaceDestinationDetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .newSearchPlaceDestinationDetails h2 .job-title {
    font-size: 16px;
    line-height: 1.5;
    color: white;
    font-weight: 300;
    display: block;
  }

  .slick-next::before {
    color: black;
    margin-left: 50%;
    display: none !important;
  }

  .slick-prev::before {
    color: black;
    margin-right: 50%;
    display: none;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
