.cruiseDetailsInnerWebPageCardsFilterContainer {
  /* border: 2px solid red; */
  width: 100%;
  height: auto;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
}
.cruiseDetailsInnerWebPageCardsFilterDiv {
  /* border: 2px solid gold; */
  width: 20%;
  height: auto;
  margin-left: 5%;
}
.cruiseDetailsInnerWebPageCardsDiv {
  /* border: 2px solid green; */
  width: 75%;
  height: auto;
}

/* ---------------------------------------------Filter Div CSS ---------------------------------------------------- */

.cruiseDetailsInnerWebpageDataFilter {
  border-right: 3px solid lightgray;
  /* border: 2px solid lightgray; */
  margin-left: 1%;
  width: 95%;
  height: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.cruiseDetailsInnerWebpageFilter1 {
  /* border: 2px solid gold; */
  width: 90%;
  /* height: 180px; */
  margin-top: 2%;
}
.cruiseDetailsInnerWebpageFilter1 input {
  /* border: 2px solid red; */
  margin-top: 1.5%;
}
.cruiseDetailsInnerWebpageFilter1 label {
  /* border: 2px solid gold; */
  margin-top: 2.5%;
  font-size: 17px;
  height: 25px;
  margin-left: 2%;
}

.cruiseDetailsInnerWebpageFilter2 {
  /* border: 2px solid red; */
  width: 90%;
  height: 180px;
  margin-top: 4%;
}
.cruiseDetailsInnerWebpageFilter2 input {
  /* border: 2px solid red; */
  margin-top: 1.5%;
}
.cruiseDetailsInnerWebpageFilter2 label {
  /* border: 2px solid gold; */
  margin-top: 2.5%;
  font-size: 17px;
  height: 25px;
}
.cruiseDetailsInnerWebpageFilter3 {
  /* border: 2px solid red; */
  width: 90%;
  height: 200px;
  /* margin-top: 2%; */
}
.cruiseDetailsInnerWebpageFilter4 {
  /* border: 2px solid red; */
  width: 90%;
  height: 200px;
  margin-top: 5%;
}
.cruiseDetailsInnerWebpageFilter4 input {
  /* border: 2px solid red; */
  margin-top: 1.5%;
}
.cruiseDetailsInnerWebpageFilter4 label {
  /* border: 2px solid gold; */
  margin-top: 2.5%;
  font-size: 15px;
  height: 25px;
}
.cruiseDetailsInnerWebpageFilter5 {
  /* border: 2px solid red; */
  width: 90%;
  height: 250px;
  margin-top: 5%;
}
.cruiseDetailsInnerWebpageStops {
  font-size: 20px;
  font-weight: 700;
  /* border: 2px solid red; */
  margin-bottom: 1%;
}
.cruiseDetailsInnerWebpageDurations select {
  background-color: none;
  color: #393939;
  margin-top: 3%;
  width: 70%;
  border: 1px solid lightgray;
  font-size: 15px;
  height: 40px;
  outline: none;
  /* margin-left: %; */
}
.cruiseDetailsInnerWebpageTime {
  /* border: 2px solid firebrick; */
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1%;
}
.cruiseDetailsInnerUserRatings {
  /* border: 2px solid red; */
  width: 70%;
  display: flex;
  flex-direction: row;
  height: 45px;
}
.cruiseDetailsInnerRatings {
  border: 2px solid lightgray;
  width: 20%;
  height: 35px;
  color: grey;
  margin-left: 5%;
}
.cruiseDetailsInnerRatings:hover {
  color: #3380f2;
  border: 2px solid #3380f2;
}
.cruiseDetailsInnerWebpageTimeButtons {
  background-color: white;
  border: 1px solid lightgray;
  width: 40%;
  height: 40px;
}
.cruiseDetailsInnerWebpageTimeButtons:hover {
  background-color: #3380f2;
  color: white;
}
.flightnearinnerpageslidecontainer {
  width: 80%;
  margin-left: 3%;
  margin-top: -1%;
}
.flightNeartakeoffData {
  color: gray;
  font-size: 12px;
  /* margin-top: 2%; */
  margin-left: 3%;
}
.flightNearDurationrangeText {
  color: #393939;
  font-weight: 700;
  font-size: 15px;
  margin-top: 1%;
  /* border: 2px solid green; */
}

/* -------------------------------------------------Cards CSS ----------------------------------------- */

.cruiseDetailsInnerWebPageCardsContainer {
  border: 2px solid lightgray;
  width: 90%;
  height: 250px;
  border-radius: 10px;
  /* margin: auto; */
  margin-top: 2%;
  margin-left: 2%;
  display: flex;
  flex-direction: row;
}
.cruiseDetailsInnerWebPageCardsImage {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 220px; */
  margin-top: 4%;
  margin-left: 5%;
}

.cruiseDetailsInnerWebPageCardsHeadings {
  /* border: 2px solid red; */
  width: 97%;
  height: 220px;
  margin-top: 1%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
}

.cruiseDetailsInnerContentDiv {
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: row;
  height: 180px;
}

.cruiseDetailsInnerContentSubDiv1 {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  width: 70%;
}

.cruiseDetailsContentDetailsIcons {
  /* border: 1px solid red; */
  row-gap: 40px;
  /* height: 50px; */
}

.cruiseDetailsMoonIcon {
  /* border: 1px solid black; */
  font-size: 25px;
}

.cruiseDetailsBoatIcon {
  /* border: 1px solid black; */
  font-size: 25px;
}

.cruiseDetailsLocation1Icon {
  /* border: 1px solid black; */
  font-size: 30px;
}

.cruiseDetailsLocation2Icon {
  /* border: 1px solid black; */
  font-size: 51px;
}

.cruiseDetailsMoonDetails {
  /* border: 1px solid red; */
  margin-top: 2%;
  font-weight: 600;
  margin-left: 1%;
}

.cruiseDetailsBoatDetails {
  border: 1px solid white;
  margin-top: 4%;
  font-weight: 600;
  margin-left: 1%;
}

.cruiseDetailsLocation1Details {
  border: 1px solid white;
  margin-top: 4.5%;
  font-weight: 600;
  margin-left: 1%;
}

.cruiseDetailsLocation1Details span {
  color: #3380f2;
}

.cruiseDetailsLocation2Details {
  border: 1px solid white;
  margin-top: 4%;
  font-weight: 600;
}

.cruiseDetailsLocation2Details span {
  color: #3380f2;
}

.cruiseDetailsMoonIcon img {
  margin-top: 2%;
}

.cruiseDetailsInnerContentSubDiv2 {
  /* border: 1px solid green; */
  width: 30%;
}

.cruiseDetailsCompayLogo {
  /* border: 1px solid red; */
  width: 100%;
}

.cruiseDetailsCompayLogo img {
  object-fit: cover;
  height: 50px;
  margin-left: 75%;
  margin-top: 5%;
}

.cruiseDetailsCruisePriceContent p {
  /* border: 1px solid red; */
  margin-left: 60%;
  margin-bottom: 1%;
}

.cruiseDetailsCruisePrice h3 {
  /* border: 1px solid red; */
  margin-left: 55%;
  color: #3380f2;
  font-weight: 600;
  font-size: 27px;
}

.cruiseDetailsInnerButtonDiv {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 53%;
}

.cruiseDetailsViewDealsBtn {
  margin-left: 0%;
  width: 100%;
  padding-left: 21px;
  align-items: center;
  background-color: white;
  color: #393939;
}

.cruiseDetailsViewDealsBtn:hover {
  background-color: none !important;
}

.cruiseDetailsBookNowBtn {
  margin-left: 4%;
  width: 34%;
  text-align: center;
  padding-left: 26px;
}

.cruiseDetailsInnerWebPageCardsButtons {
  border-left: 2px solid lightgray;
  /* border: 2px solid red; */
  width: 18%;
  height: 220px;
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: right;
  display: flex;
  flex-direction: column;
}
.cruiseDetailsInnerSemiHeadings1 {
  color: #3380f2;
  margin-top: 1%;
}
.cruiseDetailsInnerSemiHeadings2 {
  color: #3380f2;
  margin-top: -1%;
}
.cruiseDetailsInnerWebPageCardsButtons h2 {
  color: #3380f2;
  font-size: 25px;
  font-weight: 700;
}
.cruiseDetailsInnerWebPageViewMoreButtons {
  border: 2px solid lightgray;
  background-color: white;
  color: #393939;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  margin-left: 20%;
}
.cruiseDetailsInnerWebPageBookNowButtons {
  border: none;
  /* border: 2px solid lightgray; */
  background-color: #3380f2;
  color: white;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  margin-left: 20%;
  margin-top: 5%;
}
.cruiseDetailsInnerWebPageTimeRatings {
  /* border: 2px solid gold; */
  height: 45px;
  display: flex;
  flex-direction: row;
}
.cruiseDetailsInnerWebPageTimeRatings button {
  width: 22%;
  height: 30px;
  border-radius: 5px;
  margin-left: 15%;
  font-size: 14px;
  border: 1px solid lightgray;
  background-color: white;
  color: #393939;
}
.innercruiseDetailsShowButton {
  background-color: #3380f2;
  color: white;
  width: 60%;
  height: 60px;
  margin: auto;
  margin-left: 20%;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
}
