.ChepestFlightMainDiv{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    margin-left: 4%;
    margin-top: 2%;


}
.cheapflightheading1{
    font-size: 25px;
    font-weight: 700;
    margin-left: 3%;
    margin-top: 1%;

}
.Flightshowmorebutton{
    color: white;
    background-color: #3380F2;
    width: 30%;
    height: 50px;
    border-radius: 10px;
    margin-left: 30%;
    border: none;
    margin-top: 2%;
    font-size: 18px;
    font-weight: 700;

}