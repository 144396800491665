.all_hotel_card_div{
    display: flex !important;
  }
  
  .cardtextendbuttons{
    /* border: 1px solid red; */
    width: 20%;
  }
  
  .cardtextDomesticFlightdropbutton{
    width: 100% !important;
    display: flex;
    flex-direction: row;
  }
  
  .cardtextDomesticFlightdropbutton h2{
    color: white;
    font-weight: 600;
    margin-top: 4%;
    margin-left: 7%;
    font-size: 32px;
  }

  .newFlightDomesticDropContainer{
    width: 20%;
    margin-left: 43%;
    margin-top: 2.1%;
  }
  
  .newFlightInternationalCountryDrop{
    margin-top: 13% !important;
    margin-left: 7%;
    color: #3380f2 !important;
    font-weight: 700;
    cursor: pointer;
    border-radius: 20px !important;
  }
  .newFlightInternationalCountryDrop:hover{
    background-color: #3380f2;
    color: white !important;
    font-weight: 700;
  }
  
  .newFlightDomesticmaindiv{
    width: 100%;
    height: 600px !important;
    background-color: #3380f2;
    opacity: 0.9;
    /* background-image: url(/publichttps://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/BackgroundImages/HotelServicesBackground.png); */
  }
  
  .slick-slide{
    width: 280px;
  }
  
  .activityaroundouterdiv {
    /* border: 2px solid red !important; */
    height: 460px !important;
    /* border: 2px solid lightgray; */
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    /* border: none; */
  }
  .bestFlightDomesticHeadingcontainer {
  
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #3380f2;
    height: 80px;
    margin: auto;
    /* margin-top: 2%; */
    /* border: 1px solid black; */
  }
  
  .activityaroundheading1 {
    /* border: 1px solid black; */
    font-size: 25px;
    font-weight: 600;
    width:30%;
    margin: auto;
    margin-left: 5.5% !important;
    color: white;
  }
  
  /* .viewallhomehotelcardtext{
  
  } */
  .experimentheading2 {
    /* border: 1px solid blue; */
    width: 60%;
    height: 40px;
    justify-content: center;
    margin: -0.5% 0 1% 1%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    /* padding: 0px 0px; */
  }
  .experimentheading {
    border-radius: 50px;
  }
  
  .experimentheading2 div {
    border: 3px solid rgb(51, 128, 242);
    width: 100px !important;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5%;
    margin-right: 4%;
    border-radius: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #3380f2;
  }
  
  .experimentheading2 div:hover {
    background-color: #3380f2;
    color: white;
  }
  .viewallactivityhotelcardbuttons {
    color: white;
    font-weight: 700;
    /* text-decoration: underline; */
    /* border: 1px solid red; */
    display: flex;
    margin: 6.5% 2% 0 5% !important;
    width: 55% !important; 
    margin-left: 40% !important;
  }
  
  .viewallhomehotelcardtext{
    margin-top: 0px !important;
    border: 2px solid white;
    border-radius: 20px;
    height: 38px !important;
    width: 57% !important;
    font-size: 14px;
    height: 30px;
    font-weight: 700;
    padding-left: 15px !important;
    padding-top: 5.3px !important;
    margin-bottom: 8%;
    text-decoration: none !important;
  }
  
  .viewallhomehotelcardtext:hover{
    background-color: white;
    color: #3380f2;
    /* padding-top: 1%; */
  }
  .activityaroundnewFlightSlidercontainer{
    /* border: 2px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 2%;
  }
  
  .activityaroundcardtitle {
    color: #3380f2 !important;
    font-size: 22px;
    margin-top: 1%;
  }
  
  .activityaroundcard_desc {
    /* border: 1px solid red; */
    width: 90%;
    color: #3380f2;
    font-size: 13px;
    text-align: left;
  }
  
  .activityaroundimagetextcontainer {
    width: 100%;
    height: 440px !important;
  }
  
  .activityaroundtextcard {
    margin-left: 9% !important;
    width: 100% !important;
  }
  /* 
    .activityaroundtextcard:hover{
      color: white;
      background-color: #3380F2;
    } */
  
  .activityaroundtextcard:hover div {
    color: white;
  }
  
  .exclusivedealsimagediv {
    /* border: 2px solid red !important; */
    width: 800px;
    height: 200px !important;
  }
  .exclusivedealsimage {
    /* border: 1px solid black !important; */
    width: 700px;
    height: 10px;
    margin-left: 40%;
  }
  
  .activityaroundcardimage {
    /* border: 1px solid red !important; */
    /* height: 300px; */
    border: none;
    width: 100% !important;
  }
  
  .activityaroundcarddescmain {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: -9%;
  }
  
  .exclusivecarddesc:hover {
    color: white;
  }
  

  .activityaroundheadingbuttons {
    /* border: 1px solid yellow !important; */
    justify-content: flex-end;
    /* width: -1%; */
    margin-left: 6%;
    margin-top: -2%;
  }
  
  .line-indicator li {
    /* border: 2px solid; */
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }
  
  .line-indicator li:hover {
    background: gray;
  }
  
  .line-indicator li.slick-active {
    background: blue;
  }
  .buttons {
    /* background: orangered !important; */
    background: rgb(51, 128, 242) !important;
    color: white !important;
    transition: 700ms;
    border: 3px solid red;
  }
  .buttons:hover {
    /* background: white !important; */
    /* color: orangered !important; */
    transform: scale(1.1);
  }
  .activityaroundbutton {
    border: 3px solid #3380f2;
    width: 34%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 4px 12px;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 4%;
    font-size: 15px !important;
  }
  .activityaroundbutton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    font-size: 14px !important;
  }

  @media screen and (max-width: 950px) {
    .activityaroundimagetextcontainer {
      flex-direction: column;
    }
    .col-md-4 {
      width: 90%;
      height: 280px;
      margin-left: 3%;
      margin-top: 10%;
    }
    .card-title {
      font-size: 17px;
    }
    .card-text {
      font-size: 13px;
    }
    .viewallactivityhotelcardbuttons {
      display: none;
    }
    .activityaroundheading1 {
      display: none;
    }
    .bestFlightDomesticHeadingcontainer {
      flex-direction: column;
    }
    .experimentheading2 {
      width: 100%;
    }
    .activityaroundimagetextcontainer img {
      width: 100%;
      height: 100%;
    }
    .exclusivedealsimage {
      width: 100%;
      height: 100%;
    }
    .activityaroundtextcard {
      margin-left: 8px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .activityaroundimagetextcontainer {
      flex-direction: column;
    }
    .col-md-4 {
      width: 90%;
      height: 280px;
      margin-left: 3%;
      margin-top: 5%;
    }
    .card-title {
      font-size: 17px;
    }
    .card-text {
      font-size: 13px;
    }
    .viewallactivityhotelcardbuttons {
      display: inline-block;
      vertical-align: top;
      margin-top: -50px;
      text-align: right;
      width: 40%;
      font-size: 14px;
      margin-left: 52%;
    }
    .experimentheading2 {
      display: none;
    }
    .activityaroundheadingbuttons {
      display: none;
    }
    .activityaroundheading1 {
      display: inline-block;
      vertical-align: top;
      width: 40%;
      font-size: 16px;
      margin-left: 3%;
    }
    .bestFlightDomesticHeadingcontainer {
      flex-direction: column;
    }
    .activityaroundimagetextcontainer img {
      width: 100%;
      height: 100%;
    }
    .exclusivedealsimage {
      width: 100%;
      height: 100%;
    }
    .activityaroundtextcard {
      margin-left: 8px;
    }
  }
  
  
  
  .newFlightDomesticmaindiv{
    /* border: 1px solid red; */
    width: 100%;
  }
  
  .activityaroundouterdiv {
    /* border: 2px solid red !important; */
    height: 460px !important;
    /* border: 2px solid lightgray; */
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    /* border: none; */
  }
  .bestFlightDomesticHeadingcontainer {
  
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #3380f2;
    height: 80px;
    margin: auto;
    /* margin-top: 2%; */
    /* border: 1px solid black; */
  }
  
  .activityaroundheading1 {
    /* border: 1px solid black; */
    font-size: 25px;
    font-weight: 600;
    width:30%;
    margin: auto;
    margin-left: 5.5% !important;
    color: white;
  }
  
  /* .viewallhomehotelcardtext{
  
  } */
  .experimentheading2 {
    /* border: 1px solid blue; */
    width: 60%;
    height: 40px;
    justify-content: center;
    margin: -0.5% 0 1% 1%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    /* padding: 0px 0px; */
  }
  .experimentheading {
    border-radius: 50px;
  }

 
  
  @media screen and (max-width: 950px) {
    .activityaroundimagetextcontainer {
      flex-direction: column;
    }
    .col-md-4 {
      width: 90%;
      height: 280px;
      margin-left: 3%;
      margin-top: 10%;
    }
    .card-title {
      font-size: 17px;
    }
    .card-text {
      font-size: 13px;
    }
    .viewallactivityhotelcardbuttons {
      display: none;
    }
    .activityaroundheading1 {
      display: none;
    }
    .bestFlightDomesticHeadingcontainer {
      flex-direction: column;
    }
    .experimentheading2 {
      width: 100%;
    }
    .activityaroundimagetextcontainer img {
      width: 100%;
      height: 100%;
    }
    .exclusivedealsimage {
      width: 100%;
      height: 100%;
    }
    .activityaroundtextcard {
      margin-left: 8px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .activityaroundimagetextcontainer {
      flex-direction: column;
    }
    .col-md-4 {
      width: 90%;
      height: 280px;
      margin-left: 3%;
      margin-top: 5%;
    }
    .card-title {
      font-size: 17px;
    }
    .card-text {
      font-size: 13px;
    }
    .viewallactivityhotelcardbuttons {
      display: inline-block;
      vertical-align: top;
      margin-top: -50px;
      text-align: right;
      width: 40%;
      font-size: 14px;
      margin-left: 52%;
    }
    .experimentheading2 {
      display: none;
    }
    .activityaroundheadingbuttons {
      display: none;
    }
    .activityaroundheading1 {
      display: inline-block;
      vertical-align: top;
      width: 40%;
      font-size: 16px;
      margin-left: 3%;
    }
    .bestFlightDomesticHeadingcontainer {
      flex-direction: column;
    }
    .activityaroundimagetextcontainer img {
      width: 100%;
      height: 100%;
    }
    .exclusivedealsimage {
      width: 100%;
      height: 100%;
    }
    .activityaroundtextcard {
      margin-left: 8px;
    }
  }
  
  
  
  