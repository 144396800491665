.newhomeawardsstoriescontainer{
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    height: 160px;
    margin-bottom: 0%;
}

.newhomeawardsstoriescontainer h2{
    color: #3380f2;
    font-weight: 700;
    font-size: 30px;
    margin-top: 0%;
    margin-left: 45%;
}

.achievements_title {
    font-size: 28px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3%;
    margin-bottom: 3%;
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    color: rgb(51, 128, 242);
  }
  
  .awardsnewhomecardwrapper {
    /* border: 2px solid red; */
    position: relative;
    width: 100%;
    height: 110px;
    /* padding: 0 10px; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
  }
  
  
  /* .awardsnewhomecardwrapper:hover img{
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  } */
  
  .awardsnewhomecard{
    border: none !important;
    /* border: 2px solid red; */
    height: 60px;
    width: 90%;
    margin: auto;
  }
  .awardsnewhomecard img {
   height: 70px;
   width: 60%;
   /* border: 2px solid red; */
   margin: auto;
  }
  

  
  
  .travelstoriesdiv {
    width: 106%;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }
  
  .card-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 450px;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .card-img-top img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .awardsnewhomecard_title h4 {
    font-size: 16px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    line-height: 29px;
    vertical-align: baseline;
    color: rgb(52, 64, 82);
    font-weight: 700;
    /* border: 2px solid yellow; */
    margin-top: -12%;
    margin-left: 2%;
    width: 100% !important;
  }
  
  .awardsnewhomecard_desc p {
    font-size: 13px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    line-height: 23.8px;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: baseline;
    color: rgb(170, 176, 180);
  }
  
  .travelstoriesdiv:hover .card-image {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  
  .travelstoriesdetails {
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
  }
  
  .travelstoriesdetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
  }
  
  .travelstoriesdetails h2 .job-title {
    font-size: 16px;
    line-height: 2;
    color: white;
    font-weight: 300;
    display: block;
  }
  
  .card:hover .travelstoriesdetails {
    bottom: 0;
  }
  
  .carousel .slick-dots {
    bottom: -2.5vw;
  }
  
  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  
  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }
  
  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }
  
  .carousel .slick-next {
    right: -55px;
  }
  
  .carousel .slick-prev {
    left: -55px;
  }
  
  