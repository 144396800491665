.holidayBannerContainer{
    /* border: 1px solid red; */
    height: 740px;
}

.holidayBannerImagesmaindiv img{
    /* border: 2px solid red; */
    height: 730px;
    object-fit: cover;
    filter: brightness(0.6);
}


.newVisaBannerTexts{
    width: 70%;
    height: 100px;
    z-index: 11;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
    top: 80%;
    left: 50%;
  }

  .newVisaBannerTexts>p {
    color: whitesmoke;
    font-size: 40px;
    font-family: sans-serif;  
  }

  .newVisaBannerTexts span {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 56px;
    padding-left: 5px;
    padding-right: 5px;
    color: #3153f2;
  }
 
  @media screen and (min-width: 300px) and (max-width: 500px) {
    .newVisaBannerTexts{
      /* border: 2px solid red; */
      width: 98%;
      margin: auto;
    }
    .newVisaBannerTexts>p {
      color: whitesmoke;
      font-size: 30px;
      font-family: sans-serif;  
    }
  
    .newVisaBannerTexts span {
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 46px;
      padding-left: 5px;
      padding-right: 5px;
      color: #3153f2;
    }
  }