.Footermaindiv {
  display: flex;
  flex-direction: column;
  /* border: 5px solid green; */
  background-color: #212a30;
  margin-top: 2%;
  /* height: 800px; */
  font-family: "TT Hoves Variable", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.Footerlogodiv {
  display: flex;
  flex-direction: row;
  /* border: 2px solid blue; */
  margin-left: 5%;
  margin-top: 1.5%;
}

.Footercontaindiv {
  /* border: 2px solid purple; */
  width: 54%;
  /* padding-left: 10px;
  padding-top: 15px; */
}

.innerfooterlogoSocialIcon h2 {
  /* border: 1px solid red; */
  color: white;
  margin-left: 74%;
  margin-top: -6%;
}

.innerfooterlogoSocialIcon img {
  object-fit: cover;
  background-size: cover;
}

.innerfootersocialmedia {
  /* border: 2px solid red; */
  display: flex;
  width: 50%;
  height: 35px;
  justify-content: center;
  column-gap: 20px;
  /* margin-top: -6%; */
  color: white;
  margin-left: 58%;
  margin-bottom: 2%;
}

.Footerlogocontain {
  font-size: 15px;
  font-weight: 500;
  gap: 5% !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  justify-content: center;
  color: white;
  /* border: 2px solid red; */
  width: 70%;
}

.dropdowndiv {
  /* border: 2px solid brown; */
  margin-left: 28%;
  padding-top: 30px;
}

.countryselectlistheading {
  color: white;
  font-size: 15px;
}

.DropDownlist {
  justify-content: left;
  margin-left: 30%;
  margin-top: -5%;
}

.FooterCountryDroplist {
  /* background-color: #252323 !important; */
  width: 65% !important;
  height: 35px !important;
  margin-bottom: 3%;
  font-weight: 400 !important;
  font-size: 15px !important;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 4px;
  border: none;
  color: #252323;
  box-shadow: none;
  border-radius: 0%;
}

.Footervideodiv {
  /* border: 2px solid pink; */
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
}

.Footervideoexperimentcontainer-items {
  /* border: 2px solid white; */
  height: 300px;
  margin-top: 5%;
  margin-left: 15%;
}

.Footervideoexperimentcontainer {
  position: absolute;
  /* margin-top: -23.5%; */
  /* margin-left:55%; */
  background-color: #3380f2;
  color: black;
  font-weight: 400;
  opacity: 0.5;
  /* filter: brightness(0.6); */
  padding: 2px;
  font-size: medium;
  height: 250px;
  /* padding: 25px; */
  /* width: 35%; */
}

.listdiv {
  /* border: 2px solid seagreen; */
  width: 80%;
  color: white;
  display: flex;
  flex-direction: row;
  padding-top: 23px;
  margin-left: 6%;
  text-decoration: none !important;
  /* margin-top: 2%; */
}

.listdiv Link {
  text-decoration: none !important;
}

.listwise {
  font-size: 12px;
  /* font-weight: 300; */

  margin-top: 2%;
  color: rgb(194, 194, 194);
}

.listwiseCountry {
  font-size: 12px;
  /* font-weight: 300; */

  margin-top: 2%;
  color: rgb(194, 194, 194);
}

.listwiseCountry li {
  /* border: 1px solid red; */
  margin-top: 26%;
}


.listwise li {
  border: 1px solid #212a30;
  margin-bottom: 2%;
  margin-top: 0%;
}

.listwise1 {
  font-size: 12px;
  /* font-weight: 300; */
}

.listwiseheading {
  font-size: 14px;
  /* border: 2px solid khaki; */
}

.listwiseheading1 {
  font-size: 14px;
  margin-left: 20%;
}

.listwiseheading2 {
  font-size: 14px;
  /* margin-left: 20%; */
}

.innerFooterContactUsDiv {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  text-align: left;
  /* color: #252323; */
  /* margin-left: 5%; */
  /* padding-left: 10%; */
}

.Footercontactinfo {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-left: 3%;
  margin-top: -6%;
  position: relative;
  /* bottom: 5%; */
}

.Learnmorediv {
  color: white;
  /* margin-left: 5%; */
  /* border: 2px solid green; */
}

.productsdiv {
  color: white;
  /* border: 2px solid goldenrod; */
  margin-left: 2%;
}

.contactusdiv {
  color: white;
  /* border: 1px solid red; */
  margin-left: 1%;
}

.socialdiv {
  color: white;
  /* border: 1px solid red; */
  /* margin-left: 7%; */
}

/* .innerwebpageFooterHeadingIcons{
  border: 1px solid red;
} */

.footernewHomesocialmedia {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  justify-content: center;
  /* column-gap: 20px; */
  margin-top: 5%;
  margin-left: 0%;
  color: white;
}

.socialinnerwebpageFooterIcons {
  /* border: 2px solid whitesmoke; */
  color: white;
  font-size: 26px;
  margin-top: 100% !important;
  /* padding: 20%; */
  /* border-radius: 30px; */
  gap: 50% !important;
}

.weacceptdiv {
  color: white;
  display: flex;
  flex-direction: column;
  /* border: 2px solid white; */
  width: 20%;
  margin-left: 5%;
}
.weacceptinnerdiv {
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  width: 35%;
  margin-left: 5%;
}
.weacceptinnerdiv img {
  width: 100%;
  height: 20px;
  margin-top: 10%;
  object-fit: cover;
}

.newFooterPaymentImages {
  /* border: 2px solid white; */
  width: 10%;
  height: 35px;
  margin-left: 3%;
}
.newFooterPaymentImages img {
  height: 25px;
  width: 100%;
}

.footerlogolastdiv {
  /* border: 2px solid white; */
  color: white;
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 90%;
  /* margin-top: 5% !important; */
  border-top: 0.5px solid rgb(254, 252, 252);
}
.footerlogoinnerdiv {
  display: flex;
  flex-direction: row;
}
.copyrightdiv {
  color: rgb(194, 194, 194);
  font-size: 12px;
  width: 20%;
  padding-top: 25px;
  /* border: 2px solid red; */
  /* margin-left: 7%; */
  /* margin-top: 10%; */
  /* text-align: center; */
}

.innerwebpageFooter {
  /* border: 2px solid black; */
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  color: #3380f2;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  background-color: #212a30;
  margin-top: 1%;
}
.innerwebpageFooter p {
  padding-top: 12px;
  margin-top: 2%;
}
.innerwebpageFooterDiv {
  /* border: 2px solid gold; */
  width: 60%;
  margin: auto;
  margin-left: 22%;
  display: flex;
  flex-direction: row;
}

.innerwebpageFooterImages {
  /* border: 2px solid black; */
  width: 25%;
  margin-top: 1%;
  height: 42px;
  /* column-gap: 5% !important; */
}

.innerwebpageFooterlinkImage {
  /* border: 1px solid red; */
  width: 24%;
  height: 45px;
  margin-top: 3%;
  margin-left: 10%;
  object-fit: cover;
}
.innerwebpageFooterImages {
  /* border: 2px solid white; */
  height: 42px;
  width: 100%;
  gap: 5% !important;
  /* margin-top: 5%; */
  /* object-fit: cover; */
}
.innerwebpageFooterTravelFlap {
  /* border: 1px solid red; */
  height: 90px;
  width: 90%;
  margin: auto;
  margin-top: -8%;
  /* margin-left: 15%; */
  object-fit: cover;
  /* z-index: 10000; */
  /* background-repeat: no-repeat; */
}

.innerwebpageFooterBlueberry {
  /* border: 1px solid white; */
  height: 32px;
  width: 85%;
  margin: auto;
  /* margin-top: 5%; */
  /* margin-left: 15%; */
  object-fit: cover;
}

.innerwebpageFooterNextHolidays {
  /* border: 1px solid red; */
  height: 28px;
  width: 93%;
  margin: auto;
  /* margin-top: 3%; */
  /* margin-left: -8%; */
  object-fit: cover;
}

.innerwebpageFooterVisaFetch {
  /* border: 1px solid red; */
  height: 40px;
  width: 65%;
  margin: auto;
  /* margin-top: 2%; */
  margin-left: -23%;
  object-fit: cover;
}

.innerfooterPaymentOptions {
  background-color: #212a30;
  height: 60px;
  display: flex;
  flex-direction: row;
}
.innerfooterPaymentcards {
  width: 60%;
  height: 60px;
  /* margin-left: 10%; */
  display: flex;
  flex-direction: row;
  /* border: 2px solid red; */
  margin-top: 1%;
}
