.activitiesInnerWebPageCountryFilterdiv{
    width: 89%;
    height: 180px;
    display: flex;
    margin: auto;
    margin-top: 7%;
    /* border: 2px solid red; */
    background-color: #E8F4FF;
}
.activitiesInnerWebPageCountryFiltericons{
    width: 15%;
    height: 158px;
    /* border: 2px solid lightgray; */
    /* color: #3380F2; */
    text-align: center;
    font-weight: 700;
    margin-left: 5%;
    color: #393939;
    /* margin-top: 5%; */
    font-size: 15px;
    background-color: white;
}
.activitiesInnerWebPageCountryFiltericonsdiv {
    width: 90%;
    margin: auto;
    height: 160px;
    /* border: 2px solid gold; */
  }
.activitiesInnerWebPageCountryFilterimages{
     margin-left: 5%;
     margin-top: 5%;
     width: 90%;
     height: 80px;

     /* border: 2px solid red; */
  }
  .activitiesInnerWebPageCountryFiltericons h4{
    margin-top: 15%;
    font-size: 15px;
    font-weight: 700;
  }
  .activitiesInnerWebPageCountryFiltericons p{
    color: lightgray;
    font-size: 12px;
  }
