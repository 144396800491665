.navbar-container {
    height: 80px;
    z-index: 100000;
    position: fixed;
    width: 75% !important;
    position: absolute;
    top: 0;
    /* border: 2px solid red; */
  }
  .navbar-fixed {
    background: rgb(51,128,242);
    position: fixed;
    transition: all 1s ease;
    width: 100%;
  }
  .navbar_fixed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    height: 90px;
    /* border: 2px solid red; */
    background-color: #3380f2;
  }
  .menu-links {
    display: flex;
    flex-direction: row;
    color: white;
    text-align: center;
    margin-top: 3%;
    line-height: 15px;
    /* border: 2px solid red; */
  }
  
  .fixed-menu-link {
    height: 40px;
    width: 50px;
    border-radius: 7px;
    color: #fff;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    margin-right: 12px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }
  .transparentNavbar{
    display: flex;
    width: 85%;
    height: 70px;
    margin-left: 5% !important;
  }
  
  .logo2 {
    height: 100px;
    width: 150px;
    margin-top: 1%;
    margin-left: -15%;
    /* border: 2px solid red; */
  }
  .logo2 img {
    height: 121px;
    width: 121%;
    margin-top: -15%;
    /* border: 1px solid black; */
  }
  .logo1 {
    cursor: pointer;
    height: 105px;
    width: 170px;
    /* border: 2px solid red; */
  }

  .logo1 img {
    height: 100%;
    width: 100%;
  }

  .navbar {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 70px;
  }
  #navbarLinksButtons {
    margin-top: 5% 0 5% 0;
  }
  .navbar-links-container {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: row;
    margin-right: 2%;
    color: white;
    font-size: 13px;
    margin-top: 1%;
  }
  .navbar-links-container1 {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: row;
    margin-right: 2%;
    color: white;
    font-size: 13px;
    margin-top: 1%;
    margin-left: 97%;
  }
  
  .nav-link {
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    /* border: 2px solid white; */
    color: white;
    margin-top: 0;
  }
  
  #offerslink{
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    border: 2px solid white;
    color:  white !important;
    
    margin-top:-2%;
    text-decoration: none !important;
  }
  
  #offerslink:hover{
    text-decoration: none;
    cursor: pointer;
    background-color: #3380f2;
    border:3px solid #3380f2;
    color: blue;
    font-size: 20px !important;
  }
  .nav-link p {
    margin-top: 6px;
    /* padding-top: 1px; */
    font-weight: 700;
  }
  
  .login-register p{
    margin-top: -2px;
  }
  
  #offerlink{
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0% 5px 0 0;
    width: 70px;
    text-align: center;
    border: 2px solid white;
    color: white;
    margin-top: 0;
    font-weight: 700;
    text-decoration: none;
  }

  .linkdecoration{
    text-decoration: none !important;
  }
  
  #offerlink p{
    margin-top: 10%;
    text-decoration: none !important;
  }
  #offerlink:hover{
    text-decoration: none !important;
    color: #3380f2 !important;
    cursor: pointer;
    background-color: white !important;
  }
  
  /* #offerslink p{
    height: 36px;
    border-radius: 4px;
    background-color: #3380f2;
    cursor: pointer;
    margin: 0 5px 0 0;
    width: 70px;
    text-align: center;
    border: 2px solid white;
    color:  white !important;
    font-size: 17px;
    font-weight: 700;
    
    text-decoration: none !important;
  }
  #offerslink p:hover{
    background-color: white;
    text-decoration: none;
    color: #3380f2 !important
  } */
  .nav-link:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: white !important;
    color: #3380f2 !important;
    border: 2px solid white !important;
  }
  
  .login-register {
    height: 36px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
    border: 2px solid white !important;
    background-color: transparent;
    width: 40%;
    padding-left: 7px;
  }
  .login-register:hover {
    background-color: white;
    color: #3380f2;
  }
  
  
  .login-register:hover .LoginRegisterDropDown {
    display: block;
  }
  .topbanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 10px 10px;
    background: #fff;
    border-radius: 7px;
    position: relative;
    z-index: 0;
    height: 240px;
    margin: -10px 60px;
  }
  
  .topbanner-service {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding: 0 15px 15px;
    width: 98%;
  }
  
  .nav-link:hover .chooseCountry {
    display: block;
  }
  
  
  /* .chooseCountry {
    width: 20%;
    height: 220px;
    position: absolute;
    z-index: 1;
    margin-top: -0.5%;
    background-color: #3380f2;
    border-radius: 5px;
    display: none;
    font-weight: 700;
    color: white;
  } */
  .countrynames{
    margin-top: 4%;
    margin-left: 2%;
    padding: 3%;
    background-color: #3380f2;
    color: white;
    
  }
  .countrynames:hover{
    background-color: white;
    color: #3380f2;
  }
  .flags {
    margin-left: 20%;
    width: 20px;
    height: 12px;
  }
  .partLeft {
    width: 50%;
    height: 100%;
    border-right: 3px solid #3380f2;
    color: #3380f2;
  }
  .partRight {
    width: 50%;
    height: 100%;
    color: #3380f2;
  }
  .chooseCountry ul li {
    list-style: none;
    margin-top: 15px;
  }
  .chooseCountry ul li img {
    width: 20px;
    height: 12px;
  }
  .chooseCountry ul li:hover {
    background-color: #3380f2;
    color: white;
  }
  .chooseCurrrency {
    width: 350px;
    height: 200px;
    border: 3px solid #3380f2;
    position: fixed;
    z-index: 2;
    margin-top: 16%;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    display: flex;
    line-height: 20px;
  }
  .chooseCurrrency ul li {
    list-style: none;
    margin-top: 15px;
  }
  .chooseCurrrency ul li img {
    width: 40px;
    height: 24px;
    margin: auto;
  }
  .partone {
    width: 33%;
    height: 100%;
    border-right: 3px solid #3380f2;
  }
  .parttwo {
    width: 33%;
    height: 100%;
    border-right: 3px solid #3380f2;
  }
  .partthree {
    width: 33%;
    height: 100%;
  }
  .chooseCurrrency ul li:hover {
    background-color: #3380f2;
    color: white;
  }
  .NavbarMoreOption {
    width: 200px;
    height: 160px;
    position: fixed;
    z-index: 1;
    margin-top: 4%;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    margin-left: 30%;
    color: white;
    background-color:#3380f2;
  }
  .navbarOptions {
    height: 25%;
    text-align: left;
  }
  .contactIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .miceIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .inspireIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .offersIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .LoginRegisterDropDown {
    width: 200px;
    height: 220px;
    position: absolute;
    display: none;
    z-index: 1;
    margin-top: -0.5%;
    right: 0;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    font-size: 15px;
    color: white;
    background-color:#3380f2;
  }
  .LoginRegisterOptions {
    margin-top: 2%;
    height: 18%;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
  }
  .LoginRegisterOptions:hover{
    background-color: white;
    color: #3380f2;
  }
  .LoginRegisterOptions1 {
    margin-top: 2%;
    height: 18%;
    text-align: center;
    font-weight: 200;
    margin-left: 2%;
  }
  .LoginRegisterDropDown1 {
    width: 200px;
    height: 220px;
    position: absolute;
    display: none;
    z-index: 1;
    margin-top: 0%;
    left:0;
    background-color: rgb(199, 225, 236);
    border-radius: 5px;
    font-size: 15px;
    color: white;
    background-color:#3380f2;
  }
  .LoginRegisterOptions1:hover{
    background-color: white;
    color: #3380f2;
  }
  .LoginIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .RegisterIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .BlogsIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .PaymentIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  .BookingIcon {
    margin-left: 30px;
    margin-right: 10px;
  }
  #hide {
    margin-bottom: 20px;
  }
  .travelflapmainlogo{
    /* border: 1px  solid red !important; */
    color: white;
    margin-top: 20px;
    position: fixed;
    z-index:1 ;
    display: none;
  }
  .checkmark{
    border:"1px solid";
    height: 20px;
    width: 100%;
  }
  @media screen and (max-width: 950px) {
    .navbar-container{
        width: 100%;
    }
    .transparentNavbar{
      width: 100%;
      margin: auto;
    }
    .navbar_fixed {
      width: 100%;
      font-size: 20px;
    }
    
    .navbar-links-container1{
        margin: auto;
    }
    .navbar-links-container{
      display: none;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -20%;
    }
    .LoginRegisterDropDown
    {
      margin-top: 30%;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
  }
  
  @media screen and (max-width: 780px) {
    .navbar-container{
        width: 85%;
        margin: auto;
    }
    .logo2{
      display: none;
    }
    .navbar_fixed {
      width: 100%;
      font-size: 20px;
    }
    .navbar-links-container1{
        margin-left:25%;
    }
    .navbar-links-container{
      display: none;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -25%;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
  }
  @media screen and (max-width: 450px) {
    .navbar-container{
        width: 25%;
        margin: auto;
    }
    .logo2{
      display: none;
    }
    .navbar_fixed {
      width: 100%;
      font-size: 20px;
      border: 2px solid;
      display: none;
    }
    .transparentNavbar{
      display: none;
    }
    .navbar-links-container1{
      display: none;
    }
    .navbar-links-container1{
      margin-left: -35px;
    }
    .travelflapmainlogo{
      display: flex;
      flex-direction: row;
      width: 85%;
    }
    .hamburgermenuicon{
      margin-left: 30%;
      margin-top: 2%;
    }
    .logo1{
      display: none;
    }
    .menu-links{
      margin-left: -25%;
      border: 1px solid;
    }
    .fixed-menu-link{
      border: 1px solid ;
    }
    .NavbarMoreOption{
      margin-top: 8%;
    }
    .chooseCountry{
      margin-top: 23%;
    }
    .chooseCurrrency{
      margin-top: 30%;
    }
    .login-register{
      font-size: 10px;
      font-weight: 100;
      width: 80px;
    }
    .Menuoptions{
      width: 80px;
      height: 320px;
      position: fixed;
      z-index: 1;
      transition: all 1s ease;
      margin-top: 18%;
      background-color: #3380f2;
      border-radius: 5px;
      margin-left: 38%;
      color: white;
    }
    .Menudropdowns div{
      margin-top: 5px;
    }
    .Menuoptions:hover{
      background-color: white;
      color: #3380f2;
    }
    .Menudropdownsoptions{
      padding: 8px;
    }
    .Menudropdownsoptions:hover{
      background-color: #3380f2;
      color: white;
    }
  }
  
  