.newVarietyOfStaysMaincardDiv {
  height: 560px;
  border: none;
  background-color: white !important;
}

.newVarietyOfStaysHeadingouterdiv h2 {
  color: #3380f2;
  margin-left: 3.6%;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0.5%;
  margin-bottom: 2%;
}

.newVarietyOfStaysHeadingouterdiv {
  display: flex;
  flex-direction: row;
}

.newPopularbelowHeadingdiv {
  display: flex;
  flex-direction: row;
  color: #393939;
}

.belowInternationalheading {
  margin-left: 2%;
  width: 12%;
  font-size: 28px;
  margin-bottom: 3%;
  border-right: 2px solid lightgray;
}

.belowDomesticHeading {
  margin-left: 1.5%;
  font-size: 28px;
}

.newVarietyOfStaysSlidercontainer {
  display: flex;
  flex-direction: row;
}

.newhomeTopCititesSlidercontentdiv {
  width: 30% !important;
}

.newhomeTopCititesSlidercontentdiv h2 {
  font-size: 28px;
  margin-left: 3%;
}

.newVarietyOfStaysSlickslidercontainer {
  width: 97%;
  margin: auto;
}

.card-wrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.newVarietyOfStaysYouDiv {
  width: 100%;
  border: none;
  border-radius: 16px !important;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.newVarietyOfStaysCardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newVarietyOfStaysCardimage img {
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  -o-object-fit: cover;
  object-fit: cover;
}

.newVarietyOfStaysYouDiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newVarietyOfStaysYouDiv:hover .newVarietyOfStaysCardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newVarietyOfStaysDetails {
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.newVarietyOfStaysDetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.newVarietyOfStaysDetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.newVarietyOfStaysDetails button {
  margin-left: 35%;
}

.newhomeTopCitiesBottomTexts {
  margin-top: -20%;
  z-index: 100000;
}

.newhomeTopCitiesBottomTexts h2 {
  text-align: center;
  font-size: 20px;
}

.newhomeTopCitiesBottomTexts p {
  text-align: center;
  font-size: 14px;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: whitesmoke !important;
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: white !important;
  background-color: blue !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

#hotelsVarietyOfStaysDropContainer {
  width: 9.5%;
  margin-left: 60%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  outline: none;
  padding-left: 15px;
}

#hotelsVarietyOfStaysCountrydrop {
  background-color: #3380f2 !important;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 15px;
}

#hotelsVarietyStaysIcondiv {
  border: 1px solid #3380f2 !important;
  width: 20%;
  color: white;
  background-color: #3380f2;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  /* border-left: none; */
}

#hotelsVarietyStaysicon {
  color: white;
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: 47%;
}

.varietyButtonMainDiv {
  width: 90%;
  margin: auto;
  margin-left: 5%;
}

.varietyButtonsSelector {
  width: 90%;
  margin: auto;
  margin-bottom: 2%;
}

.varietyButton1 {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  text-align: center !important;
}

.varietyButton2 {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  text-align: center !important;
}

.varietyButton3 {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  text-align: center !important;
}

.varietyButton4 {
  outline: none;
  border: 2px solid #3380f2;
  width: 10%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  text-align: center !important;
}

.varietyButton5 {
  outline: none;
  border: 2px solid #3380f2;
  width: 8%;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 50px;
  margin-right: 2%;
  text-align: center !important;
}

.varietyButton6 {
  outline: none;
  border: 2px solid #3380f2;
  height: 40px;
  border-radius: 5px !important;
  padding-left: 45px;
}

/* media query css part starts from here for different devices */

@media (max-width: 480px) {
  .newVarietyOfStaysMaincardDiv {
    /* border: 1px solid red !important; */
    height: 470px !important;
    /* border: none; */
    background-color: white !important;
  }

  .newVarietyOfStaysHeadingouterdiv {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    height: 50px;
    text-align: center;
  }

  .newVarietyOfStaysHeadingouterdiv h2 {
    /* border: 1px solid red; */
    width: 100%;
    color: black !important;
    margin-left: 3.6%;
    font-size: 20px !important;
    font-weight: 700;
    margin: auto;
    margin-top: 3% !important;
    margin-bottom: 2%;
  }

  .newPopularbelowHeadingdiv {
    display: flex;
    flex-direction: row;
    color: #393939;
  }

  .belowInternationalheading {
    margin-left: 2%;
    width: 12%;
    font-size: 28px;
    margin-bottom: 3%;
    border-right: 2px solid lightgray;
  }

  .belowDomesticHeading {
    margin-left: 1.5%;
    font-size: 28px;
  }

  .newVarietyOfStaysSlidercontainer {
    display: flex;
    flex-direction: row;
  }

  .newhomeTopCititesSlidercontentdiv {
    width: 30% !important;
  }

  .newhomeTopCititesSlidercontentdiv h2 {
    font-size: 28px;
    margin-left: 3%;
  }

  .newVarietyOfStaysSlickslidercontainer {
    width: 97%;
    margin: auto;
  }

  .card-wrapper {
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .newVarietyOfStaysYouDiv {
    width: 100%;
    border: none;
    border-radius: 16px !important;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .newVarietyOfStaysCardimage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newVarietyOfStaysCardimage img {
    width: 100%;
    height: 100%;
    filter: brightness(0.6);
    -o-object-fit: cover;
    object-fit: cover;
  }

  .newVarietyOfStaysYouDiv:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newVarietyOfStaysYouDiv:hover .newVarietyOfStaysCardimage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newVarietyOfStaysDetails {
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: white;
  }

  .newVarietyOfStaysDetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .newVarietyOfStaysDetails h2 .job-title {
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    display: block;
  }

  .newVarietyOfStaysDetails button {
    margin-left: 35%;
  }

  .newhomeTopCitiesBottomTexts {
    margin-top: -20%;
    z-index: 100000;
  }

  .newhomeTopCitiesBottomTexts h2 {
    text-align: center;
    font-size: 20px;
  }

  .newhomeTopCitiesBottomTexts p {
    text-align: center;
    font-size: 14px;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    background: whitesmoke !important;
    border: 1px solid rgb(51, 128, 242);
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: white !important;
    background-color: blue !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }

  #hotelsVarietyOfStaysDropContainer {
    display: none !important;  
  }

  #hotelsVarietyOfStaysDropContainer {
    width: 40%;
    margin-left: 5%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white !important;
    font-weight: 700;
    justify-content: space-between;
    outline: none;
    padding-left: 15px;
  }

  #hotelsVarietyOfStaysCountrydrop {
    background-color: #3380f2 !important;
    width: 80%;
    height: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 15px;
  }

  #hotelsVarietyStaysIcondiv {
    width: 20%;
    color: white;
    background-color: #3380f2;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  #hotelsVarietyStaysicon {
    color: white;
    height: 21px;
    width: 20px;
    border-radius: 100%;
    margin-top: 47%;
  }

  .varietyButtonMainDiv {
    width: 90%;
    margin: auto;
    margin-left: 5%;
  }

  .varietyButtonsSelector {
    width: 90%;
    margin: auto;
    margin-bottom: 2%;
  }

  .varietyButton1 {
    outline: none;
    border: 2px solid #3380f2;
    width: 10%;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    text-align: center !important;
  }

  .varietyButton2 {
    outline: none;
    border: 2px solid #3380f2;
    width: 10%;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    text-align: center !important;
  }

  .varietyButton3 {
    outline: none;
    border: 2px solid #3380f2;
    width: 10%;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    text-align: center !important;
  }

  .varietyButton4 {
    outline: none;
    border: 2px solid #3380f2;
    width: 10%;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    text-align: center !important;
  }

  .varietyButton5 {
    outline: none;
    border: 2px solid #3380f2;
    width: 8%;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 50px;
    margin-right: 2%;
    text-align: center !important;
  }

  .varietyButton6 {
    outline: none;
    border: 2px solid #3380f2;
    height: 40px;
    border-radius: 5px !important;
    padding-left: 45px;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
