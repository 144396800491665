/* .newHelpBannerOuterDiv {
  border: 2px solid red;
} */

.newHelpImageDiv {
  /* border: 2px solid black; */
  height: 400px;
  /* object-fit: cover; */
}

.newHelpImageDiv img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
