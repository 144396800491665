/* This css is for the upper slider countries sliders one */

.countrycardmaindiv {
  /* border: 2px solid red !important; */
  width: 90%;
  margin: auto;
}
/* .multiCardSlider{
  border: 2px solid rebeccapurple;
} */
.achievements_title {
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 4%;
  margin-bottom: 5%;
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 128, 242);
}

.maincountrycardwrapper {
  /* border: 1px solid green; */
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* .countrycard_img:hover {
  width: 160px;
  height: 160px;
  border-radius: 50% !important;
  transition: 0.5s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
} */

.countrycard {
 /* border: 3px solid red; */
 width: 180px;
 height: 220px;
}

.counrtycard-img-top img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.countrycard_img{
  /* border: 3px solid greenyellow; */
  width: 150px;
  height: 150px;
}

.countrycard_img img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 25px;
  /* border: 3px solid saddlebrown; */
}
.countrycard_img img:hover{
  border-radius: 50%;
}

.countrycard_img:hover{
  width: 160px;
  height: 160px;
  border: none !important;
  border-radius: 50% !important;
  transition: 0.5s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
} 
/* .countrycard_img img:hover {
  border-radius: 50%;
} */

.country_title .countrycard-title{
  margin: auto;
  /* border: 1px solid blue; */
  margin-top: 2%;
  text-align: center;
  /* margin-left: 30%; */
  width: 100%;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 29px;
  vertical-align: baseline;
  color: #3380f2;
  font-weight: 700;
}
/* .countrycard-title {
  border: 1px solid red;
  margin-top: 2%;
  width: 100%;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 29px;
  vertical-align: baseline;
  color: #3380f2;
  font-weight: 700;
} */

.awards_desc p {
  font-size: 13px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 23.8px;
  font-weight: 700;
  text-transform: uppercase;
  vertical-align: baseline;
  color: rgb(170, 176, 180);
}

.travelstoriesdiv:hover .card-image {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.travelstoriesdetails {
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.travelstoriesdetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.travelstoriesdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  color: white;
  font-weight: 300;
  display: block;
}

.card:hover .travelstoriesdetails {
  bottom: 0;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

.main {
  width: 100%;
  height: 350px;
  /* border: 1px solid lightgray; */
  margin: auto;
  margin-top: 20px;
  border-radius: 10px;
}
.head {
  display: flex;
  width: 100%;

  background-color: #3380f2;
}
.title {
  display: flex;
  /* border: 1px solid white; */
  margin-left: 3%;
  font-size: 35px;
}
.populartitle1 {
  margin: 25px 0 20px 26%;
  color: white;
  font-weight: 700;
  font-size: 25px;
}
.populartitle2 {
  /* border: 1px solid white; */
  margin-left: 5%;
  font-weight: bolder;
  margin: 25px 0 20px 5%;
  color: white;
  font-size: 25px;
}

.populardestinationhomediv{
  /* border: 3px solid red; */
  margin-left: 5%;
}

.populardestinationhomediv select{
  margin-top: 8%;
  background-color: #3380f2;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
}
.populardestinationhomediv select:hover{
  background-color: white;
  color: #3380f2;
}

.populartitle3 {
  /* border: 3px solid rgb(51, 128, 242); */
  border: 2px solid white;
  text-align: center;
  margin: 25px 0 20px 15%;
  /* color: black; */
  color: white;
  font-weight: 800;
  border-radius: 25px;
  width: 8%;
  height: 38px !important;
  font-size: 11px !important;
  /* padding: 16px; */
  padding-top: 9px !important;
}

.populartitle3:hover {
  color: #3380f2;
  background-color: white;
}

.populartitle3_inner1 {
  border: 2px solid white;
  text-align: center;
  margin: 24px 0 20px 1%;
  /* color: black; */
  color: white;
  font-weight: 800;
  border-radius: 25px;
  width: 8%;
  height: 38px !important;
  font-size: 11px !important;
  /* padding: 16px; */
  padding-top: 9px !important;
}

.populartitle3_inner1:hover {
  color: #3380f2;
  background-color: white;
}

.populartitle3_inner2 {
  border: 2px solid white;
  text-align: center;
  margin: 24px 0 20px 1%;
  /* color: black; */
  color: white;
  font-weight: 800;
  border-radius: 25px;
  width: 8%;
  height: 38px !important;
  font-size: 11px !important; 
  /* padding: 16px; */
  padding-top:9px !important;
}

.populartitle3_inner2:hover {
  color: #3380f2;
  background-color: white;
}
.title4_viewall {
  border: 2px solid white;
  padding-top: 8px;
  padding-left: 9px;
  width: 10%;
  height: 38px !important;
  margin: 24px 0 30px 40%;
  color: #3380f2;
  border-radius: 25px;
  height: 32px;
  background-color: white;
  font-weight: 700;
  font-size: 13px;
}
.title4_viewall:hover {
  color: white;
  background-color: #3380f2;
}

.data {
  width: 96%;
  margin: 25px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}
.populardestinationsnames {
  width: 100%;
  border-radius: 10px;
  height: 230px;
}
.namesContainer {
  width: 88%;
  /* border: 1px solid white; */
  /* border: 1px solid red; */
  height: 230px;
  margin: auto;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.popularimagediv:hover {
  border-radius: 50%;
  overflow: hidden;
}

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
} */

::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgb(161, 161, 241);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(51, 128, 242);
}

.pic {
  display: inline-block;
  vertical-align: top;
  /* border: 1px solid black; */
  width: 150px;
  height: 150px;
  margin-right: 25px;
  border-radius: 25px !important;
}
.pic img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.pic img:hover {
  border-radius: 50%;
}


.pic:hover {
  width: 160px;
  height: 160px;
  border-radius: 50% !important;
  transition: 0.5s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.country {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  /* border: 1px solid white; */
  margin: 20px;
  font-weight: bold;
  font-size: 20px;
}
.images {
  width: 98%;
  border: 1px solid white;
  border-radius: 10px;
  height: 300px;
  display: flex;
  flex-direction: row;
}
.pic .img {
  width: 90%;
  height: 250px;
  /* border: 1px solid red !important; */
  /* border: 1px solid lightgray; */
  border-radius: 10px;
  overflow: hidden;
  margin: 0px 0 20px 7%;
  position: relative;
  text-align: center;
  border: none;
}

.populardestinationscontainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  margin-top: 2%;
}

img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bottom-left {
  position: absolute;
  bottom: 40px;
  left: 20px;
  color: white;
  font-weight: bold;
  font-size: 25px;
}

@media screen and (max-width: 950px) {
  .populartitle3 {
    display: none;
  }
  .popularpopulartitle1 {
    font-size: 30px;
  }
  .populartitle2 {
    font-size: 30px;
  }
  .title4_viewall {
    margin-left: 200px;
  }
  .data {
    flex-direction: column;
  }
  .populardestinationsnames {
    width: 98%;
  }
  .populardestinationscontainer {
    margin: 20px 500px 20px 0;
    width: 95%;
  }
  .main {
    width: 90%;
    height: auto;
  }
  img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .populartitle3 {
    display: none;
  }
  .popularpopulartitle1 {
    font-size: 25px;
  }
  .populartitle2 {
    font-size: 25px;
  }
  .title4_viewall {
    margin-left: 50px;
  }
  .data {
    flex-direction: column;
  }
  .populardestinationsnames {
    width: 95%;
  }
  .populardestinationscontainer {
    margin: 20px 500px 20px 0;
    width: 100%;
  }
  .main {
    width: 98%;
    height: auto;
  }
  .images {
    flex-direction: column;
  }
  .img {
    margin: 10px 0px 10px 5px;
    width: 95%;
  }
  .title4_viewall {
    font-size: 12px;
  }
}

/* This css is for the carousel part */
.topcities_title {
  color: rgb(51, 128, 242);
  margin-top: 4%;
}
.slider_title {
  font-size: 35px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15%;
  margin-bottom: 5%;
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 128, 242);
}

.maincountrycardwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.populardestinationssliderdiv {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.populardestinationcardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.populardestinationcardimage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.populardestinationssliderdiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* border-radius: 10%; */
}

.populardestinationssliderdiv:hover .populardestinationcardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  /* border-radius: 10%; */
}

.card:hover .populardestinationsdetails {
  bottom: -40%;
  transition: 0.5s ease-in-out;
}

.populardestinationsdetails {
  position: absolute;
  bottom: -77%;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 100;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.populardestinationsdetails :hover {
  color: white;
}

.populardestinationsdetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.populardestinationsdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.card:hover .populardestinationsdetails {
  bottom: 1;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}
