.newBudgetfriendlyStaycationmaindiv {
  width: 100%;
  /* border: 2px solid red; */
  margin-top: 2%;
  /* background-color: #3380f2; */
  height: 480px;
}

.newBudgetFriendlyOuterdiv {
  border-top: none !important;
  border: 1px solid lightgray;
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  height: 370px !important;
  transition: all 300ms ease-in-out;
  /* border-radius-bottom-right: 25px; */
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;
}

.newBudgetFriendlyOuterdiv:hover {
  width: 100%;
  padding: 5px;
}

.newBudgetFriendlyStaycationHeadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  margin-top: 0%;
}

.newBudgetFriendlyStaycationHeading1 {
  font-size: 30px;
  font-weight: 700;
  /* margin-top: 2%; */
  margin-left: 5%;
  width: 65%;
  color: #3380f2;
  /* color: #3380f2; */
}

.newviewallActivityAroundbuttons {
  color: white;
  font-weight: 700;
  text-decoration: none !important;
  display: flex;
  margin: 1.5% 0 0 55%;
  width: 56%;
}

.newBudgetFriendlySliderContainer {
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 1.7%;
  /* background-color: #3380f2; */
}

.newBudgetFriendlyCardtitle {
  color: #393939 !important;
  font-size: 22px;
  margin-top: 1%;
}

.newBudgetFriendlyCard_desc {
  width: 90%;
  color: gray;
  font-size: 14px;
  text-align: left;
}

.newBudgetFriendlyStaycationImageTextContainer {
  width: 100%;
  height: 370px;
}

.newBudgetFriendlyStaycationsTextCard {
  margin-left: 9% ;
  width: 100% !important;
}

.newBudgetFriendlyStaycationsTextCard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}

.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.newBudgetFriendlyCardimage {
  /* border: 2px solid rebeccapurple; */
  width: 100% !important;
  height: 230px;
}

.activityAroundimage {
  height: 240px !important;
  width: 100% !important;
  border-radius: 10px 10px 0px 0px;
}

.newBudgetFriendlyStaycationCardDescMain {
  width: 100%;
  margin-left: -9%;
}

.contentButtoninnerdiv {
  /* border: 1px solid red !important; */
  display: flex;
  flex-direction: row;
}

.contentButtoninnerdiv button {
  margin-left: 32%;
  border: none;
  width: 30%;
  color: white;
  font-size: 15px;
  background-color: #3380f2;
  border-radius: 5px;
}

.exclusivecarddesc:hover {
  color: white;
}

.newviewallActivityAroundtext {
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 34%;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-top: 4px;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.newviewallActivityAroundtext:hover {
  background-color: white;
  color: #3380f2;
}

.newActivityAroundHeadingbuttons {
  justify-content: flex-end;
  /* width: -1%; */
  margin-left: 6%;
  margin-top: -1%;
  background-color: #3380f2 !important;
}

.newActivityAroundHeadingbuttons button {
  color: #3380f2 !important;
  background-color: white !important;
}

.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}

.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
}

.buttons:hover {
  transform: scale(1.1);
}

.newBudgetFriendlyButton {
  border: 3px solid #3380f2;
  width: 34%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 9px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: 3%;
  font-size: 15px !important;
}

.newBudgetFriendlyButton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}

/* this is the css code for the comp to be responsive for the different devices */

@media (max-width: 480px) {
  .newBudgetfriendlyStaycationmaindiv {
    width: 100%;
    /* border: 2px solid red; */
    margin-top: 2%;
    /* background-color: #3380f2; */
    height: 480px;
  }

  .newBudgetFriendlyOuterdiv {
    border-top: none !important;
    border: 1px solid lightgray;
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    height: 370px !important;
    transition: all 300ms ease-in-out;
    /* border-radius-bottom-right: 25px; */
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
  }

  .newBudgetFriendlyOuterdiv:hover {
    width: 100%;
    padding: 5px;
  }

  .newBudgetFriendlyStaycationHeadingcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    margin-top: 0%;
  }

  .newBudgetFriendlyStaycationHeading1 {
    font-size: 25px;
    font-weight: 700;
    /* margin-top: 2%; */
    margin-left: 5%;
    width: 100%;
    text-align: center;
    color: black;
    /* color: #3380f2; */
  }

  .newviewallActivityAroundbuttons {
    color: white;
    font-weight: 700;
    text-decoration: none !important;
    display: flex;
    margin: 1.5% 0 0 55%;
    width: 56%;
  }

  .newBudgetFriendlySliderContainer {
    /* border: 2px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 1.7%;
    /* background-color: #3380f2; */
  }

  .newBudgetFriendlyCardtitle {
    color: #393939 !important;
    font-size: 22px;
    margin-top: 1%;
  }

  .newBudgetFriendlyCard_desc {
    width: 90%;
    color: gray;
    font-size: 14px;
    text-align: left;
  }

  .newBudgetFriendlyStaycationImageTextContainer {
    width: 100%;
    height: 370px;
  }

  .newBudgetFriendlyStaycationsTextCard {
    margin-left: 9% !important;
    width: 100% !important;
  }

  .newBudgetFriendlyStaycationsTextCard:hover div {
    color: white;
  }

  .exclusivedealsimagediv {
    width: 800px;
    height: 200px !important;
  }

  .exclusivedealsimage {
    width: 700px;
    height: 10px;
    margin-left: 40%;
  }

  .newBudgetFriendlyCardimage {
    /* border: 2px solid rebeccapurple; */
    width: 100% !important;
    height: 230px;
  }

  .activityAroundimage {
    height: 240px !important;
    width: 100% !important;
    border-radius: 10px 10px 0px 0px;
  }

  .newBudgetFriendlyStaycationCardDescMain {
    width: 100%;
    margin-left: -9%;
  }

  .contentButtoninnerdiv {
    /* border: 1px solid red !important; */
    display: flex;
    flex-direction: row;
  }

  .contentButtoninnerdiv button {
    margin-left: 32%;
    border: none;
    width: 30%;
    color: white;
    font-size: 15px;
    background-color: #3380f2;
    border-radius: 5px;
  }

  .exclusivecarddesc:hover {
    color: white;
  }

  .newviewallActivityAroundtext {
    margin-top: 0.5%;
    border: 2px solid white;
    border-radius: 20px;
    width: 34%;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    padding-left: 16px;
    padding-top: 4px;
    margin-bottom: 8%;
    text-decoration: none !important;
  }

  .newviewallActivityAroundtext:hover {
    background-color: white;
    color: #3380f2;
  }

  .newActivityAroundHeadingbuttons {
    justify-content: flex-end;
    /* width: -1%; */
    margin-left: 6%;
    margin-top: -1%;
    background-color: #3380f2 !important;
  }

  .newActivityAroundHeadingbuttons button {
    color: #3380f2 !important;
    background-color: white !important;
  }

  .line-indicator li {
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }

  .buttons {
    background: rgb(51, 128, 242) !important;
    color: white !important;
    transition: 700ms;
  }

  .buttons:hover {
    transform: scale(1.1);
  }

  .newBudgetFriendlyButton {
    border: 3px solid #3380f2;
    width: 34%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 4px 9px;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 3%;
    font-size: 15px !important;
  }

  .newBudgetFriendlyButton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    font-size: 14px !important;
  }
}

/* @media screen and (max-width: 950px) {
  .newBudgetFriendlyStaycationImageTextContainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .newviewallActivityAroundbuttons {
    display: none;
  }
  .newBudgetFriendlyStaycationHeading1 {
    display: none;
  }
  .newBudgetFriendlyStaycationHeadingcontainer {
    flex-direction: column;
  }
  .internationalheading2 {
    width: 100%;
  }
  .newBudgetFriendlyStaycationImageTextContainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .newBudgetFriendlyStaycationsTextCard {
    margin-left: 8px;
  }
} */
