

.InnerPopularDestination2categoriesmainDiv{
    width: 85%;
    height: 220px;
    margin: auto;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    border-radius: 25px;
    background-color: #F3F7FA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.innerPopularDestination2Categorycard{
    width: 25%;
    height: 170px;
    margin: 2% 0% 0% 0%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    text-align: left;
}
.innerPopularDestination2CategorycardimageDiv{
    height: 100px;
    border-radius: 10px;
    width: 70%;
    margin: auto;
    margin-top: 15%;
    cursor: pointer;
}


.innerPopularDestination2Categorycardsubheading{
    color:#393939;
    font-size: 25px;
    font-weight: 700;
    margin: auto;
    z-index: 1;
    text-align: center;
    /* margin-top: 6%; */
    margin-left: 3.5%;
    /* border: 2px solid yellow; */
    position: absolute;
    
}
.innerPopularDestination2Categorycardimages{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    filter: brightness(0.6);
    z-index: 0;
}