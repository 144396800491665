.CurrencyDataFetchTestContainerDiv{
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    margin-top: 10%;
    margin: auto;
    background-color: #E8F4FF;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-row-gap:10px ;
}
.CurrencyDataFetchdetails{
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    padding: auto;
    padding-left: 5%;
    margin-top: 10%;
    margin-left: 20%;
}
.CurrencyDataFetchdetails :hover{
    background-color: white;
    color: #3380F2;
}
.CurrencyDataFetchNames{
    border: 1px solid lightgray;
    color: #3380F2;
    width: 120px;
    margin: auto;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    padding-left: 15px;
    /* margin-top: 5%; */
}
.CurrencyDataFetchNames:hover{
    background-color: white;
    color: #3380F2;
    cursor: pointer;
}
.CurrencyDataFetchCodes{
    color: #393939;
    font-size: 10px;
    font-weight: 700;
    margin-top: 2%;
    margin-left: 5%;
}
.CurrencyDataFetchSymbol{
    color: #3380F2;
    font-size:12px;
    font-weight: 700;
    margin-left: 10%;
}
.CurrencyDataFetchImage{
    width: 25px;
    height: 15px;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 2%;
    /* border: 2px solid lightgray; */
}