.BlogPageFeaturedexplore-maindiv{
    /* border: 2px solid red; */
    margin-left: 4%;
    margin-right: 4%;

}
.BlogPageFeaturedexplore-innermaindiv{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    /* margin-top: 5%; */

}
.BlogPageFeaturedexplore-innermaindiv h4{
    margin-top: 2%;
    margin-left: 3%;
    font-weight: 700;
    font-size: 30px;
}
.BlogPageFeaturedexplore-carddiv{
    /* border: 2px solid yellowgreen; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.BlogPageFeaturedexplore-cardinnerdiv{
    /* border: 2px solid pink; */
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-left: 2.5%;
    border-radius: 10px;
    height:520px ;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.BlogPageFeaturedexplore-imagediv{
    /* border: 2px solid yellow ; */
    height: 250px;
    width: 100%;

}

.imgexplore{
    width: 100%;
    height: 100%;
}
.containerofcontain{
    /* border: 2px solid red; */
    margin-left: 4%;
    margin-top: 3%;
    padding-bottom: 3%;
}
.spancontaindiv{
    margin-top: 2%;
    margin-left: 2%;
}
.BlogPageFeaturedexplore-containdivheading{
    font-size: 22px;
    font-weight: 700;

}
.BlogPageFeaturedexplore-containdiv{
    color: gray;
}

.BlogPageFeaturedexplore-latestblogdiv{
    /* border: 4px solid green; */
    display: flex;
    flex-direction: row;

}
.BlogPageFeaturedexplore-latestbloginnerdiv1div{
    /* border: 2px solid purple; */
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    width: 63%;
}

  
.BlogPageFeaturedexplore-latestbloginnerdiv1div h4{
    font-weight: 700;
    font-size: 30px;
}


.BlogPageFeaturedexplore-cardinnerdiv2{
display: flex;
flex-direction: row;
/* border: 2px solid yellow; */

width: 100%;
margin-left: 2.5%;
border-radius: 10px;
height:240px ;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
margin-top: 2%;
margin-right: 5%;

}
.BlogPageFeaturedexplore-imagediv2{
    /* border: 2px solid yellow ; */
    width: 150%;

}
.imgexplore2{
   width: 100%;
   height: 100%;
 
}
.BlogPageFeaturedexplore-containdiv2{
    color: gray;
}
.BlogPageFeaturedexplore-latestbloginnerdiv2div{
    /* border: 2px solid teal; */
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-left: 5%;
    margin-top: 5%;

}
.BlogPageFeaturedexplore-popularblog{
    display: flex;
    flex-direction: column;
    /* border: 2px solid yellow; */
    width: 100%;
    margin-top: 7%;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.popularblogsmallcarddiv{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    height: 105px;
    margin-top: 3%;
}
.popularblogsmallcarddiv-contain{
    /* border: 2px solid green ; */
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2%;
    padding-top: 2%;

}
.imgppb{
    /* border: 2px     solid slateblue; */
    width: 45%;
    height: 100%;
    border-radius: 10px;

}
.imgppbinnerdiv{
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

.BlogPageFeaturedexplore-popularblog2{
    /* border: 2px solid red; */
    
   
    display: flex;
    flex-direction: row;
    

}



.iconblogdiv{
    background-color: white;
    border-radius: 50%;
    /* border: 2px solid pink; */
    height: 55px;
    width: 55px;
    margin-left: 4%;
    /* margin-top: 10%; */
    font-size: 27px;
    font-weight: 700;
    padding-left: 3.5%;
    padding-top: 1%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #393939;

}
.iconblogdiv a{
    text-decoration: none;
    color: #393939;
}
.iconblogdiv2{
    background-color: #3380f2;
    border-radius: 50%;
    /* border: 2px solid pink; */
    height: 55px;
    width: 55px;
    margin-left: 6%;
    /* margin-top: 10%; */
    font-size: 27px;
    font-weight: 700;
    padding-left: 3.5%;
    padding-top: 1%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: white;
}
.iconblogdiv2 a{
    color: white;
    text-decoration: none;
}
.BlogPageFeaturedexplore-popularblog3{
    /* border: 2px solid green; */
    width: 100%;
    margin-top: 20%;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: row;
    height: 230px;

}
.travelerdiv{
    /* border: 2px solid rebeccapurple; */
    display: flex;
    flex-direction: column;
    text-decoration: underline;
    color: #3380f2;
    margin-left: 5%;


}
.travelerdivinner{
    margin-top: 15%;
}

.tingsdiv h6{
    font-size: 18px;
    font-weight: 700;
}
.BlogPageFeaturedexplore-popularblog2div{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    border-radius: 5px;
    height: 130px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
}

.BlogPageFeaturedexplore-popularblog2div p{
    font-size: 22px;
    font-weight: 700;
    color: #393939;
    margin-left: 4%;
    margin-top: 2%;
   

}


