.travelFlapSignUpOuterDiv {
  border: 1px solid white;
}

.travelFlapSignUpParentDiv {
  /* border: 1px solid black; */
  position: relative;
  /* margin-top: 3%; */
  height: 400px;
  /* width: 90%; */
}

.travelFlapSignupInnerDiv {
  /* border: 1px solid red; */
  height: 100px;
  /* margin-top: 5%; */
  position: absolute;
  left: 50%;
  top: 18%;
  width: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
}

.travelFlapSignupFormDiv {
  /* border: 1px solid black; */
  width: 45%;
  justify-content: center;
  text-align: center;
}

.travelFlapSignupFormDiv h3 {
  font-weight: 600;
  font-size: 35px;
  vertical-align: baseline;
  line-height: 30px;
  margin-top: 7%;
}

.signupSocialIcons {
  /* border: 1px solid red; */
  margin-top: 3%;
}

.signupSocialIcons img {
  /* border: 1px solid red; */
  /* width: 100%; */
  object-fit: cover;
}

.signupNameDiv {
  /* border: 1px solid red; */
  margin-left: 10%;
}

.signupNumberDiv {
  margin-left: 10%;
}

.signupEmailDiv {
  margin-left: 10%;
}

.signupPasswordDiv {
  margin-left: 10%;
}

.signupConfirmPasswordDiv {
  margin-left: 10%;
}

.signupAgreementDiv {
  /* border: 1px solid red; */
  width: 45%;
  margin-left: 8%;
}

/* .signupAgreementCheckBoxDiv {
  border: 1px solid black;
}

.signupAgreementTextDiv {
  border: 1px solid green;
} */

.signupSubmitButtonDiv {
  /* border: 1px solid red; */
  width: 84%;
  margin-left: 7.5%;
  text-align: center;
  justify-content: center;
}

.signUpFormInnerDiv {
  /* border: 1px solid red; */
  margin-left: 15%;
}

.signUpFormInnerDiv label {
  color: #393939;
}

.travelFlapSignupImageDiv {
  /* border: 1px solid green; */
  width: 55%;
  /* height: 400px; */
  object-fit: cover;
}

.travelFlapSignupImageDiv img {
  width: 64%;
  object-fit: cover;
}

.signupAlradyAccountDiv {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.loginPageRouteText {
  font-weight: 600;
  margin-left: 30%;
}

.loginPageRouteButton {
  color: #3380f2;
  cursor: pointer;
  margin-left: 3%;
  font-weight: 600;
}
