/* This is a new css file */
.domesticmaindiv {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 1%;
}

.domesticheadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #3380f2;
  height: 80px;
  /* margin-top: 3%; */
  /* border: 1px solid black !important; */
}

.domesticouterdivtop {
  /* border: 2px solid red !important; */
  /* border: 2px solid lightgray; */
  display: flex !important;
  flex-direction: row !important;
  width: 95% !important;
  box-shadow: 4px 4px 4px rgb(211,211,211);
  border: none;
  transition: all 300ms ease-in-out;
}

.domesticouterdivtop:hover {
  width: 100%;
  padding: 3px;
  box-shadow: 5px 10px 25px  #3380f2;
}



.domesticheading1 {
  /* border: 1px solid black; */
  font-size: 22px;
  font-weight: 700;
  width: 17%;
  margin-left: 4%;
  margin-top: 1.5%;
  color: white;
}

.domesticheading2 {
  /* border: 1px solid blue; */
  width: 20%;
  height: 40px;
  justify-content: center;
  margin: 0.5% 0 1% 0%;
  font-size: 15px;
  /* display: flex;
  flex-direction: row; */
  /* padding: 0px 0px; */
}
.domesticheading {
  border-radius: 50px;
}

.domesticheading2 div {
  border: 2px solid white;
  width: 130px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 4%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
}

.domesticheading2 div:hover {
  background-color: white;
  color: #3380f2;
}
.viewalldomesticbuttons {
  color: white;
  font-weight: 700;
  display: flex;
  margin: 1.3% 0 0 40% !important;
  width: 18%;
}

/* .viewalldomestictext{
  margin-top: 1.5%;
} */

.viewalldomestictext{
  margin-top: 0.5%;
  border: 2px solid white;
  /* padding: 3px; */
  margin-bottom: 9%;
  margin-left: 11px;
  border-radius: 20px;
  width: 40%;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  /* padding: 0px 3px 3px 8px; */
  padding-left: 12px;
  padding-top: 5px;
}

.viewalldomestictext:hover{
  background-color: white;
  color: #3380f2;
}
.experimentheadingbuttons {
  /* border: 1px solid yellow !important; */
  justify-content: flex-end;
  margin-left: 6%;
  margin-top: 0%;
  /* margin-top: -2%; */
}

.experimentheadingbuttons button{
  color: #3380f2 !important;
  background-color: white !important;
}

.domesticslidercontainer{
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 2%;
}

.domesticcardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.domesticcard_desc {
  /* border: 1px solid red; */
  width: 90%;
  color: #3380f2;
  font-size: 13px;
}

.domesticimagetextcontainer {
  width: 100%;
  height: 440px;
}

.domestictextcard {
  margin-left: 9% !important;
  width: 100% !important;
}
/* 
    .domestictextcard:hover{
      color: white;
      background-color: #3380F2;
    } */

.domestictextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  /* border: 2px solid red !important; */
  width: 800px;
  height: 200px !important;
}
.exclusivedealsimage {
  /* border: 1px solid black !important; */
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.domesticcardimage {
  /* border: 1px solid red !important; */
  /* height: 300px; */
  border: none;
  width: 100% !important;
}

.domesticcardimage img{
  object-fit: cover;
  height: 210px;
  width: 100%;
  border-radius: 8px;
}

.domesticcarddescmain {
  /* border: 1px solid red; */
  width: 100%;
  margin-left: -9%;
  height: auto;
}

.exclusivecarddesc:hover {
  color: white;
}

/* .domesticheading2 div:hover{
      color: white;
      background-color: #3380F2;
    } */

/* .domesticheading2 p{
      width: 300px;
      color: #3380F2;
      font-weight: 500;
    } */
.experimentheadingbuttons {
  /* border: 1px solid yellow !important; */
  justify-content: flex-end;
  width: -1%;
  margin-left: 6%;
}

.line-indicator li {
  /* border: 2px solid; */
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}
.buttons {
  /* background: orangered !important; */
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
  /* border: 3px solid red; */
}
.buttons:hover {
  /* background: white !important; */
  /* color: orangered !important; */
  transform: scale(1.1);
}
.domesticbutton {
  border: 3px solid #3380f2;
  width: 35%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 12px;
  font-weight: 500;
  margin-bottom: 5% !important;
  font-size: 15px !important;
}
.domesticbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  width: 40% !important;
  font-size: 14px !important;
}
/* .carousel-control-prev{
        border: 1px solid red;
    
    } */

/* .main_container{
        border: 1px solid red;
        height: 500px;
    }
    
    .line-indicator li {
        height: 4px;
        width: 70px;
        background: lightgray;
        border-radius: 5px;
      }
      
      .line-indicator li:hover {
        background: gray;
      }
      
      .line-indicator li.slick-active {
        background: blue;
      }
      .buttons {
        background: orangered !important;
        color: white !important;
        transition: 700ms;
      }
      .buttons:hover {
        transform: scale(1.1);
      } */

/* .partners_title{
        font-size: 35px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 10%;
      margin-bottom: 4%;
      text-decoration: none solid rgb(154, 148, 148);
      vertical-align: baseline;
      letter-spacing: normal;
      text-align: center;
      color: #3380f2;
    }
    
    ._carouseldiv1{
       position: relative;
    }
    ._imagediv{
        height:100%;
        width:100%;
    }
    .slick-slide > div {
      margin: 0 10px;
    }
    .slick-list {
      margin: 0 -10px;
    }
    ._hoverdiv{
        border:1px solid green;
        position: absolute;
        top:0px;
        width:100%;
        height:85%;
        background-color: yellow;
        visibility: hidden;
    }
    ._carouseldiv1 > div:hover{
        visibility: visible;
    }
    ._staticdiv{
        border:1px solid darkblue;
        position: absolute;
        bottom:0px;
        width:100%;
        height:14.3%;
        background-color:#203e50; 
        text-align: center;
        box-sizing: border-box;
        font-size: larger;
        padding-top: 4%;
    }
    
       */

@media screen and (max-width: 950px) {
  .domesticimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewalldomesticbuttons {
    display: none;
  }
  .domesticheading1 {
    display: none;
  }
  .domesticheadingcontainer {
    flex-direction: column;
  }
  .domesticheading2 {
    width: 100%;
  }
  .domesticimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .domestictextcard {
    margin-left: 8px;
  }
}

@media screen and (max-width: 450px) {
  .domesticimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewalldomesticbuttons {
    display: inline-block;
    vertical-align: top;
    margin-top: -50px;
    text-align: right;
    width: 40%;
    font-size: 14px;
    margin-left: 52%;
  }
  .domesticheading2 {
    display: none;
  }
  .experimentheadingbuttons {
    display: none;
  }
  .domesticheading1 {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    font-size: 16px;
    margin-left: 3%;
  }
  .domesticheadingcontainer {
    flex-direction: column;
  }
  .domesticimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .domestictextcard {
    margin-left: 8px;
  }
}


/* This is a old css file */
/* .domesticmaindiv {
  width: 100%;
  margin-left: -2.5%;
  margin-top: 5%;
}

.domesticouterdivtop {
  display: flex !important;
  flex-direction: row !important;
  width: 95% !important;
  box-shadow: 4px 4px 4px rgb(211,211,211);
  border: none;
  transition: all 300ms ease-in-out;
}

.domesticouterdivtop:hover {
  width: 100%;
  padding: 5px;
  box-shadow: 5px 10px 25px  #3380f2;
}

.domesticheadingcontainer {
  display: flex;
  flex-direction: row;
  width: 105%;
  margin: auto;
  margin-top: 3%;
}

.domesticheading1 {
  font-size: 22px;
  font-weight: 600;
  width: 31%;
  color: #3380f2;
}

.domesticheading2 {
  width: 60%;
  height: 40px;
  justify-content: center;
  margin: -0.5% 0 1% 1%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
}
.domesticheading {
  border-radius: 50px;
}

.domesticheading2 div {
  border: 3px solid rgb(51, 128, 242);
  width: 130px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #3380f2;
}

.domesticheading2 div:hover {
  background-color: #3380f2;
  color: white;
}
.viewalldomesticbuttons {
  color: #3380f2;
  font-weight: 700;
  text-decoration: underline;
  display: flex;
  margin: 0% 0 0 18%;
  width: 25%;
}

.domesticcardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.domesticcard_desc {
  width: 90%;
  color: #3380f2;
  font-size: 13px;
}

.domesticimagetextcontainer {
  width: 100%;
  height: 520px;
}

.domestictextcard {
  margin-left: 9% !important;
  width: 100% !important;
}

.domestictextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}
.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.domesticcardimage {
  border: none;
  width: 100% !important;
}

.domesticcarddescmain {
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

.experimentheadingbuttons {
  justify-content: flex-end;
  width: -1%;
  margin-left: 6%;
}

.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}
.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
}
.buttons:hover {
  transform: scale(1.1);
}
.domesticbutton {
  border: 3px solid #3380f2;
  width: 30%;
  border-radius: 25px;
  background-color: white;
  color: #3380f2;
  padding: 4px 12px;
  font-weight: 500;
  margin-bottom: 1%;
  font-size: 15px !important;
}
.domesticbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}


@media screen and (max-width: 950px) {
  .domesticimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewalldomesticbuttons {
    display: none;
  }
  .domesticheading1 {
    display: none;
  }
  .domesticheadingcontainer {
    flex-direction: column;
  }
  .domesticheading2 {
    width: 100%;
  }
  .domesticimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .domestictextcard {
    margin-left: 8px;
  }
}

@media screen and (max-width: 450px) {
  .domesticimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewalldomesticbuttons {
    display: inline-block;
    vertical-align: top;
    margin-top: -50px;
    text-align: right;
    width: 40%;
    font-size: 14px;
    margin-left: 52%;
  }
  .domesticheading2 {
    display: none;
  }
  .experimentheadingbuttons {
    display: none;
  }
  .domesticheading1 {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    font-size: 16px;
    margin-left: 3%;
  }
  .domesticheadingcontainer {
    flex-direction: column;
  }
  .domesticimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .domestictextcard {
    margin-left: 8px;
  }
} */
