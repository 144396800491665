.holidayBannerContainer {
  height: 750px !important;
}

.holidayBannerImagesmaindiv img {
  height: 750px !important;
  object-fit: cover;
  filter: brightness(0.6) !important;
}

.newFlightsBannerTexts {
  width: 70%;
  height: 100px;
  z-index: 1;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 85%;
  left: 50%;
}

.newFlightsBannerTexts > p {
  color: whitesmoke;
  font-size: 40px;
  font-family: sans-serif;
}

.newFlightsBannerTexts span {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 56px;
  padding-left: 5px;
  padding-right: 5px;
  color: #3153f2;
}

@media (max-width: 480px) {
  .newFlightsBannerTexts {
    width: 85%;
    height: 100px;
    z-index: 1;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 80%;
    left: 50%;
  }

  .newFlightsBannerTexts > p {
    /* border: 1px solid red; */
    width: 100%;
    color: whitesmoke;
    font-size: 35px;
    font-family: sans-serif;
  }

  .newFlightsBannerTexts span {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 40px;
    padding-left: 5px;
    padding-right: 5px;
    color: #3153f2;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
