/* .newStaycationTopCardSliderMaincarddiv{
    border: 1px solid red;
} */

.newStaycationTopCardSliderContainer {
  /* border: 1px solid red; */
  height: 800px;
}

.newStaycationTopCardSliderMaincarddiv {
  /* border: 1px solid red; */
  height: 810px;
  /* border: none; */
  background-color: white !important;
  margin-bottom: 5%;
  /* margin-top: 25%; */
}

.newStaycationTopCardSliderHeadingouterdiv h2 {
  color: #3380f2;
  margin-left: 3.6%;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0.5%;
  margin-bottom: 2%;
}

.newStaycationTopCardSliderHeadingouterdiv {
  display: flex;
  flex-direction: row;
}

.newPopularbelowHeadingdiv {
  display: flex;
  flex-direction: row;
  color: #393939;
}

.belowInternationalheading {
  margin-left: 2%;
  width: 12%;
  font-size: 28px;
  margin-bottom: 3%;
  border-right: 2px solid lightgray;
}

.belowDomesticHeading {
  margin-left: 1.5%;
  font-size: 28px;
  /* border: 2px solid red; */
}

.newStaycationTopCardSliderSlidercontainer {
  display: flex;
  flex-direction: row;
}

.newhomeTopCititesSlidercontentdiv {
  width: 30% !important;
}

.newhomeTopCititesSlidercontentdiv h2 {
  font-size: 28px;
  margin-left: 3%;
}

/* This css refernce is taken from the TopCitites main page if any issue look at that component */
.topcitiescontainer {
  /* border: 1px solid blue; */
  width: 100% !important;
  margin-bottom: 4%;
  /* background-color: green; */
}

.topcities_title {
  display: flex;
  margin-left: 40%;
  margin-bottom: 2%;
  margin-top: -3%;
  font-size: 40px;
  color: rgb(51, 128, 242);
}

.topcities_title h2 {
  color: rgb(51, 128, 242);
  text-decoration: none solid rgb(45, 44, 44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  margin-top: 0.4%;
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.newStaycationTopCardSliderSlickslidercontainer {
  width: 100%;
  margin: auto;
}

.card-wrapper {
  /* border: 1px solid black; */
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.newStaycationTopCardSliderDiv {
  /* border: 3px solid red !important; */
  width: 100%;
  border: none;
  border-radius: 16px !important;
  /* border-bottom-left-radius: 50%; */
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.newStaycationTopCardSliderCardimage {
  top: 0;
  left: 0;
  width: 100%;
  height: 360px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* border-bottom-left-radius: 50%; */
}

.newStaycationTopCardSliderCardimage img {
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  /* border-radius: 50%; */
  -o-object-fit: cover;
  object-fit: cover;
}

.newStaycationTopCardSliderDiv:hover img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.newStaycationTopCardSliderDiv:hover .newStaycationTopCardSliderCardimage {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.newStaycationTopCardSliderDetails {
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.newStaycationTopCardSliderDetails h2 {
  margin: 0px 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.newStaycationTopCardSliderDetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  display: block;
}

.newStaycationTopCardSliderDetails button {
  margin-left: 35%;
}

.newhomeTopCitiesBottomTexts {
  margin-top: -20%;
  z-index: 100000;
}

.newhomeTopCitiesBottomTexts h2 {
  /* border: 1px solid red; */
  text-align: center;
  font-size: 20px;
}

.newhomeTopCitiesBottomTexts p {
  /* border: 1px solid red; */
  text-align: center;
  font-size: 14px;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: whitesmoke !important;
  /* background: rgb(51, 128, 242); */
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: white !important;
  background-color: blue !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}


/* this css file is for the different devices responsive */

@media (max-width: 480px) {
  .newStaycationTopCardSliderContainer {
    /* border: 1px solid red; */
    height: 800px;
  }

  .newStaycationTopCardSliderMaincarddiv {
    border: 1px solid red;
    height: 810px;
    margin-bottom: 5%;
    /* border: none; */
    background-color: white !important;
    /* margin-top: 25%; */
  }

  .newStaycationTopCardSliderHeadingouterdiv h2 {
    color: #3380f2;
    margin-left: 3.6%;
    font-size: 30px;
    font-weight: 700;
    margin-top: 0.5%;
    margin-bottom: 2%;
  }

  .newStaycationTopCardSliderHeadingouterdiv {
    display: flex;
    flex-direction: row;
  }

  .newPopularbelowHeadingdiv {
    display: flex;
    flex-direction: row;
    color: #393939;
  }

  .belowInternationalheading {
    margin-left: 2%;
    width: 12%;
    font-size: 28px;
    margin-bottom: 3%;
    border-right: 2px solid lightgray;
  }

  .belowDomesticHeading {
    margin-left: 1.5%;
    font-size: 28px;
    /* border: 2px solid red; */
  }

  .newStaycationTopCardSliderSlidercontainer {
    display: flex;
    flex-direction: row;
  }

  .newhomeTopCititesSlidercontentdiv {
    width: 30% !important;
  }

  .newhomeTopCititesSlidercontentdiv h2 {
    font-size: 28px;
    margin-left: 3%;
  }

  /* This css refernce is taken from the TopCitites main page if any issue look at that component */
  .topcitiescontainer {
    /* border: 1px solid blue; */
    width: 100% !important;
    margin-bottom: 4%;
    /* background-color: green; */
  }

  .topcities_title {
    display: flex;
    margin-left: 40%;
    margin-bottom: 2%;
    margin-top: -3%;
    font-size: 40px;
    color: rgb(51, 128, 242);
  }

  .topcities_title h2 {
    color: rgb(51, 128, 242);
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    margin-top: 0.4%;
    font-size: 28px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  .newStaycationTopCardSliderSlickslidercontainer {
    width: 100%;
    margin: auto;
  }

  .card-wrapper {
    /* border: 1px solid black; */
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .newStaycationTopCardSliderDiv {
    /* border: 3px solid red !important; */
    margin-bottom: 7%;
    width: 100%;
    border: none;
    border-radius: 16px !important;
    /* border-bottom-left-radius: 50%; */
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .newStaycationTopCardSliderCardimage {
    top: 0;
    left: 0;
    width: 100%;
    height: 360px !important;
    z-index: 2;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /* border-bottom-left-radius: 50%; */
  }

  .newStaycationTopCardSliderCardimage img {
    width: 100%;
    height: 100%;
    filter: brightness(0.6);
    /* border-radius: 50%; */
    -o-object-fit: cover;
    object-fit: cover;
  }

  .newStaycationTopCardSliderDiv:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .newStaycationTopCardSliderDiv:hover .newStaycationTopCardSliderCardimage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .newStaycationTopCardSliderDetails {
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: white;
  }

  .newStaycationTopCardSliderDetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .newStaycationTopCardSliderDetails h2 .job-title {
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    display: block;
  }

  .newStaycationTopCardSliderDetails button {
    margin-left: 35%;
  }

  .newhomeTopCitiesBottomTexts {
    margin-top: -20%;
    z-index: 100000;
  }

  .newhomeTopCitiesBottomTexts h2 {
    /* border: 1px solid red; */
    text-align: center;
    font-size: 20px;
  }

  .newhomeTopCitiesBottomTexts p {
    /* border: 1px solid red; */
    text-align: center;
    font-size: 14px;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }

  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    background: whitesmoke !important;
    /* background: rgb(51, 128, 242); */
    border: 1px solid rgb(51, 128, 242);
    border-radius: 20px;
  }

  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: white !important;
    background-color: blue !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }

  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .carousel .slick-next {
    right: -55px;
  }

  .carousel .slick-prev {
    left: -55px;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
