.carRentalsMainOuterDiv {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 3%;
}

.carRentalsReviewHeading h3 {
  font-size: 25px;
  line-height: 20px;
  font-weight: 600;
  vertical-align: baseline;
}

.carRentalsReviewMainCOntainer {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.carRentalsReviewInnerDiv1 {
  /* border: 1px solid rebeccapurple; */
  width: 50%;
}

.rentalsReviewImageContentDiv {
  border-right: 1px solid gray;
  margin-top: 4%;
}

.rentalsReviewImageContentDiv img {
  object-fit: cover;
  width: 25%;
  height: 35px;
  margin-left: 3%;
  margin-bottom: 2%;
}

.rentalsReviewImageContentDiv p {
  /* border: 1px solid red; */
  margin-left: 3%;
  font-weight: 400;
  font-size: 15px;
  color: #393939;
}

.rentalsReviewImageContentDiv button {
  margin-left: 3%;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
}

/* .rentalsReviewRatingDiv{
    border: 1px solid black;
} */

.rentalsReviewRatingDiv h5 {
  font-weight: 600;
  font-size: 25px;
}

.reviewSpan1 {
  border-right: 1.5px solid black;
  font-size: 18px;
}

.reviewSpan2 {
  color: #393939;
  font-size: 18px;
  margin-left: 2%;
}

.carRentalsReviewInnerDiv2 {
  /* border: 1px solid red; */
  width: 50%;
}

.carRentalsReviewProgressBarDiv {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.rentalsProgressContentDiv {
  /* border: 1px solid red; */
  width: 40%;
}

.rentalsProgressContentDiv p {
  font-weight: 600;
}

/* .progressInnerDiv{
    border: 2px solid black;
} */

.cleanProgress {
  /* border: 1px solid red; */
  width: 50%;
  /* margin-left: 5%; */
  margin-top: 1%;
}

/* .rentalsReviewShowAll{
    border: 1px solid red;
} */

.rentalsReviewShowAll p {
  color: #3380f2;
  font-size: 18px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
  margin-top: 3%;
  margin-bottom: 4%;
}

.rentalsReviewShowAll p:hover {
  color: #393939;
  cursor: pointer;
}
