.rentalsBookingSupplierOuterDiv {
  border: 1px solid gray;
  width: 66.5%;
  /* margin-left: 5% */
  margin-top: 6%;
  border-radius: 7px;
  top: 45%;
  position: absolute;
}

.rentalsBookingSupplierHeading {
  width: 95%;
  margin: auto;
  margin-top: 3%;
}

.rentalsBookingSupplierHeading h3 {
  font-size: 25px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
}

/* .rentalsBookingContentMainDiv{
    border: 1px solid red;
} */

.rentalsBookingContentInnerDiv1 {
  width: 95%;
  margin: auto;
  margin-top: 1%;
}

.rentalsBookingContentInnerDiv1 p {
  font-size: 17px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
}

.rentalsBookingContentInnerBookingHours {
  /* border: 1px solid red; */
  width: 95%;
  margin: auto;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}

.rentalsBookingContentHeading1 {
  width: 51%;
  font-size: 17px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
}

.rentalsInnerBookingHoursInnerDiv {
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
}

.bookingHourInnerContent1 {
  color: #3380f2;
  font-size: 15px;
}

.bookingHourInnerContent2 {
  /* border: 1px solid red; */
  width: 100%;
  font-weight: 200;
  font-size: 14px;
  color: #393939;
}

.rentalsSupplierContactNumberDiv {
  /* border: 1px solid red; */
  width: 95%;
  margin: auto;
}

.rentalsSupplierContactNumberDiv p {
  font-size: 17px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
  width: 30%;
}

.rentalsSupplierContactNumberDiv p span {
  /* border: 1px solid black; */
  margin-left: 23%;
  font-weight: 200;
  font-size: 14px;
  color: #393939;
}

.rentalsBoookingPickUpInstructionMainDiv {
  /* border: 1px solid red; */
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.rentalsBoookingPickUpInstructionMainDiv p {
  font-size: 17px;
  font-weight: 600;
  vertical-align: baseline;
  line-height: 20px;
  width: 30%;
}
