* {
    margin: 0;
  }
  .BottomBarSection {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 90px;
    z-index: 1;
    box-sizing: border-box;
    color: white;
    background: #3380f2;  
    background-size: 200% 200%;
  }
  .BottomBarSection ul {
    margin-top: 3% !important;
    font-weight: 600;
    display: flex;
    list-style-type: none;
    text-decoration: none;
    margin: 0px 0px 0 0;
  }
  .BottomBarSection ul li {
    margin: 0 20px 15px 20px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
  }
  
  .BottomBarSection ul li:hover{
    color: blue;
  }

  #checkbox {
    display: none;
  }
  .bars {
    display: none;
  }
  
  .logo > .logo-text{
    margin-top: 8%;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    line-height: 45px;
  }
  
  .logo > img{
    border: 1px solid green;
  }
  
  #searchBar {
    font-size: 30px;
    position: absolute;
    z-index: 1;
    color: white;
    right: 125px;
    top: 28px;
  }
  
  