.wayToRentCarMainDiv {
  width: 90%;
  margin: auto;
}

.wayToRentCarInnerDiv {
  border-radius: 25px;
}

.wayToRentCarInnerDiv img {
  filter: brightness(0.6);
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.rentCarContentDiv {
  width: 50%;
  margin: auto;
  text-align: center;
}

.rentCarContentDiv h5 {
  font-weight: 600;
  font-size: 30px;
  margin-top: 10%;
  color: white;
}

.rentCarContentDiv p {
  font-weight: 600;
  font-size: 16px;
}

/* this is the css code responsive of the comps for the multiple devices */

@media (max-width: 480px) {
  .wayToRentCarMainDiv {
    width: 90%;
    margin: auto;
  }

  .wayToRentCarInnerDiv {
    border-radius: 25px;
  }

  .wayToRentCarInnerDiv img {
    filter: brightness(0.6);
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .rentCarContentDiv {
    /* border: 1px solid red; */
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .rentCarContentDiv h5 {
    font-weight: 600;
    font-size: 22px;
    margin-top: 20%;
    color: white;
  }

  .rentCarContentDiv p {
    font-weight: 400;
    font-size: 16px;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
