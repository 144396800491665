.InnerHandPicked2VideoText-Content-Footer img{
    width:100%;
    height:600px;
    opacity:0.5;
}

.InnerHandPicked2VideoText-Content-Items{
    position:relative;
    margin-top: -34%;
    margin-left: 6%;
}

.InnerHandPicked2VideoText-Content-Footer3{
    position:absolute;
    margin-top: -27.5%;
    margin-left:55%;
    background-color:lightcoral;
    color:white;
    font-weight:400;
    padding:2px;
    font-size:medium;
    height: 420px;
}

/* .InnerHandPicked2VideoText-Content-Footer3 h3{
    position:absolute;
    margin-top:-40%;
} */

