/* .hotelInnerImageSlider{
    border: 2px solid red;
    text-align: center;
    margin-top:30px ;
  } */

  .hotelInnerImageSlider{

    position: relative;
  }
  .hotelInnedMainImageDiv{
    /* border: 2px solid red; */
    height: 400px;
    width: 100%;
    object-fit: cover;
    /* background-size: cover; */
  }

  .flex_row{
    position: absolute !important;
    border: 2px solid red;
    /* justify-content: center; */
    display: flex;
    /* padding-top: 20px; */
  }
  
.thumbnail{
  padding-left: 20px;
}
.clicked{
  border: 5px solid black;
}