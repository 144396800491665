.popularDestinationsheadingdiv {
  color: #3380f2;
  height: 50px;
  font-size: 35px;
  font-weight: 700;
  margin-top: 2%;
  width: 100%;
  /* border: 2px solid red; */
  padding-left: 5%;
  display: flex;
  flex-direction: row;
}

.newHomePopularDestinationDropContainer {
  width: 9% !important;
  margin-left: 58.5%;
  margin-top: 2.1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  background-color: #3380f2 !important;
  display: flex;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  border: 2px solid #3380f2;
  outline: none;
  font-size: 15px;
  padding-left: 15px;
  padding-right: 10px !important;
}

.popularDestinationsselectoptions {
  border: 2px solid gold !important;
  width: 90%;
  margin: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.popularDestinationselect1 {
  border: none;
  border-bottom: 2px solid #3380f2;
  margin-left: 15%;
  color: #3380f2;
  font-size: 25px;
  font-weight: 500;
  width: 14%;
}

.populardestinationselectdiv {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-left: 35%;
}

.popularDestinationselect2 {
  border: 2px solid #3380f2;
  background-color: #3380f2;
  color: white;
  width: 27%;
  margin-left: 69%;
  font-size: 21px;
  height: 40px;
  outline: none !important;
  border-radius: 25px 0px 0px 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: 1.5%;
  padding-right: 1.5% !important;
  text-decoration: none;
  font-weight: 700;
  /* appearance: none; */
}

.populardestinationsicondiv {
  width: 20%;
  color: white;
  border: 3px solid #3380f2;
  background-color: #3380f2;
  height: 39px;
  /* font-size: 18px !important; */
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
  /* border: 2px solid yellow; */
}

.populardestinationsicon {
  margin-left: -37%;
  /* width: 100% !important; */
  color: #3380f2;
  border: 3px solid #3380f2;
  background-color: white;
  height: 25px;
  width: 25px;
  font-size: 18px !important;
  border-radius: 100%;
  margin-top: -140% !important;
  /* margin-bottom: 105%;*/
}

.mainCarouselDiv {
  width: 90%;
  height: 500px;
  position: relative;
  margin: auto;
  margin-bottom: 2%;
}

.sliderDiv {
  width: 98%;
  height: 400px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 53%;
  left: 50%;
  margin-top: -3%;
}

.div3d {
  background-color: blueviolet;
  width: 300px;
}

.populardestinationsslide {
  /* border: 1px solid red; */
  width: 105% !important;
  height: 410px;
  text-align: center;
  transform: scale(0.8);
  transition: 0.7s;
  border-radius: 20px;
}

.populardestinationsslide {
  margin-left: 3%;
  width: 102%;
  border-radius: 25px !important;
  overflow: hidden !important;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.populardestinationimagestransition > img {
  width: 100%;
  height: 100%;
  filter: brightness(0.5) !important;
  border-radius: 25px;
}

.populardestinationsslide:hover .populardestinationimagestransition > img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.populardestinationimagestransition {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border-radius: 20px !important;
  z-index: -1;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.populardestinationimagestransition img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  position: relative;
}

.populardestinationsslide > h5 {
  /* border: 1px solid red; */
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* text-align: center !important; */
  color: white;
  font-weight: 700;
  font-size: 25px;
  line-height: 26.4px;
  vertical-align: baseline;
  text-transform: capitalize;
  font-variant: normal;
  margin-top: -57%;
  /* margin-left: 32%; */
  z-index: 2;
  position: absolute;
}

.populardestinationsslide > p {
  /* border: 1px solid red; */
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  vertical-align: baseline;
  letter-spacing: normal;
  margin-top: -46%;
  /* margin-left: 30%; */
  text-align: center;
  z-index: 2;
  position: absolute;
}

.populardestinationsslide > h6 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  vertical-align: baseline;
  letter-spacing: normal;
  margin-top: -31%;
  z-index: 2;
  position: absolute;
}

.populardestinationsslide > h3 {
  padding: 5px;
  text-align: left;
  color: white;
  font-weight: 100;
  font-size: 17px;
  margin-top: -22%;
  margin-left: 18%;
  z-index: 2;
  position: absolute;
}

.newFlightsPrice {
  width: 100%;
  /* padding: 5px; */
  text-align: left;
  color: white;
  font-size: 21px;
  margin-top: -24%;
  margin-left: 66% !important;
  font-weight: bold !important;
  z-index: 2;
  /* position: absolute; */
}

.populardestinationsslide-active {
  width: 105% !important;
  height: 420px;
  filter: blur(0);
  opacity: 1;
  transform: scale(1);
  /* border: 2px solid red; */
  border-radius: 20px;
}

/* -----------------------New Button Css --------------------------- */

#popularDestinationsDropContainer {
  width: 9.5%;
  margin-left: 60%;
  /* margin-top: 2%; */
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  /* border: 2px solid #3380F2; */
  outline: none;
  padding-left: 15px;
}

#popularDestinationsCountryDrop {
  background-color: #3380f2 !important;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 15px;
}

#popularDestinationsicondiv {
  width: 20%;
  color: white;
  background-color: #3380f2;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

#popularDestinationsicon {
  margin-left: -37%;
  color: white;
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: -106%;
}

@media (max-width: 480px) {
  .popularDestinationsheadingdiv {
    color: #3380f2;
    height: 50px;
    margin-top: 2%;
    width: 100%;
    /* border: 2px solid red; */
    padding-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .popularDestinationHeadingDiv {
    /* border: 1px solid red; */
    width: 90%;
    margin-top: 2%;
    text-align: center;
  }

  .popularDestinationHeadingDiv > p {
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    vertical-align: baseline;
    color: #393939;
  }

  #popularDestinationsDropContainer {
    width: 35%;
    margin-left: 4%;
    /* margin-top: 2%; */
    display: none;
    flex-direction: row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white !important;
    font-weight: 700;
    justify-content: space-between;
    /* border: 2px solid #3380F2; */
    outline: none;
    padding-left: 15px;
  }

  #popularDestinationsCountryDrop {
    background-color: #3380f2;
    width: 80%;
    height: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 15px;
  }

  #popularDestinationsicondiv {
    width: 20%;
    color: white;
    background-color: #3380f2;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  #popularDestinationsicon {
    color: white;
    height: 21px;
    width: 20px;
    border-radius: 100%;
  }

  .mainCarouselDiv {
    width: 90%;
    height: 470px;
    position: relative;
    margin: auto;
    margin-bottom: 10%;
    /* border: 2px solid red; */
  }
  .populardestinationimagestransition{
    /* border: 2px solid yellow; */
    width: 100%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
