.all_hotel_card_div {
  display: flex !important;
}

.cardtextendbuttons {
  /* border: 1px solid red; */
  width: 20%;
}

.cardtextFavStaycationDropbutton {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.cardtextFavStaycationDropbutton h2 {
  color: #3380f2;
  font-weight: 600;
  margin-top: 2%;
  margin-left: 6%;
  font-size: 35px;
  /* border: 2px solid red; */
}

.newFavStaycationmaindiv {
  /* border: 1px solid red; */
  width: 100%;
  height: 500px !important;
  /* background-color: #3380f2; */
  opacity: 0.9;
  object-fit: cover !important;
  /* background-image: url(/publichttps://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/BackgroundImages/HotelServicesBackground.png); */
}

.slick-slide {
  width: 280px;
}

.newFavStaycationSlidercontainer .hotelservicesslickDots {
  bottom: -2.5vw;
}

.newFavStaycationSlidercontainer .hotelservicesslickDots li button {
  width: 20px;
  height: 20px;
  background: white !important;
  /* background: rgb(51, 128, 242); */
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.newFavStaycationSlidercontainer .hotelservicesslickDots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: white !important;
  background-color: blue !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.activityaroundouterdiv {
  /* border: 2px solid red !important; */
  height: 460px !important;
  /* border: 2px solid lightgray; */
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  /* border: none; */
}

.bestFavStaycationHeadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* background-color: #3380f2; */
  height: 80px;
  margin: auto;
  /* margin-top: 2%; */
  /* border: 1px solid black; */
}

.activityaroundheading1 {
  /* border: 1px solid black; */
  font-size: 25px;
  font-weight: 600;
  width: 30%;
  margin: auto;
  margin-left: 5.5% !important;
  color: white;
}

.newFavStaycationDotsdiv .slick-dots {
  bottom: -2.5vw;
  /* border: 1px solid red !important; */
  margin-top: 1% !important;
}

.newFavStaycationDotsdiv .slick-dots li {
  /* border: 1px solid red !important; */
  bottom: 2%;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: white !important;
}

.newFavStaycationDotsdiv .slick-dots li button:active {
  background-color: blue !important;
}

.newFavStaycationDotsdiv .slick-dots li:before {
  position: absolute;
  font-size: 20px;
  top: 60%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 210px;
  content: ".";
  text-align: center;
  opacity: 0.5;
  /* color: #000; */
  background-color: white !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.experimentheading2 {
  /* border: 1px solid blue; */
  width: 60%;
  height: 40px;
  justify-content: center;
  margin: -0.5% 0 1% 1%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  /* padding: 0px 0px; */
}

.experimentheading {
  border-radius: 50px;
}

.experimentheading2 div {
  border: 3px solid rgb(51, 128, 242);
  width: 100px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #3380f2;
}

.experimentheading2 div:hover {
  background-color: #3380f2;
  color: white;
}

.viewallactivityhotelcardbuttons {
  color: white;
  font-weight: 700;
  display: flex;
  margin: 6.5% 2% 0 5% !important;
  width: 55% !important;
  margin-left: 40% !important;
}

.viewallhomehotelcardtext {
  margin-top: 0px !important;
  border: 2px solid white;
  border-radius: 20px;
  height: 38px !important;
  width: 57% !important;
  font-size: 14px;
  height: 30px;
  font-weight: 700;
  padding-left: 15px !important;
  padding-top: 5.3px !important;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.viewallhomehotelcardtext:hover {
  background-color: white;
  color: #3380f2;
}

.newFavStaycationSlidercontainer {
  width: 90%;
  margin: auto;
  margin-top: 2%;
}

.activityaroundcardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.activityaroundcard_desc {
  width: 90%;
  color: #3380f2;
  font-size: 13px;
  text-align: left;
}

.activityaroundimagetextcontainer {
  width: 100%;
  height: 440px !important;
}

.activityaroundtextcard {
  margin-left: 9% !important;
  width: 100% !important;
}

.activityaroundtextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}

.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.activityaroundcardimage {
  border: none;
  width: 100% !important;
}

.activityaroundcarddescmain {
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

.activityaroundheadingbuttons {
  justify-content: flex-end;
  margin-left: 6%;
  margin-top: -2%;
}

.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}

.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
  border: 3px solid red;
}

.buttons:hover {
  transform: scale(1.1);
}

.activityaroundbutton {
  border: 3px solid #3380f2;
  width: 34%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 12px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: 4%;
  font-size: 15px !important;
}

.activityaroundbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}

.newFavStaycationmaindiv {
  /* border: 1px solid red; */
  width: 100%;
}

.activityaroundouterdiv {
  /* border: 2px solid red !important; */
  height: 460px !important;
  /* border: 2px solid lightgray; */
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  /* border: none; */
}

.bestFavStaycationHeadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  margin: auto;
  /* margin-top: 2%; */
  /* border: 1px solid black; */
}

.activityaroundheading1 {
  /* border: 1px solid black; */
  font-size: 25px;
  font-weight: 600;
  width: 30%;
  margin: auto;
  margin-left: 5.5% !important;
  color: white;
}

/* .viewallhomehotelcardtext{
      
      } */
.experimentheading2 {
  /* border: 1px solid blue; */
  width: 60%;
  height: 40px;
  justify-content: center;
  margin: -0.5% 0 1% 1%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  /* padding: 0px 0px; */
}
.experimentheading {
  border-radius: 50px;
}

.experimentheading2 div {
  border: 3px solid rgb(51, 128, 242);
  width: 100px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #3380f2;
}

.experimentheading2 div:hover {
  background-color: #3380f2;
  color: white;
}
.viewallactivityhotelcardbuttons {
  color: white;
  font-weight: 700;
  /* text-decoration: underline; */
  /* border: 1px solid red; */
  display: flex;
  margin: 47.5% 2% 0 5%;
  width: 65%;
  margin-left: 70% !important;
}

.viewallhomehotelcardtext {
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 38%;
  font-size: 14px;
  font-weight: 700;
  padding-left: 6px;
  padding-top: 2.3px;
  margin-bottom: 9%;
  text-decoration: none !important;
}

.viewallhomehotelcardtext:hover {
  background-color: white;
  color: #3380f2;
  padding-top: 1%;
}
.newFavStaycationSlidercontainer {
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 2%;
  /* background-color: #3380f2; */
}

.activityaroundcardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.activityaroundcard_desc {
  /* border: 1px solid red; */
  width: 90%;
  color: #3380f2;
  font-size: 13px;
  text-align: left;
}

.activityaroundimagetextcontainer {
  width: 100%;
  height: 440px !important;
}

.activityaroundtextcard {
  margin-left: 9% !important;
  width: 100% !important;
}

.activityaroundtextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  /* border: 2px solid red !important; */
  width: 800px;
  height: 200px !important;
}
.exclusivedealsimage {
  /* border: 1px solid black !important; */
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.activityaroundcardimage {
  /* border: 1px solid red !important; */
  /* height: 300px; */
  border: none;
  width: 100% !important;
}

.activityaroundcarddescmain {
  /* border: 1px solid red; */
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

.activityaroundheadingbuttons {
  /* border: 1px solid yellow !important; */
  justify-content: flex-end;
  /* width: -1%; */
  margin-left: 6%;
  margin-top: -2%;
}

.line-indicator li {
  /* border: 2px solid; */
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}
.buttons {
  /* background: orangered !important; */
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
  /* border: 3px solid red; */
}
.buttons:hover {
  /* background: white !important; */
  /* color: orangered !important; */
  transform: scale(1.1);
}
.activityaroundbutton {
  border: 3px solid #3380f2;
  width: 34%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 12px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: 4%;
  font-size: 15px !important;
}
.activityaroundbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}

/* This css is for the card reference taken from the hotels page */
.hotelroomfilterdrop {
  /* border: 2px solid red; */
  display: flex;
  margin-top: 1.2%;
  margin-left: 5%;
}

.hotelroomfilterdrop h5 {
  margin-top: 2%;
  color: white;
  font-size: 19px;
  font-weight: 700;
}

.hoteltop-heading {
  /* border: 1px solid red; */
  /* margin-bottom: 0.5%; */
  margin-top: 3.5%;
  margin-right: 55%;
  text-align: left !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 43.2px;
  color: white;
  width: 100%;
  /* color: rgb(51, 128, 242); */
}

.hotelhomecardtextheading {
  /* border: 2px solid red !important; */
  /* background-color: red; */
  width: 26% !important;
  margin-left: 24%;
  text-align: left;
}

.home_hotelmaincard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 88%;
  margin: auto;
}

.hotelmaincardouter {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 400px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.cardtextFavStaycation_maindiv {
  width: 100% !important;
  height: 355px;
  position: relative;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 18px;
  justify-content: space-around;
}

.cardtextFavStaycation_maindiv:hover {
  transform: scale(1.05);
  cursor: pointer;
  height: 350px;
  transition: 0.3s ease-in-out;
}

.reviewratingFavStaycationSection {
  background-color: white;
  width: 40%;
  height: 35px;
  /* border: 1px solid red; */
  font-size: 12px;
  top: 25px;
  right: 10px !important;
  display: flex;
  position: absolute;
  /* color: rgb(244, 244, 249); */
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  z-index: 10;
}

.cardtextFavStaycation_maindiv img {
  width: 100%;
  height: 270px;
  border-radius: 10px 10px 0px 0px;
}

.cardtextFavStaycation_maindiv > img:hover {
  /* transform: scale(0.9); */
  overflow: hidden;
}

.cardFavStaycation_title {
  color: #3380f2;
  display: flex;
  flex-direction: row;
  width: 97%;
  height: 50px;
  margin-top: 5%;
  font-size: 15px;
  font-weight: 700;
  margin-left: 3%;
}

.cardFavStaycation_title h5 {
  font-size: 18px;
  margin-top: 0%;
  margin-left: 5%;
  font-weight: 700;
  /* border: 1px solid blue; */
  width: 50%;
}

.favStaycationtitle_rating {
  color: #3380f2;
  margin-left: -51%;
  margin-top: 8%;
  width: 50%;
  font-size: 13px;
}

.staricon {
  margin-left: 6%;
}

.favStaycationRatesdiv {
  border: 1px solid #3380f2;
  width: 62%;
  background-color: #3380f2;
  margin-left: 110%;
  height: 40px;
  border-radius: 7px;
  margin-top: -25%;
}

.favStaycationRatesdiv p {
  color: white;
  font-weight: 700;
  font-size: 13px;
  padding-top: 10px;
  padding-left: 20px;
}

.hoteldicsountprice {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.review_rating_num {
  color: white;
  margin-left: -40px;
  margin-right: 20px;
  margin-top: -6px;
}

.hoteldiscountpercent {
  border: 2px solid #3380f2;
  border-radius: 5px;
  color: #3380f2;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.hotelPricebeforediscount {
  text-decoration: line-through;
  font-size: 13px;
}

.hotel_Priceafterdiscount {
  color: #3380f2;
  font-weight: bold;
  font-size: 13px;
}

.Favouite_hotels {
  color: #3380f2;
  margin-left: 38%;
  font-weight: 700;
}

.reviwe {
  background-color: white;
  width: 150px;
  height: 50px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: -82px;
  margin-right: -60px;
  z-index: 10;
}

.reviwe_rating_num {
  color: white;
  margin-left: -40px;
  margin-right: 20px;
  margin-top: -6px;
}

/* ----------------- New button css ----------------  */

#newFavStaycationDropContainer {
  width: 9% !important;
  margin-left: 51%;
  margin-top: 2.1%;
  display: flex;
  flex-direction: 0.5 row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white !important;
  font-weight: 700;
  justify-content: space-between;
  /* border: 2px solid #3380F2; */
  outline: none;
  padding-left: 15px;
}

#newFavStaycationCountryDrop {
  background-color: #3380f2 !important;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 15px;
}

#newFavStaycationicondiv {
  width: 20%;
  color: white;
  background-color: #3380f2;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

#newFavStaycationicon {
  margin-left: -37%;
  color: white;
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: 47%;
}

/* this css is for the different devices for the comps to be responsive */
@media (max-width: 480px) {
  .all_hotel_card_div {
    display: flex !important;
  }

  .cardtextendbuttons {
    /* border: 1px solid red; */
    width: 20%;
  }

  .cardtextFavStaycationDropbutton {
    /* border: 1px solid red; */
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    text-align: center; 
  }

  .cardtextFavStaycationDropbutton h2 {
    /* border: 1px solid red; */
    width: 100%;
    color: black;
    font-weight: 600;
    margin-top: 4%;
    margin-left: 6%;
    font-size: 25px;
    text-align: center;
    /* border: 2px solid red; */
  }

  .newFavStaycationmaindiv {
    /* border: 1px solid red; */
    width: 100%;
    height: 500px !important;
    /* background-color: #3380f2; */
    opacity: 0.9;
    object-fit: cover !important;
    /* background-image: url(/publichttps://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/BackgroundImages/HotelServicesBackground.png); */
  }

  .slick-slide {
    width: 280px;
  }

  .newFavStaycationSlidercontainer .hotelservicesslickDots {
    bottom: -2.5vw;
  }

  .newFavStaycationSlidercontainer .hotelservicesslickDots li button {
    width: 20px;
    height: 20px;
    background: white !important;
    /* background: rgb(51, 128, 242); */
    border: 1px solid rgb(51, 128, 242);
    border-radius: 20px;
  }

  .newFavStaycationSlidercontainer .hotelservicesslickDots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: white !important;
    background-color: blue !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .activityaroundouterdiv {
    /* border: 2px solid red !important; */
    height: 460px !important;
    /* border: 2px solid lightgray; */
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    /* border: none; */
  }

  .bestFavStaycationHeadingcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: #3380f2; */
    height: 50px;
    margin: auto;
    /* margin-top: 2%; */
    /* border: 1px solid black; */
  }

  .activityaroundheading1 {
    /* border: 1px solid black; */
    font-size: 25px;
    font-weight: 600;
    width: 30%;
    margin: auto;
    margin-left: 5.5% !important;
    color: white;
  }

  .newFavStaycationDotsdiv .slick-dots {
    bottom: -2.5vw;
    /* border: 1px solid red !important; */
    margin-top: 1% !important;
  }

  .newFavStaycationDotsdiv .slick-dots li {
    /* border: 1px solid red !important; */
    bottom: 2%;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
    background-color: white !important;
  }

  .newFavStaycationDotsdiv .slick-dots li button:active {
    background-color: blue !important;
  }

  .newFavStaycationDotsdiv .slick-dots li:before {
    position: absolute;
    font-size: 20px;
    top: 60%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 210px;
    content: ".";
    text-align: center;
    opacity: 0.5;
    /* color: #000; */
    background-color: white !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .experimentheading2 {
    /* border: 1px solid blue; */
    width: 60%;
    height: 40px;
    justify-content: center;
    margin: -0.5% 0 1% 1%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    /* padding: 0px 0px; */
  }

  .experimentheading {
    border-radius: 50px;
  }

  .experimentheading2 div {
    border: 3px solid rgb(51, 128, 242);
    width: 100px !important;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5%;
    margin-right: 4%;
    border-radius: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    color: #3380f2;
  }

  .experimentheading2 div:hover {
    background-color: #3380f2;
    color: white;
  }

  .viewallactivityhotelcardbuttons {
    color: white;
    font-weight: 700;
    display: flex;
    margin: 6.5% 2% 0 5% !important;
    width: 55% !important;
    margin-left: 40% !important;
  }

  .viewallhomehotelcardtext {
    margin-top: 0px !important;
    border: 2px solid white;
    border-radius: 20px;
    height: 38px !important;
    width: 57% !important;
    font-size: 14px;
    height: 30px;
    font-weight: 700;
    padding-left: 15px !important;
    padding-top: 5.3px !important;
    margin-bottom: 8%;
    text-decoration: none !important;
  }

  .viewallhomehotelcardtext:hover {
    background-color: white;
    color: #3380f2;
  }

  .newFavStaycationSlidercontainer {
    width: 90%;
    margin: auto;
    margin-top: 0%;
  }

  .activityaroundcardtitle {
    color: #3380f2 !important;
    font-size: 22px;
    margin-top: 1%;
  }

  .activityaroundcard_desc {
    width: 90%;
    color: #3380f2;
    font-size: 13px;
    text-align: left;
  }

  .activityaroundimagetextcontainer {
    width: 100%;
    height: 440px !important;
  }

  .activityaroundtextcard {
    margin-left: 9% !important;
    width: 100% !important;
  }

  .activityaroundtextcard:hover div {
    color: white;
  }

  .exclusivedealsimagediv {
    width: 800px;
    height: 200px !important;
  }

  .exclusivedealsimage {
    width: 700px;
    height: 10px;
    margin-left: 40%;
  }

  .activityaroundcardimage {
    border: none;
    width: 100% !important;
  }

  .activityaroundcarddescmain {
    width: 100%;
    margin-left: -9%;
  }

  .exclusivecarddesc:hover {
    color: white;
  }

  .activityaroundheadingbuttons {
    justify-content: flex-end;
    margin-left: 6%;
    margin-top: -2%;
  }

  .line-indicator li {
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }

  .buttons {
    background: rgb(51, 128, 242) !important;
    color: white !important;
    transition: 700ms;
    border: 3px solid red;
  }

  .buttons:hover {
    transform: scale(1.1);
  }

  .activityaroundbutton {
    border: 3px solid #3380f2;
    width: 34%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 4px 12px;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 4%;
    font-size: 15px !important;
  }

  .activityaroundbutton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    font-size: 14px !important;
  }

  .newFavStaycationmaindiv {
    /* border: 1px solid red; */
    width: 100%;
  }

  .activityaroundouterdiv {
    /* border: 2px solid red !important; */
    height: 460px !important;
    /* border: 2px solid lightgray; */
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    /* border: none; */
  }

  .activityaroundheading1 {
    /* border: 1px solid black; */
    font-size: 25px;
    font-weight: 600;
    width: 30%;
    margin: auto;
    margin-left: 5.5% !important;
    color: white;
  }

  /* .viewallhomehotelcardtext{
        
        } */
  .experimentheading2 {
    /* border: 1px solid blue; */
    width: 60%;
    height: 40px;
    justify-content: center;
    margin: -0.5% 0 1% 1%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    /* padding: 0px 0px; */
  }
  .experimentheading {
    border-radius: 50px;
  }

  .experimentheading2 div {
    border: 3px solid rgb(51, 128, 242);
    width: 100px !important;
    height: 33px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5%;
    margin-right: 4%;
    border-radius: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    color: #3380f2;
  }

  .experimentheading2 div:hover {
    background-color: #3380f2;
    color: white;
  }
  .viewallactivityhotelcardbuttons {
    color: white;
    font-weight: 700;
    /* text-decoration: underline; */
    /* border: 1px solid red; */
    display: flex;
    margin: 47.5% 2% 0 5%;
    width: 65%;
    margin-left: 70% !important;
  }

  .viewallhomehotelcardtext {
    margin-top: 0.5%;
    border: 2px solid white;
    border-radius: 20px;
    width: 38%;
    font-size: 14px;
    font-weight: 700;
    padding-left: 6px;
    padding-top: 2.3px;
    margin-bottom: 9%;
    text-decoration: none !important;
  }

  .viewallhomehotelcardtext:hover {
    background-color: white;
    color: #3380f2;
    padding-top: 1%;
  }
  .newFavStaycationSlidercontainer {
    /* border: 2px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 2%;
    /* background-color: #3380f2; */
  }

  .activityaroundcardtitle {
    color: #3380f2 !important;
    font-size: 22px;
    margin-top: 1%;
  }

  .activityaroundcard_desc {
    /* border: 1px solid red; */
    width: 90%;
    color: #3380f2;
    font-size: 13px;
    text-align: left;
  }

  .activityaroundimagetextcontainer {
    width: 100%;
    height: 440px !important;
  }

  .activityaroundtextcard {
    margin-left: 9% !important;
    width: 100% !important;
  }

  .activityaroundtextcard:hover div {
    color: white;
  }

  .exclusivedealsimagediv {
    /* border: 2px solid red !important; */
    width: 800px;
    height: 200px !important;
  }
  .exclusivedealsimage {
    /* border: 1px solid black !important; */
    width: 700px;
    height: 10px;
    margin-left: 40%;
  }

  .activityaroundcardimage {
    /* border: 1px solid red !important; */
    /* height: 300px; */
    border: none;
    width: 100% !important;
  }

  .activityaroundcarddescmain {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: -9%;
  }

  .exclusivecarddesc:hover {
    color: white;
  }

  .activityaroundheadingbuttons {
    /* border: 1px solid yellow !important; */
    justify-content: flex-end;
    /* width: -1%; */
    margin-left: 6%;
    margin-top: -2%;
  }

  .line-indicator li {
    /* border: 2px solid; */
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }
  .buttons {
    /* background: orangered !important; */
    background: rgb(51, 128, 242) !important;
    color: white !important;
    transition: 700ms;
    /* border: 3px solid red; */
  }
  .buttons:hover {
    /* background: white !important; */
    /* color: orangered !important; */
    transform: scale(1.1);
  }
  .activityaroundbutton {
    border: 3px solid #3380f2;
    width: 34%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 4px 12px;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 4%;
    font-size: 15px !important;
  }
  .activityaroundbutton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    font-size: 14px !important;
  }

  /* This css is for the card reference taken from the hotels page */
  .hotelroomfilterdrop {
    /* border: 2px solid red; */
    display: flex;
    margin-top: 1.2%;
    margin-left: 5%;
  }

  .hotelroomfilterdrop h5 {
    margin-top: 2%;
    color: white;
    font-size: 19px;
    font-weight: 700;
  }

  .hoteltop-heading {
    /* border: 1px solid red; */
    /* margin-bottom: 0.5%; */
    margin-top: 3.5%;
    margin-right: 55%;
    text-align: left !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 43.2px;
    color: white;
    width: 100%;
    /* color: rgb(51, 128, 242); */
  }

  .hotelhomecardtextheading {
    /* border: 2px solid red !important; */
    /* background-color: red; */
    width: 26% !important;
    margin-left: 24%;
    text-align: left;
  }

  .home_hotelmaincard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    margin: auto;
  }

  .hotelmaincardouter {
    /* border: 2px solid red; */
    width: 100%;
    /* height: 400px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
  }

  .cardtextFavStaycation_maindiv {
    width: 100% !important;
    height: 355px;
    position: relative;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 18px;
    justify-content: space-around;
  }

  .cardtextFavStaycation_maindiv:hover {
    transform: scale(1.05);
    cursor: pointer;
    height: 350px;
    transition: 0.3s ease-in-out;
  }

  .reviewratingFavStaycationSection {
    background-color: white;
    width: 40%;
    height: 35px;
    /* border: 1px solid red; */
    font-size: 12px;
    top: 25px;
    right: 10px !important;
    display: flex;
    position: absolute;
    /* color: rgb(244, 244, 249); */
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
    z-index: 10;
  }

  .cardtextFavStaycation_maindiv img {
    width: 100%;
    height: 270px;
    border-radius: 10px 10px 0px 0px;
  }

  .cardtextFavStaycation_maindiv > img:hover {
    /* transform: scale(0.9); */
    overflow: hidden;
  }

  .cardFavStaycation_title {
    color: #3380f2;
    display: flex;
    flex-direction: row;
    width: 97%;
    height: 50px;
    margin-top: 5%;
    font-size: 15px;
    font-weight: 700;
    margin-left: 3%;
  }

  .cardFavStaycation_title h5 {
    font-size: 18px;
    margin-top: 0%;
    margin-left: 5%;
    font-weight: 700;
    /* border: 1px solid blue; */
    width: 50%;
  }

  .favStaycationtitle_rating {
    color: #3380f2;
    margin-left: -51%;
    margin-top: 8%;
    width: 50%;
    font-size: 13px;
  }

  .staricon {
    margin-left: 6%;
  }

  .favStaycationRatesdiv {
    border: 1px solid #3380f2;
    width: 62%;
    background-color: #3380f2;
    margin-left: 110%;
    height: 40px;
    border-radius: 7px;
    margin-top: -25%;
  }

  .favStaycationRatesdiv p {
    color: white;
    font-weight: 700;
    font-size: 13px;
    padding-top: 10px;
    padding-left: 20px;
  }

  .hoteldicsountprice {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .review_rating_num {
    color: white;
    margin-left: -40px;
    margin-right: 20px;
    margin-top: -6px;
  }

  .hoteldiscountpercent {
    border: 2px solid #3380f2;
    border-radius: 5px;
    color: #3380f2;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .hotelPricebeforediscount {
    text-decoration: line-through;
    font-size: 13px;
  }

  .hotel_Priceafterdiscount {
    color: #3380f2;
    font-weight: bold;
    font-size: 13px;
  }

  .Favouite_hotels {
    color: #3380f2;
    margin-left: 38%;
    font-weight: 700;
  }

  .reviwe {
    background-color: white;
    width: 150px;
    height: 50px;
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: -82px;
    margin-right: -60px;
    z-index: 10;
  }

  .reviwe_rating_num {
    color: white;
    margin-left: -40px;
    margin-right: 20px;
    margin-top: -6px;
  }

  /* ----------------- New button css ----------------  */

  #newFavStaycationDropContainer {
    display: none !important;
  }

  #newFavStaycationDropContainer {
    width: 9% !important;
    margin-left: 51%;
    margin-top: 2.1%;
    display: flex;
    flex-direction: 0.5 row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white !important;
    font-weight: 700;
    justify-content: space-between;
    /* border: 2px solid #3380F2; */
    outline: none;
    padding-left: 15px;
  }

  #newFavStaycationCountryDrop {
    background-color: #3380f2 !important;
    width: 80%;
    height: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 15px;
  }

  #newFavStaycationicondiv {
    width: 20%;
    color: white;
    background-color: #3380f2;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
  }

  #newFavStaycationicon {
    margin-left: -37%;
    color: white;
    height: 21px;
    width: 20px;
    border-radius: 100%;
    margin-top: 47%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
