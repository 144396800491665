.howRentalsWorkouterdiv {
  margin-top: 3%;
}

.howRentalsWorkmaindiv h2 {
  margin-left: 5%;
  color: #3380f2;
  font-weight: 700;
}

.howRentalsWorkImagediv {
  margin: auto;
}

.howRentalsWorkImagediv img {
  object-fit: cover;
  align-items: center;
  margin-left: 11%;
}

.trustTravelFlapMaindiv {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.trustTravelFlapcontentdiv {
  width: 80%;
}

.trustTravelFlapHeading {
  color: #3380f2;
  font-size: 25px;
  font-weight: 700;
  font-family: sans-serif;
}

/* this css is for the respnsive of the components for the different devices */

@media (max-width: 480px) {
  .howRentalsWorkouterdiv {
    margin-top: 3%;
  }

  .howRentalsWorkmaindiv h2 {
    margin-left: 5%;
    color: #3380f2;
    font-weight: 700;
  }

  .howRentalsWorkImagediv {
    width: 92%;
    margin: auto;
  }

  .howRentalsWorkImagediv img {
    object-fit: cover;
    align-items: center;
    margin-left: 5%;
  }

  .trustTravelFlapMaindiv {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }

  .trustTravelFlapcontentdiv {
    width: 80%;
  }

  .trustTravelFlapHeading {
    /* border: 1px solid red; */
    color: black;
    font-size: 21px;
    font-weight: 600;
    font-family: sans-serif;
  }

  .trustTravelFlapcontentdiv > p {
    /* border: 1px solid red; */
    font-size: 14px;
  }

  .trustTravelFlapImagediv {
    /* border: 1px solid red; */
    height: 100px;
    object-fit: contain;
    margin-top: 5%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
