.newFlightsNearYoumaindiv {
  width: 100%;
  height: fit-content;
}

.newFlightsNearYououterdiv {
  border: 2px solid lightgray;
  display: flex;
  flex-direction: row;
  width: 96%;
  height: 430px;
  border: none;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-decoration: none;
}

.newFlightsNearYououterdiv:hover {
  width: 100%;
  padding: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.newFlightsNearYouHeadingcontainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  /* margin: auto; */
  margin-top: 3%;
}

.newFlightNeearYouHeading1 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 2%;
  margin-left: 5%;
  width: 65%;
  color: #3380f2;
}

.newFlightsNearYouAllDeals {
  margin-left: 16%;
  width: 20%;
}

.newFlightsNearYouAllDeals h5 {
  border: 1px solid #3380f2;
  color: #3380f2;
  margin-top: 12%;
  margin-left: 15%;
  width: 45%;
  height: 32px;
  font-size: 16px;
  padding-left: 18px;
  padding-top: 6px;
  border-radius: 15px;
  cursor: pointer;
  /* text-decoration: underline; */
}

.newFlightsNearYouAllDeals h5:hover {
  background-color: #3380f2;
  color: white;
}

.newFlightsNearYouSliderContainer {
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 1.7%;
}

.newFlightsNearYouCardtitle {
  color: #474747;
  font-size: 20px;
  font-weight: 700;
  margin-top: 17%;
}

.newFlightNearContentImagediv img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.newFlightsContentDiv1 {
  display: flex;
  flex-direction: row;
}

.newFlightNearContentDatediv {
  margin-left: 3%;
  line-height: 10px;
}

.newFlightNearContentDatediv h5 {
  color: grey;
  font-size: 15px;
}

.newFlightNearContentDatediv p {
  color: grey;
  font-size: 9px;
}

.newFlightsNearYouCard_desc {
  width: 90%;
  color: #3380f2;
  font-size: 14px;
  text-align: left;
}

.newFlightsNearYouImagetextcontainer {
  width: 100%;
  height: 440px;
}

.newFlightsNearYouTextcard {
  width: 100% !important;
}

.newFlightsNearYouTextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}

.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.newFlightsNearYouCardimage {
  border: none;
  width: 100% !important;
  height: 180px;
}

.activityAroundimage {
  height: 180px;
  object-fit: cover;
  width: 100%;
  border-radius: 7px 7px 0px 0px !important;
}

.newFlightsNearYouCarddescmain {
  width: 100%;
}

.exclusivecarddesc:hover {
  color: white;
}

.newviewallActivityAroundtext {
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 34%;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-top: 4px;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.newviewallActivityAroundtext:hover {
  background-color: white;
  color: #3380f2;
}

.newActivityAroundHeadingbuttons {
  justify-content: flex-end;
  margin-left: 6%;
  margin-top: -1%;
  background-color: #3380f2 !important;
}

.newActivityAroundHeadingbuttons button {
  color: #3380f2 !important;
  background-color: white !important;
}

.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}

.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
}

.buttons:hover {
  transform: scale(1.1);
}

.newFlightsNearYouButton {
  width: 40%;
  border-radius: 15px !important;
  background-color: white;
  color: black;
  padding: 4px 9px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: -5%;
  margin-left: 65%;
  font-size: 15px !important;
  text-align: right;
  align-items: flex-end;
}

.newFlightsNearYouButton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}

/* this css is for the responsive of the pages f0r the mobile and web responsive */

/* Media query for the Mobile Device */

@media (max-width: 480px) {
  .newFlightsNearYouHeadingcontainer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    /* margin: auto; */
    margin-top: 3%;
  }

  .newFlightNeearYouHeading1 {
    font-size: 21px;
    font-weight: 700;
    margin-top: 2%;
    margin-left: 5%;
    width: 55%;
    color: #3380f2;
  }

  .newFlightsNearYouAllDeals {
    margin-left: 0%;
    width: 30%;
  }

  .newFlightsNearYouAllDeals h5 {
    border: 1px solid #3380f2;
    color: #3380f2;
    margin-top: 8%;
    width: 100%;
    height: 32px;
    font-size: 14px;
    padding-left: 18px;
    padding-top: 6px;
    border-radius: 15px;
    cursor: pointer;
    /* text-decoration: underline; */
  }

  .newFlightsNearYouAllDeals h5:hover {
    background-color: #3380f2;
    color: white;
  }
  .newFlightsNearYouButton{
    /* border: 2px solid red; */
    margin-left: 55%;
    padding-right: 15px;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
