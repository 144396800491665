.newhomedownloadmaindiv {
  width: 100%;
  /* margin-top: 0.5%; */
  height: 500px;
  background-size: cover;
  object-fit: cover;
  background-image: url("./vplane.gif");
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
}

.mobileinhand {
  width: 39%;
  height: 90%;
  margin-left: -2.6%;
  /* border: 2px solid red; */
  margin-top: 3.3%;
}

.newhomedownloadbuttons {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}

.newhomedownloadtext {
  width: 80%;
  height: 30%;
  /* border: 2px solid red; */
  margin: auto;
  color: white;
  font-size: 27px;
  margin-top: 12%;
  /* padding-top: 10%; */
  text-align: center;
  font-weight: 600;
}

.newhomesearchbox {
  width: 94%;
  height: 55%;
  margin: auto;
  margin-top: 12%;
  display: flex;
  flex-direction: row;
}

.newhomeplaybuttons {
  /* border: 1px solid red; */
  width: 100%;
  height: 25% !important;
  margin-top: -2%;
  display: flex;
  flex-direction: row;
}

.playstoreimage {
  width: 30%;
  height: 45%;
  margin: 5%;
  margin-left: 20%;
}

.googlePlayimage {
  width: 30%;
  height: 45%;
  margin: 5%;
  margin-left: -3%;
}

.newhomebarcode {
  width: 30%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  /* border: 2px solid red; */
}

.qrcodeimage {
  width: 35%;
  height: 155px;
  margin: auto;
  margin-top: 10%;
}

.starsimage {
  width: 40%;
  height: 40%;
  margin: auto;
  margin-left: 50%;
}

.qrcodetext {
  color: white;
  font-size: 20px;
  width: 65%;
  font-weight: 700;
  margin-left: 18%;
  margin-top: 5%;
}

.newhomecountryselectdiv {
  width: 85%;
}

.newhomecountryselectdivouter {
  width: 100%;
  height: 40px !important;
  padding-top: 0%;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
}

.newhomevectorimage {
  width: 15%;
  height: 100%;
  padding-top: 2%;
  padding-left: 4%;
  border-radius: 25px 0px 0px 25px;
}

.newhomecountryselect {
  border: none;
  color: #3380f2;
  font-weight: 700;
  font-size: 15px;
  padding-top: 4%;
  padding-left: 5%;
  appearance: none;
  width: 138% !important;
  outline: none;
}

.newhomeflagsimage {
  width: 20%;
  margin-top: 32%;
  margin-left: -111%;
}

.newhomestdcode {
  margin-left: -80%;
  margin-top: -13%;
  margin-bottom: -5%;
  color: white;
  font-weight: 700;
}

.newhomecontactplaceholder {
  height: 22px;
  margin-left: 10%;
  margin-top: -15%;
  border: none;
}

.newhomecontactplaceholder::placeholder {
  color: #3380f2;
  padding-left: 5%;
  font-size: 15px;
}

.newhomesearchcountrydiv {
  border: none;
  background-color: white;
  width: 70%;
  height: 45px;
  margin-left: 17%;
  border-radius: 25px;
  color: #3380f2 !important;
}

.newhomesearchcountrydiv ::placeholder {
  color: #3380f2 !important;
  font-weight: 700;
  padding-left: 25%;
}

.newhomeappdownloadsearchbutton {
  border: 2px solid white;
  background-color: #3380f2;
  font-weight: 700;
  color: white;
  border-radius: 25px;
  text-align: center;
  padding-top: 1.5%;
  width: 25%;
  height: 40px;
  margin-left: -7% !important;
}

.newhomeappdownloadsearchbutton:hover {
  background-color: white;
  color: #3380f2;
  cursor: pointer;
}

.qrcodetext1 {
  color: white;
  font-size: 20px;
  width: 65%;
  font-weight: 700;
  margin-left: 20%;
  margin-top: 0%;
}

/* Media queries for the mobile and the tablet devices */

@media (max-width: 480px) {

  .newhomedownloadmaindiv{
    background-image: none;
    background-color: #3380f2;
  }

  .mobileinhand {
    display: none;
  }
  
  .newhomedownloadbuttons {
    display: none;
  }
  
  .newhomebarcode {
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }

  .qrcodetext1 {
    color: white;
    font-size: 25px;
    width: 65%;
    font-weight: 700;
  }
  
  .qrcodetext1 {
    color: white;
    font-size: 25px;
    width: 65%;
    font-weight: 700;
  }
  
  .qrcodeimage {
    /* border: 2px solid red; */
    width: 40%;
    margin: auto;
    z-index: 1000000000;
  }
  
  .playstoreimage {
    width: 38%;
    height: 50%;
    margin: 5%;
    margin-left: 10%;
  }
  
  .googlePlayimage {
    width: 38%;
    height: 50%;
    margin: 5%;
    margin-left: 2%;
  }
}
