.activitiesChildInnerPageBannerConatinerDiv{
    /* border: 2px solid red; */
    width: 100%;
    height: 400px;
}
.activitiesChildInnerPageSliderDiv{
    /* border: 2px solid gold; */
    width:80%;
    height: 400px;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
}
.activitiesChildInnerPageSliderDiv1{
    /* border: 2px solid green; */
    width: 65%;
    height: 400px;
    margin-left: 7%;
    object-fit: cover;
    background-size: cover;
}
.activitiesChildInnerPageSliderDiv2{
    /* border: 2px solid blue; */
    width: 35%;
    height: 400px;
    display: flex;
    flex-direction: column;
}
.activitiesChildInnerPageSliderImages1{
    /* border: 2px solid red; */
    width: 95%;
    height: 180px;
    margin-left: 5%;
    object-fit: cover;
    background-size: cover;
}
.activitiesChildInnerPageSliderImages2{
    /* border: 2px solid saddlebrown; */
    width: 95%;
    height: 180px;
    margin-left: 5%;
    margin-top: 10%;
    object-fit: cover;
    background-size: cover;
}
.activitiesChildSliderImage1{
    width: 100%;
    height: 100%;
}