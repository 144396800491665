.holdiaysThemeFilterOuterDiv{
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    margin-top: 2%;
}


.holidaysFilterdiv1{
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
}

.holidayFilterInnerdiv1{
    width: 85%;
    color: #3380F2;
    font-size: 30px;
    font-weight: 700;
    /* border: 1px solid black; */
}

.holidayFilterInnerdiv1 input{
    border: 2px solid lightgrey;
    /* border: 2px solid #dddddd78; */
    width: 100%;
    height: 50px;
    color: grey;
    font-size: 20px;
    padding-left: 6px;
    border-radius: 6px;
}

.holidayFilterInnerdiv1 input::placeholder{
    color: grey;
    padding-left: 7px;
    font-size: 20px;
}

.holidayFilterInnerdiv2{
    /* background-color: green; */
    margin-left: 1%;
    width: 10%;
    height: 50px;
}

.holidayFilterInnerdiv2 select{
    height: 50px;
    border: 2px solid lightgrey;
    color: grey;
    box-shadow: none !important;
    outline: none !important;
}

.holidayFilterInnerdiv2 select::placeholder{
    color: grey;
}

.holidaysFilterdiv2{
    /* border: 1px solid red; */
    height: 200px;
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.holidaysFilterHolidayOffers{
    /* border: 1px solid red; */
    width: 40%;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    height: 140px;
}

/* .holidaysFilterHolidayOffers:hover img{
    opacity: none;
    filter: blur(none);
} */
.holidaysFilterHolidayOffers img{
    /* filter: blur(10); */
    /* filter: brightness(0.5); */
    object-fit: cover;
    height: 140px;
    opacity: 0.3;
    outline: none !important;
    border: none !important;
    cursor: pointer;
}

.holidaysFilterHolidayOffers img:hover{
    opacity: 0 !important;
    filter: blur(none);
}

.holidaysFilterHolidayThemes{
    width: 40%;
    margin-left: 3%;
    outline: none !important;
    height: 140px;
}


.holidayThemeImageCard img{
    /* filter: blur(1px); */
    object-fit: cover;
    opacity: 0.3;
    height: 140px;
    outline: none !important;
    border: none !important;
    cursor: pointer;
}

.holidayThemeImageCard img:hover{
    cursor: pointer;
    opacity: none !important;
}
.holidayFilterHeading h5{
    font-size: 30px !important;
    margin-top: 18%;
    margin-left: 10%;
    font-weight: 700;
}

.holidayFilterHeading1 h5{
    font-size: 30px !important;
    margin-top: 18%;
    margin-left: 3%;
    font-weight: 700;
}

/* ----------------- view all deals button css ----------------  */


.newHolidaysThemedealsbutton {
    width: 8%;
    margin-left: 5%;
    /* margin-top: 3%; */
    display: flex;
    padding-top: 5px;
    /* border: 2px solid red; */
    border-radius: 8px;
    flex-direction: row;
    border-right: none;
    height: 40px;
    color: #3380F2;
    text-decoration: underline;
    font-weight: 700;
    /* border: 2px solid green;*/
  }
  .newHolidaysThemedealsbutton:hover{
    background-color: #3380F2;
    color: white;
  }