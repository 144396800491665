* {
  margin: 0;
}
.BottomBarNewHomeSection {
  height: 67px;
  width: 100%;
  display: flex;
  /* padding: 0 90px; */
  z-index: 1;
  box-sizing: border-box;
  color: white;
  background: #3380f2;
  border-top: 1px solid white;
  margin-top: 4%;
}

/* .newHomeBottomButtons{
  border: 1px solid red !important;
} */
.BottomBarNewHomeSection ul {
  /* border: 1px solid red; */
  margin-top: 4.5% !important;
  font-weight: 600;
  display: flex;
  text-decoration: none;
}

.newHomeBottomImages {
  /* border: 1px solid purple; */
  width: 30%;
}

.bottomBarImage1 {
  /* border: 1px solid red; */
  width: 26%;
  margin-top: 5%;
  margin-left: 5%;
}

.bottomBarImage2 {
  width: 26%;
  margin-top: 5%;
  margin-left: 5%;
}

.BottomBarNewHomeSection ul li {
  margin: 0 56px 15px 21px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.BottomBarNewHomeSection ul li:hover {
  color: blue;
}

#checkbox {
  display: none;
}
.bars {
  display: none;
}

.newHomeBotttomlogo{
  /* border: 1px solid red; */
  width: 35%;
}
.newHomeBotttomlogo > .logo-text {
  margin-top: 3%;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.newHomeBottomBarCopyrightIcon{
  margin-right: 1%;
  font-size: 25px;
}