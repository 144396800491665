/* .InnerhandpickedFooterMain-container-Headline{
    position:relative;
    top:20px;
    text-align:center;
    border: 2px solid red;
}
.image-background{
    margin-top: 10%z;
    opacity: 0.5;
}


.InnerhandpickedFooterMain-container-image{
    display:inline-block;
    
}
.InnerhandpickedFootersubclass-container iframe{
    position:relative;
}

.InnerhandpickedFooterMain-container2{
    background-color: rgb(163, 73, 73);
    width: 40%;
    height: 65%;
    position: absolute;
    margin-top:-560px;
    margin-left:500px;
    padding:8px;
} */

.innerHandPickedFooterMain-Container3{
  background-color:#071330;
  margin-top: 1%;
  height: 250px;
  /* border: 2px solid red; */
  /* width:auto; */
  /* height: 500px; */
}

.innerHandPickedFootersubclass-item{
  display:flex;
  flex-direction:row;
  /* border:2px solid red; */
  width:18%;
}

.InnerhandpickedFooterfootersocialmedia2{
  width:25%;

}
.innerHandPickedFooterContact{
  margin-left: 60%;
  /* border: 1px solid red; */
  width: 40%;
}
.innerHandPickedFooterContactDetailsdiv{
  margin-left:850px;
  /* margin-top:5px; */
}

.innerHandPickedFooterContactContainer {
  border: 1px solid green !important;
  width: 100%;
}
.innerHandPickedFooterContactDetailsdiv{
/* border: 2px solid white !important; */
margin-top: -5%;
margin-left: 5%;
width: 90%;
height: auto;
}
 
.innerHandPickedFooterContactContent {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  /* margin-top:2%; */
  width: 100%;
  border-radius: 7px;
  display: flex;
  height: 30px;
  /* margin-left: 5%; */
  flex-direction: row;
  cursor: pointer;
  /* border: 2px solid red; */
}

/* .DescriptionAboutPlace{
  color: #3380f2;
} */


.innerHandPickedFooterContactContentInner2{
  margin-left: 3%;
  /* border: 2px solid red; */
  width: 100%;
  /* margin-top: 1%; */
}

.innerHandPickedFooterContactContentInner2 p {
  margin-left: 5%;
  color: white;
}



.innerHandPickedFooterContactContentInner2span{
  text-align: right !important;
}

.innerHandPickedFootersubclass-item{
  margin-left:4%;
}

.innerHandPickedFootersocialIcons {
  border: 2px solid whitesmoke;
  color: #dee2e6;
  font-size: 29px;
  margin-top: 110%;
  /* padding: 20%; */
  border-radius: 30px;
  gap: 50% !important;
}
.innerHandPickedFootersocialIcons:hover {
  color: blue;
  background-color: white;
}


.innerHandPickedFooterfootersocialmedia1 {
  /* border: 1px solid red; */
  display: flex;
  /* width: 100%; */
  justify-content: center;
  column-gap: 20px;
  margin-top: 3%;
  /* margin-left: 5%; */
}
