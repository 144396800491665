.airlinesDetailsBannerMainDiv {
  /* background-color: #89b8fe; */
  width: 100%;
  height: 350px;
  border: 2px solid white;
  background-image: url("https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/AirlinesDetails/banner.png");
  object-fit: cover;
}

.airlinesDetailsBannerContainerDiv {
  /* border: 2px solid grey; */
  width: 70%;
  height: 150px;
  margin-left: 5%;
  margin-top: 7%;
}
.airlinesDetailsinputBox1 {
  /* border: 2px solid saddlebrown; */
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
}
.airlinesDetailsTravellerInput {
  /* border: 2px solid gold; */
  width: 20%;
  height: 50px;
}
.airlinesDetailsdetailforminputs {
  color: #393939;
  font-size: 14px;
  width: 80%;
  height: 45px;
  margin-left: 10%;
  border: none;
  /* border: 2px solid red; */
  background-color: white;
  outline: none;
  padding-left: 6px;
  padding-right: 15px;
}

.airlinesDetailsinputBox2 {
  /* border: 2px solid red; */
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
}
.airlinesDetailslocation1 {
  /* border: 2px solid red; */
  height: 50px;
  width: 20%;
}
.airlinesDetailsdepart {
  border: none;
  /* border: 2px solid green; */
  height: 45px;
  width: 80%;
  padding-left: 20px;
  margin-left: 10%;
  outline: none;
}

.airlinesDetailsDates {
  /* border: 2px solid slateblue; */
  width: 40%;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}

.airlinesDetailscheckin {
  border: none;
  /* border: 2px solid blue; */
  border-right: 1px solid lightgray;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.airlinesDetailscheckOut {
  border: none;
  /* border: 2px solid purple; */
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.airlinesDetailssearchButton {
  /* border: 2px solid black; */
  width: 7%;
  height: 45px;
  background-color: white;
  margin-left: 4%;
}
.airlinesDetailssearchButtonIcon {
  font-size: 20px;
  color: #393939;
  margin: 16% 35%;
}
