.multicardscontainer{
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.clientstestimonial_title {
  font-size: 27px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3%;
  margin-bottom: 3%;
  text-decoration: none solid rgb(45,44,44);
  vertical-align: baseline;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51,128,242);
}


.card-wrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.multisection-image {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.card-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.card-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.multisection-image:hover img {
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.multisection-image:hover .card-image {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: all 0.9s;
  transition: all 0.9s;
}

.multicardsdetails {
  position: absolute;
  bottom: -55%;
  left: 0;
  background: #3380f2;
  width: 100%;
  height: 213px;
  z-index: 100000000;
  padding: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.multicardsimage {
  /* border: 3px solid red !important; */
  top: 0;
  left: 0;
  width: 100%;
  height: 400px !important;
  border-radius: 16px !important;
  z-index: 2;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.multicardsimage img {
  width: 100%;
  height: 100%;
  /* border-radius: 15px !important; */
  -o-object-fit: cover;
  object-fit: cover;
}

.multicardsdetails h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
  color: white;
}

.multicardsdetails h2 .job-title {
  font-size: 16px;
  line-height: 2;
  color: white;
  font-weight: 300;
  display: block;
}

.card:hover .multicardsdetails {
  bottom: 0;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}



.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}
