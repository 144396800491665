.innerHandpicked1ActivitiesMainContainerPage1{
    /* overflow:hidden; */
    /* border: 2px solid red; */
    width: 85%;
    margin: auto;
  }
  .innerHandpicked1ActivitiesCardAlignment{
    /* border: 2px solid red; */
  }
  .innerHandpicked1Activitiestitle{
    text-align: left;
    font-size: 18px;
    font-weight: 700;
  }
  .innerHandpicked1ActivitiesDetails{
    text-align: left;
    font-size: 13px;
  }
  .innerHandpicked1ActivitiesButton{
    font-size: 15px;
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    font-weight: 12px;
    font-weight: 700;
    padding-left: 13px;
  }