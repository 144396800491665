.newTopDemandedVisamaindiv{
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
  /* background-color: #3380f2; */
  /* border: 3px solid red; */
  height: 470px;
}


.newTopDemandedVisaHeadingContainer{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.newTopDemandedVisaHeadingContainer h5{
  margin-left: 6%;
  margin-top: 1%;
  font-size: 30px;
  color: #3380f2;
  font-weight: 700;
}


.countryhomecardselectdrop1{
  border: 2px solid white !important;
  background-color: #3380f2 !important;
  border-radius: 20px !important;
  margin-top: 2%;
  color: white !important;
  font-weight: 600 !important;
  height: 40px !important;
  width: 58% !important;
}

.countryhomecardselectdrop1:hover{
  background-color: white !important;
  cursor: pointer;
  color: #3380f2 !important;
}

.countryhomecardselectdrop1 option:hover{
  background-color: blue !important;
}


.internationalouterdiv {
  border: 2px solid lightgray;
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  height: 460px !important;
  box-shadow: 4px 4px 4px rgb(211,211,211);
  border: none;
  transition: all 300ms ease-in-out;
}

.internationalouterdiv:hover {
  width: 100%;
  padding: 5px;
  box-shadow: 5px 10px 25px  #3380f2;
}

.newTopDemandedVisaheadingcontainer {
  background-color: #3380f2;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  /* margin: auto; */
  margin-top: 3%;
}

.newTopDemandedheading1 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 1.5%;
  margin-left: 6%;
  width:65%;
  color: white;
  /* color: #3380f2; */
}

.internationalcruiseheading2 {
  width: 50%;
  height: 40px;
  justify-content: center;
  margin: -0.5% 0 1% 1%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
}
#internationalcruisecountrydrop{
  width:39% !important;
  border: 2px solid red;
  margin-top: 7% !important;
  height: 35px !important;
  padding-top: 1%;
  margin-left: -170%;
}
.internationalcruiseheading {
  border-radius: 50px;
}

.internationalcruiseheading:hover{
  background-color: white;
  color: #3380f2;
}
.internationalcruiseheading2 div {
  border: 2px solid white;
  width: 130px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 4%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
}

/* .internationalcruiseheading2 div:hover {
  background-color: #3380f2;
  color: white;
} */
.viewallinternationalcruisebuttons {
  color: white;
  font-weight: 700;
  text-decoration: none !important;
  display: flex;
  margin: 1% 0 0 6%;
  width: 56%;
}

.internationalslidercontainer{
  width: 90%;
  margin: auto;
  margin-top: 1.7%;
}



.viewallinternationalcruisetext{
  margin-top: 1.5%;
  border: 2px solid white;
  margin-bottom: 9%;
  margin-left: 25%;
  border-radius: 20px;
  width: 24%;
  font-size: 15px;
  height: 35px;
  font-weight: 700;
  padding-left: 17px;
  padding-top: 3px;
}

.viewallinternationalcruisetext:hover{
  background-color: white;
  color: #3380f2;
}

.internationalcruiseheadingbuttons {
  justify-content: flex-end;
  width: -1%;
  margin-left: 8%;
}


.internationalcruiseheadingbuttons button{
  color: #3380f2 !important;
  background-color: white !important;
}

/* -------------------------See all button css --------------------------------- */


.newTopDemandedVisadealsbutton {
  width: 9%;
  height: 35px !important;
  margin-left: 60%;
  margin-top: 1%;
  display: flex;
  padding-top: 5px;
  padding-left: 20px;
  border: 2px solid #3380F2;
  border-radius: 8px;
  flex-direction: row;
  border-right: none;
  height: 40px;
  color: #3380F2;
  font-weight: 700;
  border: 2px solid #3380F2;
  border-radius: 25px;
}
.newTopDemandedVisadealsbutton:hover{
  background-color:#3380F2;
  color: white;
}

@media screen and (max-width: 950px) {
  .internationalimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewallinternationalcruisebuttons {
    display: none;
  }
  .newTopDemandedheading1 {
    display: none;
  }
  .newTopDemandedVisaheadingcontainer {
    flex-direction: column;
  }
  .internationalcruiseheading2 {
    width: 100%;
  }
  .internationalimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .internationaltextcard {
    margin-left: 8px;
  }
}

@media screen and (max-width: 450px) {
  .internationalimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewallinternationalcruisebuttons {
    display: inline-block;
    vertical-align: top;
    margin-top: -50px;
    text-align: right;
    width: 40%;
    font-size: 14px;
    margin-left: 52%;
  }
  .internationalcruiseheading2 {
    display: none;
  }
  .internationalcruiseheadingbuttons {
    display: none;
  }
  .newTopDemandedheading1 {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    font-size: 16px;
    margin-left: 3%;
  }
  .newTopDemandedVisaheadingcontainer {
    flex-direction: column;
    border: 2px solid red;
  }
  .newTopDemandedVisaHeadingContainer>h5{
    font-size: 20px;
    /* border: 2px solid  red; */
    color: #393939;
    width: 50%;
  }
  .newTopDemandedVisadealsbutton{
    margin-left: 0%;
    display: none;
  }
  .internationalimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .internationaltextcard {
    margin-left: 8px;
  }

}



/* This is the  css for the card components referral take from the easy visa destinations*/
.easyvisadestinationscontainer{
  /* border: 2px solid red; */
  width: 100%;
  margin-top: 5%;
}

.easyvisadestinations_title {
    font-size: 23px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    /* margin-top: 15%; */
    margin-bottom: 2%;
    text-decoration: none solid rgb(45, 44, 44);
    vertical-align: baseline;
    letter-spacing: normal;
    margin-left: 41.5%;
    /* text-align: center; */
    color: rgb(51, 128, 242);
  }

  .easyvisaslidercontainer{
    /* border: 2px solid red; */
    width: 94%;
    margin: auto;
  }
  
  .newTopDemandedVisacardmain {
    
    position: relative;
    width: 107% !important;
    padding: 0 10px;
    /* -webkit-box-sizing: border-box;
    box-sizing: border-box; */
  }
  
  
  .newTopDemandedVisadestinationsdiv {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: #3380f2;
    border: none;
  }
  
  .newTopDestinationVisacardimage {
    top: 0;
    left: 0;
    width: 100%;
    height: 400px !important  ;
    /* z-index: 2; */
    background-color: #3380f2;
    /* border: 1px solid #3380f2 !important; */
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .newTopDestinationVisacardimage img {
    width: 100%;
    height: 400px;
    border-radius: 16px !important;
    /* height: 100%; */
    /* -o-object-fit: cover; */
    object-fit: cover;
    background: cover;
    filter: brightness(0.6);
    border: none !important;
  }
  
  .newTopDemandedVisadestinationsdiv:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .newTopDemandedVisadestinationsdiv:hover .newTopDestinationVisacardimage {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  
  .newTopDemandedVisadestinationsdetails {
    position: absolute;
    bottom: -60%;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: 100000000;
    padding: 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
  }
  
  .newTopDemandedVisadestinationsdetails h2 {
    margin: 0px 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 700;
  }
  
  .newTopDemandedVisadestinationsdetails h2 .job-title {
    font-size: 16px;
    line-height: 2;
    color: white;
    font-weight: 300;
    display: block;
  }
  
  /* .card:hover .newTopDemandedVisadestinationsdetails {
    bottom: 0;
  } */
  
  /* .carousel .slick-dots {
    bottom: -2.5vw;
  }
  
  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: 0.5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
   */
  .carousel .slick-prev,
  .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  
  .carousel .slick-prev:hover,
  .carousel .slick-prev:focus,
  .carousel .slick-next:hover,
  .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }
  
  .carousel .slick-prev:before,
  .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }
  
  .carousel .slick-next {
    right: -55px;
  }
  
  .carousel .slick-prev {
    left: -55px;
  }
  
  