#filter-newVisaTrave-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    margin: auto;
    /* top: 42%; */
    background-color: white;
    width: 64%;
    /* border: 3px solid #3380f2; */
    color: #3380f2 !important;
    height: 245px;
    /* opacity: 0.9; */
    left: 1%;
    bottom: 67%;
    border-radius: 10px;
    position: relative !important;
    z-index: 10;
    height: 100px;
  }
  
  /* This css is for the experiment input div part */
  .newVisainputmaindiv {
    display: flex;
    flex-direction: row;
    /* border: 2px solid green; */
    width: 97%;
    margin: auto;
    height: 100%;
  }
  
  .newHolidayDestinationInputContainer {
    width: 40%;
    height: 65px;
  }
  

  .newHolidaysinputNameContainer {
    /* border: 1px solid maroon; */
    width: 90%;
  }
  .newHolidaysinputNameContainer input {
    /* background-color: #8DBBFF; */
    /* border: 1px solid red; */
    height: 65px;
    /* margin-top: 3% !important; */
    width: 100%;
    color: black;
    border: none !important;
    outline: none !important;
    /* padding-left: 4%; */
    font-size: 18px;
  }
  
  .newHolidaysinputNameContainer input::placeholder {
    /* padding-left: 5%; */
    color: black;
  }
  
  .newHolidaysInputicon {
    /* border: 1px solid black !important; */
    margin-top: 2.4%;
    font-size: 25px;
    color: black;
    width: 7%;
  
    /* margin-right: 4%; */
    margin-left: 4%;
  }
  
  /* This css is for the below form */
  /* .newHolidaysBannerMultiInput{
    border: 1px solid red;
  } */
  
  .innerNewVisadropdowncontainer2 {
    /* border: 1px solid red; */
    /* border: 3px solid white; */
    /* background-color: blue; */
    width: 90%;
  }
  
  .optionsNewVisaBannercontainer {
    /* border: 2px solid green; */
    display: flex;
    flex-direction: row;
    margin-top: 2%;
  }
  
  .singleNewVisaBannerdropcontainer {
    /* border: 1px solid red !important; */
    width: 40%;
    margin-left: 1%;
    border: none;
  }

  .singleNewVisaBannerdropcontainer p{
    /* border: 1px solid red; */
    height: 10px;
    color: #818090;
    /* margin-top: -4%; */
  }

  .singleNewVisaBannerdropcontainer select{
    /* margin-top: 2% !important; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    height: 36px;
    border: 2px solid #dddddd78 !important;
    /* padding-top: -5px !important; */
  }

  .singleNewVisaBannerdropcontainer select::placeholder{
    padding-top: 4px;
  }
  
  .singleNewVisaBannerdropcontainer1 {
    margin-left: 1%;
    width: 35%;
    /* margin-top: 2%; */
    /* border: 1px solid red; */
  }

  .singleNewVisaBannerdropcontainer1 p{
     /* border: 1px solid red; */
     height: 10px;
     color: #818090;
     /* margin-top: -7%; */
  }

  .singleNewVisaBannerdropcontainer1 select{
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    height: 35px;
    padding: 5px 10px;
    border: 2px solid #dddddd78;
  }

  .singleNewVisaBannerdropcontainer2 {
    /* border: 1px solid red !important; */
    width: 33%;
    margin-left: 1%;
    border: none;
    /* margin-bottom: 4%; */
  }

  .singleNewVisaBannerdropcontainer2 p{
    /* border: 1px solid red; */
    /* margin-left: 40% !important; */
    height: 10px;
    color: #818090;
    /* margin-top: -4%; */
  }

  .singleNewVisaBannerdropcontainer2 select{
    /* margin-top: 2% !important; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    height: 35px;
    border: 2px solid #dddddd78;
    padding: 5px 10px !important;
  }


  .newVisaform{
    /* border: 1px solid red; */
    width: 100% !important;
  }

  .newVisaLivingdrop{
    /* border: 1px solid red; */
    margin-left: 0% !important;
  }
  
  .singleNewVisaBannerdropcontainerbutton .holidayformbutton {
    width: 100%;
    background-color: #3380f2 !important;
    color: white;
  }
  
  .singleNewVisaBannerdropcontainerbutton .holidayformbutton:hover {
    background-color: white !important;
    color: #3380f2 !important;
  }
  
  .newHolidaysinputNameContainer .hotelinputIconContainer {
    font-size: 30px !important;
  }
  
  .newHolidaysCheckIn_dateContainer {
    /* border: 1px solid purple; */
    width: 20%;
  }
  
  .newHolidaysCheckIn_dateContainer input {
    width: 100%;
    border-left: 1px solid black !important;
    outline: none !important;
  }
  
  .newHolidaysCheckIn_dateContainer input {
    border-left: 1px solid black !important;
    margin-top: 4%;
    width: 100%;
    border: none;
    outline: none !important;
    padding-top: 2%;
    height: 50px;
    width: 90% !important;
    color: black;
    /* border: none !important; */
    outline: none !important;
    padding-left: 4%;
    font-size: 18px;
  }
  
  .newHolidaysCheckout_DateContainer {
    /* border-right: 1px solid orange; */
    width: 22%;
    /* background-color: #8DBBFF; */
  }
  
  .newHolidaysCheckout_DateContainer input {
    border-left: 1px solid black !important;
    margin-top: 4%;
    width: 100%;
    border: none;
    outline: none !important;
    padding-top: 2%;
    height: 50px;
    width: 90% !important;
    color: black;
    outline: none !important;
    padding-left: 4%;
    font-size: 18px;
  }
  
  .newHolidaysGuest_outerDiv {
    /* border: 1px solid black; */
    width: 20%;
  }
  
  #newHolidaysPage_departure {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* color: #3380f2; */
    color: black;
    background-color: white !important;
    border-radius: 5px;
  }
  
  #newHolidaysPage_departure h3 {
    border-left: 1px solid black;
    font-size: 20px;
    /* font-weight: 700; */
    /* margin-left: -7%; */
    padding: 7px;
    padding-left: 16px;
    margin-top: 4%;
    height: 50px;
    /* padding-top: 10% !important; */
  }
  
  .dropdownnewHotelPage-content {
    display: none;
    position: absolute;
    color: white;
    background-color: white;
    width: 200px;
    height: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 5%;
  }
  .dropdownnewHotelPage-content div:hover {
    background-color: white;
    color: #3380f2;
  }
  #newHolidaysPage_departure:hover .dropdownnewHotelPage-content {
    display: block;
  }
  
  .navbarnewHotelPageOptions {
    margin-top: 5%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  
  .experimentHomeGuestIcon {
    /* border: 1px solid red; */
    font-size: 24px;
    margin-left: 15% !important;
    margin-top: 11% !important;
    background-color: white !important;
    /* background-color: #8DBBFF; */
  }
  
  .newVisa_SearchButton {
    /* border: 1px solid red; */
    width: 15%;
  }
  
  .newVisa_SearchButton button {
    margin-top: 22%;
    width: 90%;
    height: 45px;
    margin-left: 4%;
    padding-left: 13%;
    padding-top: 3%;
    font-size: 15px;
  }

  .newVisa_SearchButton button:hover{
    background-color: white;
    color: #3380f2;
  }
  
  /* This is prior css code for the homepage */
  .choosenewhomebanneroptions {
    display: flex;
    flex-direction: row;
    /* border: 1px solid black; */
    font-weight: 600;
    font-size: 18px;
    width: 95%;
    border-bottom: 1px solid lightblue;
    height: 50px;
    margin: 4% 0 0% 4.5%;
  }
  
  .radiodesc {
    /* border: 1px solid red; */
    margin-left: 0.6%;
  }
  .choosenewhomebanneroptions1 label {
    margin-top: 1% !important;
    margin-left: 0.5%;
  }
  .choosenewhomebanneroptions2 {
    margin-left: 5%;
  }
  .choosenewhomebanneroptions3 {
    margin-left: 5%;
  }
  .mainnewhomedatesections {
    border-bottom: 1px solid lightblue;
    width: 100%;
    height: 90px;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
  }
  .filternewhomebanner-fromTo {
    display: flex;
    cursor: pointer;
    padding: 15px;
    position: relative;
    transition: background-color 0.5s linear;
    height: 60%;
    width: 35%;
    margin-top: 1%;
    left: 3%;
    border-right: 2px solid #3380f2;
  }
  
  #dateonewaynewhomebannerform {
    border: none;
    /* border: 2px solid #3380f2; */
    width: 100% !important;
    margin-top: -9%;
    margin-left: 0%;
    /* border-right: 2px solid  #3380f2; */
    height: 45px;
  }
  
  #dateonewaynewhomebannerform::placeholder {
    /* border: 2px solid #3380F2; */
    font-size: 28px !important;
    font-weight: 500;
    color: #3380f2;
  }
  .selectnewhomepagedate {
    /* border: 1px solid ; */
    height: 70%;
    margin-top: 2%;
    margin-left: 5%;
    border-right: 2px solid #3380f2;
  }
  .selectnewhomepagedate h5 {
    font-size: 18px !important;
    /* font-weight: 600; */
    margin-top: -5%;
    margin-bottom: 13%;
    padding-left: 15px;
    /* border-right: 2px solid #3380f2; */
  }
  .fromnewhomebanner-name {
    line-height: 1px;
    width: 45% !important;
    font-size: 11.5px;
    /* border-right: 2px solid #3380f2; */
  }
  .fromnewhomebanner-name h5 {
    font-size: 17px;
    margin-left: 13%;
    width: 97%;
    margin-top: -9%;
    border-right: 2px solid #3380f2;
  }
  .fromnewhomebanner-name2 h5 {
    font-size: 17px;
    margin-left: 13%;
    width: 100%;
    padding-left: 60px;
    margin-top: -7%;
    /* border-right: 2px solid #3380f2; */
  }
  .citynewhomebannerinput1 {
    margin-top: 1%;
    margin-left: 10%;
    color: #3380f2;
    border: none;
    height: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 25px !important;
    border-right: 2px solid #3380f2;
  }
  .citynewhomebannerinput1::placeholder {
    color: #3380f2;
  }
  .citynewhomebannerinput2 {
    margin-top: -5%;
    margin-left: 10%;
    color: #3380f2;
    border: none;
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 25px !important;
    /* border-right: 2px solid #3380f2; */
    padding-left: 44px;
  }
  .citynewhomebannerinput2::placeholder {
    color: #3380f2;
  }
  .fromnewhomebanner-name2 {
    line-height: 5px;
    width: 45%;
    margin-left: 4%;
    margin-top: -1%;
    font-size: 11.5px;
    /* border-right: 2px solid #3380f2; */
  }
  #formnewhomepagedates {
    display: flex;
    width: 450px;
    margin: 12px;
    margin-left: 5%;
    margin-top: 0.6%;
    /* border: 2px solid red; */
  }
  
  .selectnewhomepagedate {
    line-height: 2px;
  }
  #selectnewhomepageReturndate1 {
    line-height: 2px;
    margin-left: 7%;
    height: 70%;
    margin-top: 2%;
    border-right: 2px solid #3380f2;
  }
  #selectnewhomepageReturndate1 h5 {
    font-size: 20px;
    font-weight: 600;
    font-weight: 500;
    padding-left: 10px;
    margin-top: -4.5%;
    margin-bottom: 13%;
    /* border-right: 2px solid #3380f2; */
  }
  #newhomepagetrave {
    /* display: flex;
    flex-direction: row;
    margin-right: 10px; */
    width: 18%;
    /* border: 1px solid; */
    height: 70px;
    margin-top: 3%;
  }
  #newhomepage_departure {
    margin: 12px;
    /* border: 1.4px solid #3380f2; */
    /* background-color: #3380f2; */
    width: 100%;
    /* border-radius: 8px; */
    /* height: 43px; */
    /* cursor: pointer; */
    margin-left: 0.5%;
    margin-top: -13%;
    color: #3380f2;
  }
  
  #newhomepage_departure h3 {
    font-size: 25px;
    /* font-weight: 700; */
    margin-left: -7%;
    padding: 12px;
    padding-left: 16px;
  }
  .newhometravellers {
    margin-top: -10.5%;
    margin-left: 10%;
    color: #3380f2 !important;
    border: none;
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 29px !important;
    border-right: 2px solid #3380f2;
    padding-left: 44px;
  }
  .searchbtn {
    font-size: 15px;
    font-weight: 500;
    background-color: #3380f2 !important;
    text-transform: capitalize;
    color: white !important;
    border: 2px solid #3380f2 !important;
    border-radius: 10px;
    margin-top: 3%;
    height: 45px;
    width: 40%;
    margin-left: 2%;
    margin-top: -5%;
  }
  .searchbtn:hover {
    background-color: blue !important;
    border: 3px solid blue !important;
    color: white !important;
  }
  .carouselnewVisabannermenu {
    width: 64%;
    height: 90px;
    margin: auto;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    position: relative;
    /* position: absolute; */
    bottom: 70%;
    left: 5.2%;
    z-index: 10000000;
    color: white !important;
    border-radius: 40px;
  }
  
  /* This css part includes to overlapping and hovering the things */
  .bottomupHolidaysclass1 {
    top: 10%;
    right: 86%;
    z-index: 9 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass1:hover {
    z-index: 107 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass2 {
    top: 10%;
    right: 78%;
    z-index: 8 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass2:hover {
    z-index: 106 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass3 {
    top: 10%;
    right: 70%;
    z-index: 7 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass3:hover {
    z-index: 106 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass4 {
    top: 10%;
    right: 61.6%;
    z-index: 6 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass4:hover {
    z-index: 105 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass5 {
    top: 10%;
    right: 52.5%;
    z-index: 5 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass5:hover {
    z-index: 104 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass6 {
    top: 10%;
    right: 44%;
    z-index: 4 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass6:hover {
    z-index: 103 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass7 {
    top: 10%;
    right: 36%;
    z-index: 3 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass7:hover {
    z-index: 102 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass8 {
    top: 10%;
    right: 27%;
    z-index: 2 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass8:hover {
    z-index: 101 !important;
    margin-top: -2%;
  }
  
  .bottomupHolidaysclass9 {
    top: 10%;
    right: 19%;
    z-index: 1 !important;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    font-size: 15px;
    font-weight: 500;
  }
  
  .bottomupHolidaysclass9:hover {
    z-index: 100 !important;
  }
  
  /* This is the next part css */
  .newhomesearchbutton {
    background-color: #3380f2;
    color: white;
    border: none;
    width: 14%;
    height: 42px;
    border-radius: 15px;
    font-weight: 700;
    margin-left: 8%;
    margin-top: -1.5%;
  }
  
  .newhomesearchbutton:hover {
    background-color: white;
    border: 1px solid #3380f2;
    color: #3380f2;
  }
  /* This css is for the icons and there hovering properties */
  
  .carouselnewVisabannermenu-icon {
    height: 98px;
    width: 10%;
    border-radius: 12px;
    /* background-color: #3380f2; */
    background: transparent;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    cursor: pointer;
    border: none !important;
    color: white;
    text-decoration: none;
    z-index: 100;
    position: absolute;
  }
  
  .carouselnewVisabannermenu-icon span {
    white-space: nowrap;
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .carouselnewVisabannermenu-icon-active {
    background: #3380f2 !important;
    border-color: white !important;
    height: 70px;
    width: 90px;
    border-radius: 7px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    color: white !important;
    text-decoration: none;
  }
  
  .flighttext {
    color: white;
    text-decoration: none;
  }
  .flighttext:hover {
    color: #3380f2;
  }
  .carouselnewVisabannermenu-icon:hover {
    text-decoration: none;
    color: #3380f2;
    background-color: white;
    transition: all 0.5s ease;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  
  .carouselnewVisabannermenu-icon span {
    white-space: nowrap;
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .carouselnewVisabannermenu-icon-active {
    background: #3380f2 !important;
    border-color: white !important;
    height: 70px;
    width: 90px;
    border-radius: 7px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
    color: white !important;
    text-decoration: none;
  }
  .newHomemoredropdownbannercontent {
    display: none;
    position: absolute;
    color: white;
    background-color: #3380f2;
    width: 200px;
    height: 245px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    top: 58%;
    /* border: 2px solid red; */
  }
  
  #borderrightnewhomebanner:hover .newHomemoredropdownbannercontent {
    display: block;
  }
  
  /* This css is for the one way round trip button */
  .onewaybutton {
    background-color: white !important;
    color: #3380f2 !important;
    width: 12%;
    font-size: 15px !important;
    font-weight: 600 !important;
    height: 40px;
    outline: none !important;
    border: 2px solid #3380f2 !important;
  }
  
  .onewaybutton:hover {
    background-color: #3380f2 !important;
    color: white !important;
    border: 2px solid white !important;
  }
  
  .roundtripbutton {
    background-color: white !important;
    color: #3380f2 !important;
    width: 12%;
    font-size: 15px !important;
    font-weight: 600 !important;
    height: 40px;
    margin-left: 3%;
    outline: none !important;
    border: 2px solid #3380f2 !important;
  }
  
  .roundtripbutton:hover {
    background-color: #3380f2 !important;
    color: white !important;
    border: 2px solid white !important;
  }
  
  .multiwaybutton {
    background-color: white !important;
    color: #3380f2 !important;
    width: 12%;
    font-size: 15px !important;
    font-weight: 600 !important;
    height: 40px;
    margin-left: 3%;
    outline: none !important;
    border: 2px solid #3380f2 !important;
  }
  
  .multiwaybutton:hover {
    background-color: #3380f2 !important;
    color: white !important;
    border: 2px solid white !important;
  }
  
  .moreOptions:hover {
    background-color: white;
    color: #3380f2;
  }
  .moreOptions {
    margin-top: 5%;
    padding: 5%;
    color: white;
  }
  .moreOptions a {
    text-decoration: none;
    color: white;
  }
  .date-picker input {
    width: 100%;
    border: none;
  }
  .downarrowicon {
    margin-top: 1%;
  }
  .dropdownnewhomepage-content {
    display: none;
    position: absolute;
    color: white;
    background-color: #3380f2;
    width: 200px;
    height: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -1%;
  }
  .dropdownnewhomepage-content div:hover {
    background-color: white;
    color: #3380f2;
  }
  #newhomepage_departure:hover .dropdownnewhomepage-content {
    display: block;
  }
  .navbarnewhomepageOptions {
    margin-top: 5%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .decrease {
    margin-left: 10px;
    margin-right: 20px;
  }
  
  .checkmark {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    height: 20px;
  }
  .flightscheckbox {
    margin-left: 5%;
    margin-bottom: -0.5%;
    margin-top: -3%;
  }
  .flightscheckbox1 {
    margin-left: 0.5%;
    margin-top: -1.4%;
    font-size: 15px;
  }
  
  @media screen and (min-width: 560px) and (max-width: 950px) {

    .carouselnewVisabannermenu {
      display: none;
    }
    .selectnewhomepagedate {
      margin-top: 2%;
    }
    #selectnewhomepageReturndate1 {
      margin-top: 2%;
    }
    /* .fromnewhomebanner-name{
            border: 1px solid red;
        } */
    .filternewhomebanner-fromTo {
      width: 33%;
      margin-top: 0% !important;
    }
  
    .dropdownnewhomepage-content {
      margin-top: 8%;
    }
  
    .filter-newVisaTrave-container {
      flex-direction: column;
      margin-top: 5%;
    }
    .filternewhomebanner-fromTo {
      margin: auto;
      margin-top: 25px;
    }
    #formnewhomepagedates {
      width: 33%;
      margin: auto;
      margin-top: -3%;
      margin-left: 6%;
      border: 1px solid;
    }
  
    #newhomepagetrave {
      margin: auto;
      margin-top: 1%;
      margin-left: 16%;
      width: 65%;
      flex-direction: row;
      font-size: 13px;
    }
    .navbar_fixed {
      /* display: none; */
      width: 100%;
      font-size: 20px;
    }
    .searchbtn {
      width: 100%;
      margin-left: 28%;
      margin-top: 15%;
      padding: 9px;
      font-size: 15px;
    }
  }
  
  @media screen and (min-width: 300px) and (max-width: 500px) {
    #filter-newVisaTrave-container{
      width: 80%;
      /* border:2px solid black; */
      background-color: transparent;
      height: auto;
      bottom: 80%;
    }
    .selectnewhomepagedate {
      margin-top: -8%;
      margin-left: 3%;
    }
    #selectnewhomepageReturndate1 {
      margin-top: -8%;
      margin-left: 4%;
    }
    #dateonewaynewhomebannerform::placeholder {
      margin-top: -2% !important;
      font-size: 13px;
      border: none;
    }
  
    .carouselnewVisabannermenu {
      display: none;
    }
    .fromnewhomebanner-name {
      margin-top: -2%;
      line-height: -20px;
      /* border: 1px solid green; */
    }
  
    .fromnewhomebanner-name2 {
      margin-top: -6%;
      line-height: -20px;
      /* border: 2px solid red; */
    }
    .citynewhomebannerinput::placeholder {
      font-size: 15px;
      border: none;
    }
  
    .dropdownnewhomepage-content {
      margin-top: 13%;
    }
  
    .searchbtn {
      margin-top: 20%;
      width: 100%;
      padding: 10px;
      font-size: 12px;
    }
  
    #formnewhomepagedates {
      display: flex;
      flex-direction: row;
      /* border: 1px solid; */
    }
    .filter-newVisaTrave-container {
      flex-direction: column;
      border: none;
    }
    .filter-newVisaTrave-container {
      flex-direction: column;
    }
    .filternewhomebanner-fromTo {
      margin: auto;
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      /* border:1px solid blueviolet; */
      width: 100%;
    }
    #formnewhomepagedates {
      margin: auto;
      margin-top: 25px;
    }
    #newhomepagetrave {
      margin: auto;
      margin-right: 14%;
      margin-top: 3%;
      flex-direction: row;
      font-size: 13px;
    }
    #newhomepage_departure {
      margin-top: 1%;
    }
    .newVisainputmaindiv{
      display: flex;
      flex-direction: column;
    }
    .optionsNewVisaBannercontainer{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .singleNewVisaBannerdropcontainer{
      /* border: 2px solid gold; */
      width: 100%;
    }
    .singleNewVisaBannerdropcontainer>p{
      color: white;
    }
    .singleNewVisaBannerdropcontainer1{
      /* border: 2px solid gold; */
      width: 100%;
    }
    .singleNewVisaBannerdropcontainer1>p{
      color: white;
      margin-top: 7%;
    }
    .singleNewVisaBannerdropcontainer2{
      /* border: 2px solid gold; */
      width: 100%;
    }
    .singleNewVisaBannerdropcontainer2>p{
      color: white;
      margin-top: 7%;
    }
    .innerNewVisadropdowncontainer2{
      width: 100%;
      /* border:2px solid red; */
    }
    .newVisa_SearchButton {
      /* border: 1px solid red; */
      width: 50%;
      margin: auto;
      margin-top: 5%;
    }
    
    .newVisa_SearchButton button {
      width: 100%;
      height: 45px;
      margin: auto;
      padding-left: 15px;
      padding-top: 5px;
    }
  }
  