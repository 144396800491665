.rideWithsMainOterDiv {
  border: 1px solid white;
  width: 90%;
  margin: auto;
}

.rideWithHEadingsDiv {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.rideWithHEadingsDiv h3 {
  font-weight: 600;
  vertical-align: baseline;
  font-size: 30px;
  width: 50%;
  color: #3380f2;
}

.rideWithSelectDiv {
  margin-left: 38%;
  border: 1px solid #3380f2;
  border-radius: 7px;
  width: 11.5%;
}

.rideWithSelectDiv select {
  color: #3380f2;
  box-shadow: none;
  background-image: cross-fade(0.5);
}

.rideWithCardsOuterDiv {
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  column-gap: 45px;
}

.rideWithInnerImageDiv {
  border: none;
  height: 320px !important;
  margin-top: 1%;
  margin-left: 1%;
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.carImage {
  width: 88%;
  object-fit: cover;
  margin-top: 12%;
  margin-left: 2%;
}

.autoImage {
  width: 80%;
  object-fit: cover;
  margin-top: 11%;
  margin-left: 2%;
}

.scooterImage {
  width: 70% !important;
  object-fit: cover;
  margin-top: 12%;
  margin-left: 18%;
}

.busImage {
  width: 70% !important;
  object-fit: cover;
  margin-top: 3%;
  margin-left: 19%;
}

.offeredServicesCarContentFiv {
  position: absolute;
  width: 100%;
  top: 67%;
}

.offeredServicesCarContentFiv h5 {
  font-weight: 600;
  font-size: 25px;
  vertical-align: baseline;
  line-height: 20px;
  text-align: center;
  margin-top: 3%;
}

.offeredServicesCarContentFiv p {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  vertical-align: baseline;
  color: #393939;
  margin-top: 4%;
  margin-bottom: 3%;
}

.rideWithInnerImageDiv1 {
  border: none;
  height: 250px;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  width: 25%;
  cursor: pointer;
}

.rideWithInnerImageDiv1 img {
  height: 250px;
  object-fit: cover;
}

/* this is the css code file for the responsive of the comps for the multiple devices */

@media (max-width: 480px) {
  .rideWithsMainOterDiv {
    border: 1px solid white;
    width: 90%;
    margin: auto;
  }

  .rideWithHEadingsDiv {
    width: 100%;
    margin-top: 3%;
  }

  .rideWithHEadingsDiv h3 {
    font-weight: 600;
    vertical-align: baseline;
    font-size: 30px;
    width: 100%;
    color: black;
    text-align: center;
  }

  .rideWithSelectDiv {
    margin-left: 38%;
    border: 1px solid #3380f2;
    border-radius: 7px;
    width: 11.5%;
  }

  .rideWithSelectDiv select {
    color: #3380f2;
    box-shadow: none;
    background-image: cross-fade(0.5);
  }

  .rideWithCardsOuterDiv {
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
  }

  .rideWithInnerImageDiv {
    border: none;
    height: 170px !important;
    margin-top: 1%;
    margin-left: 1%;
    width: 100%;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .carImage {
    width: 88%;
    object-fit: cover;
    margin-top: 12%;
    margin-left: 2%;
  }

  .autoImage {
    width: 80%;
    object-fit: cover;
    margin-top: 11%;
    margin-left: 2%;
  }

  .scooterImage {
    width: 70% !important;
    object-fit: cover;
    margin-top: 12%;
    margin-left: 18%;
  }

  .busImage {
    width: 70% !important;
    object-fit: cover;
    margin-top: 3%;
    margin-left: 19%;
  }

  .offeredServicesCarContentFiv {
    /* border: 1px solid red; */
    position: absolute;
    width: 100%;
    top: 32%;
  }

  .offeredServicesCarContentFiv h5 {
    font-weight: 600;
    font-size: 25px;
    vertical-align: baseline;
    line-height: 20px;
    text-align: center;
    margin-top: 3%;
  }

  .offeredServicesCarContentFiv p {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    vertical-align: baseline;
    color: #393939;
    margin-top: 4%;
    margin-bottom: 3%;
  }

  .rideWithInnerImageDiv1 {
    /* border: 1px solid rebeccapurple; */
    /* border: none; */
    height: 250px;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    width: 25%;
    cursor: pointer;
  }

  .rideWithInnerImageDiv1 img {
    height: 250px;
    object-fit: cover;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
