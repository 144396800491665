.handpickednewhomepageoutercontainer {
  height: auto;
  /* border: 2px solid red; */
}

.newHomeHandpickedHeadingContainer {
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
}

.handpickednewhomepagelink {
  text-decoration: none;
  height: 430px;
}

.handpickednewhomepageheading {
  font-size: 30px;
  font-weight: 700;
  width: 30%;
  margin-left: 5.5%;
  margin-top: 2.1%;
  font-weight: 700;
  margin-bottom: 2%;
  color: #3380f2;
}

.handmadesicon {
  width: 10%;
  color: #3380f2 !important;
  border: 3px solid #3380f2;
  background-color: #3380f2 !important;
  height: 43px;
  font-size: 18px !important;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
}

.newHandPickedCountryDrop {
  /* margin-top: 17% !important; */
  margin-left: 20%;
  color: white !important;
  font-weight: 700;
  cursor: pointer;
  height: 43px !important;
  border-radius: 20px 0px 0px 20px !important;
  background-color: #3380f2 !important;
  border-right: none !important;
  border: 2px solid red !important;
  width: 52% !important;
}

.newHandPickedCountryDrop:hover {
  background-color: white !important;
  color: #3380f2 !important;
  font-weight: 700;
}

.handpickednewhomepagesliderbuttons {
  color: white;
  border-radius: 20px;
  display: flex;
  margin: 1% 6% 1% 52%;
  width: 5%;
}

.handpickednewhomepagebuttons {
  justify-content: flex-end;
  width: -1%;
}

.handpickednewhomepageheadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #3380f2 !important;
  height: 75px;
  margin-top: 2%;
}

.handpickednewhomepagecardmaindiv {
  width: 90%;
  margin: auto;
}

.handpickednewhomepagecardwrapper {
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.handpickedcard {
  width: 100%;
  height: 350px;
  /* border: 2px solid black; */
}

.handpickedcard:hover .handpickedcard-img-top {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.handpickednewhomepage_title1 {
  /* border: 2px solid red; */
  position: absolute;
  top: 80%;
  left: 7%;
  height: 70px;
  width: 100%;
}

.handpickednewhomepagecard-title {
  color: white;
  font-size: 20px;
  margin-top: 1%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  z-index: 2;
  position: absolute;
}

.handpickednewhomepagebottomheading {
  top: 65%;
  background-color: white;
  border-radius: 12px;
  left: 10%;
  width: 35%;
  height: 26px;
}

.handpickednewhomepagebottomheading h1 {
  color: #3380f2;
  font-size: 18px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 9px;
}

.handpickednewhomepagecard_img {
  margin-left: 0% !important;
  height: 350px !important;
  width: 100% !important;
  border-radius: 20px !important;
  overflow: hidden !important;
  margin-top: 3%;
}

.handpickednewhomepagecard_img {
  margin-left: 3%;
  width: 100%;
  border-radius: 20px !important;
  overflow: hidden !important;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.testhover > img {
  width: 100%;
  height: 100%;

  border-radius: 25px;
}

.handpickednewhomepagecard_img:hover .testhover > img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.testhover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border-radius: 20px !important;
  z-index: -1;
  background-color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
}

.testhover img {
  width: 100%;
  filter: brightness(0.8);
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  position: relative;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background-color: darkblue !important;
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.handpickednewhomepagecardwrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  background: rgb(51, 128, 242);
  border: 1px solid rgb(51, 128, 242);
  border-radius: 20px;
}

.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: 0.5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}

.carousel .slick-prev:before,
.carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.carousel .slick-next {
  right: -55px;
}

.carousel .slick-prev {
  left: -55px;
}

/* ----------------- New button css ----------------  */

#newHomehandpickedDropContainer {
  width: 8%;
  margin-left: 50%;
  margin-top: 2.1%;
  display: flex;
  flex-direction: row;
  border-right: none;
  height: 39px;
  border-radius: 25px;
  align-items: center;
  color: white;
  font-weight: 700;
  justify-content: space-between;
  /* border: 2px solid #3380F2; */
  border: none;
  outline: none;
  /* padding-left: 15px; */
  background-color: #3380f2;
  /* border: 2px solid green; */
}

#newHomeHandpickedCountryDrop {
  background-color: transparent;
  width: 80%;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  border-radius: 25px 0px 0px 25px;
  border-right: none;
  appearance: none;
  border: none;
  /* border: 1px solid red; */
  outline: none;
  padding-left: 15px;
  margin-left: 1%;
}

#newHomehandpickedicondiv {
  width: 20%;
  /* color: white; */
  background-color: transparent;
  height: 100%;
  border-radius: 0px 25px 25px 0px;
  padding-right: 5%;
  border-left: none;
  /* border: 2px solid yellow; */
}

#handpickedicon {
  margin-left: -37%;
  /* color: white; */
  height: 21px;
  width: 20px;
  border-radius: 100%;
  margin-top: 47%;
}

@media (max-width: 480px) {
  .newHomeHandpickedHeadingContainer {
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
  }

  .handpickednewhomepageheading {
    /* border: 1px solid red; */
    font-size: 20px; 
    font-weight: 700;
    width: 90%;
    margin: auto;
    margin-top: 8%;
    text-align: center;
    color: #393939;
  }

  #newHomehandpickedDropContainer {
    width: 30%;
    margin-left: 10%;
    margin-top: 2.1%;
    display: none;
    flex-direction: row;
    border-right: none;
    height: 39px;
    border-radius: 25px;
    align-items: center;
    color: white;
    font-weight: 700;
    justify-content: space-between;
    border: none;
    outline: none;
    /* padding-left: 15px; */
    background-color: #3380f2;
    border: 2px solid red;
  }

  #newHomeHandpickedCountryDrop {
    background-color: transparent;
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    border-radius: 25px 0px 0px 25px;
    border-right: none;
    appearance: none;
    border: none;
    border: 1px solid red;
    outline: none;
    padding-left: 15px;
  }

  #newHomehandpickedicondiv {
    width: 20%;
    /* color: white; */
    background-color: transparent;
    height: 100%;
    border-radius: 0px 25px 25px 0px;
    padding-right: 5%;
    border-left: none;
    /* border: 2px solid yellow; */
  }

  #handpickedicon {
    margin-left: -37%;
    /* color: white; */
    height: 21px;
    width: 20px;
    border-radius: 100%;
    margin-top: 47%;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
