.Flightgraphmaindiv{
    /* border: 2px solid aqua; */
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    /* padding-top: 3%; */
    margin-top: 4%;

}
.Flightgraphmainheading h5{
    font-size: 25px;
    font-weight: 700;
    /* padding-left: 5%; */

}

.flightestimatepricediv{
    border:  1px solid gray;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    padding-top: 1%;
    border-radius: 5px;
    margin-right: 6%;

}

.flightestimatepricediv{
    font-size: 14px;
    font-weight: bold;
}
.tripdiv{
    background-color: rgb(198, 224, 249);
    /* font-weight: bold; */
    margin-left: 1%;
    text-align: center;
    /* border: 2px solid yellow; */
    margin-bottom: 1%;

}
.resetdiv{
    font-size: 17px;
    font-weight: 600;
    /* padding-left: 55%; */
    /* margin-bottom: 2px; */
    /* border: 2px solid yellow; */
    /* padding-top: 1%; */
    /* width: 10%; */
    margin-left: 58%;
    
}

.searchdiv{
    background-color: rgb(81, 81, 244);
    width: 100%;
    height: 40px;
    /* margin-left: 10%; */
    border: none;
    /* margin-bottom: 2px; */
    color: white;
    /* border: 2px solid yellow; */
    /* padding: 2%; */
    border-radius: 5%;
    font-size: 16px;

    font-weight: 500;
}
.searchbuttondiv{
    /* border: 2px solid green; */
    width: 8%;
    /* margin-bottom: 1%; */
    margin-left: 2%;
    margin-top: -0.5%;
}



.Flightgraphimagediv1{
    /* border: 2px solid pink; */
    display: flex;
    flex-direction: row;
}

    

.cheapFlightsdiv h3{
    font-size: 25px;
    font-weight: 700;
    margin-top: 3%;
}
.cheapFlightsdiv ul li{
    padding: 1%;
}