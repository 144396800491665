.newVisaSteps{
    /* border: 1px solid red; */
    height: 250px;
    margin-top: 1%;
}

.newVisaSteps h5{
    color: #3380f2;
    font-weight: 700;
    margin-left: 5%;
    font-size: 28px;
    margin-top: 2%;
}

.newVisaSteps img{
    object-fit: cover;
    width: 80%;
    height: 200px;
    margin-left: 10%;
    margin-top: 2%;
    /* border: 1px solid red; */
}

@media screen and (min-width: 300px) and (max-width: 500px) {
    .newVisaSteps{
        display: none;
    }
}