.visaslidermaindiv{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0.5%;
}
.visasliderouterdiv {
    /* border: 2px solid red !important; */
    width: 90%;
    margin: auto;
  }
  .visaslidercardwrapper {
    /* border: 1px solid green; */
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .visaslidercard_img{
    /* border: 3px solid greenyellow; */
    width: 100%;
    height:300px;
  }
  .visaslidercard_img :hover{
    width: 110%;
    height: 500px;
    overflow: hidden;
  }
  .visaslidercard_img img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px;
    /* border: 3px solid saddlebrown; */
  }