.newActivityAroundmaindiv {
  width: 100%;
  height: 620px;
}

.newActivityAroundouterdiv {
  border: 2px solid lightgray;
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  height: 430px !important;
  border: none;
  transition: all 300ms ease-in-out;
}

.newActivityAroundouterdiv:hover {
  width: 100%;
  padding: 5px;
}

.newActivityAroundHeadingcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  margin-top: 3%;
}

.newActivityAroundHeading1 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 2%;
  margin-left: 5%;
  width: 65%;
  color: #3380f2;
}

.newviewallActivityAroundbuttons {
  color: white;
  font-weight: 700;
  text-decoration: none !important;
  display: flex;
  margin: 1.5% 0 0 55%;
  width: 56%;
}

.newActivityAroundSliderContainer {
  width: 90%;
  margin: auto;
  margin-top: 1.7%;
}

.newActivityAroundCardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.newActivityAroundCard_desc {
  width: 90%;
  color: #3380f2;
  font-size: 14px;
  text-align: left;
}

.newActivityAroundImagetextcontainer {
  width: 100%;
  height: 430px;
  border: 1px solid lightgray;
}

.newActivityAroundTextcard {
  margin-left: 9% !important;
  width: 100% !important;
}

.newActivityAroundTextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  width: 800px;
  height: 200px !important;
}

.exclusivedealsimage {
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.newActivityAroundCardimage {
  width: 100% !important;
  height: 230px;
}

.activityAroundimage {
  height: 240px !important;
  width: 100% !important;
  border-radius: 10px 10px 0px 0px;
}

.newActivityAroundCarddescmain {
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

.newviewallActivityAroundtext {
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 34%;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-top: 4px;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.newviewallActivityAroundtext:hover {
  background-color: white;
  color: #3380f2;
}

.newActivityAroundHeadingbuttons {
  justify-content: flex-end;
  margin-left: 6%;
  margin-top: -1%;
  background-color: #3380f2 !important;
}

.newActivityAroundHeadingbuttons button {
  color: #3380f2 !important;
  background-color: white !important;
}

.line-indicator li {
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}

.buttons {
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
}

.buttons:hover {
  transform: scale(1.1);
}

.newActivityAroundButton {
  border: 3px solid #3380f2;
  width: 34%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 9px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: 3%;
  font-size: 15px !important;
}

.newActivityAroundButton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}

/* this is the css file is for the comps responsive for the multiple devices */

@media (max-width: 480px) {
  .newActivityAroundmaindiv {
    width: 100%;
    height: 620px;
  }

  .newActivityAroundouterdiv {
    border: 2px solid lightgray;
    display: flex !important;
    flex-direction: row !important;
    width: 96%;
    height: 430px !important;
    border: none;
    transition: all 300ms ease-in-out;
  }

  .newActivityAroundouterdiv:hover {
    width: 100%;
    padding: 5px;
  }

  .newActivityAroundHeadingcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    margin-top: 3%;
  }

  .newActivityAroundHeading1 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 2%;
    margin-left: 5%;
    width: 100%;
    color: black;
    text-align: center;
  }

  .newviewallActivityAroundbuttons {
    color: white;
    font-weight: 700;
    text-decoration: none !important;
    display: flex;
    margin: 1.5% 0 0 55%;
    width: 56%;
  }

  .newActivityAroundSliderContainer {
    width: 90%;
    margin: auto;
    margin-top: 1.7%;
  }

  .newActivityAroundCardtitle {
    color: #3380f2 !important;
    font-size: 22px;
    margin-top: 1%;
  }

  .newActivityAroundCard_desc {
    width: 90%;
    color: #3380f2;
    font-size: 14px;
    text-align: left;
  }

  .newActivityAroundImagetextcontainer {
    width: 100%;
    height: 430px;
    border: 1px solid lightgray;
  }

  .newActivityAroundTextcard {
    margin-left: 9% !important;
    width: 100% !important;
  }

  .newActivityAroundTextcard:hover div {
    color: white;
  }

  .exclusivedealsimagediv {
    width: 800px;
    height: 200px !important;
  }

  .exclusivedealsimage {
    width: 700px;
    height: 10px;
    margin-left: 40%;
  }

  .newActivityAroundCardimage {
    width: 100% !important;
    height: 230px;
  }

  .activityAroundimage {
    height: 240px !important;
    width: 100% !important;
    border-radius: 10px 10px 0px 0px;
  }

  .newActivityAroundCarddescmain {
    width: 100%;
    margin-left: -9%;
  }

  .exclusivecarddesc:hover {
    color: white;
  }

  .newviewallActivityAroundtext {
    margin-top: 0.5%;
    border: 2px solid white;
    border-radius: 20px;
    width: 34%;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    padding-left: 16px;
    padding-top: 4px;
    margin-bottom: 8%;
    text-decoration: none !important;
  }

  .newviewallActivityAroundtext:hover {
    background-color: white;
    color: #3380f2;
  }

  .newActivityAroundHeadingbuttons {
    justify-content: flex-end;
    margin-left: 6%;
    margin-top: -1%;
    background-color: #3380f2 !important;
  }

  .newActivityAroundHeadingbuttons button {
    color: #3380f2 !important;
    background-color: white !important;
  }

  .line-indicator li {
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }

  .buttons {
    background: rgb(51, 128, 242) !important;
    color: white !important;
    transition: 700ms;
  }

  .buttons:hover {
    transform: scale(1.1);
  }

  .newActivityAroundButton {
    border: 3px solid #3380f2;
    width: 34%;
    border-radius: 15px !important;
    background-color: white;
    color: #3380f2;
    padding: 4px 9px;
    font-weight: 500;
    margin-bottom: 1%;
    margin-top: 3%;
    font-size: 15px !important;
  }

  .newActivityAroundButton:hover {
    border: 3px solid white;
    background-color: #3380f2;
    color: white;
    font-size: 14px !important;
  }
}

/* @media (min-width: 481px) and (max-width: 1024px) {
} */
