.facilitiesMainDiv{
    width: 90%;
    margin: auto;
}

.facilitiesMainDiv h2{
    font-size: 30px;
}

.amenitiesOuterDiv{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row;
}

.amenitiesInnnerDiv1{
    /* border: 1px solid red; */
    width: 50%;
}

.amenitiesInnnerDiv2{
    /* border: 1px solid black; */
    width: 50%;
}

.amenitiesInnerSubDiv{
    display: flex;
    flex-direction: row;
}

.facilityIconsDiv{
    margin-right: 2%;
    font-size: 22px;
    margin-top: 0.5%;
}

.amenitiesInnerSubDiv p{
   margin-top: 1.5%;
   font-size: 17px;
   color: #393939 !important;
}

.dropButtonAMenities{
    margin-left: 50%;
    margin-top: 1%;
    font-size: 19px;
    cursor: pointer;
    color: #3380f2;
    margin-bottom: 3%;
}

.dropButtonAMenities :hover{
    color: #3380f2 !important;
}








