.InnerHandpicked1NavbarMainPageContainer{
    text-align: center;
    margin-left: 6%;
    margin-right:6%;
}

.InnerHandpicked1NavbarMainPageContainer2{
    text-align:center;
    margin-left: 12%;
   
    /* background-color:blue; */
    width:65%;

}
.InnerHandpicked1NavbarlistItems{
    margin-left:9%;
    font-style:bold;
    font-weight: 400;
    color:white;
}
