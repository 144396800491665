.bestbudgetmaindiv{
  /* border: 1px solid red; */
  width: 100%;
}

.bestbudgetouterdiv {
  /* border: 2px solid red !important; */
  height: 460px !important;
  /* border: 2px solid lightgray; */
  display: flex !important;
  flex-direction: row !important;
  width: 96%;
  /* border: none; */
}
.bestbudgetheadingcontainer {

  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #3380f2;
  height: 80px;
  margin: auto;
  /* margin-top: 2%; */
  /* border: 1px solid black; */
}

.bestbudgetheading1 {
  /* border: 1px solid black; */
  font-size: 25px;
  font-weight: 600;
  width:30%;
  margin: auto;
  margin-left: 5.5% !important;
  color: white;
}

/* .viewallbestbudgettext{

} */
.experimentheading2 {
  /* border: 1px solid blue; */
  width: 60%;
  height: 40px;
  justify-content: center;
  margin: -0.5% 0 1% 1%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  /* padding: 0px 0px; */
}
.experimentheading {
  border-radius: 50px;
}

.experimentheading2 div {
  border: 3px solid rgb(51, 128, 242);
  width: 100px !important;
  height: 33px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5%;
  margin-right: 4%;
  border-radius: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #3380f2;
}

.experimentheading2 div:hover {
  background-color: #3380f2;
  color: white;
}
.viewallbestbudgetbuttons {
  color: white;
  font-weight: 700;
  /* text-decoration: underline; */
  /* border: 1px solid red; */
  display: flex;
  margin: 1.5% 2% 0 5%;
  width: 20%;
  margin-left: 15% !important;
}

.viewallbestbudgettext{
  margin-top: 0.5%;
  border: 2px solid white;
  border-radius: 20px;
  width: 37%;
  font-size: 15px;
  font-weight: 700;
  height: 35px;
  padding-left: 12px;
  padding-top: 3px;
  margin-bottom: 8%;
  text-decoration: none !important;
}

.viewallbestbudgettext:hover{
  background-color: white;
  color: #3380f2;
  padding-top: 1%;
}
.bestbudgetslidercontainer{
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 2%;
}

.bestbudgetcardtitle {
  color: #3380f2 !important;
  font-size: 22px;
  margin-top: 1%;
}

.bestbudgetcard_desc {
  /* border: 1px solid red; */
  width: 90%;
  color: #3380f2;
  font-size: 13px;
  text-align: left;
}

.bestbudgetimagetextcontainer {
  width: 100%;
  height: 440px !important;
}

.bestbudgettextcard {
  margin-left: 9% !important;
  width: 100% !important;
}
/* 
  .bestbudgettextcard:hover{
    color: white;
    background-color: #3380F2;
  } */

.bestbudgettextcard:hover div {
  color: white;
}

.exclusivedealsimagediv {
  /* border: 2px solid red !important; */
  width: 800px;
  height: 200px !important;
}
.exclusivedealsimage {
  /* border: 1px solid black !important; */
  width: 700px;
  height: 10px;
  margin-left: 40%;
}

.bestbudgetcardimage {
  /* border: 1px solid red !important; */
  /* height: 300px; */
  border: none;
  width: 100% !important;
}

.bestbudgetcardimage img{
  height: 200px;
  width: 100%;
}

.bestbudgetcarddescmain {
  /* border: 1px solid red; */
  width: 100%;
  margin-left: -9%;
}

.exclusivecarddesc:hover {
  color: white;
}

/* .experimentheading2 div:hover{
    color: white;
    background-color: #3380F2;
  } */

/* .experimentheading2 p{
    width: 300px;
    color: #3380F2;
    font-weight: 500;
  } */
.bestbudgetheadingbuttons {
  /* border: 1px solid yellow !important; */
  justify-content: flex-end;
  /* width: -1%; */
  margin-left: 6%;
  margin-top: -2%;
}

.line-indicator li {
  /* border: 2px solid; */
  height: 4px;
  width: 70px;
  background: lightgray;
  border-radius: 5px;
}

.line-indicator li:hover {
  background: gray;
}

.line-indicator li.slick-active {
  background: blue;
}
.buttons {
  /* background: orangered !important; */
  background: rgb(51, 128, 242) !important;
  color: white !important;
  transition: 700ms;
  border: 3px solid red;
}
.buttons:hover {
  /* background: white !important; */
  /* color: orangered !important; */
  transform: scale(1.1);
}
.bestbudgetbutton {
  border: 3px solid #3380f2;
  width: 34%;
  border-radius: 15px !important;
  background-color: white;
  color: #3380f2;
  padding: 4px 12px;
  font-weight: 500;
  margin-bottom: 1%;
  margin-top: 4%;
  font-size: 15px !important;
}
.bestbudgetbutton:hover {
  border: 3px solid white;
  background-color: #3380f2;
  color: white;
  font-size: 14px !important;
}
/* .carousel-control-prev{
      border: 1px solid red;
  
  } */

/* .main_container{
      border: 1px solid red;
      height: 500px;
  }
  
  .line-indicator li {
      height: 4px;
      width: 70px;
      background: lightgray;
      border-radius: 5px;
    }
    
    .line-indicator li:hover {
      background: gray;
    }
    
    .line-indicator li.slick-active {
      background: blue;
    }
    .buttons {
      background: orangered !important;
      color: white !important;
      transition: 700ms;
    }
    .buttons:hover {
      transform: scale(1.1);
    } */

/* .partners_title{
      font-size: 35px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10%;
    margin-bottom: 4%;
    text-decoration: none solid rgb(154, 148, 148);
    vertical-align: baseline;
    letter-spacing: normal;
    text-align: center;
    color: #3380f2;
  }
  
  ._carouseldiv1{
     position: relative;
  }
  ._imagediv{
      height:100%;
      width:100%;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  ._hoverdiv{
      border:1px solid green;
      position: absolute;
      top:0px;
      width:100%;
      height:85%;
      background-color: yellow;
      visibility: hidden;
  }
  ._carouseldiv1 > div:hover{
      visibility: visible;
  }
  ._staticdiv{
      border:1px solid darkblue;
      position: absolute;
      bottom:0px;
      width:100%;
      height:14.3%;
      background-color:#203e50; 
      text-align: center;
      box-sizing: border-box;
      font-size: larger;
      padding-top: 4%;
  }
  
     */

@media screen and (max-width: 950px) {
  .bestbudgetimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 10%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewallbestbudgetbuttons {
    display: none;
  }
  .bestbudgetheading1 {
    display: none;
  }
  .bestbudgetheadingcontainer {
    flex-direction: column;
  }
  .experimentheading2 {
    width: 100%;
  }
  .bestbudgetimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .bestbudgettextcard {
    margin-left: 8px;
  }
}

@media screen and (max-width: 450px) {
  .bestbudgetimagetextcontainer {
    flex-direction: column;
  }
  .col-md-4 {
    width: 90%;
    height: 280px;
    margin-left: 3%;
    margin-top: 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .card-text {
    font-size: 13px;
  }
  .viewallbestbudgetbuttons {
    display: inline-block;
    vertical-align: top;
    margin-top: -50px;
    text-align: right;
    width: 40%;
    font-size: 14px;
    margin-left: 52%;
  }
  .experimentheading2 {
    display: none;
  }
  .bestbudgetheadingbuttons {
    display: none;
  }
  .bestbudgetheading1 {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    font-size: 16px;
    margin-left: 3%;
  }
  .bestbudgetheadingcontainer {
    flex-direction: column;
  }
  .bestbudgetimagetextcontainer img {
    width: 100%;
    height: 100%;
  }
  .exclusivedealsimage {
    width: 100%;
    height: 100%;
  }
  .bestbudgettextcard {
    margin-left: 8px;
  }
}
